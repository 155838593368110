// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// $FlowIgnore
import Routes from 'Routes';
import withClearCache from 'enhancers/withClearCache';

const mapStateToProps = (state) => ({
  auth: state.auth.get('data'),
  user: state.user.get('data'),
  userLoading: state.user.get('loading'),
});

type RootProps = {|
  auth: Object,
  user: Object,
  userLoading: boolean,
|};
const Root = (props: RootProps) => {
  const { auth, user, userLoading } = props;
  const childProps = { auth, user, userLoading };
  return (
    <div className="application">
      <Routes childProps={childProps} />
    </div>
  );
};

// $FlowIgnore
export default compose(
  connect(mapStateToProps),
  withClearCache,
)(Root);
