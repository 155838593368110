import client from 'api/apollo';
import { logError } from 'helpers';
import { LIST_NOMINATIONS } from 'graphql/queries/nominations/listNominations';
import { mergeEventsSteps } from 'constants/mergeEvent';
import moment from 'moment';
import { listPaginatedEntryPool } from './entryPool';

export const MERGE_EVENT_ASYNC_START = 'MERGE_EVENT_ASYNC_START';
export const MERGE_EVENT_ASYNC_FAIL = 'MERGE_EVENT_ASYNC_FAIL';
export const MERGE_EVENT_ASYNC_SUCCESS = 'MERGE_EVENT_ASYNC_SUCCESS';
export const MERGE_EVENT_CLEAR = 'MERGE_EVENT_CLEAR';
export const MERGE_EVENT_SET_BAD_EVENT_NOMINATIONS =
  'MERGE_EVENT_SET_BAD_EVENT_NOMINATIONS';
export const MERGE_EVENT_SET_EVENT_INFO = 'MERGE_EVENT_SET_EVENT_INFO';
export const MERGE_EVENT_SET_NEXT_STEPS = 'MERGE_EVENT_SET_NEXT_STEPS';
export const MERGE_EVENTS_SET_GOOD_PERFORMANCES =
  'MERGE_EVENTS_SET_GOOD_PERFORMANCES';
export const MERGE_EVENTS_SET_FORM_INFO = 'MERGE_EVENTS_SET_FORM_INFO';
export const MERGE_EVENTS_NEXT_STEP = 'MERGE_EVENTS_NEXT_STEP';
export const MERGE_EVENTS_PREVIOUS_STEP = 'MERGE_EVENTS_PREVIOUS_STEP';
export const MERGE_EVENTS_SET_NOMINATIONS_DATA_TO_SEND =
  'MERGE_EVENTS_SET_NOMINATIONS_DATA_TO_SEND';
export const MERGE_EVENT_SET_BAD_EVENT_ENTRIES =
  'MERGE_EVENT_SET_BAD_EVENT_ENTRIES';
export const MERGE_EVENTS_SET_ENTRIES_DATA_TO_SEND =
  'MERGE_EVENTS_SET_ENTRIES_DATA_TO_SEND';
export const MERGE_EVENTS_SET_MISSING_DISCIPLINES =
  'MERGE_EVENTS_SET_MISSING_DISCIPLINES';
export const MERGE_EVENTS_CLEAN_MISSING_DISCIPLINES =
  'MERGE_EVENTS_CLEAN_MISSING_DISCIPLINES';

export function mergeEventAsyncStart() {
  return {
    type: MERGE_EVENT_ASYNC_START,
  };
}

export function mergeEventAsyncFail(errorMessage) {
  return {
    type: MERGE_EVENT_ASYNC_FAIL,
    errorMessage,
  };
}

export function mergeEventAsyncSuccess(data) {
  return {
    type: MERGE_EVENT_ASYNC_SUCCESS,
    data,
  };
}

export function mergeEventClear() {
  return {
    type: MERGE_EVENT_CLEAR,
  };
}

export function mergeEventSetEventInfo(data) {
  return {
    type: MERGE_EVENT_SET_EVENT_INFO,
    data,
  };
}

export function mergeEventSetBadEventNominations(data) {
  return {
    type: MERGE_EVENT_SET_BAD_EVENT_NOMINATIONS,
    data,
  };
}

export function mergeEventSetBadEventEntries(data) {
  return {
    type: MERGE_EVENT_SET_BAD_EVENT_ENTRIES,
    data,
  };
}

export function mergeEventsSetGoodPerformances(data) {
  return {
    type: MERGE_EVENTS_SET_GOOD_PERFORMANCES,
    data,
  };
}

export function mergeEventsSetFormInfo(data) {
  return {
    type: MERGE_EVENTS_SET_FORM_INFO,
    data,
  };
}

export function mergeEventsNextStep() {
  return {
    type: MERGE_EVENTS_NEXT_STEP,
  };
}

export function mergeEventsPreviousStep() {
  return {
    type: MERGE_EVENTS_PREVIOUS_STEP,
  };
}

export function mergeEventsSetNominationsDataToSend() {
  return {
    type: MERGE_EVENTS_SET_NOMINATIONS_DATA_TO_SEND,
  };
}

export function mergeEventsSetEntriesDataToSend(data) {
  return {
    type: MERGE_EVENTS_SET_ENTRIES_DATA_TO_SEND,
    data,
  };
}

export function mergeEventsCleanMissingDisciplines() {
  return {
    type: MERGE_EVENTS_CLEAN_MISSING_DISCIPLINES,
  };
}

export function mergeEventsSetMissingDisciplines(data) {
  return {
    type: MERGE_EVENTS_SET_MISSING_DISCIPLINES,
    data,
  };
}

export function getMergeEventNextStep(eventInfo) {
  const nexSteps = [];
  mergeEventsSteps.forEach(({ key, condition }) => {
    const isValid = condition(eventInfo);
    if (isValid) {
      nexSteps.push(key);
    }
  });
  return nexSteps;
}

export function mergeEventSetNextSteps(data) {
  return {
    type: MERGE_EVENT_SET_NEXT_STEPS,
    data,
  };
}

const convertPerformances = (performances) => {
  const disciplineByPerformance = {};
  performances.forEach((performance) => {
    const {
      PerformanceUID,
      Disciplines,
      PerformanceDateWithTimezone,
    } = performance;
    const performanceInfo = {
      PerformanceUID,
      id: PerformanceUID,
      PerformanceDateWithTimezone,
      label: moment(PerformanceDateWithTimezone).format('MM/DD/YYYY @ HH:mm A'),
    };
    Disciplines.forEach((discipline) => {
      const {
        id: DisciplineUID,
        EventEntryDisciplineFeeUID,
        name,
        ClassName,
      } = discipline;
      const disciplineInfo = {
        EventEntryDisciplineFeeUID,
        name,
        id: EventEntryDisciplineFeeUID,
        label: ClassName,
        ClassName,
      };
      if (!disciplineByPerformance[DisciplineUID]) {
        // no discipline
        disciplineByPerformance[DisciplineUID] = [
          {
            performances: [
              {
                ...performanceInfo,
              },
            ],
            ...disciplineInfo,
          },
        ];
      } else {
        // has discipline
        const hasDisciplineClassName = disciplineByPerformance[
          DisciplineUID
        ].find(
          (item) =>
            item.EventEntryDisciplineFeeUID === EventEntryDisciplineFeeUID,
        );
        if (hasDisciplineClassName) {
          // has discipline and class name
          hasDisciplineClassName.performances.push({
            ...performanceInfo,
          });
        } else {
          // has discipline but no class name
          disciplineByPerformance[DisciplineUID].push({
            performances: [
              {
                ...performanceInfo,
              },
            ],
            ...disciplineInfo,
          });
        }
      }
    });
  });
  return disciplineByPerformance;
};

const validateNominationsDisciplines = (
  badEventNominations,
  goodEventPerformances,
  eventsInfo,
) => {
  const { goodEventName } = eventsInfo;
  const missingDisciplines = badEventNominations.flatMap(
    ({ CompType, DisciplineAsString }) => {
      const [DisciplineUID] = CompType;
      const hasDiscipline = goodEventPerformances[DisciplineUID];
      if (!hasDiscipline) {
        const errorMessage = `An athlete has nominated ${DisciplineAsString} and this discipline IS NOT added to ${goodEventName}. Please Update before or after merging! This nomination will be carried over to the new event.`;
        return [errorMessage];
      }
      return [];
    },
  );
  if (missingDisciplines.length > 1) {
    return [
      `Athletes have nominated disciplines that are NOT added to ${goodEventName}. Please Update the event or nomination before completing this merge.`,
    ];
  }
  return missingDisciplines;
};

const validateEntriesDisciplines = (
  badEventEntries,
  goodEventPerformances,
  eventsInfo,
) => {
  const { goodEventName } = eventsInfo;
  const missingDisciplines = badEventEntries.flatMap(
    ({ CompType, Discipline }) => {
      const hasDiscipline = goodEventPerformances[CompType];
      if (!hasDiscipline) {
        const errorMessage = `An athlete has entered ${Discipline} and this discipline IS NOT added to ${goodEventName}. Please Update before or after merging! This entry will be carried over to the new event.`;
        return [errorMessage];
      }
      return [];
    },
  );
  if (missingDisciplines.length > 1) {
    return [
      `Athletes have entered disciplines that are NOT added to ${goodEventName}. Please Update the event or entry before completing this merge.`,
    ];
  }
  return missingDisciplines;
};

export function mergeEventsValidation(eventInfo) {
  const { badEventHasNominations, badEventId, badEventHasEntries } = eventInfo;
  return async (dispatch) => {
    let missingEntries = [];
    let missingNominations = [];
    const nextSteps = getMergeEventNextStep(eventInfo);
    const convertedPerformances = convertPerformances(
      eventInfo.goodEventPerformances,
    );
    dispatch(mergeEventsSetGoodPerformances(convertedPerformances));
    dispatch(mergeEventAsyncStart());
    dispatch(mergeEventSetEventInfo(eventInfo));
    dispatch(mergeEventSetNextSteps(nextSteps));
    try {
      if (badEventHasNominations) {
        const variables = {
          input: {
            EventUID: badEventId,
          },
        };

        const nominationsQuery = {
          query: LIST_NOMINATIONS,
          variables,
          fetchPolicy: 'network-only',
        };
        const response = await client.query(nominationsQuery);
        const nominations = response.data.nominationsPayload.nominations;
        missingNominations = validateNominationsDisciplines(
          nominations,
          convertedPerformances,
          eventInfo,
        );
        await dispatch(
          mergeEventSetBadEventNominations(
            response.data.nominationsPayload.nominations,
          ),
        );
      }
      if (badEventHasEntries) {
        const payload = {
          EventUID: badEventId,
          resultsPerPage: 100,
          pageNumber: 0,
          sortDirection: 'desc',
          orderBy: 'EventUID',
        };
        const entries = await dispatch(listPaginatedEntryPool(payload));
        missingEntries = validateEntriesDisciplines(
          entries.entries,
          convertedPerformances,
          eventInfo,
        );
        await dispatch(mergeEventSetBadEventEntries(entries.entries));
      }
      const missingEntriesAndNominations = [
        ...missingEntries,
        ...missingNominations,
      ];
      dispatch(mergeEventsSetMissingDisciplines(missingEntriesAndNominations));
      await dispatch(mergeEventAsyncSuccess());
      return missingEntriesAndNominations.length === 0;
    } catch (error) {
      logError(error);
      dispatch(mergeEventAsyncFail(error.message));
      return null;
    }
  };
}
