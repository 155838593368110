import { getNestedProperty } from './getNestedProperty';
export const getToken = (authPayload) => {
  return authPayload && authPayload.token
    ? authPayload.token.accessToken
    : null;
};

export const getLocalAccessToken = () => {
  // get items from redux-persist
  const root = window.localStorage.getItem('persist:root');
  if (root && root.length) {
    const parsedRoot = JSON.parse(root);
    const auth = JSON.parse(parsedRoot.auth);
    return getNestedProperty('data.authPayload.token.accessToken', auth);
  }
  return null;
};
