// @flow
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

type LeaderboardStandingsTableProps = {|
  standings: Object,
|};

export const LeaderboardStandingsTable = (
  props: LeaderboardStandingsTableProps,
) => {
  const { standings } = props;

  return (
    <>
      {standings && standings.length > 0 ? (
        <Table aria-label="simple table" padding="none">
          <TableHead>
            <TableRow>
              <TableCell padding="none" className="linked-accounts-cell">
                DISCIPLINE
              </TableCell>
              <TableCell
                padding="none"
                align="left"
                className="linked-accounts-cell"
              >
                CLASSIFICATION
              </TableCell>
              <TableCell
                padding="none"
                align="left"
                className="linked-accounts-cell"
              >
                RANK
              </TableCell>
              <TableCell
                padding="none"
                align="left"
                className="linked-accounts-cell"
              >
                POINTS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {standings && standings.length > 0
              ? standings.map((point) => (
                  <TableRow key={point.disciplineId}>
                    <TableCell
                      padding="none"
                      component="th"
                      scope="row"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {point.discipline}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="left"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {point.classification !== null
                        ? point.classification
                        : 'N/A'}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="left"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {point.rank !== null ? point.rank : '-'}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="left"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {point.points !== null ? point.points : '-'}
                    </TableCell>
                  </TableRow>
                ))
              : ''}
          </TableBody>
        </Table>
      ) : (
        <p style={{ fontWeight: 100, paddingTop: '30px' }}>
          This athlete has not received points or nominated for an event yet.
        </p>
      )}
    </>
  );
};
