import PersonnelRole from 'models/PersonnelRole';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  PERSONNEL_ROLE_ASYNC_SUCCESS,
  PERSONNEL_ROLE_ASYNC_FAIL,
  PERSONNEL_ROLE_ASYNC_START,
} from 'actions/personnelRole';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  list: toEntityList([], PersonnelRole),
});

const actionsMap = {
  [PERSONNEL_ROLE_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [PERSONNEL_ROLE_ASYNC_SUCCESS]: (state, action) => {
    // todo change it on the integration task
    const personnelRoles = action.data;
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(personnelRoles, PersonnelRole),
    });
  },
  [PERSONNEL_ROLE_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
};

export default function personnelRoles(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
