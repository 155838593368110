const routeCodes = {
  INDEX: '/',
  LOGIN: '/login',
  COUPONS: '/coupons',
  EVENTS: '/events',
  GIFTCARDS: '/giftcards',
  EVENTS_NEW: '/event/new',
  EVENTS_EDIT: '/event/:id',
  NOMINATIONS: '/nominations',
  NOMINATIONS_NEW: '/nominations/new',
  NOMINATION_REFUND: '/nomination/:id/refund',
  ATHLETES: '/athletes',
  ATHLETES_EDIT: '/athletes/:id',
  ATHLETE_NOMINATIONS_EDIT: '/athlete/nominations/:id',
  BULK_MERGE: '/bulk-merge',
  CONTESTANTS: '/contestants',
  CONTESTANTS_MERGE: '/contestant-merge',
  DASHBOARD: '/dashboard',
  CONTRACTORS: '/contractors',
  VENUES: '/venues',
  VENUES_NEW: '/venue/new',
  LIVESTOCK: '/livestock',
  AUTH0: '/auth0',
  LIVESTOCK_NEW: '/livestock/new',
  RESULTSANDPOINTS: '/results-and-points',
  RESULTSANDPOINTSADD: '/results-and-points/:id',
  RESULTSANDPOINTSAUDIT: '/results-and-points-audit/',
  CLASSIFICATIONS: '/classifications',
  ASSOCIATIONSETTINGS: '/association-settings',
  MERGE_EVENTS: '/merge-events',
  MEMBERSHIP_SEASON: '/membership-season',
  ASSOCIATIONS_PROMOTERS: '/associations-promoters',
  ASSOCIATIONS_PROMOTERS_NEW: '/associations-promoters/new',
  ASSOCIATIONS_PROMOTERS_EDIT: '/associations-promoters/:id',
};

export const subRouteCodes = {
  EVENTS_EDIT: {
    ENTRIES: '/entries',
    MASS_ENTRIES: '/mass-entries',
    PERFORMANCE_DRAW: '/performance-draw',
    STOCK_SELECTION: '/stock-selection',
    PERFORMANCE_ORDER: '/performance-order',
    MANAGE_PURSE: '/manage-purse',
    MANAGE_RESULTS: '/manage-results',
    NOMINATIONS: '/nominations',
    ATHLETE_ENTRY: '/entries/:id',
    LOGS: '/logs',
  },

  MANAGE_RESULTS: {
    SUMMARY: '/summary',
    UPLOAD: '/upload',
  },

  SUMMARY: {
    VIEW: '/summary/:id/:goNumber?',
  },
  ASSOCIATIONS_PROMOTERS_EDIT: {
    EVENTS: '/events',
  },
};

export default routeCodes;
