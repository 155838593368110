import Dashboard from 'models/Dashboard';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { Record } from 'immutable';
import moment from 'moment-timezone';

import {
  DASHBOARD_ASYNC_START,
  DASHBOARD_ACTION_CLEAR,
  DASHBOARD_ASYNC_FAIL,
  DASHBOARD_ASYNC_SUCCESS,
  DASHBOARD_LIST_ASYNC_SUCCESS,
  DASHBOARD_ASYNC_CLEAR_ERROR,
  DASHBOARD_UPDATE_FILTERS,
} from 'actions/dashboard';

const DashboardOptions = new Record({
  startDate: moment(Date.now()),
  endDate: moment(Date.now()),
  segmentUID: null,
  disciplineUID: null,
  divisionUID: null,
});

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Dashboard(),
  list: toEntityList([], Dashboard),
  options: DashboardOptions(),
});

const actionsMap = {
  [DASHBOARD_UPDATE_FILTERS]: (state, action) => {
    const options = state.get('options');
    return state.merge({
      options: options.merge({
        ...action.data,
      }),
    });
  },
  [DASHBOARD_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [DASHBOARD_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [DASHBOARD_ASYNC_SUCCESS]: (state, action) => {
    const options = state.get('options');
    const {
      segments: { currentSegment },
    } = action.data;
    const newOptions =
      options.get('segmentUID') === null
        ? options.set('segmentUID', currentSegment.segmentUID)
        : options;

    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Dashboard(action.data),
      options: newOptions,
    });
  },
  [DASHBOARD_LIST_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(action.data, Dashboard),
    });
  },
  [DASHBOARD_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [DASHBOARD_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
};

export default function dashboard(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
