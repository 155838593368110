// @flow
import React, { Component } from 'react';

type AppProps = {|
  children: any,
|};
export default class App extends Component<AppProps> {
  returnAvailableSlot = (performance: Object) => {
    if (performance) {
      if (!performance.position1) {
        return 1;
      } else if (!performance.position2) {
        return 2;
      } else if (!performance.position3) {
        return 3;
      }
    }
    return null;
  };

  render() {
    const { children } = this.props;
    return <div className="layout-wrapper">{children}</div>;
  }
}
