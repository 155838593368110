import gql from 'graphql-tag';

export const LIST_COUPONS = gql`
  query LIST_COUPONS($input: CouponsInput!) {
    couponsPayload(input: $input) {
      coupons {
        id
        CouponUID
        CouponCode
        CouponType
        Description
        DollarDisc
        NomDisc
        FeeDisc
        StartDate
        ExpireDate
        EventUID
        ERAUID
        createdAt
        updatedAt
        MaxUsageNumberPerContestant
        SegmentUID
        AssociationUID
        SegmentShortName
        selectedDisciplines {
          DisciplineUID
          TypeUID
          Name
          ShortName
        }
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
