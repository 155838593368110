import PerformanceAnimal from 'models/PerformanceAnimal';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  PERFORMANCE_ANIMAL_ASYNC_START,
  PERFORMANCE_ANIMAL_ASYNC_STOP,
  PERFORMANCE_ANIMAL_ASYNC_FAIL,
  PERFORMANCE_ANIMAL_LIST_ASYNC_SUCCESS,
} from 'actions/performanceAnimal';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new PerformanceAnimal(),
  list: toEntityList([], PerformanceAnimal),
});

const actionsMap = {
  [PERFORMANCE_ANIMAL_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [PERFORMANCE_ANIMAL_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [PERFORMANCE_ANIMAL_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [PERFORMANCE_ANIMAL_LIST_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(action.data, PerformanceAnimal),
    });
  },
};

export default function performanceAnimal(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
