import { SHOW_SPLASH_SCREEN, HIDE_SPLASH_SCREEN } from 'actions/layout';
import Base from 'models/Base';
import Layout from 'models/Layout';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Layout(),
});

const actionsMap = {
  [SHOW_SPLASH_SCREEN]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: { splashScreenOpen: true },
    });
  },
  [HIDE_SPLASH_SCREEN]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: { splashScreenOpen: false },
    });
  },
};

export default function layout(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
