import User from 'models/User';
import Base from 'models/Base';

import {
  USER_ASYNC_START,
  USER_ASYNC_FAIL,
  USER_ASYNC_SUCCESS,
  USER_ASYNC_CLEAR_ERROR,
  USER_TEXT_MESSAGE_ASYNC_START,
  USER_TEXT_MESSAGE_ASYNC_FAIL,
  USER_TEXT_MESSAGE_ASYNC_SUCCESS,
  USER_OPEN_TEXT_MESSAGE_DIALOG,
  USER_CLOSE_TEXT_MESSAGE_DIALOG,
  USER_OPEN_EXPORT_ENTRIES_DIALOG,
  USER_CLOSE_EXPORT_ENTRIES_DIALOG,
} from 'actions/user';

import { REHYDRATE } from 'redux-persist/src/constants';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new User(),
  options: {},
});

const actionsMap = {
  [USER_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [USER_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [USER_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [USER_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new User(action.data),
    });
  },
  [REHYDRATE]: (state, action) => {
    const payload = action.payload;
    const userPayload = payload ? payload.user : null;

    if (userPayload) {
      return new Base({
        loading: false,
        errorMessage: '',
        data: new User(userPayload.data),
      });
    }
    return new Base({
      loading: false,
      errorMessage: '',
      data: new User(),
    });
  },
  [USER_TEXT_MESSAGE_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [USER_TEXT_MESSAGE_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [USER_TEXT_MESSAGE_ASYNC_SUCCESS]: (state) => {
    const newOptions = state.options.set(
      'successMessage',
      'Text message successfully sent. Please check phone.',
    );
    return state.merge({
      loading: false,
      errorMessage: null,
      options: newOptions,
    });
  },
  [USER_OPEN_TEXT_MESSAGE_DIALOG]: (state) => {
    const newOptions = state.options
      .set('textMessageDialogIsOpen', true)
      .set('successMessage', false);

    return state.merge({
      loading: false,
      errorMessage: null,
      options: newOptions,
    });
  },
  [USER_CLOSE_TEXT_MESSAGE_DIALOG]: (state) => {
    const newOptions = state.options
      .set('textMessageDialogIsOpen', false)
      .set('successMessage', false);

    return state.merge({
      loading: false,
      errorMessage: null,
      options: newOptions,
    });
  },
  [USER_OPEN_EXPORT_ENTRIES_DIALOG]: (state) => {
    const newOptions = state.options
      .set('exportEntriesDialogIsOpen', true)
      .set('successMessage', false);

    return state.merge({
      loading: false,
      errorMessage: null,
      options: newOptions,
    });
  },
  [USER_CLOSE_EXPORT_ENTRIES_DIALOG]: (state) => {
    const newOptions = state.options
      .set('exportEntriesDialogIsOpen', false)
      .set('successMessage', false);

    return state.merge({
      loading: false,
      errorMessage: null,
      options: newOptions,
    });
  },
};

export default function user(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
