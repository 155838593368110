import gql from 'graphql-tag';

export const GET_NOMINATION = gql`
  query GET_NOMINATION($NomUID: ID!) {
    nominationPayload(NomUID: $NomUID) {
      nomination {
        NomUID
        ERAUID
        EventUID
        CompType
        SegmentUID
        SegmentAsString
        ShortName
        NomFee
        TransFee
        TTLFees
        NomDate
        chargeId
        CouponUID
        createdAt
        DisciplineAsString
        NumberOfNominations
        NominationRefundedCount
        totalOnStripe
        NominationRevenue
        totalTransactionFee
        chargeHasMoreThanOneNomination
        EventName
        EventLocation
        EventType
        firstName
        lastName
        FullName
        email
        userId
        mainDisciplineAsString
        mainDiscipline
        CouponCode
        isFree
        Segments
        ClassName
        EventEntryDisciplineFeeUID
        totalAmountWithDiscount
        DivisionName
        last4
        DollarDisc
        NomDisc
        FeeDisc
        NomFeeActual
        TransFeeActual
        hasGiftCard
        transactionFee
        currentDivision
        currentDivisionAsString
        originalDivision
        originalDivisionFee
        originalDivisionAsString
        lastRefundType
        divisionChangeCount
      }
    }
  }
`;
