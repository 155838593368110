// @flow
import type { EventType } from 'models/Event';
import type { PerformanceType } from 'models/Performance';
import type { DisciplineType } from 'models/Discipline';

export const getPerformanceNameByGoNumber = (
  event: EventType,
  disciplineId: number,
  goNumber: number,
) => {
  const performances: PerformanceType[] = event.performances;

  const performance =
    performances.find((performance: PerformanceType) => {
      const disciplines: DisciplineType[] = performance.Disciplines || [];
      const disciplineGoRound = disciplines.find(
        (discipline: DisciplineType) => {
          if (
            discipline.id === disciplineId &&
            discipline.GoNumber === goNumber
          ) {
            return true;
          }
        },
      );

      return !!disciplineGoRound;
    }) || {};

  return performance.PerformanceName;
};
