import { Record } from 'immutable';

const defaultValues = {
  AssociationUID: null,
  Name: '',
  ShortName: '',
  CollectMembershipNumber: false,
  CollectMembershipFees: false,
  IsAlliancePartner: 0,
  AssociationTypeUID: null,
  AssociationTypeAsString: null,
  createdAt: null,
  updatedAt: null,
  AllianceLevel: null,
  AllianceLevelName: '',
  SignDate: null,
  PrimaryFullName: '',
  PrimaryPhone: '',
  PrimaryEmail: '',
  SecondaryFullName: '',
  SecondaryPhone: '',
  SecondaryEmail: '',
  EventsApprovalFullName: '',
  EventsApprovalPhone: '',
  EventsApprovalEmail: '',
  EventsResultsFullName: '',
  EventsResultsPhone: '',
  AssociationLogo: '',
  EventsResultsEmail: '',
  Website: '',
  Address: '',
  City: '',
  State: '',
  Zip: '',
  ProChallenger: null,
  TotalAnnualEvents: null,
  HaveFinals: null,
  EventFacebookUrl: '',
  EventTwitterUrl: '',
  EventInstagramUrl: '',
  AssociationDisciplines: [],
  ProChallengerDisciplines: [],
  MembershipTypes: [],
  AssociationAgreements: [],
  AllianceLevelResult: '',
};

export default class Association extends Record(defaultValues, 'Association') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
