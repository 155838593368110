import api from 'api';
import { getFromState, getToken, logError } from 'helpers';

export const ANIMAL_ACTION_SUCCESS = 'ANIMAL_ACTION_SUCCESS';
export const ANIMAL_ACTION_CLEAR = 'ANIMAL_ACTION_CLEAR';
export const ANIMAL_ASYNC_START = 'ANIMAL_ASYNC_START';
export const ANIMAL_ASYNC_STOP = 'ANIMAL_ASYNC_STOP';
export const ANIMAL_ASYNC_FAIL = 'ANIMAL_ASYNC_FAIL';
export const ANIMAL_ASYNC_SUCCESS = 'ANIMAL_ASYNC_SUCCESS';
export const ANIMAL_LIST_ASYNC_SUCCESS = 'ANIMAL_LIST_ASYNC_SUCCESS';

function animalActionSuccess(data) {
  return {
    type: ANIMAL_ACTION_SUCCESS,
    data,
  };
}

function animalActionClear() {
  return {
    type: ANIMAL_ACTION_CLEAR,
  };
}

function animalAsyncStart() {
  return {
    type: ANIMAL_ASYNC_START,
  };
}

export function animalAsyncStop() {
  return {
    type: ANIMAL_ASYNC_STOP,
  };
}

export function animalAsyncSuccess(data) {
  return {
    type: ANIMAL_ASYNC_SUCCESS,
    data,
  };
}

export function animalListAsyncSuccess(data) {
  return {
    type: ANIMAL_LIST_ASYNC_SUCCESS,
    data,
  };
}

function animalAsyncFail(error) {
  return {
    type: ANIMAL_ASYNC_FAIL,
    error,
  };
}

export function listAnimals(values) {
  return async (dispatch, getState) => {
    dispatch(animalAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { ContractorUIDs, Disciplines } = values;
      const ContractorUIDString = ContractorUIDs
        ? JSON.stringify(ContractorUIDs)
        : null;
      const DisciplineString = Disciplines ? JSON.stringify(Disciplines) : null;
      const basePath = `animals?ContractorUIDs=${ContractorUIDString}&Disciplines=${DisciplineString}`;
      const query = values && values.query ? `&q=${values.query}` : '';

      const path = `${basePath}${query}`;
      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(animalListAsyncSuccess(response.data));
      return response.data;
    } catch (error) {
      logError(error);
      dispatch(animalAsyncFail(error.message));
      return null;
    }
  };
}

export function selectAnimals(animal) {
  return (dispatch) => {
    dispatch(animalActionSuccess(animal));
  };
}

export function clearAnimal() {
  return (dispatch) => {
    dispatch(animalActionClear());
  };
}
