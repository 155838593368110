import { createSelector } from 'reselect';
import radixParameter from 'constants/radix';
import { getFromState } from 'helpers/getFromState';
import { toJS } from 'helpers/toJS';

export const getContestant = (state) => getFromState(() => state, 'contestant');

export const getContestantOptions = (state) => toJS(state.contestant.options);

const getUppercase = (value) => {
  return value && typeof value === 'string' ? value.toUpperCase() : value;
};

export const getInitialContestantValues = createSelector(
  getContestant,
  (contestant) => {
    return {
      AwardUID: contestant.AwardUID,
      AdminNotes: contestant.AdminNotes,
      BioUID: contestant.BioUID,
      Brand: contestant.Brand,
      CType: contestant.CType,
      CareerEarnings: contestant.CareerEarnings,
      ContractorUID: contestant.ContractorUID,
      DOB: contestant.DOB,
      DOBVerified: contestant.DOBVerified,
      Disc: parseInt(contestant.Disc, radixParameter.DEFAULT),
      ERAUID: contestant.ERAUID,
      FName: contestant.FName,
      Facebook: contestant.Facebook,
      FullName: contestant.FullName,
      Height: contestant.Height,
      HeritageUID: contestant.HeritageUID,
      HistoryUID: contestant.HistoryUID,
      Hometown: contestant.Hometown,
      Identifier: contestant.Identifier,
      InjuryCurrentUID: contestant.InjuryCurrentUID,
      InjuryHistoryUID: contestant.InjuryHistoryUID,
      Instagram: contestant.Instagram,
      LName: contestant.LName,
      LastUpdate: contestant.LastUpdate,
      LegalFirstName: contestant.LegalFirstName,
      LegalLastName: contestant.LegalLastName,
      LinkedIn: contestant.LinkedIn,
      MemType: contestant.MemType,
      Nickname: contestant.Nickname,
      PayeeNumber: contestant.PayeeNumber,
      Penalty: contestant.Penalty,
      Phone: contestant.Phone,
      PhotoFilename: contestant.PhotoFilename,
      PhotoUID: contestant.PhotoUID,
      Pinterest: contestant.Pinterest,
      PurchaseDate: contestant.PurchaseDate,
      QuoteUID: contestant.QuoteUID,
      RookieYear: contestant.RookieYear,
      SSN: contestant.SSN,
      Snapchat: contestant.Snapchat,
      StatusType: contestant.StatusType,
      Suffix: contestant.Suffix,
      Twitter: contestant.Twitter,
      USCitizen: contestant.USCitizen,
      Website: contestant.Website,
      Weight: contestant.Weight,
      WorldChampionshipEarned: contestant.WorldChampionshipEarned,
      YouTube: contestant.YouTube,
      city: contestant.city,
      createdAt: contestant.createdAt,
      email: contestant.email,
      firstName: contestant.firstName,
      lastName: contestant.lastName,
      numberOfResults: contestant.numberOfResults,
      role: contestant.role,
      socialTags: contestant.socialTags,
      state: getUppercase(contestant.state),
      status: contestant.status,
      street: contestant.street,
      street2: contestant.street2,
      userId: contestant.userId,
      zip: contestant.zip,
      country: getUppercase(contestant.country),
      type: contestant.type,
      SegmentUID: contestant.SegmentUID,
      leaderboardType: contestant.leaderboardType,
      Gender: contestant.Gender,
    };
  },
);
