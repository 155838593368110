import api from 'api';

import { getFromState, getToken, logError } from 'helpers';
export const GIFTCARDACCOUNT_LIST_ASYNC_START =
  'GIFTCARDACCOUNT_LIST_ASYNC_START';
export const GIFTCARDACCOUNT_LIST_ASYNC_SUCCESS =
  'GIFTCARDACCOUNT_LIST_ASYNC_SUCCESS';
export const GIFTCARDACCOUNT_LIST_ASYNC_FAIL =
  'GIFTCARDACCOUNT_LIST_ASYNC_FAIL';

export function giftCardAccountListAsyncStart() {
  return {
    type: GIFTCARDACCOUNT_LIST_ASYNC_START,
  };
}

export function giftCardAccountListAsyncSuccess(data) {
  return {
    type: GIFTCARDACCOUNT_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function giftCardAccountListAsyncFail() {
  return {
    type: GIFTCARDACCOUNT_LIST_ASYNC_FAIL,
  };
}

export function listGiftCardAccounts(userId) {
  return async (dispatch, getState) => {
    dispatch(giftCardAccountListAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `gift-card-accountNumber/by-user-id/${userId}`,
        method: 'GET',
        accessToken,
      });

      dispatch(giftCardAccountListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(giftCardAccountListAsyncFail(error.message));
      return null;
    }
  };
}
