import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';

import Athletes from 'models/Athletes';
import Nominations from 'models/Nominations';
import Coupon from 'models/Coupon';

const defaultValues = {
  athletes: toEntityList([], Athletes),
  nominations: toEntityList([], Nominations),
  segments: {},
  nominationsTotals: 0,
  couponUsage: [],
  couponTotals: {
    useCount: 0,
    totalSpent: 0,
    totalDiscounted: 0,
  },
};

export default class Dashboard extends Record(defaultValues, 'Dashboard') {
  constructor(values) {
    super({
      ...values,
      athletes:
        values && values.athletes
          ? toEntityList(values.athletes, Athletes)
          : defaultValues.athletes,
      nominations:
        values && values.nominations
          ? toEntityList(values.nominations, Nominations)
          : defaultValues.nominations,
      couponUsage:
        values && values.couponUsage
          ? toEntityList(values.couponUsage, Coupon)
          : defaultValues.couponUsage,
    });
  }
}
