// @flow
import {
  ROUGH_STOCK_TYPE,
  TIMED_DISCIPLINE_TYPE,
  BARREL_RACING_TYPE,
  disciplineCategories,
} from 'constants/disciplines';
import { groupTogether } from './groupTogether';

// $FlowIgnore
const flattenArray = (arr: Object[]) => [].concat([], ...arr);

export const calculateBarrelRacingSubtotal = (results: Object) => {
  try {
    return results.map((result) => {
      let Subtotal =
        result.Time +
        result.FirstPenalty +
        result.SecondPenalty +
        result.ThirdPenalty;
      if (result.Time === 0) {
        Subtotal = 0;
      }
      return { ...result, Subtotal };
    });
  } catch (error) {
    throw error;
  }
};

export const calculateSteerWrestlingSubtotal = (results: Object) => {
  try {
    return results.map((result) => {
      let Subtotal = result.Time + result.Barrier + result.Field;
      if (result.Time === 0) {
        Subtotal = 0;
      }
      return { ...result, Subtotal };
    });
  } catch (error) {
    throw error;
  }
};

export const calculateRoughStockSubtotal = (results: Object) => {
  try {
    return results.map((result) => {
      const Subtotal =
        result.Ride && result.Stock ? result.Ride + result.Stock : null;
      return { ...result, Subtotal };
    });
  } catch (error) {
    throw error;
  }
};
// eslint-disable-next-line
const getSubTotals = (group, CompType) => {
  if (disciplineCategories[CompType] === ROUGH_STOCK_TYPE) {
    return calculateRoughStockSubtotal(group);
  }

  if (disciplineCategories[CompType] === BARREL_RACING_TYPE) {
    return calculateBarrelRacingSubtotal(group);
  }

  if (disciplineCategories[CompType] === TIMED_DISCIPLINE_TYPE) {
    return calculateSteerWrestlingSubtotal(group);
  }

  return [];
};

const getTotal = (group: Object[]) => {
  let Total = 0;
  group.forEach((item) => {
    if (item.Subtotal) {
      Total += item.Subtotal;
    }
  });
  if (group.length === 4) {
    Total /= 2;
  }
  return group.map((item) => ({ ...item, Total }));
};

const calculateGroup = (group: Object[]) => {
  try {
    const { CompType } = group[0];
    const subTotals = getSubTotals(group, CompType);
    return getTotal(subTotals);
  } catch (error) {
    throw error;
  }
};

const assignRank = (
  rankedGroup: Object,
  discipline: string = '',
  currentRank: number = 0,
) => {
  return rankedGroup.map((result, i, results) => {
    if (result.Total && !result.IsRerideCancelled) {
      if (i === 0) {
        currentRank += 1;
      } else if (results[i - 1]) {
        const decimalPlaceCount = discipline === BARREL_RACING_TYPE ? 3 : 2;
        const prevResultTotals = results[i - 1].Total;
        const currResultTotals = result.Total;

        const prevResultTotalsRounded = getDecimalRounded(
          prevResultTotals,
          decimalPlaceCount,
        );
        const currResultTotalsRounded = getDecimalRounded(
          currResultTotals,
          decimalPlaceCount,
        );

        if (prevResultTotalsRounded !== currResultTotalsRounded) {
          currentRank += 1;
        }
      }

      return {
        ...result,
        Rank: currentRank,
      };
    }
    return {
      ...result,
      Total: 0,
      Subtotal: 0,
      Rank: null,
    };
  });
};

const timedRanking = (a: Object, b: Object) => {
  return a.Total - b.Total;
};

const untimedRanking = (a: Object, b: Object) => {
  return b.Total - a.Total;
};

const ordering = (a: Object, b: Object) => {
  if (a.Position && b.Position) {
    return a.Position - b.Position;
  }
  return 0;
};

// eslint-disable-next-line
const calculateGroupRankings = (group: Object[]) => {
  const { CompType } = group[0];
  if (disciplineCategories[CompType] === ROUGH_STOCK_TYPE) {
    const groupOrdered = group.sort(untimedRanking);
    const rankedGroup = assignRank(groupOrdered, ROUGH_STOCK_TYPE);
    return rankedGroup.sort(ordering);
  }

  if (disciplineCategories[CompType] === BARREL_RACING_TYPE) {
    const groupOrdered = group.sort(timedRanking);
    const rankedGroup = assignRank(groupOrdered, BARREL_RACING_TYPE);
    return rankedGroup.sort(ordering);
  }

  if (disciplineCategories[CompType] === TIMED_DISCIPLINE_TYPE) {
    const groupOrdered = group.sort(timedRanking);
    const rankedGroup = assignRank(groupOrdered, TIMED_DISCIPLINE_TYPE);
    return rankedGroup.sort(ordering);
  }
};

export const calculateTotal = (results: Object) => {
  const identifier = (item) => `${item.AnimalUID}-${item.EPUID}`;
  const groups = groupTogether(results, identifier);
  const calculations = groups.map(calculateGroup);
  return flattenArray(calculations);
};

export const calculateRanking = (results: Object) => {
  const resultsWithTotals = calculateTotal(results);
  const identifier = (item) => `${item.JudgeNumber}-${item.CompType}`;
  const groups = groupTogether(resultsWithTotals, identifier);
  const groupRankings = groups.map(calculateGroupRankings);
  return flattenArray(groupRankings);
};

export const getDecimalRounded = (
  value: string,
  decimalPlaceCount: number = 2,
) => {
  const decimalValue = parseFloat(value);
  return decimalValue >= 0
    ? Number(decimalValue.toFixed(decimalPlaceCount))
    : null;
};
