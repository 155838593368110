// @flow
type OrderStateEnumType = 'asc' | 'desc';
type GetSortDirectionArgsType = {
  currOrderColumn: string,
  defaultOrderDirection?: OrderStateEnumType,
  orderDirection: OrderStateEnumType,
  prevOrderColumn: string,
};
export const getSortDirection = ({
  prevOrderColumn,
  currOrderColumn,
  orderDirection,
  defaultOrderDirection = 'desc',
}: GetSortDirectionArgsType): OrderStateEnumType => {
  const newOrderDirection = defaultOrderDirection === 'desc' ? 'asc' : 'desc';
  return prevOrderColumn === currOrderColumn &&
    orderDirection === defaultOrderDirection
    ? newOrderDirection
    : defaultOrderDirection;
};
