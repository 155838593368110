import moment from 'moment';
import { authenticationRefresh } from 'actions/auth';
import { getKeyFromState } from 'helpers';
import { hasFeatureFlag } from '../utils/flagsmith';
import { authenticationRefreshAuth0 } from '../actions/auth';

const intervalInMs = 10000;
const expiryPaddingInMs = intervalInMs + 5000;

const isAuthorized = (auth) => auth && auth.authenticated && auth.authPayload;

const isRefreshExpired = (exp) => {
  return exp ? moment(exp) - moment() < expiryPaddingInMs : false;
};

export default function({ dispatch, getState }) {
  // dot remove this async you, it is needed for correct state update
  return (next) => async (action) => {
    let processHandle;
    if (typeof action !== 'object') return next(action);

    switch (action.type) {
      case 'AUTH_ASYNC_SUCCESS':
      case 'persist/REHYDRATE':
        if (processHandle) {
          clearInterval(processHandle);
        }

        processHandle = setInterval(async () => {
          const authState = getKeyFromState(getState, 'auth');
          const {
            data: { authPayload, refreshTokenPromise },
            data: authData,
            loading,
          } = authState;
          const { token } = authPayload;
          if (
            isAuthorized(authData) &&
            isRefreshExpired(token.expiresIn) &&
            !loading
          ) {
            if (!refreshTokenPromise) {
              const auth0 = hasFeatureFlag('auth0');
              if (auth0) {
                await authenticationRefreshAuth0(dispatch, getState);
              } else {
                await authenticationRefresh(dispatch, getState);
              }
            }
          }
        }, intervalInMs);
        break;
      case 'DE_AUTH':
        clearInterval(processHandle);
        break;
    }
    next(action);
  };
}
