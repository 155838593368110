import OverAllResult from 'models/OverAllResult';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  OVERALL_RESULT_ASYNC_START,
  OVERALL_RESULT_ASYNC_FAIL,
  OVERALL_RESULT_ASYNC_SUCCESS,
  OVERALL_RESULT_SELECT_ALL,
  OVERALL_RESULT_SELECT_LINE,
  OVERALL_RESULT_DESELECT_ALL,
  OVERALL_RESULT_DESELECT_LINE,
  OVERALL_ADVANCE_ATHLETES,
} from 'actions/overAllResult';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  list: toEntityList([], OverAllResult),
  apiList: toEntityList([], OverAllResult),
});

const actionsMap = {
  [OVERALL_RESULT_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [OVERALL_ADVANCE_ATHLETES]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      list: initialState.get('list'),
    });
  },
  [OVERALL_RESULT_SELECT_ALL]: (state) => {
    const apiList = state.get('apiList');

    return state.merge({
      loading: false,
      errorMessage: null,
      list: apiList,
      apiList: apiList.map((item) => item.set('Selected', true)),
    });
  },
  [OVERALL_RESULT_DESELECT_ALL]: (state) => {
    const apiList = state.get('apiList');

    return state.merge({
      loading: false,
      errorMessage: null,
      list: initialState.get('list'),
      apiList: apiList.map((item) => item.set('Selected', false)),
    });
  },
  [OVERALL_RESULT_SELECT_LINE]: (state, action) => {
    const overAllLine = new OverAllResult(action.overAllLine);
    const list = state.get('list');
    const apiList = state.get('apiList');

    return state.merge({
      loading: false,
      errorMessage: null,
      list: list.push(overAllLine),
      apiList: apiList.map((item) => {
        if (item.get('EPUID') === overAllLine.get('EPUID')) {
          return item.set('Selected', true);
        }

        return item;
      }),
    });
  },
  [OVERALL_RESULT_DESELECT_LINE]: (state, action) => {
    const overAllLine = new OverAllResult(action.overAllLine);
    const list = state.get('list');
    const apiList = state.get('apiList');

    return state.merge({
      loading: false,
      errorMessage: null,
      list: list.filter(
        (line) => line.get('EPUID') !== overAllLine.get('EPUID'),
      ),
      apiList: apiList.map((item) => {
        if (item.get('EPUID') === overAllLine.get('EPUID')) {
          return item.set('Selected', false);
        }

        return item;
      }),
    });
  },
  [OVERALL_RESULT_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: initialState.get('list'),
      apiList: toEntityList(action.data, OverAllResult),
    });
  },
  [OVERALL_RESULT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
};

export default function overAllResult(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
