// @flow
import React, { useState } from 'react';
// $FlowIgnore
import { useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClassificationForm from './Classifications/ClassificationForm';
import { updateContestantDisciplineCard } from 'actions/contestantDiscipline';

type ClassificationsTableProps = {|
  classifications: Object,
  athleteObj: Function,
  loadClassificationsCard: Function,
  disabled: boolean,
|};

export const ClassificationsTable = (props: ClassificationsTableProps) => {
  const dispatch = useDispatch();
  const {
    classifications,
    athleteObj,
    loadClassificationsCard,
    disabled,
  } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentClassification, setCurrentClassification] = useState({});

  const onEditClassification = (classification) => {
    const {
      CompetitionLevel: Classification,
      CompetitionLevelID: CompetitionLevel,
      DisciplineUID,
      Name: DisciplineName,
      ERAContestantDisciplineUID,
      Verified,
      DOB,
      Notes,
      ClassificationDate,
    } = classification;

    const classificationObj = {
      Classification,
      CompetitionLevel,
      DisciplineName,
      DisciplineUID,
      Verified,
      DOB,
      Notes,
      ClassificationDate,
      ERAContestantDisciplineUID,
    };

    const currentClassificationObj = {
      ...classificationObj,
      ...athleteObj(),
    };

    setCurrentClassification(currentClassificationObj);
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const setVerifiedRecord = (data) => {
    const finalData = {
      ...data,
      Verified: currentClassification.Verified,
    };
    dispatch(updateContestantDisciplineCard(finalData));
    loadClassificationsCard();
    setIsOpenModal(false);
  };

  const renderClassifications = (classification) => {
    return (
      <TableRow key={classification.DisciplineUID}>
        <TableCell padding="none" className="column-padding-small">
          <EditIcon
            style={{ cursor: 'pointer' }}
            onClick={() => !disabled && onEditClassification(classification)}
          />
        </TableCell>
        <TableCell
          padding="none"
          component="th"
          scope="row"
          style={{ textTransform: 'uppercase' }}
        >
          {classification.Name}
        </TableCell>
        <TableCell
          padding="none"
          align="left"
          style={{ textTransform: 'uppercase' }}
        >
          {classification.CompetitionLevel}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {Array.isArray(classifications) && classifications.length ? (
        <Table aria-label="simple table" padding="none">
          <TableHead>
            <TableRow>
              <TableCell
                padding="none"
                className="linked-accounts-cell"
                style={{ width: '5px', padding: '0 0' }}
              />
              <TableCell padding="none" className="linked-accounts-cell">
                DISCIPLINE
              </TableCell>
              <TableCell
                padding="none"
                align="left"
                className="linked-accounts-cell"
              >
                CLASSIFICATION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classifications && classifications.length
              ? classifications.map((classification) =>
                  renderClassifications(classification),
                )
              : ''}
          </TableBody>
        </Table>
      ) : (
        <p style={{ fontWeight: 100, paddingTop: '30px' }}>
          This athlete has not nominated an event or the classification has not
          been updated yet.
        </p>
      )}
      <ClassificationForm
        isOpenModal={isOpenModal}
        onCloseModal={onCloseModal}
        setVerifiedRecord={setVerifiedRecord}
        classification={currentClassification}
        isClassificationCard={true}
      />
    </>
  );
};
