import Base from 'models/Base';
import { CSV_ASYNC_CLEAR_ERROR, CSV_ASYNC_FAIL } from 'actions/csv';

const initialState = new Base({
  errorMessage: '',
});

const actionsMap = {
  [CSV_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      errorMessage: '',
    });
  },
  [CSV_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      errorMessage: action.errorMessage,
    });
  },
};

export default function csv(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
