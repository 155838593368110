import gql from 'graphql-tag';

export const GET_CONTESTANT_DISCIPLINES = gql`
  query LIST_CONTESTANT_DISCIPLINES($input: ContestantDisciplinesInput!) {
    contestantDisciplinesPayload(input: $input) {
      contestantDisciplines {
        ERAContestantDisciplineUID
        ERAUID
        FullName
        DOB
        Email
        PhoneNumber
        NomDate
        SegmentName
        DisciplineName
        Classification
        Verified
        DisciplineUID
        SegmentUID
        SegmentShortName
        HasPro
        HasChallenger
        HasYouth
        HasOpen
        HasLimited
        CompetitionLevel
        ClassificationDate
        Notes
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
