// @flow
import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { Field, reduxForm, formValueSelector, isInvalid } from 'redux-form';
import { connect } from 'react-redux';
import FormField from 'components/FormField';
import { renderSelectBoxField } from 'components/FormField/SelectBox';
import 'react-select/dist/react-select.css';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
} from '@material-ui/core';
import { compose } from 'recompose';
import LayoutDialog from 'components/Layout/LayoutDialog';
import withToJS from 'enhancers/withToJS';
import { getCompetitionBySegment } from 'helpers';
import { required } from 'components/Forms/Validation';

const FORM_NAME = 'editClassification';
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => ({
  formSelector: (fieldName) => selector(state, fieldName),
  isInvalid: isInvalid(FORM_NAME)(state),
});

type ClassificationFormProps = {|
  isOpenModal: boolean,
  classification: Object,
  onCloseModal: () => void,
  change: (key: string, value: Object) => void,
  formSelector: (string) => any,
  setVerifiedRecord: (o: Object) => void,
  isInvalid: boolean,
  isClassificationCard: boolean,
|};

type ClassificationFormState = {|
  competitionLevels: Array<Object>,
|};

export class ClassificationFormBase extends PureComponent<
  ClassificationFormProps,
  ClassificationFormState,
> {
  constructor(props: ClassificationFormProps) {
    super(props);
    this.state = { competitionLevels: [] };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: Object) {
    const { classification } = this.props;
    if (classification !== prevProps.classification) {
      const {
        SegmentShortName: ShortName,
        HasOpen,
        HasPro,
        HasChallenger,
        HasYouth,
        HasLimited,
        DisciplineUID,
      } = classification;
      const segment = {
        ShortName,
        HasPro,
        HasChallenger,
        HasYouth,
        HasOpen,
        HasLimited,
        DisciplineUID,
      };
      let currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      const competitionLevels = getCompetitionBySegment(segment, DisciplineUID);
      this.props.change('fullName', classification.FullName);
      this.props.change('Age', moment().diff(classification.DOB, 'years'));
      this.props.change('DOB', classification.DOB);
      this.props.change('CompetitionLevel', classification.CompetitionLevel);
      this.props.change('DisciplineName', classification.DisciplineName);
      this.props.change('email', classification.Email);
      this.props.change('phoneNumber', classification.PhoneNumber);
      this.props.change('segmentShortName', classification.SegmentShortName);
      this.props.change('Notes', classification.Notes);
      this.props.change(
        'ClassificationDate',
        classification.ClassificationDate || currentDate,
      );
      this.setState({ competitionLevels });
    }
  }

  onVerify(event: Object) {
    event.preventDefault();
    // Stop the parent form (Main athlete form) from submitting too
    event.stopPropagation();
    const { classification, formSelector, setVerifiedRecord } = this.props;
    const { ERAContestantDisciplineUID, SegmentUID } = classification;
    const CompetitionLevel = formSelector('CompetitionLevel');
    const ClassificationDate = formSelector('ClassificationDate');
    const Notes = formSelector('Notes');

    const currentClassification = {
      ERAContestantDisciplineUID,
      CompetitionLevel,
      SegmentUID,
      ClassificationDate,
      Notes: Notes || '',
    };
    setVerifiedRecord(currentClassification);
  }

  render() {
    const {
      isOpenModal,
      onCloseModal,
      classification,
      isInvalid,
      isClassificationCard,
    } = this.props;
    const { competitionLevels } = this.state;
    let emailLabel = 'Email';
    let phoneLabel = 'Phone #';

    if (classification && classification.HasYouth) {
      emailLabel = 'Parent/Guardian Email';
      phoneLabel = 'Parent/Guardian Phone #';
    }

    return (
      <Dialog
        disableBackdropClick
        scroll="paper"
        maxWidth="md"
        open={isOpenModal}
        onClose={onCloseModal}
        className="add-classification-main-modal"
        aria-labelledby="responsive-dialog-title"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(68, 68, 68, 0.5)',
          },
        }}
      >
        <LayoutDialog className="add-classification-modal" loading={false}>
          <form onSubmit={(e) => this.onVerify(e)} role="presentation">
            <div>
              <DialogTitle
                id="form-dialog-title"
                className="edit-classification-title"
              >
                EDIT CLASSIFICATION
              </DialogTitle>
              <DialogContent className="classification-modal-content">
                <Grid container spacing={24} className="marTop-5">
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="CompetitionLevel"
                      component={renderSelectBoxField}
                      label="Classification"
                      placeholder="Classification"
                      parentClass="no-padding-select"
                      variant="filled"
                      options={competitionLevels}
                      validate={[required]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="fullName"
                      className="form-row-field"
                      label="Name"
                      parentClass="no-padding"
                      placeholder="Name"
                      type="text"
                      variant="filled"
                      disabled
                      component={FormField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="DisciplineName"
                      className="form-row-field"
                      label="Discipline"
                      parentClass="no-padding"
                      placeholder="Discipline"
                      type="select"
                      variant="filled"
                      disabled
                      component={FormField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="email"
                      className="form-row-field"
                      label={emailLabel}
                      parentClass="no-padding"
                      placeholder="Email"
                      type="text"
                      variant="filled"
                      disabled
                      component={FormField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="segmentShortName"
                      className="form-row-field"
                      label="Segment"
                      parentClass="no-padding"
                      placeholder="Segment"
                      type="select"
                      variant="filled"
                      disabled
                      component={FormField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="phoneNumber"
                      className="form-row-field"
                      label={phoneLabel}
                      parentClass="no-padding"
                      placeholder="Phone"
                      type="text"
                      variant="filled"
                      disabled
                      component={FormField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="ClassificationDate"
                      className="form-row-field"
                      label="Classification Date"
                      parentClass="no-padding"
                      placeholder="Classification Date"
                      type="date"
                      helpertext=""
                      variant="filled"
                      component={FormField}
                      validate={[required]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Field
                      margin="none"
                      name="Age"
                      className="form-row-field"
                      label={'Age'}
                      parentClass="no-padding"
                      placeholder="Age"
                      type="text"
                      variant="filled"
                      disabled
                      component={FormField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Field
                      margin="none"
                      name="DOB"
                      className="form-row-field"
                      label={'DOB'}
                      parentClass="no-padding"
                      placeholder="DOB"
                      type="text"
                      variant="filled"
                      disabled
                      component={FormField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="none"
                      name="Notes"
                      className="form-row-field"
                      label="Notes"
                      parentClass="no-padding"
                      placeholder="Notes"
                      rows={3}
                      type="text"
                      variant="filled"
                      component={FormField}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="classification-actions">
                <Button
                  onClick={onCloseModal}
                  variant="contained"
                  className="wcra-dark-blue-cta"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="wcra-green-cta"
                  disabled={isInvalid}
                >
                  {isClassificationCard ? 'Save' : 'Verify'}
                </Button>
              </DialogActions>
            </div>
          </form>
        </LayoutDialog>
      </Dialog>
    );
  }
}

const ClassificationForm = withToJS(ClassificationFormBase);

export default compose(
  // $FlowIgnore
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  }),
)(ClassificationForm);
