// @flow
import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  FormControlLabel,
  Checkbox as MaterialCheckbox,
} from '@material-ui/core';

type CheckboxProps = {|
  input: Object,
  label: ?string,
  className: ?string,
  disabled: ?boolean,
  required: ?boolean,
|};

export const Checkbox = (props: CheckboxProps) => {
  const { input, label, className, disabled, required } = props;
  const [isCheckedTrue, setIsCheckedTrue] = React.useState(input.value);

  useEffect(
    () => {
      setIsCheckedTrue(input.value);
    },
    [input.value],
  );
  return (
    <FormControlLabel
      disabled={disabled}
      name={input.name}
      control={
        <MaterialCheckbox
          className={classNames('checkbox-component checkbox-color', className)}
          onChange={(e) => {
            setIsCheckedTrue(e.target.checked);
            if (input && input.onChange) {
              input.onChange(e.target.checked);
            }
          }}
          checked={!!isCheckedTrue}
        />
      }
      label={<div className="checkbox-label">{label}</div>}
      required={required}
    />
  );
};

Checkbox.defaultProps = {
  label: null,
  className: '',
  disabled: false,
  required: false,
};

export default Checkbox;
