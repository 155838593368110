// @flow
export const getFormattedDate = (selectedDate: string) => {
  if (!selectedDate) return '';

  const date = new Date(selectedDate);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const twoDigitMonth = getTwoDigitMonth(month);
  const twoDigitDay = getTwoDigitDay(day);

  return `${twoDigitMonth}/${twoDigitDay}/${year}`;
};

export const getFormattedDateUTC = (selectedDate: string) => {
  if (!selectedDate) return '';

  const date = new Date(selectedDate);
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const twoDigitMonth = getTwoDigitMonth(month);
  const twoDigitDay = getTwoDigitDay(day);

  return `${twoDigitMonth}/${twoDigitDay}/${year}`;
};

export const getFormattedDateLong = (selectedDate: string) => {
  if (!selectedDate) return '';

  const date = new Date(selectedDate);
  const month = getLongMonth(date.getMonth());
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const getFormattedDateLongUTC = (selectedDate: string) => {
  if (!selectedDate) return '';

  const date = new Date(selectedDate);
  const month = getLongMonth(date.getUTCMonth());
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};

export const getFormattedDateRange = (startDate: string, endDate: string) => {
  const formattedStartDate = startDate ? getFormattedDate(startDate) : 'N/A';
  const formattedEndDate = endDate ? getFormattedDate(endDate) : 'N/A';

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getFormattedDateRangeUTC = (
  startDate: string,
  endDate: string,
) => {
  const formattedStartDate = startDate ? getFormattedDateUTC(startDate) : 'N/A';
  const formattedEndDate = endDate ? getFormattedDateUTC(endDate) : 'N/A';

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getFormattedDateRangeLong = (
  startDate: string,
  endDate: string,
) => {
  const formattedStartDate = startDate
    ? getFormattedDateLong(startDate)
    : 'N/A';
  const formattedEndDate = endDate ? getFormattedDateLong(endDate) : 'N/A';

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getFormattedDateRangeLongUTC = (
  startDate: string,
  endDate: string,
) => {
  const formattedStartDate = startDate
    ? getFormattedDateLongUTC(startDate)
    : 'N/A';
  const formattedEndDate = endDate ? getFormattedDateLongUTC(endDate) : 'N/A';

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getFormattedTime = (selectedDate: string) => {
  if (!selectedDate) return '';

  const date = new Date(selectedDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return getTimeString(hours, minutes);
};

export const getFormattedTimeUTC = (selectedDate: string) => {
  if (!selectedDate) return '';

  const date = new Date(selectedDate);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  return getTimeString(hours, minutes);
};

export const getFormattedDateTimeLong = (selectedDate: string) => {
  const formattedDate = getFormattedDateLong(selectedDate);
  const formattedTime = getFormattedTime(selectedDate);

  return `${formattedDate} ${formattedTime}`;
};

export const getFormattedDateTimeLongUTC = (selectedDate: string) => {
  const formattedDate = getFormattedDateLongUTC(selectedDate);
  const formattedTime = getFormattedTimeUTC(selectedDate);

  return `${formattedDate} ${formattedTime}`;
};

export const getFormattedDateTime = (selectedDate: string) => {
  const formattedDate = getFormattedDate(selectedDate);
  const formattedTime = getFormattedTime(selectedDate);

  return `${formattedDate} ${formattedTime}`;
};

export const getFormattedDateTimeUTC = (selectedDate: string) => {
  const formattedDate = getFormattedDateUTC(selectedDate);
  const formattedTime = getFormattedTimeUTC(selectedDate);

  return `${formattedDate} ${formattedTime}`;
};

const getTwoDigitMonth = (month: number) => {
  return month < 10 ? `0${month}` : month;
};
const getTwoDigitDay = (day: number) => {
  return day < 10 ? `0${day}` : day;
};

const getLongMonth = (monthId: number = 0) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[monthId];
};

const getTimeString = (hours, minutes) => {
  const minutesString = minutes ? minutes.toString() : '00';
  const isMidnight = hours === 0;
  const isAM = hours < 12 || hours === 24;
  const isNoon = hours === 12;
  const adjustedHours = isMidnight ? hours + 12 : hours;
  const formattedHours = adjustedHours - (isAM || isNoon ? 0 : 12);
  const formattedMinutes =
    minutesString.length === 1 ? `0${minutesString}` : minutesString;
  const timeOfDay = isAM ? 'AM' : 'PM';

  return `${formattedHours}:${formattedMinutes} ${timeOfDay}`;
};
