import { Record } from 'immutable';

const defaultValues = {
  AnimalUID: null,
  LegalName: null,
  NickName: null,
  AnimalType: null,
  BioUID: null,
  AwardUID: null,
  HeritageUID: null,
  HistoryUID: null,
  InjuryCurrentUID: null,
  InjuryHistoryUID: null,
  Identifier: null,
  ContractorUID: null,
  ERAUID: null,
  Brand: null,
  WCEligibleDate: null,
  GenderType: null,
  Color: null,
  Delivery: null,
  Weight: null,
  BirthYear: null,
  Classification: null,
  LastUpdate: null,
  ContractorName: null,
};

export default class Animal extends Record(defaultValues, 'Animal') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
