// @flow
import moment from 'moment-timezone';
import { isNumber } from 'lodash';
export const getSorting = (
  order: string,
  orderBy: string,
  isDate: boolean = false,
): ((a: Object[], b: Object[]) => number) => {
  if (!isDate) {
    return (a: Object[], b: Object[]) => {
      // $FlowIgnore
      if (a[orderBy] === null || a[orderBy] === undefined) {
        return -1;
      }

      // $FlowIgnore
      if (b[orderBy] === null || b[orderBy] === undefined) {
        return -1;
      }

      // $FlowIgnore
      const valueA = isNumber(a[orderBy])
        ? a[orderBy]
        : a[orderBy].toUpperCase();

      // $FlowIgnore
      const valueB = isNumber(b[orderBy])
        ? b[orderBy]
        : b[orderBy].toUpperCase();

      if (valueA === valueB) {
        return 0;
      }
      if (order === 'desc') {
        return valueB < valueA ? -1 : 1;
      }
      return valueA < valueB ? -1 : 1;
    };
  }

  return (a: Object[], b: Object[]) => {
    // $FlowIgnore
    const dateA = moment(a[orderBy]);
    // $FlowIgnore
    const dateB = moment(b[orderBy]);
    if (!dateA.isValid()) {
      return 1;
    }

    if (!dateB.isValid()) {
      return -1;
    }
    return order === 'desc'
      ? // $FlowIgnore
        dateB < dateA
        ? -1
        : 1
      : // $FlowIgnore
      dateA < dateB
      ? -1
      : 1;
  };
};
