import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { decode } from 'query-params';
import routeCodes from 'constants/routes';
import withToJS from 'enhancers/withToJS';
import { hasFeatureFlag } from 'utils/flagsmith';
import { useAuth } from '../../hooks/useAuth';

class UnauthenticatedRoute extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    props: PropTypes.object.isRequired,
    exact: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    location: PropTypes.object,
  };
  static defaultProps = {
    location: null,
  };
  querystring = (name) => {
    const { location } = this.props;

    if (location && location.search) {
      const params = decode(location.search.replace(/\?/g, ''));
      return params[name];
    }
    return null;
  };

  renderComponent(props) {
    const { component: C, props: cProps } = this.props;
    const { auth, user } = cProps;
    const { authenticated } = auth;
    const redirect = this.querystring('redirect');

    if (!authenticated || !user.role) {
      return <C {...props} {...cProps} />;
    }

    const auth0 = hasFeatureFlag('auth0');

    if (auth0 && !this.props.isAuthenticated) {
      this.props.getToken();
    }

    return (
      <Redirect
        to={!redirect || redirect === '' ? routeCodes.DASHBOARD : redirect}
      />
    );
  }

  render() {
    const { exact, path } = this.props;
    return (
      <Route
        exact={exact}
        path={path}
        render={(props) => this.renderComponent(props)}
      />
    );
  }
}

const UnauthenticatedRouteWithAuth0 = (props) => {
  const { getToken } = useAuth({ callGetToken: false });
  return <UnauthenticatedRoute {...props} getToken={getToken} />;
};

export default withToJS(UnauthenticatedRouteWithAuth0);
