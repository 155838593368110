import api from 'api';
import client from 'api/apollo';
import { userAsyncSuccess, userAsyncStart } from 'actions/user';
import { showSplashScreen } from 'actions/layout';
import { getFromState, logError } from 'helpers';
import { LOGIN_USER } from '../graphql/mutations/user/loginUser';
import { ALLOWED_LOGIN_ROLES, ROLES } from '../models/User';

import { LocalStorageCache } from '@auth0/auth0-react';
export const AUTH_ASYNC_START = 'AUTH_ASYNC_START';
export const AUTH_ASYNC_FAIL = 'AUTH_ASYNC_FAIL';
export const AUTH_ASYNC_SUCCESS = 'AUTH_ASYNC_SUCCESS';
export const AUTH_ACTION_FINISH = 'AUTH_ACTION_FINISH';
export const DE_AUTH = 'DE_AUTH';

export function authAsyncStart() {
  return {
    type: AUTH_ASYNC_START,
  };
}

export function authAsyncFail(error) {
  return {
    type: AUTH_ASYNC_FAIL,
    error,
  };
}

export function authActionFinish(error) {
  return {
    type: AUTH_ACTION_FINISH,
    error,
  };
}

export function authAsyncSuccess(data) {
  return {
    type: AUTH_ASYNC_SUCCESS,
    data,
  };
}

export function deAuthSuccess() {
  return {
    type: DE_AUTH,
  };
}

export async function authenticationRefreshAuth0(dispatch, getState) {
  const { authPayload } = getFromState(getState, 'auth');
  const { refreshToken } = authPayload.token;
  const { email } = getFromState(getState, 'user');
  dispatch(authAsyncStart());
  try {
    const response = await api({
      path: 'auth/refresh-token-auth0',
      method: 'POST',
      body: {
        refreshToken,
        email,
      },
    });
    const { token } = response;
    dispatch(authAsyncSuccess({ token }));
    return response;
  } catch (error) {
    logError(error);
    dispatch(authAsyncFail('Your session has expired. Please log in'));
    return null;
  }
}

export const auth0ValidateToken = (token) => async (dispatch) => {
  dispatch(authAsyncStart());
  try {
    const response = await api({
      path: 'auth/auth0-validation',
      method: 'get',
      accessToken: token,
    });
    const { token: tokenInfo, user } = response;
    const storageCache = new LocalStorageCache();
    const key = storageCache.allKeys().find((key) => key.includes('auth0spa'));
    const refreshTokenInfo = storageCache.get(key);
    const refreshToken = refreshTokenInfo.body.refresh_token;
    const isAllowedUser = ALLOWED_LOGIN_ROLES.includes(user.role);
    if (!isAllowedUser) {
      const message = 'You are not authorized to access this page';
      dispatch(authAsyncFail(message));
      throw new Error(message);
    }
    dispatch(authAsyncSuccess({ token: { ...tokenInfo, refreshToken } }));
    dispatch(userAsyncSuccess(user));
    dispatch(showSplashScreen());
    return response;
  } catch (error) {
    logError(error);
    const message = 'Your session has expired. Please log in';
    dispatch(authAsyncFail(error.message || message));
    throw new Error(message);
  }
};

export async function authenticationRefresh(dispatch, getState) {
  dispatch(authAsyncStart());
  try {
    const { email } = getFromState(getState, 'user');
    const { authPayload } = getFromState(getState, 'auth');
    const refreshTokenVal = authPayload.token.refreshToken;
    const response = await api({
      path: 'auth/refresh-token',
      method: 'POST',
      body: {
        email,
        refreshToken: refreshTokenVal,
      },
    });
    const { token } = response;
    dispatch(authAsyncSuccess({ token }));
    return response;
  } catch (error) {
    logError(error);
    dispatch(authAsyncFail('Your session has expired. Please log in'));
    return null;
  }
}

export function registerUser(values) {
  return async (dispatch) => {
    dispatch(authAsyncStart());
    try {
      const response = await api({
        path: 'auth/register',
        method: 'POST',
        body: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          password: values.password,
          disciplineId: values.disciplineId,
          DOB: values.DOB,
        },
      });

      const { token, user } = response;
      dispatch(authAsyncSuccess({ token }));
      dispatch(userAsyncSuccess(user));
      return response;
    } catch (error) {
      logError(error);
      const err =
        'There was a problem registering your account, please try again.';
      dispatch(authAsyncFail(err));
      return null;
    }
  };
}

export function requestPasswordReset(values) {
  return async (dispatch) => {
    dispatch(authAsyncStart());
    try {
      await api({
        path: 'auth/password/forgot',
        method: 'POST',
        body: {
          email: values.email,
        },
      });

      dispatch(authActionFinish());
      return 'Your password reset request has been received, please check your email inbox!';
    } catch (error) {
      logError(error);
      const err = 'This email is not registered';
      dispatch(authAsyncFail(err));
      return null;
    }
  };
}

export function resetPassword(values) {
  return async (dispatch) => {
    dispatch(authAsyncStart());
    try {
      const response = await api({
        path: `auth/password/reset?token=${values.token}`,
        method: 'POST',
        body: {
          newPassword: values.newPassword,
        },
      });

      const { token, user } = response;
      dispatch(authAsyncSuccess({ token }));
      dispatch(userAsyncSuccess(user));
      return response;
    } catch (error) {
      logError(error);
      const err = 'Invalid token';
      dispatch(authActionFinish(err));
      return null;
    }
  };
}

export function authenticateUser(email, password) {
  return async (dispatch) => {
    dispatch(authAsyncStart());
    dispatch(userAsyncStart());
    try {
      const variables = {
        input: { email, password },
      };

      const loginUserPayload = {
        mutation: LOGIN_USER,
        variables,
      };

      const response = await client.mutate(loginUserPayload);
      const { token, user } = response.data.loginUser;

      if (
        user.role !== ROLES.ADMIN &&
        user.role !== ROLES.SECRETARY &&
        user.role !== ROLES.ATHLETE_SUPPORT &&
        user.role !== ROLES.ADMIN_VIEW_ONLY &&
        user.role !== ROLES.SALES_REP
      ) {
        throw new Error('Incorrect email or password');
      }

      dispatch(authAsyncSuccess({ token }));
      dispatch(userAsyncSuccess(user));
      dispatch(showSplashScreen());

      return response;
    } catch (error) {
      logError(error);
      dispatch(authAsyncFail('Incorrect email or password'));
      return null;
    }
  };
}

export function deauthenticateUser() {
  return (dispatch) => {
    return dispatch(deAuthSuccess());
  };
}

export function clearAuthState() {
  return (dispatch) => {
    return dispatch(authAsyncFail(''));
  };
}
