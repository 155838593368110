// @flow
/**
 * This function will merge the new state of the filter with its previous
 * state and merge in localStorage
 * @param {*} filterConfig filterSchemas/{filterName}.js
 * @param {*} filterValues value of a specific field passes to filterChanged()
 */
export const persistAndMergeFilter = (
  filterConfig: Object,
  filterValues: Object,
) => {
  const merged = { ...filterConfig };
  const { name: filterName } = filterConfig;
  const indexToBeUpdated = filterConfig[filterName].findIndex(
    (filterColumn) => {
      return filterColumn.columnName === filterValues[filterName].columnName;
    },
  );
  merged[filterName][indexToBeUpdated] = filterValues[filterName];
  localStorage.setItem(
    `@listing-filter/${filterConfig.name}`,
    JSON.stringify({ filterName, filterValues: merged }),
  );
  return merged;
};
