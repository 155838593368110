// @flow
type PluralizeParams = {
  count: number,
  noun: string,
  suffix: string,
};

export function pluralize({
  count = 0,
  noun = '',
  suffix = 's',
}: PluralizeParams) {
  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
}
