// @flow
import { Record } from 'immutable';

export type ContestantDisciplineType = {
  Id: number,
  ERAContestantDisciplineUID: number,
  ERAUID: number,
  FullName: string,
  BOB: String,
  Email: String,
  PhoneNumber: String,
  NomDate: string,
  SegmentName: string,
  DisciplineName: string,
  Classification: string,
  DisciplineUID: number,
  // This represents if this classification is marked as verified in the DB
  Verified: boolean,
  SegmentShortName: string,
  HasPro: boolean,
  HasChallenger: boolean,
  HasYouth: boolean,
  HasOpen: boolean,
  HasLimited: boolean,
  CompetitionLevel: number,
  SegmentUID: number,
  ClassificationDate: string,
  Notes: string,
  // This represents if this classification has been set as verified in the UI (still hasn't been verified in the DB)
  LocalVerified: boolean,
};

const defaultValues = {
  Id: 0,
  ERAContestantDisciplineUID: 0,
  ERAUID: 0,
  SegmentUID: 0,
  FullName: '',
  DOB: '',
  Email: '',
  PhoneNumber: '',
  NomDate: '',
  SegmentName: '',
  DisciplineName: '',
  Classification: '',
  DisciplineUID: 0,
  Verified: false,
  SegmentShortName: '',
  HasPro: false,
  HasChallenger: false,
  HasYouth: false,
  HasOpen: false,
  HasLimited: false,
  CompetitionLevel: 0,
  ClassificationDate: null,
  Notes: '',
  LocalVerified: false,
};

export default class ContestantDiscipline extends Record(
  defaultValues,
  'ContestantDiscipline',
) {
  constructor(values: ?ContestantDisciplineType) {
    super({
      ...values,
    });
  }
}
