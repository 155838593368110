// @flow
import { Record } from 'immutable';

export type GiftCardType = {
  GiftCardUID: ?number,
  Color: ?string,
  Name: ?string,
  Balance: ?number,
  Cost: ?number,
  Validity: ?string,
  GiftCardStatus: ?string,
  Visibility: ?string,
  Subject: ?string,
  EmailBody: ?string,
  createdAt: ?string,
  updatedAt: ?string,
  LastUpdatedByERAUID: ?number,
  RemainingBalance: ?number,
  CreatedForERAUID: ?number,
  athleteName: ?string,
  athleteEmail: ?string,
};

const defaultValues: GiftCardType = {
  GiftCardUID: null,
  Color: null,
  Name: null,
  Balance: null,
  Cost: null,
  Validity: null,
  GiftCardStatus: null,
  Visibility: null,
  Subject: null,
  EmailBody: null,
  createdAt: null,
  updatedAt: null,
  LastUpdatedByERAUID: null,
  RemainingBalance: null,
  CreatedForERAUID: null,
  athleteName: null,
  athleteEmail: null,
};

export default class GiftCard extends Record(defaultValues, 'GiftCard') {
  constructor(values: ?GiftCardType) {
    super({
      ...values,
    });
  }
}
