// @flow
import { Record } from 'immutable';

export type PersonnelRoleType = {
  PersonnelRoleId: number,
  PersonnelRoleName: string,
};

const defaultValues: PersonnelRoleType = {
  PersonnelRoleId: 0,
  PersonnelRoleName: '',
};

export default class PersonnelRole extends Record(
  defaultValues,
  'PersonnelRole',
) {
  constructor(values: ?PersonnelRoleType) {
    super({
      ...values,
    });
  }
}
