// @flow
import type { DisciplineType } from 'models/Discipline';
import {
  isSecondaryTeamDiscipline,
  disciplinesByName,
  disciplinesById,
  disciplinesByAbbreviation,
} from '../constants/disciplines';
const {
  TEAM_ROPING_HEADER,
  TEAM_ROPING_HEELER,
  DALLY_RIBBON_ROPER,
  DALLY_RIBBON_RUNNER,
} = disciplinesById;

export function consolidateTeamPerformance(
  disciplines: DisciplineType[] = [],
): DisciplineType[] {
  let disciplineList = disciplines.filter(
    ({ id }) => !isSecondaryTeamDiscipline(id),
  );

  disciplineList.forEach((d) => {
    if (d.id === TEAM_ROPING_HEADER) {
      disciplineList = [
        ...disciplineList,
        {
          ...d,
          id: TEAM_ROPING_HEELER,
          name: disciplinesByName[TEAM_ROPING_HEELER],
          Short: disciplinesByAbbreviation[TEAM_ROPING_HEELER],
        },
      ];
    }

    if (d.id === DALLY_RIBBON_ROPER) {
      disciplineList = [
        ...disciplineList,
        {
          ...d,
          id: DALLY_RIBBON_RUNNER,
          name: disciplinesByName[DALLY_RIBBON_RUNNER],
          Short: disciplinesByAbbreviation[DALLY_RIBBON_RUNNER],
        },
      ];
    }
  });

  return disciplineList;
}
