// @flow

type DataType = {|
  [key: string]: {
    data: ?any,
  },
|};
type GetState = () => DataType;

export const getFromState = (
  getState: GetState,
  key: string,
  defaultValue: ?any = {},
): any => {
  const stateData = getState()[key].data;
  try {
    return stateData ? stateData.toJS() : defaultValue;
  } catch (error) {
    return stateData || defaultValue;
  }
};

export const getKeyFromState = (
  getState: () => Object,
  key: string,
  defaultValue: ?any = {},
): any => {
  const stateData = getState()[key];
  try {
    return stateData ? stateData.toJS() : defaultValue;
  } catch (error) {
    return stateData || defaultValue;
  }
};
