// @flow
import { getNestedProperty } from './getNestedProperty';

export const loadPersistedFilter = (filterConfig: Object) => {
  const loadedFilters = localStorage.getItem(
    `@listing-filter/${filterConfig.name}`,
  );
  const loadedFiltersJS = loadedFilters ? JSON.parse(loadedFilters) : null;

  const usableLoadedFilters =
    loadedFiltersJS &&
    loadedFiltersJS.filterValues[filterConfig.name].length ===
      filterConfig[filterConfig.name].length
      ? loadedFiltersJS
      : null;

  // Don't load filter scheme options from local storage, replace with scheme
  if (usableLoadedFilters) {
    usableLoadedFilters.filterValues[filterConfig.name].forEach(
      (filterValues, index) => {
        if (getNestedProperty('options', filterValues)) {
          usableLoadedFilters.filterValues[filterConfig.name][index].options =
            filterConfig[filterConfig.name][index].options;
        }
      },
    );
  }

  return loadedFiltersJS
    ? loadedFiltersJS
    : { filterName: filterConfig.name, filterValues: filterConfig };
};
