import api from 'api';
import client from 'api/apollo';
import { createAlertSuccess } from 'actions/alert';
import { postToastMessage } from 'helpers/postToastMessage';
import {
  getFromState,
  getKeyFromState,
  getToken,
  toJS,
  logError,
} from 'helpers';
import StockDraw from 'models/StockDraw';
import { shuffleList } from 'models/BaseList';
import { barrelRacingDisciplines } from 'constants/disciplines';
import stockDrawClearTypes from 'constants/stockDrawClearTypes';
import { GET_AVAILABLE_STOCKDRAW_TEAMS } from 'graphql/queries/stockDraw/getTeams';
import { GET_TEAM_STOCK_DRAW_LIST } from 'graphql/queries/stockDraw/getTeamStockDrawList';
import { CREATE_UPDATE_TEAM_STOCK_DRAW } from 'graphql/mutations/stockDraw/createUpdateTeamStockDraw';

export const PERFORMANCE_ORDER_ASYNC_START = 'PERFORMANCE_ORDER_ASYNC_START';
export const PERFORMANCE_ORDER_ASYNC_STOP = 'PERFORMANCE_ORDER_ASYNC_STOP';
export const PERFORMANCE_ORDER_ASYNC_FAIL = 'PERFORMANCE_ORDER_ASYNC_FAIL';
export const PERFORMANCE_ORDER_LIST_ASYNC_SUCCESS =
  'PERFORMANCE_ORDER_LIST_ASYNC_SUCCESS';
export const MOVE_PERFORMANCE_ORDER_SUCCESS = 'MOVE_PERFORMANCE_ORDER_SUCCESS';
export const MOVE_PERFORMANCE_ORDER_FAIL = 'MOVE_PERFORMANCE_ORDER_FAIL';
export const ADD_UPDATE_PERFORMANCE_ORDER_SUCCESS =
  'ADD_UPDATE_PERFORMANCE_ORDER_SUCCESS';
export const ADD_UPDATE_PERFORMANCE_ORDER_FAIL =
  'ADD_UPDATE_PERFORMANCE_ORDER_FAIL';

export const ADD_UPDATE_TEAM_PERFORMANCE_ORDER_SUCCESS =
  'ADD_UPDATE_TEAM_PERFORMANCE_ORDER_SUCCESS';

function stockDrawAsyncStart() {
  return {
    type: PERFORMANCE_ORDER_ASYNC_START,
  };
}

function stockDrawAsyncFail(error) {
  return {
    type: PERFORMANCE_ORDER_ASYNC_FAIL,
    error,
  };
}

export function stockDrawAsyncStop() {
  return {
    type: PERFORMANCE_ORDER_ASYNC_STOP,
  };
}

export function stockDrawListAsyncSuccess(data) {
  return {
    type: PERFORMANCE_ORDER_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function moveStockDrawSuccess(position, direction, limit) {
  return {
    type: MOVE_PERFORMANCE_ORDER_SUCCESS,
    position,
    direction,
    limit,
  };
}

export function moveStockDrawFail(error) {
  return {
    type: MOVE_PERFORMANCE_ORDER_FAIL,
    error,
  };
}

export function addUpdateStockDrawSuccess(
  fieldName,
  athleteData,
  position,
  fieldType,
) {
  return {
    type: ADD_UPDATE_PERFORMANCE_ORDER_SUCCESS,
    fieldName,
    value: athleteData,
    position,
    fieldType,
  };
}

export function addUpdateStockDrawFail(error) {
  return {
    type: ADD_UPDATE_PERFORMANCE_ORDER_FAIL,
    error,
  };
}

export function addUpdateTeamStockDrawSuccess(
  fieldName,
  teamData,
  position,
  fieldType,
) {
  return {
    type: ADD_UPDATE_TEAM_PERFORMANCE_ORDER_SUCCESS,
    fieldName,
    value: teamData,
    position,
    fieldType,
  };
}
export function getStockDrawsFromState() {
  return async (dispatch, getState) => {
    const stockDraw = getKeyFromState(getState, 'stockDraw');
    return stockDraw;
  };
}
export function listStockDraws(performance, discipline) {
  return async (dispatch, getState) => {
    dispatch(stockDrawAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `stock-draw?PerformanceUID=${
          performance.PerformanceUID
        }&CompType=${discipline.id}`,
        method: 'GET',
        accessToken,
      });
      dispatch(stockDrawListAsyncSuccess(response.data));
      return response.data;
    } catch (error) {
      logError(error);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}

export async function getPartnerStockDraw(performance, discipline) {
  try {
    const variables = {
      performanceUID: performance.PerformanceUID,
      compType: discipline,
    };
    const teamStockDrawListQuery = {
      query: GET_TEAM_STOCK_DRAW_LIST,
      variables,
      fetchPolicy: 'network-only',
    };
    const teamStockDrawList = await client.query(teamStockDrawListQuery);
    return teamStockDrawList.data.stockDrawListGet;
  } catch (error) {
    logError(error);
    return null;
  }
}

export function listTeamStockDraws(performance, discipline) {
  return async (dispatch) => {
    dispatch(stockDrawAsyncStart());
    try {
      const variables = {
        performanceUID: performance.PerformanceUID,
        compType: discipline.id,
      };
      const teamStockDrawListQuery = {
        query: GET_TEAM_STOCK_DRAW_LIST,
        variables,
        fetchPolicy: 'network-only',
      };
      const teamStockDrawList = await client.query(teamStockDrawListQuery);
      dispatch(
        stockDrawListAsyncSuccess(teamStockDrawList.data.stockDrawListGet),
      );
      return teamStockDrawList.data.stockDrawListGet;
    } catch (error) {
      logError(error);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}
export function listAvailableTeams(performance, discipline) {
  return async (dispatch) => {
    dispatch(stockDrawAsyncStart());
    try {
      const variables = {
        performanceUID: performance.PerformanceUID,
        disciplineId: discipline.id,
      };

      const availableTeamsStockDrawQuery = {
        query: GET_AVAILABLE_STOCKDRAW_TEAMS,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(availableTeamsStockDrawQuery);
      dispatch(stockDrawAsyncStop());

      return response.data.stockDrawAvailableTeamsGet;
    } catch (error) {
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}
export function listAvailableAthletes(performance, discipline) {
  return async (dispatch, getState) => {
    dispatch(stockDrawAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `stock-draw/available-athletes?PerformanceUID=${
          performance.PerformanceUID
        }&CompType=${discipline.id}`,
        method: 'GET',
        accessToken,
      });
      dispatch(stockDrawAsyncStop());
      return response.data;
    } catch (error) {
      logError(error);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}
export function moveStockDraw(position, direction, limit) {
  return async (dispatch) => {
    try {
      await dispatch(moveStockDrawSuccess(position, direction, limit));
      return position;
    } catch (error) {
      logError(error);
      dispatch(moveStockDrawFail(error.message));
      return null;
    }
  };
}
export function addUpdateTeamStockDraw(fieldName, teamData, position, type) {
  return async (dispatch) => {
    try {
      dispatch(
        addUpdateTeamStockDrawSuccess(fieldName, teamData, position, type),
      );
      return position;
    } catch (error) {
      logError(error);
      dispatch(addUpdateStockDrawFail(error.message));
      return null;
    }
  };
}
export function addUpdateStockDraw(fieldName, value, position, fieldType) {
  return async (dispatch) => {
    try {
      dispatch(
        addUpdateStockDrawSuccess(fieldName, value, position, fieldType),
      );
      return position;
    } catch (error) {
      logError(error);
      dispatch(addUpdateStockDrawFail(error.message));
      return null;
    }
  };
}

export function saveTeamStockDraw() {
  return async (dispatch, getState) => {
    const { list: stockDrawList } = getKeyFromState(getState, 'stockDraw');
    dispatch(stockDrawAsyncStart());
    try {
      const { PerformanceUID } = getFromState(getState, 'performance');
      const { id: CompType } = getFromState(getState, 'discipline');
      const input = {
        PerformanceUID,
        CompType,
        list: stockDrawList,
      };
      const mutationPayload = {
        mutation: CREATE_UPDATE_TEAM_STOCK_DRAW,
        variables: { input },
      };
      const response = await client
        .mutate(mutationPayload)
        .then(({ data }) => data.createUpdateTeamStockDraw);

      await dispatch(
        createAlertSuccess({
          message: 'Stock draw options saved!',
          type: 'success',
        }),
      );
      setTimeout(() => {
        dispatch({ type: 'CLEAR_ALERT_SUCCESS' });
      }, 5000);
      await dispatch(stockDrawListAsyncSuccess(response));
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Unable to save stock draw. Please contact administrator.',
          type: 'fail',
        }),
      );
      setTimeout(() => {
        dispatch({ type: 'CLEAR_ALERT_SUCCESS' });
      }, 5000);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}
export function saveStockDraw() {
  return async (dispatch, getState) => {
    dispatch(stockDrawAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { list: stockDrawList } = getKeyFromState(getState, 'stockDraw');
      const { PerformanceUID } = getFromState(getState, 'performance');
      const { id: CompType } = getFromState(getState, 'discipline');

      const response = await api({
        path: `stock-draw?PerformanceUID=${PerformanceUID}&CompType=${CompType}`,
        method: 'PATCH',
        accessToken,
        body: stockDrawList,
      });

      await dispatch(
        createAlertSuccess({
          message: 'Stock draw options saved!',
          type: 'success',
        }),
      );
      setTimeout(() => {
        dispatch({ type: 'CLEAR_ALERT_SUCCESS' });
      }, 5000);

      await dispatch(stockDrawListAsyncSuccess(response.data));

      return response.data;
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Unable to save stock draw. Please contact administrator.',
          type: 'fail',
        }),
      );

      setTimeout(() => {
        dispatch({ type: 'CLEAR_ALERT_SUCCESS' });
      }, 5000);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}

export function postStockDraw({ messages, isRepost, disciplineId }) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { id: EventUID } = getFromState(getState, 'event');
      const { PerformanceUID } = getFromState(getState, 'performance');
      const { id } = getFromState(getState, 'discipline');
      const CompType = disciplineId ? disciplineId : id;
      const response = await api({
        path: `stock-draw/status?EventUID=${EventUID}&PerformanceUID=${PerformanceUID}&CompType=${CompType}`,
        method: isRepost ? 'PUT' : 'POST',
        accessToken,
        body: { messages },
      });

      dispatch(
        postToastMessage('Performance order posted and text messages sent!'),
      );

      return response.data;
    } catch (error) {
      logError(error);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}

export function getStockDrawStatus() {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { PerformanceUID } = getFromState(getState, 'performance');
      const { id: CompType } = getFromState(getState, 'discipline');

      const response = await api({
        path: `stock-draw/status?PerformanceUID=${PerformanceUID}&CompType=${CompType}`,
        method: 'GET',
        accessToken,
      });
      return response;
    } catch (error) {
      logError(error);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}
export function clearTeamStockDraw(
  stockDrawClearType = stockDrawClearTypes.ALL,
) {
  return async (dispatch, getState) => {
    dispatch(stockDrawAsyncStart());
    try {
      const { list: stockDrawList } = getKeyFromState(getState, 'stockDraw');
      const stockDrawListToSave = stockDrawList.map((stockDrawItem) => {
        switch (stockDrawClearType) {
          case stockDrawClearTypes.ANIMAL: {
            stockDrawItem.AnimalUID = null;
            break;
          }
          case stockDrawClearTypes.TEAM: {
            stockDrawItem.ERAUID = null;
            stockDrawItem.EPUID = null;
            stockDrawItem.PartnerERAUID = null;
            stockDrawItem.PartnerEPUID = null;
            break;
          }
          default: {
            stockDrawItem.AnimalUID = null;
            stockDrawItem.ERAUID = null;
            stockDrawItem.EPUID = null;
            stockDrawItem.PartnerERAUID = null;
            stockDrawItem.PartnerEPUID = null;
          }
        }

        return stockDrawItem;
      });

      const { PerformanceUID } = getFromState(getState, 'performance');
      const { id: CompType } = getFromState(getState, 'discipline');
      const input = {
        PerformanceUID,
        CompType,
        list: stockDrawListToSave,
      };

      const mutationPayload = {
        mutation: CREATE_UPDATE_TEAM_STOCK_DRAW,
        variables: { input },
      };
      const response = await client
        .mutate(mutationPayload)
        .then(({ data }) => data.createUpdateTeamStockDraw);

      const successType =
        stockDrawClearType === stockDrawClearTypes.ANIMAL ? 'animals' : 'teams';

      await dispatch(
        createAlertSuccess({
          message: `Stock draw ${successType} cleared!`,
          type: 'success',
        }),
      );
      await dispatch(stockDrawListAsyncSuccess(response));
    } catch (error) {
      logError(error);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}
export function clearStockDraw(stockDrawClearType = stockDrawClearTypes.ALL) {
  return async (dispatch, getState) => {
    dispatch(stockDrawAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { list: stockDrawList } = getKeyFromState(getState, 'stockDraw');
      const stockDrawListToSave = stockDrawList.map((stockDrawItem) => {
        switch (stockDrawClearType) {
          case stockDrawClearTypes.ANIMAL:
            stockDrawItem.AnimalUID = null;
            break;
          case stockDrawClearTypes.ATHLETE:
            stockDrawItem.EPUID = null;
            stockDrawItem.ERAUID = null;
            break;
          default:
            stockDrawItem.AnimalUID = null;
            stockDrawItem.EPUID = null;
            stockDrawItem.ERAUID = null;
        }

        return stockDrawItem;
      });

      const { PerformanceUID } = getFromState(getState, 'performance');
      const { id: CompType } = getFromState(getState, 'discipline');

      const response = await api({
        path: `stock-draw?PerformanceUID=${PerformanceUID}&CompType=${CompType}`,
        method: 'PATCH',
        accessToken,
        body: stockDrawListToSave,
      });

      const successType =
        stockDrawClearType === stockDrawClearTypes.ANIMAL
          ? 'animals'
          : 'athletes';

      await dispatch(
        createAlertSuccess({
          message: `Stock draw ${successType} cleared!`,
          type: 'success',
        }),
      );
      setTimeout(() => {
        dispatch({ type: 'CLEAR_ALERT_SUCCESS' });
      }, 6000);

      dispatch(stockDrawListAsyncSuccess(stockDrawListToSave));
      return response.data;
    } catch (error) {
      logError(error);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}

function getShuffledAthleteIds(athletes, stockDrawForm) {
  if (!stockDrawForm) return [];
  const athleteIds = athletes.map((athlete) => {
    return { EPUID: athlete.EPUID, ERAUID: athlete.ERAUID };
  });
  return shuffleList(athleteIds);
}

function getShuffledAnimalIds(animals, stockDrawForm) {
  if (!stockDrawForm) return [];

  const animalIds = animals.map((animal) => animal.AnimalUID);

  return shuffleList(animalIds);
}

function getShuffledTeamsIds(teams, stockDrawForm) {
  if (!stockDrawForm) return [];
  const teamIds = teams.map((team) => team.HeaderEPUID);

  return shuffleList(teamIds);
}

function getTeamAutoFillStockDrawList(state, teams, animals) {
  const { form, discipline: disciplineBase, stockDraw } = state;
  const stockDrawForm = toJS(form.editStockDrawPerformance);
  const discipline = toJS(disciplineBase.data);

  const animalLimit = discipline.NumberOfAnimals;
  const stockDrawList = toJS(stockDraw.list);

  const positions = animalLimit;
  const positionIds = Array.from(Array(positions), (val, idx) => idx + 1);

  const shuffledTeams = getShuffledTeamsIds(teams, stockDrawForm);
  const shuffledAnimalIds = getShuffledAnimalIds(animals, stockDrawForm);

  if (stockDrawList.length === 0) {
    const updatedList = positionIds.map((position) => {
      const poppedEPUID = shuffledTeams.pop();
      const poppedAnimalId = shuffledAnimalIds.pop();
      const foundTeam = teams.find((team) => team.HeaderEPUID == poppedEPUID);
      const teamData = foundTeam != undefined ? foundTeam : null;
      return {
        AnimalUID: poppedAnimalId,
        EPUID: poppedEPUID,
        ERAUID: teamData ? teamData.HeaderID : null,
        PartnerEPUID: teamData ? teamData.HeelerEPUID : null,
        PartnerERAUID: teamData ? teamData.HeelerID : null,
        Position: position,
      };
    });
    return updatedList;
  }
  const updatedStockDrawList = positionIds.map((position) => {
    const stockDrawItem = stockDrawList.find(
      (item) => item.Position === position,
    );
    if (!stockDrawItem) {
      const poppedEPUID = shuffledTeams.pop();
      const poppedAnimalId = shuffledAnimalIds.pop();
      const foundTeam = teams.find((team) => team.HeaderEPUID == poppedEPUID);
      const teamData = foundTeam != undefined ? foundTeam : null;
      return new StockDraw({
        AnimalUID: poppedAnimalId,
        EPUID: poppedEPUID,
        ERAUID: teamData ? teamData.HeaderID : null,
        PartnerEPUID: teamData ? teamData.HeelerEPUID : null,
        PartnerERAUID: teamData ? teamData.HeelerID : null,
        Position: position,
      });
    }

    const updatedStockDrawItem = stockDrawItem;
    const assignedTeamId = shuffledTeams.pop();
    const assignedAnimalId = shuffledAnimalIds.pop();
    const foundTeam = teams.find((team) => team.HeaderEPUID == assignedTeamId);
    const teamData = foundTeam != undefined ? foundTeam : null;

    updatedStockDrawItem.EPUID = updatedStockDrawItem.EPUID
      ? updatedStockDrawItem.EPUID
      : assignedTeamId;
    if (teamData) {
      updatedStockDrawItem.ERAUID = updatedStockDrawItem.ERAUID
        ? updatedStockDrawItem.ERAUID
        : teamData.HeaderID;

      updatedStockDrawItem.PartnerEPUID = updatedStockDrawItem.PartnerEPUID
        ? updatedStockDrawItem.PartnerEPUID
        : teamData.HeelerEPUID;

      updatedStockDrawItem.PartnerERAUID = updatedStockDrawItem.PartnerERAUID
        ? updatedStockDrawItem.PartnerERAUID
        : teamData.HeelerID;
    } else {
      updatedStockDrawItem.EPUID = updatedStockDrawItem.EPUID
        ? updatedStockDrawItem.EPUID
        : null;

      updatedStockDrawItem.PartnerEPUID = updatedStockDrawItem.PartnerEPUID
        ? updatedStockDrawItem.PartnerEPUID
        : null;

      updatedStockDrawItem.PartnerERAUID = updatedStockDrawItem.PartnerERAUID
        ? updatedStockDrawItem.PartnerERAUID
        : null;
    }
    updatedStockDrawItem.AnimalUID = updatedStockDrawItem.AnimalUID
      ? updatedStockDrawItem.AnimalUID
      : assignedAnimalId;

    return new StockDraw(updatedStockDrawItem);
  });
  const reversedPositionIds = Array.from(Array(animalLimit), (val, idx) =>
    Math.abs(idx - animalLimit),
  );

  return updatedStockDrawList.map((stockDrawItem) => {
    if (stockDrawItem.Position) return stockDrawItem;

    let stockDrawItemUpdated = stockDrawItem;
    stockDrawItemUpdated = stockDrawItemUpdated.set(
      'Position',
      reversedPositionIds.pop(),
    );

    return stockDrawItemUpdated;
  });
}

export function teamAutoFillStockDraw(teams, animals) {
  return async (dispatch, getState) => {
    dispatch(stockDrawAsyncStart());
    try {
      const state = getState();
      const autoFillStockDrawList = getTeamAutoFillStockDrawList(
        state,
        teams,
        animals,
      );
      const { PerformanceUID } = getFromState(getState, 'performance');
      const { id: CompType } = getFromState(getState, 'discipline');

      const input = {
        PerformanceUID,
        CompType,
        list: autoFillStockDrawList,
      };
      const mutationPayload = {
        mutation: CREATE_UPDATE_TEAM_STOCK_DRAW,
        variables: { input },
      };
      const response = await client
        .mutate(mutationPayload)
        .then(({ data }) => data.createUpdateTeamStockDraw);
      await dispatch(
        createAlertSuccess({
          message: 'Stock draw updated!',
          type: 'success',
        }),
      );
      setTimeout(() => {
        dispatch({ type: 'CLEAR_ALERT_SUCCESS' });
      }, 6000);

      dispatch(stockDrawListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}

function getAutoFillStockDrawList(state, athletes, animals) {
  const { form, discipline: disciplineBase, stockDraw } = state;
  const stockDrawForm = toJS(form.editStockDrawPerformance);
  const discipline = toJS(disciplineBase.data);

  const animalLimit = discipline.NumberOfAnimals;
  const stockDrawList = toJS(stockDraw.list);

  const positions = barrelRacingDisciplines.includes(discipline.id)
    ? (athletes && athletes.length) || 0
    : animalLimit;
  const positionIds = Array.from(Array(positions), (val, idx) => idx + 1);

  const shuffledAthleteIds = getShuffledAthleteIds(athletes, stockDrawForm);
  const shuffledAnimalIds = getShuffledAnimalIds(animals, stockDrawForm);

  if (stockDrawList.length === 0) {
    return positionIds.map((position, index) => {
      return {
        AnimalUID: shuffledAnimalIds.pop(),
        EPUID: shuffledAthleteIds[index]
          ? shuffledAthleteIds[index].EPUID
          : null,
        ERAUID: shuffledAthleteIds[index]
          ? shuffledAthleteIds[index].ERAUID
          : null,
        Position: position,
      };
    });
  }

  const updatedStockDrawList = positionIds.map((position, index) => {
    const stockDrawItem = stockDrawList.find(
      (item) => item.Position === position,
    );
    if (!stockDrawItem) {
      return new StockDraw({
        AnimalUID: shuffledAnimalIds.pop(),
        ERAUID: shuffledAthleteIds[index]
          ? shuffledAthleteIds[index].ERAUID
          : null,
        EPUID: shuffledAthleteIds[index]
          ? shuffledAthleteIds[index].EPUID
          : null,
        Position: position,
      });
    }

    const updatedStockDrawItem = stockDrawItem;
    const assignedAthleteId = shuffledAthleteIds[index]
      ? shuffledAthleteIds[index].EPUID
      : null;
    const assignedAthleteERAUID = shuffledAthleteIds[index]
      ? shuffledAthleteIds[index].ERAUID
      : null;
    const assignedAnimalId = shuffledAnimalIds.pop();
    updatedStockDrawItem.EPUID = updatedStockDrawItem.EPUID
      ? updatedStockDrawItem.EPUID
      : assignedAthleteId;
    updatedStockDrawItem.AnimalUID = updatedStockDrawItem.AnimalUID
      ? updatedStockDrawItem.AnimalUID
      : assignedAnimalId;
    updatedStockDrawItem.ERAUID = updatedStockDrawItem.ERAUID
      ? updatedStockDrawItem.ERAUID
      : assignedAthleteERAUID;

    return new StockDraw(updatedStockDrawItem);
  });

  const reversedPositionIds = Array.from(Array(animalLimit), (val, idx) =>
    Math.abs(idx - animalLimit),
  );

  return updatedStockDrawList.map((stockDrawItem) => {
    if (stockDrawItem.Position) return stockDrawItem;

    let stockDrawItemUpdated = stockDrawItem;
    stockDrawItemUpdated = stockDrawItemUpdated.set(
      'Position',
      reversedPositionIds.pop(),
    );
    return stockDrawItemUpdated;
  });
}

export function autoFillStockDraw(athletes, animals) {
  return async (dispatch, getState) => {
    dispatch(stockDrawAsyncStart());
    try {
      const state = getState();
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const autoFillStockDrawList = getAutoFillStockDrawList(
        state,
        athletes,
        animals,
      );

      const { PerformanceUID } = getFromState(getState, 'performance');
      const { id: CompType } = getFromState(getState, 'discipline');
      const response = await api({
        path: `stock-draw?PerformanceUID=${PerformanceUID}&CompType=${CompType}`,
        method: 'PATCH',
        accessToken,
        body: toJS(autoFillStockDrawList),
      });

      await dispatch(
        createAlertSuccess({
          message: 'Stock draw updated!',
          type: 'success',
        }),
      );
      setTimeout(() => {
        dispatch({ type: 'CLEAR_ALERT_SUCCESS' });
      }, 6000);

      dispatch(stockDrawListAsyncSuccess(response.data));
      return response.data;
    } catch (error) {
      logError(error);
      dispatch(stockDrawAsyncFail(error.message));
      return null;
    }
  };
}
