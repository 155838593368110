import gql from 'graphql-tag';

export const GET_ENTRY_POOL = gql`
  query GET_ENTRY_POOL($input: EntryPoolInput!) {
    entryPoolGet(input: $input) {
      EntryChargeRequestUID
      EPUID
      EventUID
      ERAUID
      ClassName
      PayingERAUID
      userId
      CompType
      PoolTeamID
      QualifyTeamID
      Pref1
      EventEntryDisciplineFeeUID
      Pref2
      Pref3
      Out1
      Out2
      OutIfPrefNotMet
      createdAt
      updatedAt
      TotalAmountPaid
      Pref1AsDate
      Pref2AsDate
      Pref3AsDate
      Out1AsDate
      Out2AsDate
      Pref1AsString
      Pref2AsString
      Pref3AsString
      Out1AsString
      Out2AsString
      Pref1Type
      Pref2Type
      Pref3Type
      Out1Type
      Out2Type
      EventName
      EventAthleteAgreementURL
      FullName
      Discipline
      PartnerEPUID
      HorseName
      email
      firstName
      lastName
      Phone
      LegalFirstName
      LegalLastName
      Nickname
      street
      street2
      state
      city
      zip
      country
      SSNLastFour
      USCitizen
      DOB
      Hometown
      PhotoFilename
      TeammateERAUID
      PaymentStatus
      PartnerPaymentStatus
      PaidPartnerEntryFees
      TeammateName
      PaymentType
      entryRequestCreatedAt
      entryRequestUpdatedAt
      IsYouthAthlete
      HasAgreed
      HasSSN
      Status
      EntryStatus
      PayLater
      entryTransactions {
        baseFee
        ccBrand
        ccLast4
        date
        paymentType
        entries {
          EPUID
          status
          disciplineId
          entryFee
          teammateERAUID
          ERAUID
          isPaid
        }
        entryChargeRequestUID
        eventId
        isPaying
        numberOfEntries
        payeeFullName
        payingERAUID
        stripeFee
        lateFee
        stripeRawResponse
        subtotal
        totalFee
        transactionFee
      }
      isUnregisteredAthlete
      SSNLastFour
      entryPaymentLabel
    }
  }
`;
