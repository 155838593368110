import api from 'api';
import { getFromState, getToken, logError } from 'helpers';

export const PURSE_ASYNC_START = 'PURSE_ASYNC_START';
export const PURSE_ASYNC_FAIL = 'PURSE_ASYNC_FAIL';
export const PURSE_ASYNC_SUCCESS = 'PURSE_ASYNC_SUCCESS';
export const PURSE_ADD_CUSTOM_FEE = 'PURSE_ADD_CUSTOM_FEE';
export const PURSE_CHANGE_CUSTOM_FEES = 'PURSE_CHANGE_CUSTOM_FEES';
export const PURSE_REMOVE_CUSTOM_FEE = 'PURSE_REMOVE_CUSTOM_FEE';

function purseAsyncStart() {
  return {
    type: PURSE_ASYNC_START,
  };
}

function purseAsyncFail(errorMessage) {
  return {
    type: PURSE_ASYNC_FAIL,
    errorMessage,
  };
}

function purseAsyncSuccess(data) {
  return {
    type: PURSE_ASYNC_SUCCESS,
    data,
  };
}

export function purseRemoveCustomFee(customFeeId) {
  return {
    type: PURSE_REMOVE_CUSTOM_FEE,
    customFeeId,
  };
}

export function purseChangeCustomFees(customFees) {
  return {
    type: PURSE_CHANGE_CUSTOM_FEES,
    customFees,
  };
}

export function purseAddCustomFee(feeName) {
  return {
    type: PURSE_ADD_CUSTOM_FEE,
    feeName,
  };
}

export function getPurse() {
  return async (dispatch, getState) => {
    dispatch(purseAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { id: EventUID } = getFromState(getState, 'event');
      const { id: DisciplineUID } = getFromState(getState, 'discipline');

      if (!!EventUID && !!DisciplineUID) {
        const { data: response } = await api({
          path: `event-purse?EventUID=${EventUID}&DisciplineUID=${DisciplineUID}`,
          method: 'GET',
          accessToken,
        });

        dispatch(purseAsyncSuccess(response));
        return response;
      }
    } catch (error) {
      logError(error);
      dispatch(purseAsyncFail(error && error.message));
      return null;
    }
  };
}

export function savePurseCustomFee() {
  return async (dispatch, getState) => {
    dispatch(purseAsyncStart());
    try {
      const formValues = getFromState(getState, 'purse');

      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { id: EventUID } = getFromState(getState, 'event');
      const { id: DisciplineUID } = getFromState(getState, 'discipline');

      if (!!EventUID && !!DisciplineUID) {
        const { data: response } = await api({
          path: `event-purse?EventUID=${EventUID}&DisciplineUID=${DisciplineUID}`,
          method: 'PATCH',
          body: formValues,
          accessToken,
        });

        dispatch(purseAsyncSuccess(response));
        return response;
      }
    } catch (error) {
      logError(error);
      dispatch(purseAsyncFail(error && error.message));
      return null;
    }
  };
}
