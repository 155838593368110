import api from 'api';
import client from 'api/apollo';
import {
  filterToRequest,
  getFilters,
  logError,
  getFromState,
  getToken,
} from 'helpers';
import filterNames from 'constants/filters';
import { GET_CONTESTANT_DISCIPLINES } from 'graphql/queries/contestantDiscipline/getContestantDiscipline';
import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';

export const CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_START =
  'CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_START';
export const CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_SUCCESS =
  'CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_SUCCESS';
export const CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_FAIL =
  'CONTESTANTDISCIPLIE_BY_PAGINATION_ASYNC_FAIL';

export const CONTESTANT_DISCIPLINE_UPDATE_OPTIONS =
  'CONTESTANT_DISCIPLINE_UPDATE_OPTIONS';

export const CONTESTANT_DISCIPLINE_UPDATE = 'CONTESTANT_DISCIPLINE_UPDATE';

export const CONTESTANT_DISCIPLINE_CARD_UPDATE =
  'CONTESTANT_DISCIPLINE_CARD_UPDATE';

function contestantDisciplineByPaginationStart() {
  return {
    type: CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_START,
  };
}

function contestantDisciplineByPaginationSuccess(data) {
  return {
    type: CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_SUCCESS,
    data,
  };
}

function contestantDisciplineByPaginationFail(error) {
  return {
    type: CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_FAIL,
    error,
  };
}

export function contestantDisciplineByPagination(values) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const filters = getFilters(values, state);
      const { pageNumber, sortDirection, orderBy, resultsPerPage } = values;
      const variables = {
        input: {
          filters: filterToRequest(filters),
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
        },
      };

      dispatch(contestantDisciplineByPaginationStart());
      const contestantDisciplineQuery = {
        query: GET_CONTESTANT_DISCIPLINES,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(contestantDisciplineQuery);

      dispatch(contestantDisciplineByPaginationSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      const err =
        'There was a problem getting the contestant disciplines, please try again.';
      dispatch(contestantDisciplineByPaginationFail(err));
      return null;
    }
  };
}

export function updateContestantClassifications(items) {
  return async (dispatch, getState) => {
    try {
      dispatch(contestantDisciplineByPaginationStart());

      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const response = await api({
        path: 'contestant-discipline/update-classifications',
        method: 'PATCH',
        body: { items },
        accessToken,
      });

      const state = getState();
      const filterProps = {
        pageNumber: 0,
        sortDirection: 'asc',
        orderBy: 'Verified',
        resultsPerPage: 25,
      };

      const filters = getFilters(
        {
          filterName: filterNames.CLASSIFICATION_FILTER,
          ...filterProps,
        },
        state,
      );

      const variables = {
        input: {
          filters: filterToRequest(filters),
          ...filterProps,
        },
      };

      dispatch(contestantDisciplineByPaginationStart());

      const contestantDisciplineQuery = {
        query: GET_CONTESTANT_DISCIPLINES,
        variables,
        fetchPolicy: 'network-only',
      };
      const responseList = await client.query(contestantDisciplineQuery);
      dispatch(contestantDisciplineByPaginationSuccess(responseList.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(contestantDisciplineByPaginationFail(error.message));
      return null;
    }
  };
}

export function updateContestantOptions(
  sortDirection,
  orderBy,
  pageNumber,
  resultsPerPage,
) {
  return {
    type: CONTESTANT_DISCIPLINE_UPDATE_OPTIONS,
    data: { sortDirection, orderBy, pageNumber, resultsPerPage },
  };
}

export function updateContestantDisciplines(data) {
  return {
    type: CONTESTANT_DISCIPLINE_UPDATE,
    data,
  };
}

export function updateContestantDisciplineCard(data) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    try {
      const response = await api({
        path: `contestant-discipline/update-classification`,
        method: 'POST',
        body: data,
        accessToken,
      });

      await dispatch(
        createAlertSuccess({
          message: 'Contestant classification saved!',
          type: 'success',
        }),
      );
      dispatch(clearAlertSuccessAsync());

      return response;
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Unable to save contestant classification.',
          type: 'error',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      return error.message;
    }
  };
}
