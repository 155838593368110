import api from 'api';
import client from 'api/apollo';
import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';
import {
  filterToRequest,
  getFilters,
  getFromState,
  getToken,
  logError,
} from 'helpers';

import { LIST_CONTRACTORS } from 'graphql/queries/contractors/listContractors';

export const CONTRACTOR_APPLY_LIST_OPTIONS = 'CONTRACTOR_APPLY_LIST_OPTIONS';
export const CONTRACTOR_ASYNC_START = 'CONTRACTOR_ASYNC_START';
export const CONTRACTOR_ACTION_CLEAR = 'CONTRACTOR_ACTION_CLEAR';
export const CONTRACTOR_ASYNC_FAIL = 'CONTRACTOR_ASYNC_FAIL';
export const CONTRACTOR_ASYNC_SUCCESS = 'CONTRACTOR_ASYNC_SUCCESS';
export const CONTRACTOR_LIST_ASYNC_START = 'CONTRACTOR_LIST_ASYNC_START';
export const CONTRACTOR_LIST_ASYNC_SUCCESS = 'CONTRACTOR_LIST_ASYNC_SUCCESS';
export const CONTRACTOR_LIST_ASYNC_FAIL = 'CONTRACTOR_LIST_ASYNC_FAIL';
export const CONTRACTOR_ASYNC_CLEAR_ERROR = 'CONTRACTOR_ASYNC_CLEAR_ERROR';
export const CONTRACTOR_UPDATE_OPTIONS = 'CONTRACTOR_UPDATE_OPTIONS';
export const CONTRACTOR_CREATE_SAVE_ASYNC_SUCCESS =
  'CONTRACTOR_CREATE_SAVE_ASYNC_SUCCESS';

export function applyConfig(data) {
  return {
    type: CONTRACTOR_APPLY_LIST_OPTIONS,
    data,
  };
}

export function contractorAsyncStart() {
  return {
    type: CONTRACTOR_ASYNC_START,
  };
}

export function contractorActionClear() {
  return {
    type: CONTRACTOR_ACTION_CLEAR,
  };
}

function contractorAsyncFail(errorMessage) {
  return {
    type: CONTRACTOR_ASYNC_FAIL,
    errorMessage,
  };
}

function contractorCreateOrSaveAsyncSuccess() {
  return {
    type: CONTRACTOR_CREATE_SAVE_ASYNC_SUCCESS,
  };
}

export function contractorAsyncSuccess(data) {
  return {
    type: CONTRACTOR_ASYNC_SUCCESS,
    data,
  };
}

export function contractorListAsyncStart() {
  return {
    type: CONTRACTOR_LIST_ASYNC_START,
  };
}

export function contractorListAsyncSuccess(data) {
  return {
    type: CONTRACTOR_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function contractorListAsyncFail(data) {
  return {
    type: CONTRACTOR_LIST_ASYNC_FAIL,
    data,
  };
}

export function contractorAsyncClearError() {
  return {
    type: CONTRACTOR_ASYNC_CLEAR_ERROR,
  };
}

export function listContractors(values) {
  return async (dispatch, getState) => {
    try {
      dispatch(contractorListAsyncStart());
      const filters = getFilters(values, getState());
      const { pageNumber, sortDirection, orderBy, resultsPerPage } = values;

      const variables = {
        input: {
          filters: filterToRequest(filters),
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
        },
      };

      const contractorQuery = {
        query: LIST_CONTRACTORS,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(contractorQuery);

      dispatch(contractorListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(contractorListAsyncFail(error.message));
      return null;
    }
  };
}

export function createContractor(contractor) {
  return async (dispatch, getState) => {
    dispatch(contractorAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'contractors',
        method: 'POST',
        accessToken,
        body: contractor,
      });

      await dispatch(contractorCreateOrSaveAsyncSuccess());
      await dispatch(
        createAlertSuccess({
          message: 'Contractor successfully added',
          type: 'success',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      return response;
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Error on add your contractor.',
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      dispatch(contractorAsyncFail(error.message));
      return null;
    }
  };
}

export function saveContractor(contractor) {
  return async (dispatch, getState) => {
    dispatch(contractorAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `contractors/${contractor.ContractorUID}`,
        method: 'PATCH',
        accessToken,
        body: contractor,
      });

      await dispatch(contractorCreateOrSaveAsyncSuccess());
      await dispatch(
        createAlertSuccess({
          message: 'Contractor successfully updated',
          type: 'success',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      return response;
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Error on save your contractor.',
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      dispatch(contractorAsyncFail(error.message));
      return null;
    }
  };
}

export function selectContractor(contractor) {
  return (dispatch) => {
    dispatch(contractorAsyncSuccess(contractor));
  };
}

export function clearContractor() {
  return (dispatch) => {
    dispatch(contractorActionClear());
  };
}

export function updateContractorOptions(orderBy, order, page, rowsPerPage) {
  return {
    type: CONTRACTOR_UPDATE_OPTIONS,
    data: { orderBy, order, page, rowsPerPage },
  };
}
