import defaultValues from 'models/Summary';

export function getFormatedNomSegs(item) {
  const nominatedSegments = item.NominationSegments;
  if (nominatedSegments) {
    return nominatedSegments.reduce((formated, nomSeg, index) => {
      if (index === 0) {
        return nomSeg.Name;
      }
      return `${formated}, ${nomSeg.Name}`;
    }, '');
  }
  return '';
}

export function syncWithForm(item, summaryLine) {
  return Object.keys(defaultValues).reduce((summary, key) => {
    if (key !== 'NominationSegments') {
      return summary.set(key, summaryLine[key]);
    }
    return summary;
  }, item);
}

export function prepareRequest(formValues) {
  const { results: request } = formValues;
  return request
    .filter((result) => !!result.ERAUID)
    .map((result) => {
      delete result.NominatedSegmentsStr;
      delete result.Contestant;
      return result;
    });
}
