import { Map, Record } from 'immutable';
import Contact from './Contact';

const defaultValues = {
  ContractorUID: null,
  ContactUID: null,
  DBA: null,
  ContractorType: null,
  Bio: null,
  ShortName: null,
  Rank: null,
  Contact: new Map(),
};

export default class Contractor extends Record(defaultValues, 'Contractor') {
  constructor(values) {
    super({
      ...values,
      Contact:
        values && values.Contact
          ? new Contact(values.Contact)
          : defaultValues.Contact,
    });
  }
}
