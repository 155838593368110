import { getDisciplineName } from 'helpers/getDisciplineName';

export const getEntryPayload = (formValues, event, entryFeePayload) => {
  const {
    PaymentMethod,
    ERAUID,
    EventUID,
    stripeToken,
    Out1,
    Out2,
    Pref1,
    Pref2,
    Pref3,
    OutIfPrefNotMet,
    CompType,
    TeammateERAUID,
    TeammateName,
    EPUID,
    PartnerEPUID,
    saveCard,
    stripeEmail,
  } = formValues;
  const { isWcraManaged } = event;

  const {
    subtotalEntryAndNomination,
    subtotalTransactionFee,
    totalCouponDiscount,
    totalPayment,
    totalTransactionFee,
    disciplineFees,
  } = entryFeePayload;

  const theme = isWcraManaged ? 'wcra' : 'ret';

  const paymentPayload = {
    charge: {
      Disciplines: [
        {
          Out1,
          Out2,
          OutIfPrefNotMet,
          Pref1,
          Pref2,
          Pref3,
          id: parseInt(CompType, 10),
          isPayingForPartner: false,
          EPUID,
          PartnerEPUID,
          partner: {
            fullName: TeammateName,
            id: TeammateERAUID,
          },
        },
      ],
      ERAUID,
      EntryChargeRequestUID: null,
      EventUID,
      IsPaid: false,
      TeammateERAUID,
      coupon: null,
      disciplineFees,
      subtotalEntryAndNomination,
      subtotalTransactionFee,
      totalCouponDiscount,
      totalPayment,
      totalTransactionFee,
    },
    saveCard,
    selectedDisciplines: [
      {
        Out1,
        Out2,
        Pref1,
        Pref2,
        Pref3,
        label: getDisciplineName(CompType),
        isPayingForPartner: false,
        partner: {
          fullName: TeammateName,
          id: TeammateERAUID,
        },
        value: parseInt(CompType, 10),
      },
    ],
    stripeToken,
    stripeEmail,
    theme,
    paymentType: PaymentMethod,
  };
  return paymentPayload;
};
