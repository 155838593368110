import { Record } from 'immutable';

const defaultValues = {
  ContestantName: '',
  ERAUID: null,
  city: '',
  createdAt: '',
  email: '',
  firstName: '',
  lastName: '',
  DOB: null,
  phone: null,
  primaryDiscipline: '',
  socialTags: '',
  state: '',
  streetOne: '',
  streetTwo: '',
  updatedAt: '',
  userId: null,
  zip: '',
  photoFilename: '',
  PayeeNumber: '',
  numberOfNominations: 0,
  aggregateNominationAmount: 0,
  aggregatePoints: 0,
};

export default class Athletes extends Record(defaultValues, 'Athletes') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
