/* global APP_CONFIG */

import api from 'api';
import {
  getFromState,
  getKeyFromState,
  getToken,
  logError,
  getCentralStartDate,
  getCentralEndDate,
} from 'helpers';

export const DASHBOARD_ASYNC_START = 'DASHBOARD_ASYNC_START';
export const DASHBOARD_ACTION_CLEAR = 'DASHBOARD_ACTION_CLEAR';
export const DASHBOARD_ASYNC_FAIL = 'DASHBOARD_ASYNC_FAIL';
export const DASHBOARD_ASYNC_SUCCESS = 'DASHBOARD_ASYNC_SUCCESS';
export const DASHBOARD_LIST_ASYNC_SUCCESS = 'DASHBOARD_LIST_ASYNC_SUCCESS';
export const DASHBOARD_ASYNC_CLEAR_ERROR = 'DASHBOARD_ASYNC_CLEAR_ERROR';
export const DASHBOARD_UPDATE_FILTERS = 'DASHBOARD_UPDATE_FILTERS';

const buildUrl = (
  { startDate: mStartDate, endDate: mEndDate, discipline, division, segment },
  url,
) => {
  let baseURL = url;
  const startDate = mStartDate && mStartDate.startOf('day').toISOString();
  const endDate = mEndDate && mEndDate.endOf('day').toISOString();
  const centralStartDate = getCentralStartDate(startDate);
  const centralEndDate = getCentralEndDate(endDate);
  baseURL = startDate ? `${baseURL}&startDate=${centralStartDate}` : baseURL;
  baseURL = endDate ? `${baseURL}&endDate=${centralEndDate}` : baseURL;
  baseURL = discipline ? `${baseURL}&discipline=${discipline}` : baseURL;
  baseURL = division ? `${baseURL}&division=${division}` : baseURL;
  baseURL = segment ? `${baseURL}&segment=${segment}` : baseURL;

  return baseURL;
};

export function dashboardAsyncStart() {
  return {
    type: DASHBOARD_ASYNC_START,
  };
}

export function dashboardActionClear() {
  return {
    type: DASHBOARD_ACTION_CLEAR,
  };
}

function dashboardAsyncFail(errorMessage) {
  return {
    type: DASHBOARD_ASYNC_FAIL,
    errorMessage,
  };
}

export function dashboardUpdateFilters(data) {
  return {
    type: DASHBOARD_UPDATE_FILTERS,
    data,
  };
}

export function dashboardAsyncSuccess(data) {
  return {
    type: DASHBOARD_ASYNC_SUCCESS,
    data,
  };
}

export function dashboardListAsyncSuccess(data) {
  return {
    type: DASHBOARD_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function dashboardAsyncClearError() {
  return {
    type: DASHBOARD_ASYNC_CLEAR_ERROR,
  };
}

export function getDashboard() {
  return async (dispatch, getState) => {
    dispatch(dashboardAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const { options } = getKeyFromState(getState, 'dashboard');
      const path = options
        ? buildUrl(
            {
              startDate: options.startDate,
              endDate: options.endDate,
              discipline: options.disciplineUID,
              division: options.divisionUID,
              segment: options.segmentUID,
            },
            '/dashboard?',
          )
        : '/dashboard';

      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(dashboardAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch dashboard data';

      dispatch(dashboardAsyncFail(error.message));
      return err;
    }
  };
}

export function getNewAthletesCsv() {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const { options } = getKeyFromState(getState, 'dashboard');

      const base = APP_CONFIG.api.url;
      const url = `${base}/dashboard/newAthletesCsv?accessToken=${accessToken}`;

      const path = options
        ? buildUrl(
            {
              startDate: options.startDate,
              endDate: options.endDate,
              discipline: options.disciplineUID,
              division: options.divisionUID,
              segment: options.segmentUID,
              PayeeNumber: options.PayeeNumber,
            },
            url,
          )
        : url;

      window.open(path);
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch dashboard data';
      dispatch(dashboardAsyncFail(error.message));
      return err;
    }
  };
}

export function getNominationsCsv() {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const { options } = getKeyFromState(getState, 'dashboard');

      const base = APP_CONFIG.api.url;
      const url = `${base}/dashboard/nominationsCsv?accessToken=${accessToken}`;

      const path = options
        ? buildUrl(
            {
              startDate: options.startDate,
              endDate: options.endDate,
              discipline: options.disciplineUID,
              division: options.divisionUID,
              segment: options.segmentUID,
            },
            url,
          )
        : url;

      window.open(path);
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch dashboard data';
      dispatch(dashboardAsyncFail(error.message));
      return err;
    }
  };
}
