// @flow
import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';
import Performance from 'models/Performance';
import EntryTransaction from 'models/EntryTransaction';

export class Preference extends Record(
  {
    EPUID: null,
    PerformanceUID: null,
    PerformanceAsString: null,
    Position: null,
    IsNot: null,
  },
  'Preference',
) {
  // $FlowIgnore
  constructor(values) {
    super({
      ...values,
    });
  }
}

export type EntryPoolType = {
  CompType: string,
  DOB: string,
  Discipline: string,
  EPUID: string,
  ERAUID: string,
  ClassName: string,
  EntryChargeRequestUID: string,
  EventEntryDisciplineFeeUID: string,
  EntryStatus: string,
  PayLater: boolean,
  EventName: string,
  EventUID: string,
  FullName: string,
  HasAgreed: boolean,
  HasSSN: string,
  Hometown: string,
  HorseName: String,
  IsPaid: boolean,
  IsYouthAthlete: boolean,
  LegalFirstName: string,
  LegalLastName: string,
  Nickname: string,
  Out1: string,
  Out1AsDate: string,
  Out1AsString: string,
  Out1Type: string,
  Out2: string,
  Out2AsDate: string,
  Out2AsString: string,
  Out2Type: string,
  OutIfPrefNotMet: number,
  PaidPartnerEntryFees: boolean,
  PartnerEPUID: string,
  PartnerPaymentStatus: string,
  PartnerLastName: string,
  PartnerFirstName: string,
  PayingERAUID: string,
  PaymentStatus: string,
  PaymentType: string,
  Phone: string,
  PhotoFilename: string,
  PoolTeamID: string,
  Pref1: string,
  Pref1AsDate: string,
  Pref1AsString: string,
  Pref1Type: string,
  Pref2: string,
  Pref2AsDate: string,
  Pref2AsString: string,
  Pref2Type: string,
  Pref3: string,
  Pref3AsDate: string,
  Pref3AsString: string,
  Pref3Type: string,
  QualifyTeamID: string,
  SSN: string,
  Status: string,
  StripeTransactionFee: string,
  SubtotalAmount: string,
  TeammateERAUID: string,
  TeammateName: string,
  TotalAmountPaid: string,
  TotalAmountToPay: string,
  USCitizen: string,
  WcraTransactionFee: string,
  city: string,
  createdAt: string,
  dividedCostOfEntry: string,
  email: string,
  entryRequestCreatedAt: string,
  entryRequestUpdatedAt: string,
  entryTransactions: Array<EntryTransaction>,
  firstName: string,
  lastName: string,
  performances: Array<Performance>,
  preferences: Array<Preference>,
  secondaryDisciplines: Array<Preference>,
  state: string,
  street2: string,
  street: string,
  updatedAt: string,
  userId: string,
  zip: string,
  country: string,
  SSNLastFour: string,
  entryCountAsLetter: string,
  buddyGroupCountAsLetter: string,
  MembershipType: string,
  Membership: string,
  partnerEntryCountAsLetter: string,
  isUnregisteredAthlete: boolean,
  entryPaymentLabel: string,
};

const defaultValues = {
  CompType: null,
  DOB: null,
  Discipline: null,
  EPUID: null,
  ERAUID: null,
  ClassName: null,
  EntryChargeRequestUID: null,
  EventEntryDisciplineFeeUID: null,
  EntryStatus: null,
  PayLater: 0,
  EventName: null,
  EventAthleteAgreementURL: null,
  EventUID: null,
  FullName: null,
  HasAgreed: null,
  HasSSN: null,
  Hometown: null,
  HorseName: null,
  IsPaid: null,
  IsYouthAthlete: null,
  LegalFirstName: null,
  LegalLastName: null,
  Nickname: null,
  Out1: null,
  Out1AsDate: null,
  Out1AsString: null,
  Out1Type: null,
  Out2: null,
  Out2AsDate: null,
  Out2AsString: null,
  Out2Type: null,
  OutIfPrefNotMet: 0,
  PaidPartnerEntryFees: null,
  PartnerEPUID: null,
  PartnerPaymentStatus: null,
  PartnerLastName: null,
  PartnerFirstName: null,
  PayingERAUID: null,
  PaymentStatus: null,
  PaymentType: null,
  Phone: null,
  PhotoFilename: null,
  PoolTeamID: null,
  Pref1: null,
  Pref1AsDate: null,
  Pref1AsString: null,
  Pref1Type: null,
  Pref2: null,
  Pref2AsDate: null,
  Pref2AsString: null,
  Pref2Type: null,
  Pref3: null,
  Pref3AsDate: null,
  Pref3AsString: null,
  Pref3Type: null,
  QualifyTeamID: null,
  SSN: null,
  Status: null,
  StripeTransactionFee: null,
  SubtotalAmount: null,
  TeammateERAUID: null,
  TeammateName: null,
  TotalAmountPaid: null,
  TotalAmountToPay: null,
  USCitizen: null,
  WcraTransactionFee: null,
  city: null,
  createdAt: null,
  dividedCostOfEntry: null,
  email: null,
  entryRequestCreatedAt: null,
  entryRequestUpdatedAt: null,
  entryTransactions: toEntityList([], EntryTransaction),
  firstName: null,
  lastName: null,
  performances: toEntityList([], Performance),
  preferences: toEntityList([], Preference),
  secondaryDisciplines: toEntityList([], Preference),
  state: null,
  street2: null,
  street: null,
  teammate: null,
  fullNameWithNickname: null,
  updatedAt: null,
  userId: null,
  zip: null,
  country: null,
  SSNLastFour: null,
  entryCountAsLetter: null,
  partnerEntryCountAsLetter: null,
  isUnregisteredAthlete: null,
  entryPaymentLabel: null,
  buddyGroupCountAsLetter: null,
  MembershipType: null,
  Membership: null,
};

export default class EntryPool extends Record(defaultValues, 'EntryPool') {
  constructor(values: EntryPoolType) {
    super({
      ...values,
    });
  }
}
