import api from 'api';
import { getFromState, getToken, logError } from 'helpers';

export const PERFORMANCE_ANIMAL_ASYNC_START = 'PERFORMANCE_ANIMAL_ASYNC_START';
export const PERFORMANCE_ANIMAL_ASYNC_STOP = 'PERFORMANCE_ANIMAL_ASYNC_STOP';
export const PERFORMANCE_ANIMAL_ASYNC_FAIL = 'PERFORMANCE_ANIMAL_ASYNC_FAIL';
export const PERFORMANCE_ANIMAL_LIST_ASYNC_SUCCESS =
  'PERFORMANCE_ANIMAL_LIST_ASYNC_SUCCESS';

function performanceAnimalAsyncStart() {
  return {
    type: PERFORMANCE_ANIMAL_ASYNC_START,
  };
}

function performanceAnimalAsyncFail(error) {
  return {
    type: PERFORMANCE_ANIMAL_ASYNC_FAIL,
    error,
  };
}

export function performanceAnimalAsyncStop() {
  return {
    type: PERFORMANCE_ANIMAL_ASYNC_STOP,
  };
}

export function performanceAnimalListAsyncSuccess(data) {
  return {
    type: PERFORMANCE_ANIMAL_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function listPerformanceAnimals(performance, discipline) {
  return async (dispatch, getState) => {
    dispatch(performanceAnimalAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `event-animals/available-animals?PerformanceUID=${
          performance.PerformanceUID
        }&CompType=${discipline.id}`,
        method: 'GET',
        accessToken,
      });

      dispatch(performanceAnimalListAsyncSuccess(response.data));
      return response.data;
    } catch (error) {
      logError(error);
      dispatch(performanceAnimalAsyncFail(error.message));
      return null;
    }
  };
}
