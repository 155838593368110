export const competitionLevel = {
  OPEN: 'Open',
  YOUTH: 'Youth',
  CHALLENGER: 'Challenger',
  PRO: 'Pro',
  DYJR: {
    value: 'dyjr',
    label: 'Junior',
  },
  DYYTH: {
    value: 'dyyth',
    label: 'Youth',
  },
  LIMITED: 'Limited',
};

export const limitedCompetitionLevel = {
  id: 223,
  name: competitionLevel.LIMITED,
};

export const competitionLevels = [
  {
    id: competitionLevel.OPEN,
    name: competitionLevel.OPEN,
  },
  {
    id: 200,
    name: competitionLevel.YOUTH,
  },
  {
    id: 213,
    name: competitionLevel.DYYTH.label,
  },
  {
    id: 214,
    name: competitionLevel.DYJR.label,
  },
  {
    id: 201,
    name: competitionLevel.PRO,
  },
  {
    id: 199,
    name: competitionLevel.CHALLENGER,
  },
  {
    id: limitedCompetitionLevel.id,
    name: limitedCompetitionLevel.name,
  },
];

export const OLD_YOUTH_SEGMENT_SHORT_NAME = 'DY';
