import gql from 'graphql-tag';

export const GET_PERFORMANCE_DRAW = gql`
  query performanceDrawGet($input: PerformanceDrawInput!) {
    performanceDrawGet(input: $input) {
      EPUID
      EventUID
      ERAUID
      CompType
      entryCountAsLetter
      Pref1
      Pref2
      Pref3
      Out1
      Out2
      OutIfPrefNotMet
      createdAt
      updatedAt
      Pref1AsDate
      Pref2AsDate
      Pref3AsDate
      Out1AsDate
      Out2AsDate
      Pref1AsString
      Pref2AsString
      Pref3AsString
      Out1AsString
      Out2AsString
      Pref1Type
      Pref2Type
      Pref3Type
      Out1Type
      Out2Type
      FullName
      Discipline
      PartnerEPUID
      email
      firstName
      lastName
      Phone
      LegalFirstName
      LegalLastName
      Nickname
      TeammateERAUID
      PaymentType
      Status
      fullNameWithNickname
      MembershipType
      buddyGroupCountAsLetter
      teammate {
        CompType
        fullNameWithNickname
        entryCountAsLetter
      }
      performances {
        PerformanceUID
        EventUID
        PerformanceNum
        PerformanceDate
        PerformanceName
        CompLevel
        CompLevelAsString
        Rot
        Rnd
        IsSlack
        EventDate
        EventDateTo
        TimeZoneUID
        isUpcoming
        PerformanceDateWithTimezone
        TimezoneAbbreviation
        TimezoneNameIana
        TimezoneOffsetInMinutes
        Disciplines {
          id
          NumberOfAthletes
        }
      }
      preferences {
        PerformanceUID
        PerformanceAsString
        Position
        IsNot
      }
    }
  }
`;
