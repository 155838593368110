/**
 * Creates a class that parses a TXT file
 * @constructor
 */
function Reader() {
  /**
   *
   * @type {File}
   * @private
   */
  let myFile_;

  /**
   *
   * @type {FileReader}
   * @private
   */
  const handle_ = new FileReader();

  /**
   *
   * @param fileEvent
   * @private
   * @return {string}
   */
  const loadFile_ = (fileEvent) => {
    return fileEvent.target.result;
  };

  /**
   *
   * @return {Promise<string>}
   */
  this.openFile = () => {
    return new Promise((resolve, reject) => {
      handle_.onerror = (err) => {
        handle_.abort();
        reject(err);
      };

      handle_.onload = (fileEvent) => {
        const results = loadFile_(fileEvent);
        resolve(results);
      };

      handle_.readAsText(myFile_, 'UTF-8');
    });
  };

  /**
   * sets the myFile_ variable
   * @param {[]|{string}} files
   * @return {void}
   */
  this.setFile = (files) => {
    if (Array.isArray(files)) {
      myFile_ = files[0];
      return;
    }

    myFile_ = files;
  };
} // end Reader

export { Reader };
