import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';
import Performance from 'models/Performance';

const defaultValues = {
  EAUID: null,
  EventUID: null,
  CompType: null,
  CompLevel: null,
  Delivery: null,
  Pos: null,
  AnimalUID: null,
  LegalName: null,
  NickName: null,
  AnimalType: null,
  BioUID: null,
  AwardUID: null,
  HeritageUID: null,
  HistoryUID: null,
  InjuryCurrentUID: null,
  InjuryHistoryUID: null,
  Identifier: null,
  ContractorUID: null,
  ERAUID: null,
  Brand: null,
  WCEligibleDate: null,
  GenderType: null,
  Color: null,
  Weight: null,
  BirthYear: null,
  Classification: null,
  LastUpdate: null,
  ContractorName: null,
  performances: toEntityList([], Performance),
};

export default class EventAnimal extends Record(defaultValues, 'EventAnimal') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
