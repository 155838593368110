import gql from 'graphql-tag';

export const LIST_NOMINATIONS = gql`
  query LIST_NOMINATION($input: NominationsInput!) {
    nominationsPayload(input: $input) {
      nominations {
        NomUID
        ERAUID
        OriginSource
        EventUID
        CompType
        SegmentUID
        SegmentAsString
        ShortName
        NomFee
        TransFee
        TTLFees
        NomDate
        chargeId
        CouponUID
        createdAt
        DisciplineAsString
        NumberOfNominations
        totalOnStripe
        NominationRevenue
        totalTransactionFee
        chargeHasMoreThanOneNomination
        EventName
        EventLocation
        EventType
        firstName
        lastName
        FullName
        email
        userId
        mainDisciplineAsString
        mainDiscipline
        CouponCode
        isFree
        Segments
        ClassName
        EventEntryDisciplineFeeUID
        hasGiftCard
        IsRefunded
        IsInactive
        lastRefundType
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
