// @flow
export const getOriginPath = (
  pathname: string = '',
  paths?: number = 4,
): string => {
  return pathname
    .split('/')
    .slice(0, paths)
    .join('/');
};
