import { merge, assign } from 'lodash';
import {
  STRIPE_SET_STATUS,
  STRIPE_SET_TOKEN,
  STRIPE_RESET,
} from 'actions/stripe';
import { getNestedProperty } from 'helpers';

const initialState = {
  creditCardStatus: {
    complete: false,
    empty: true,
    save: false,
  },
  token: {},
};

const actionsMap = {
  [STRIPE_SET_STATUS]: (state, action) => {
    const creditCardStatus = getNestedProperty('creditCardStatus', action);
    if (creditCardStatus) {
      return merge({}, state, { creditCardStatus: creditCardStatus });
    }
    return state;
  },
  [STRIPE_SET_TOKEN]: (state, action) => {
    const token = getNestedProperty('token', action);
    if (token) {
      return merge({}, state, { token: token });
    }
    return state;
  },
  [STRIPE_RESET]: () => {
    return assign({}, initialState);
  },
};

export default function stripe(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
