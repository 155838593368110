import { Record } from 'immutable';

const defaultValues = {
  PerformancesAnimalsUID: null,
  PerformanceUID: null,
  EAUID: null,
  Brand: null,
  CompType: null,
  NickName: null,
  AnimalUID: null,
};

export default class PerformanceAnimal extends Record(
  defaultValues,
  'PerformanceAnimal',
) {
  constructor(values) {
    super({
      ...values,
    });
  }
}
