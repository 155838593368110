import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';
import GoRoundOverall from 'models/GoRoundOverall';

const defaultValues = {
  ERAUID: null,
  EPUID: null,
  PartnerEPUID: null,
  PartnerERAUID: null,
  ContestantName: '',
  TeammateName: '',
  CompType: null,
  EventUID: null,
  Discipline: '',
  GoRounds: toEntityList([], GoRoundOverall),
  TotalScore: null,
  TotalMoney: null,
  Rank: null,
  PointsAwarded: null,
  PartnerPointsAwarded: null,
  NominationSegments: [],
  PartnerNominationSegments: [],
  Selected: false,
  entryCountAsLetter: null,
  partnerEntryCountAsLetter: null,
};

export default class SummaryOverall extends Record(
  defaultValues,
  'SummaryOverall',
) {
  constructor(values) {
    super({
      ...values,
      GoRounds:
        values && values.GoRounds
          ? toEntityList(values.GoRounds, GoRoundOverall)
          : defaultValues.GoRounds,
    });
  }
}
