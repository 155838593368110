import moment from 'moment-timezone';

export const getCentralStartDate = (startDate) => {
  return moment(startDate).isDST()
    ? moment(startDate)
        .utc()
        .startOf('day')
        .hour(5)
        .format()
    : moment(startDate)
        .utc()
        .startOf('day')
        .hour(6)
        .format();
};

export const getCentralEndDate = (endDate) => {
  return moment(endDate).isDST()
    ? moment(endDate)
        .utc()
        .endOf('day')
        .hour(4)
        .minute(59)
        .second(59)
        .format()
    : moment(endDate)
        .utc()
        .endOf('day')
        .hour(5)
        .minute(59)
        .second(59)
        .format();
};

export const getReportDateString = (textDate, includeYear = false) => {
  return moment(textDate, 'YYYY-MM-DD').format(
    `dddd MMM DD ${includeYear ? 'YYYY' : ''}`,
  );
};

export const getPerformanceDateString = (textDate) => {
  return moment(textDate, 'YYYY-MM-DDTHH:mm:ss').format(
    'dddd MMM DD YYYY hh:mm A',
  );
};

export const convertUtcToCurrentTimeZone = (utcDate) => {
  return moment(utcDate)
    .local()
    .format('YYYY-MM-DDTHH:mm:ss');
};
