export const LOAD_PERFORMANCE_SUCCESS = 'LOAD_PERFORMANCE_SUCCESS';
export const CLEAR_PERFORMANCE_SUCCESS = 'CLEAR_PERFORMANCE_SUCCESS';
export const ADD_PERFORMANCE_DISCIPLINE_SUCCESS =
  'ADD_PERFORMANCE_DISCIPLINE_SUCCESS';
export const CLEAR_PERFORMANCE_DISCIPLINE_SUCCESS =
  'CLEAR_PERFORMANCE_DISCIPLINE_SUCCESS';
export const REMOVE_PERFORMANCE_DISCIPLINE_SUCCESS =
  'REMOVE_PERFORMANCE_DISCIPLINE_SUCCESS';
export const UPDATE_PERFORMANCE_DISCIPLINE_SUCCESS =
  'UPDATE_PERFORMANCE_DISCIPLINE_SUCCESS';
export const UPDATE_PERFORMANCE_SELECT_FIELD_SUCCESS =
  'UPDATE_PERFORMANCE_SELECT_FIELD_SUCCESS';
export const UPDATE_PERFORMANCE_DATE_SUCCESS =
  'UPDATE_PERFORMANCE_DATE_SUCCESS';
export const UPDATE_PERFORMANCE_NAME_SUCCESS =
  'UPDATE_PERFORMANCE_NAME_SUCCESS';
export const SET_PERFORMANCE_DISCIPLINE_SUCCESS =
  'SET_PERFORMANCE_DISCIPLINE_SUCCESS';

export function loadPerformance(data, index) {
  return {
    type: LOAD_PERFORMANCE_SUCCESS,
    data,
    index,
  };
}

export function clearPerformance(disciplines) {
  return {
    type: CLEAR_PERFORMANCE_SUCCESS,
    disciplines,
  };
}

export function clearPerformanceDiscipline() {
  return {
    type: CLEAR_PERFORMANCE_DISCIPLINE_SUCCESS,
  };
}

export function addPerformanceDiscipline() {
  return {
    type: ADD_PERFORMANCE_DISCIPLINE_SUCCESS,
  };
}

export function removePerformanceDiscipline(data) {
  return {
    type: REMOVE_PERFORMANCE_DISCIPLINE_SUCCESS,
    data,
  };
}

export function updatePerformanceDiscipline(
  fieldValue,
  fieldName,
  index,
  disciplines,
) {
  return {
    type: UPDATE_PERFORMANCE_DISCIPLINE_SUCCESS,
    fieldName,
    fieldValue,
    index,
    disciplines,
  };
}

export function updatePerformanceSelectField(fieldValue, fieldName) {
  return {
    type: UPDATE_PERFORMANCE_SELECT_FIELD_SUCCESS,
    fieldName,
    fieldValue,
  };
}

export function updatePerformanceDate(fieldValue) {
  return {
    type: UPDATE_PERFORMANCE_DATE_SUCCESS,
    fieldValue,
  };
}

export function updatePerformanceName(fieldValue) {
  return {
    type: UPDATE_PERFORMANCE_NAME_SUCCESS,
    fieldValue,
  };
}

export function setPerformancesDiscipline(disciplines) {
  return {
    type: SET_PERFORMANCE_DISCIPLINE_SUCCESS,
    disciplines,
  };
}
