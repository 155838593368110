//@flow

import {
  competitionLevels,
  OLD_YOUTH_SEGMENT_SHORT_NAME,
  limitedCompetitionLevel,
} from 'constants/competitionLevel';
import { disciplinesById } from 'constants/disciplines';

export const getCompetitionBySegment = (
  segmentObject: Object,
  DisciplineUID: string,
) => {
  if (segmentObject.HasYouth) {
    if (segmentObject.ShortName === OLD_YOUTH_SEGMENT_SHORT_NAME)
      return [competitionLevels[1]];
    else return [competitionLevels[2], competitionLevels[3]];
  } else if (
    segmentObject.HasLimited &&
    (DisciplineUID === disciplinesById.BARREL_RACING ||
      DisciplineUID === disciplinesById.LADIES_BREAKAWAY_ROPING)
  )
    return [
      competitionLevels[4],
      competitionLevels[5],
      limitedCompetitionLevel,
    ];
  else return [competitionLevels[4], competitionLevels[5]];
};
