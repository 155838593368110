// @flow
import React, { type Node } from 'react';
import { Typography } from '@material-ui/core';

type BaseTitlePropsType = {|
  children: Node,
  className?: string,
|};

type TableTitlePropsType = BaseTitlePropsType & {|
  subtitle: boolean,
  tableTitle?: boolean,
  variant?: string,
|};

export const SubTitle = (props: BaseTitlePropsType) => (
  <Typography
    variant="subtitle1"
    className={`paper-section-sub-title ${props.className || ''}`}
  >
    {props.children}
  </Typography>
);

export const SectionTitle = (props: BaseTitlePropsType) => (
  <Typography
    variant="h5"
    className={`paper-section-title ${props.className || ''}`}
    component="h3"
  >
    {props.children}
  </Typography>
);

export const Title = (props: TableTitlePropsType) => {
  const page = 'page-';
  const withId = props.tableTitle ? { id: 'table-title' } : {};
  const className = props.subtitle ? `${page}subtitle` : `${page}title`;
  return (
    <Typography
      variant={props.variant || 'h1'}
      className={className}
      {...withId}
    >
      {props.children}
    </Typography>
  );
};
