import React from 'react';
import flagsmith from 'flagsmith';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AsyncComponentHOC from 'components/HOC/AsyncComponentHOC';
import UnauthenticatedRoute from 'components/Route/UnauthenticatedRoute';
import AuthenticatedRoute from 'components/Route/AuthenticatedRoute';
import routeCodes from 'constants/routes';
import App from 'containers/App';

// $FlowIgnore
import { ABILITY_TYPES } from 'abilities/types';

const {
  NOMINATION,
  EVENT,
  GIFTCARD,
  COUPON,
  ATHLETE,
  DASHBOARD,
  CONTRACTORS,
  VENUES,
  LIVESTOCK,
  ASSOCIATION_SETTINGS,
  MEMBERSHIP_SEASON,
  ASSOCIATIONS_PROMOTERS,
  RESULTS_AND_POINTS,
} = ABILITY_TYPES;

const AsyncHome = AsyncComponentHOC(() => import('containers/Home'));
const AsyncLogin = AsyncComponentHOC(() => import('containers/Login'));
const AsyncAuth0 = AsyncComponentHOC(() => import('containers/Login/Auth0'));
const AsyncNotFound = AsyncComponentHOC(() => import('containers/NotFound'));
const AsyncCoupons = AsyncComponentHOC(() => import('containers/Coupons'));
const AsyncEvents = AsyncComponentHOC(() => import('containers/Events'));
const AsyncGiftCard = AsyncComponentHOC(() => import('containers/GiftCard'));

const AsyncEventsEdit = AsyncComponentHOC(() =>
  import('containers/Events/Edit'),
);
const AsyncNominations = AsyncComponentHOC(() =>
  import('containers/Nominations'),
);
const AsyncNewNomination = AsyncComponentHOC(() =>
  import('containers/Nominations/NewNominationPage'),
);

const AsyncNominationRefund = AsyncComponentHOC(() =>
  import('containers/Nominations/NominationRefund'),
);
const AsyncAthletes = AsyncComponentHOC(() => import('containers/Athletes'));
const AsyncAthletesEdit = AsyncComponentHOC(() =>
  import('containers/Athletes/EditAthlete'),
);
const AsyncDashboard = AsyncComponentHOC(() => import('containers/Dashboard'));
const AsyncResultsAndPoints = AsyncComponentHOC(() =>
  import('containers/ResultsAndPoints'),
);
const AsyncResultsAndPointsAdd = AsyncComponentHOC(() =>
  import('containers/ResultsAndPointsAdd'),
);
const AsyncResultsAndPointsAudit = AsyncComponentHOC(() =>
  import('containers/ResultsAndPointsAudit'),
);
const AsyncContractors = AsyncComponentHOC(() =>
  import('containers/Contractors'),
);
const AsyncVenues = AsyncComponentHOC(() => import('containers/Venues'));
const AsyncLivestock = AsyncComponentHOC(() => import('containers/Livestock'));
const AsyncAssociationSettings = AsyncComponentHOC(() =>
  import('containers/AssociationSettings'),
);
const AsyncMembershipSeason = AsyncComponentHOC(() =>
  import('containers/MembershipSeasons'),
);
const AsyncAssociationsPromoters = AsyncComponentHOC(() =>
  import('containers/AssociationsPromoters'),
);

const Routes = ({ childProps }) => {
  const showClassificationTab = flagsmith.hasFeature(
    'athletes_classifications_tab',
  );

  const showGiftCardsTab = flagsmith.hasFeature('show_gift_cards_tab');
  const showNewAddNominationUi = flagsmith.hasFeature('add_nomination_v2');
  const showNominationRefund = flagsmith.hasFeature('nominations_refund');

  return (
    <Router>
      <App>
        <Switch>
          <UnauthenticatedRoute
            path={routeCodes.INDEX}
            exact
            component={AsyncHome}
            props={childProps}
          />
          <UnauthenticatedRoute
            path={routeCodes.LOGIN}
            exact
            component={AsyncLogin}
            props={childProps}
          />
          <UnauthenticatedRoute
            path={routeCodes.AUTH0}
            exact
            component={AsyncAuth0}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.DASHBOARD}
            exact
            abilityType={DASHBOARD}
            component={AsyncDashboard}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.COUPONS}
            exact
            abilityType={COUPON}
            component={AsyncCoupons}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.EVENTS}
            exact
            abilityType={EVENT}
            component={AsyncEvents}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.MERGE_EVENTS}
            exact
            abilityType={EVENT}
            component={AsyncEvents}
            props={childProps}
          />
          {showGiftCardsTab && (
            <AuthenticatedRoute
              path={routeCodes.GIFTCARDS}
              exact
              abilityType={GIFTCARD}
              component={AsyncGiftCard}
              props={childProps}
            />
          )}
          <AuthenticatedRoute
            path={routeCodes.EVENTS_EDIT}
            exact={false}
            abilityType={EVENT}
            component={AsyncEventsEdit}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.MEMBERSHIP_SEASON}
            exact={false}
            abilityType={MEMBERSHIP_SEASON}
            component={AsyncMembershipSeason}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.ATHLETES}
            exact
            abilityType={ATHLETE}
            component={AsyncAthletes}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.ATHLETES_EDIT}
            exact={false}
            abilityType={ATHLETE}
            component={AsyncAthletesEdit}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.ATHLETE_NOMINATIONS_EDIT}
            exact={false}
            abilityType={ATHLETE}
            component={AsyncAthletesEdit}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.CONTESTANTS}
            exact
            abilityType={ATHLETE}
            component={AsyncAthletes}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.CONTESTANTS_MERGE}
            exact
            abilityType={ATHLETE}
            component={AsyncAthletes}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.BULK_MERGE}
            exact
            abilityType={ATHLETE}
            component={AsyncAthletes}
            props={childProps}
          />
          {showClassificationTab && (
            <AuthenticatedRoute
              path={routeCodes.CLASSIFICATIONS}
              exact
              abilityType={ATHLETE}
              component={AsyncAthletes}
              props={childProps}
            />
          )}

          <AuthenticatedRoute
            path={routeCodes.NOMINATIONS}
            exact
            abilityType={NOMINATION}
            component={AsyncNominations}
            props={childProps}
          />
          {showNewAddNominationUi && (
            <AuthenticatedRoute
              path={routeCodes.NOMINATIONS_NEW}
              exact
              abilityType={NOMINATION}
              component={AsyncNewNomination}
              props={childProps}
            />
          )}
          {showNominationRefund && (
            <AuthenticatedRoute
              path={routeCodes.NOMINATION_REFUND}
              exact
              abilityType={NOMINATION}
              component={AsyncNominationRefund}
              props={childProps}
            />
          )}
          <AuthenticatedRoute
            path={routeCodes.RESULTSANDPOINTS}
            exact
            abilityType={RESULTS_AND_POINTS}
            component={AsyncResultsAndPoints}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.RESULTSANDPOINTSADD}
            exact
            abilityType={RESULTS_AND_POINTS}
            component={AsyncResultsAndPointsAdd}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.RESULTSANDPOINTSAUDIT}
            exact
            abilityType={RESULTS_AND_POINTS}
            component={AsyncResultsAndPointsAudit}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.CONTRACTORS}
            exact
            abilityType={CONTRACTORS}
            component={AsyncContractors}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.VENUES}
            exact
            abilityType={VENUES}
            component={AsyncVenues}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.LIVESTOCK}
            exact
            abilityType={LIVESTOCK}
            component={AsyncLivestock}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.ASSOCIATIONSETTINGS}
            exact
            abilityType={ASSOCIATION_SETTINGS}
            component={AsyncAssociationSettings}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.ASSOCIATIONS_PROMOTERS}
            exact
            abilityType={ASSOCIATIONS_PROMOTERS}
            component={AsyncAssociationsPromoters}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.ASSOCIATIONS_PROMOTERS_NEW}
            exact
            abilityType={ASSOCIATIONS_PROMOTERS}
            component={AsyncAssociationsPromoters}
            props={childProps}
          />
          <AuthenticatedRoute
            path={routeCodes.ASSOCIATIONS_PROMOTERS_EDIT}
            exact={false}
            abilityType={ASSOCIATIONS_PROMOTERS}
            component={AsyncAssociationsPromoters}
            props={childProps}
          />
          <Route component={AsyncNotFound} />
        </Switch>
      </App>
    </Router>
  );
};

Routes.propTypes = {
  childProps: PropTypes.object.isRequired,
};

export default Routes;
