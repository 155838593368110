export const SHOW_SPLASH_SCREEN = 'SHOW_SPLASH_SCREEN';
export const HIDE_SPLASH_SCREEN = 'HIDE_SPLASH_SCREEN';

export function hideSplashScreen() {
  return {
    type: HIDE_SPLASH_SCREEN,
  };
}

export function showSplashScreen() {
  return { type: SHOW_SPLASH_SCREEN };
}
