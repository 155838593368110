// @flow
import { Record } from 'immutable';

export type MergeEventType = {
  nextSteps: string[],
  previousSteps: string[],
  badEventNominations: NominationsType[],
  badEventEntries: Object[],
  form: Object,
  goodPerformancesByDiscipline: PerformanceByDisciplineType[],
  dataToSend: Object,
  eventsInfo: eventsInfoType,
  missingDisciplines: string[],
};

type eventsInfoType = {
  goodEventAllowsClasses: boolean,
  badEventHasNominations: boolean,
  badEventHasEntries: boolean,
  goodEventId: string,
  badEventId: string,
  badEventTotalEntries: number,
  badEventTotalNominations: number,
  goodEventLocation: string,
  badEventLocation: string,
  badEventName: string,
  goodEventName: string,
  goodEventTotalNominations: number,
  goodEventTotalEntries: number,
};

type PerformanceByDisciplineType = {
  ClassName: string,
  EventEntryDisciplineFeeUID: number,
  name: string,
  performances: PerformanceType[],
};

type PerformanceType = {
  PerformanceDateWithTimezone: string,
  PerformanceUID: number,
  id: number,
  label: string,
};

type NominationsType = {
  disciplineName: string,
  disciplineId: string,
};

export const mergeEventsDefaultValues: MergeEventType = {
  nextSteps: [],
  previousSteps: [],
  badEventNominations: [],
  badEventEntries: [],
  form: {},
  missingDisciplines: [],
  goodPerformancesByDiscipline: [],
  dataToSend: {},
  eventsInfo: {
    goodEventTotalNominations: 0,
    goodEventTotalEntries: 0,
    goodEventAllowsClasses: false,
    badEventHasNominations: false,
    badEventHasEntries: false,
    badEventName: '',
    goodEventName: '',
    goodEventId: '',
    badEventId: '',
    badEventTotalEntries: 0,
    badEventTotalNominations: 0,
    goodEventLocation: '',
    badEventLocation: '',
  },
};

export default class MergeEvent extends Record(
  mergeEventsDefaultValues,
  'MergeEvent',
) {
  constructor(values: ?MergeEventType) {
    super({
      ...values,
    });
  }
}
