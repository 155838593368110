import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';
import CustomFee from 'models/CustomFee';

const defaultValues = {
  feesCollected: null,
  drawOuts: null,
  drawOutMoney: null,
  substitutions: null,
  customFees: toEntityList([], CustomFee),
};

export default class Purse extends Record(defaultValues, 'Purse') {
  constructor(values) {
    super({
      ...values,
      customFees:
        values && values.customFees
          ? toEntityList(values.customFees, CustomFee)
          : defaultValues.customFees,
    });
  }
}
