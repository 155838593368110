import { Record } from 'immutable';
import defaultContestantValues from 'models/Contestant';

export const defaultValues = {
  GoRoundResultUID: null,
  EventUID: null,
  EventDivision: null,
  EventType: null,
  CompType: null,
  GoNumber: null,
  AnimalUID: null,
  ERAUID: null,
  PartnerERAUID: null,
  EPUID: null,
  PartnerEPUID: null,
  TempUID: null,
  ContestantName: '',
  TeammateName: '',
  StockName: '',
  Score: null,
  Money: null,
  Rank: null,
  Points: null,
  PartnerPoints: null,
  Time: null,
  NominationSegments: [],
  PartnerNominationSegments: [],
  InEdit: false,
  Selected: false,
  IsNew: false,
  Contestant: null,
  partnerEntryCountAsLetter: null,
  entryCountAsLetter: null,
};

export default class Summary extends Record(defaultValues, 'Summary') {
  constructor(values) {
    super({
      ...values,
      Contestant:
        values && !values.IsNew
          ? {
              ...defaultContestantValues,
              ERAUID: values.ERAUID,
              FullName: values.ContestantName,
              entryCountAsLetter: values.entryCountAsLetter,
            }
          : defaultValues.Contestant,
    });
  }
}
