export function getGoRoundPoints(item, goNumber) {
  const goRounds = item.GoRounds;
  const goRound = goRounds.find((g) => g.GoNumber === goNumber);
  return goRound && goRound.Points ? goRound.Points : '-';
}

export function getGoRoundScores(item, goNumber) {
  const goRounds = item.GoRounds;
  const goRound = goRounds.find((g) => g.GoNumber === goNumber);
  return goRound && goRound.Score ? goRound.Score : '-';
}
