export function getDisciplinesFromPerformances(event) {
  const { performances } = event;
  if (performances && performances.length > 0) {
    return performances.reduce((disciplines, performance) => {
      const { Disciplines } = performance;
      if (Disciplines && Disciplines.length > 0) {
        disciplines.push(...Disciplines);
      }
      return disciplines;
    }, []);
  }
  return [];
}

export function getSummaryDiscipline(event, id = null) {
  const allDisciplines = getDisciplinesFromPerformances(event);
  const disciplines = allDisciplines.reduce((result, discipline) => {
    const { GoNumber } = discipline;
    if (!GoNumber) {
      return result;
    }

    const disciplineIndex = result.findIndex(
      (disciplineIn) => disciplineIn.id === discipline.id,
    );

    if (disciplineIndex >= 0) {
      const { goNumbers } = result[disciplineIndex];
      if (!goNumbers.includes(GoNumber)) {
        result[disciplineIndex].goNumbers = [...goNumbers, GoNumber];
      }
    } else {
      result.push({
        id: discipline.id,
        name: discipline.name,
        goNumbers: [GoNumber],
      });
    }

    return result;
  }, []);

  if (id) {
    return disciplines.find((discipline) => discipline.id === parseInt(id, 10));
  }

  return disciplines;
}

export const getEventSecretaryContactInfo = (phoneNumber) => {
  return phoneNumber && phoneNumber.length > 0
    ? phoneNumber
    : 'the event secretary';
};
