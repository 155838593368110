import api from 'api';
import client from 'api/apollo';
import {
  filterToRequest,
  getFilters,
  getFromState,
  getToken,
  logError,
} from 'helpers';
import { LIST_COUPONS } from 'graphql/queries/coupons/listCoupons';
import { createAlertSuccess } from 'actions/alert';
import { clearAlertSuccess } from './alert';
export const COUPON_ASYNC_START = 'COUPON_ASYNC_START';
export const COUPON_ACTION_CLEAR = 'COUPON_ACTION_CLEAR';
export const COUPON_ASYNC_FAIL = 'COUPON_ASYNC_FAIL';
export const COUPON_ASYNC_SUCCESS = 'COUPON_ASYNC_SUCCESS';
export const COUPON_LIST_ASYNC_SUCCESS = 'COUPON_LIST_ASYNC_SUCCESS';
export const COUPON_ASYNC_CLEAR_ERROR = 'COUPON_ASYNC_CLEAR_ERROR';

export function couponAsyncStart() {
  return {
    type: COUPON_ASYNC_START,
  };
}

export function couponActionClear() {
  return {
    type: COUPON_ACTION_CLEAR,
  };
}

function couponAsyncFail(errorMessage) {
  return {
    type: COUPON_ASYNC_FAIL,
    errorMessage,
  };
}

export function couponAsyncSuccess(data) {
  return {
    type: COUPON_ASYNC_SUCCESS,
    data,
  };
}

export function couponListAsyncSuccess(data) {
  return {
    type: COUPON_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function couponAsyncClearError() {
  return {
    type: COUPON_ASYNC_CLEAR_ERROR,
  };
}

export function listCoupons(values) {
  return async (dispatch, getState) => {
    try {
      dispatch(couponAsyncStart());
      const filters = getFilters(values, getState());
      const {
        CouponUID,
        pageNumber,
        sortDirection,
        orderBy,
        resultsPerPage,
      } = values;
      const variables = {
        input: {
          CouponUID,
          filters: filterToRequest(filters),
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
        },
      };

      const couponQuery = {
        query: LIST_COUPONS,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(couponQuery);

      dispatch(couponListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(couponAsyncFail(error.message));
      return null;
    }
  };
}

export function saveCoupon(values) {
  return async (dispatch, getState) => {
    dispatch(couponAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      let response;
      if (values.id) {
        response = await api({
          path: `coupon/${values.id}`,
          method: 'PATCH',
          body: values,
          accessToken,
        });
      } else {
        response = await api({
          path: 'coupon',
          method: 'POST',
          body: values,
          accessToken,
        });
      }
      return response;
    } catch (error) {
      logError(error);
      const err =
        error.message === 'Validation Error'
          ? 'Coupon Code must be unique'
          : error.message;

      dispatch(couponAsyncFail(err));
      return null;
    }
  };
}

export function selectCoupon(coupon) {
  return (dispatch) => {
    dispatch(couponAsyncSuccess(coupon));
  };
}

export function clearSelectedCoupon() {
  return (dispatch) => {
    dispatch(couponActionClear());
  };
}

export function validateCoupon(values) {
  return async (dispatch, getState) => {
    dispatch(couponAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'coupon/validate/admin',
        method: 'POST',
        body: values,
        accessToken,
      });

      dispatch(couponAsyncClearError());
      return response;
    } catch (error) {
      logError(error);
      const err = error.message;
      await dispatch(
        createAlertSuccess({
          message: 'Error on refund your nomination.',
          type: 'fail',
        }),
      );
      dispatch(couponAsyncFail(err));
      return null;
    }
  };
}

export function validateCouponByUserIdAndSegments(values) {
  return async (dispatch, getState) => {
    dispatch(couponAsyncStart());
    await dispatch(clearAlertSuccess());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const response = await api({
        path: 'coupon/validate/admin/by-userId',
        method: 'POST',
        body: values,
        accessToken,
      });

      dispatch(couponAsyncClearError());
      return response;
    } catch (error) {
      logError(error);
      const err = error.message;
      setTimeout(async () => {
        await dispatch(
          createAlertSuccess({
            message: err,
            type: 'fail',
          }),
        );
      });
      dispatch(couponAsyncFail(err));
      return null;
    }
  };
}
