import Summary from 'models/Summary';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { Record } from 'immutable';
import { syncWithForm, getSorting } from 'helpers';

import {
  SUMMARY_ASYNC_START,
  SUMMARY_ASYNC_FAIL,
  SUMMARY_ASYNC_SUCCESS,
  SUMMARY_SELECT_ALL,
  SUMMARY_DESELECT_ALL,
  SUMMARY_SELECT_LINE,
  SUMMARY_DESELECT_LINE,
  SUMMARY_EDIT_LINE,
  SUMMARY_EDIT_LINE_SAVE,
  SUMMARY_EDIT_LINE_CANCEL,
  SUMMARY_ADD_NEW_LINE,
  SUMMARY_SYNC_WITH_FORM,
  SUMMARY_REMOVE_LINE_SUCCESS,
  SUMMARY_RESET,
  SUMMARY_UPDATE_ORDERS,
} from 'actions/summary';

const SummaryOptions = new Record({
  editedList: toEntityList([], Summary),
  sortDirection: 'asc',
  orderBy: 'Rank',
});

const initialState = new Base({
  loading: false,
  errorMessage: '',
  list: toEntityList([], Summary),
  apiList: toEntityList([], Summary),
  options: SummaryOptions(),
});

const actionsMap = {
  [SUMMARY_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [SUMMARY_RESET]: () => {
    return initialState;
  },
  [SUMMARY_UPDATE_ORDERS]: (state, action) => {
    const { sortDirection, orderBy } = action.payload;
    const options = state.get('options');
    const apiList = state.get('apiList');

    return state.merge({
      apiList: apiList.sort(getSorting(sortDirection, orderBy)),
      options: options
        .set('sortDirection', sortDirection)
        .set('orderBy', orderBy),
    });
  },
  [SUMMARY_ASYNC_SUCCESS]: (state, action) => {
    const options = state.get('options');
    const { sortDirection, orderBy } = options;
    return state.merge({
      loading: false,
      errorMessage: null,
      options: initialState.get('options'),
      list: initialState.get('list'),
      apiList: toEntityList(action.data, Summary).sort(
        getSorting(sortDirection, orderBy),
      ),
    });
  },
  [SUMMARY_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [SUMMARY_SYNC_WITH_FORM]: (state, action) => {
    const { summaryLines } = action;
    const apiList = state.get('apiList');
    return state.merge({
      apiList: apiList.map((item) => {
        const key = !item.get('IsNew') ? 'ERAUID' : 'TempUID';
        const summaryLine = summaryLines.find(
          (summaryLineIn) => summaryLineIn[key] === item.get(key),
        );
        if (summaryLine) {
          return syncWithForm(item, summaryLine);
        }
        return item;
      }),
    });
  },
  [SUMMARY_ADD_NEW_LINE]: (state, action) => {
    const { rows } = action;
    const apiList = state.get('apiList');
    const apiListLength = apiList.size;
    const options = state.get('options');
    const editedList = options.get('editedList');

    const newLines = Array.from(
      Array(rows),
      (r, inx) =>
        new Summary({
          TempUID: `temp_${apiListLength + inx + 1}`,
          IsNew: true,
        }),
    );
    return state.merge({
      options: options.set('editedList', editedList.push(...newLines)),
      apiList: apiList.push(...newLines),
    });
  },
  [SUMMARY_SELECT_ALL]: (state) => {
    const apiList = state.get('apiList');

    return state.merge({
      loading: false,
      errorMessage: null,
      list: apiList,
      apiList: apiList.map((item) => item.set('Selected', true)),
    });
  },
  [SUMMARY_REMOVE_LINE_SUCCESS]: (state, action) => {
    const { data } = action;
    const apiList = state.get('apiList');

    const list = data.EPUID
      ? apiList.filter((item) => item.get('EPUID') !== data.EPUID)
      : apiList.splice(data.index, 1); // New lines without EPUID. Remove by index

    return state.merge({
      loading: false,
      errorMessage: null,
      apiList: list,
    });
  },
  [SUMMARY_EDIT_LINE]: (state, action) => {
    const { summaryLine } = action;
    const apiList = state.get('apiList');
    const options = state.get('options');
    const editedList = options.get('editedList');

    const wasAlreadyEdited = (summaryValues) =>
      editedList.findIndex(
        (item) => item.get('EPUID') === summaryValues.get('EPUID'),
      ) >= 0;

    const inEditingItems = apiList.filter(
      (item) => item.get('InEdit') && !wasAlreadyEdited(item),
    );
    return state.merge({
      loading: false,
      errorMessage: null,
      options: options.set('editedList', editedList.push(...inEditingItems)),
      apiList: apiList.map((item) => {
        if (item.get('EPUID') === summaryLine.EPUID) {
          return item.set('InEdit', true);
        }
        return item.set('InEdit', false);
      }),
    });
  },
  [SUMMARY_EDIT_LINE_CANCEL]: (state, action) => {
    const { summaryLine } = action;
    const apiList = state.get('apiList');

    return state.merge({
      loading: false,
      errorMessage: null,
      apiList: apiList.map((item) => {
        if (!summaryLine || item.get('EPUID') === summaryLine.EPUID) {
          return item.set('InEdit', false);
        }
        return item;
      }),
    });
  },
  [SUMMARY_EDIT_LINE_SAVE]: (state, action) => {
    const apiList = state.get('apiList');

    const { summaryValues } = action;
    delete summaryValues.NominatedSegmentsStr;
    delete summaryValues.Contestant;

    const options = state.get('options');
    const editedList = options.get('editedList');
    const wasAlreadyEdited =
      editedList.findIndex(
        (item) => item.get('EPUID') === summaryValues.EPUID,
      ) >= 0;

    return state.merge({
      loading: false,
      errorMessage: null,
      options: !wasAlreadyEdited
        ? options.set('editedList', editedList.push(new Summary(summaryValues)))
        : options,
      apiList: apiList.map((item) => {
        if (item.get('EPUID') === summaryValues.EPUID) {
          return item.merge({
            ...summaryValues,
            InEdit: false,
          });
        }
        return item;
      }),
    });
  },
  [SUMMARY_DESELECT_ALL]: (state) => {
    const apiList = state.get('apiList');

    return state.merge({
      loading: false,
      errorMessage: null,
      list: initialState.get('list'),
      apiList: apiList.map((item) => item.set('Selected', false)),
    });
  },
  [SUMMARY_SELECT_LINE]: (state, action) => {
    const { summaryLine } = action;
    const list = state.get('list');
    const apiList = state.get('apiList');

    return state.merge({
      loading: false,
      errorMessage: null,
      list: list.push(new Summary(summaryLine)),
      apiList: apiList.map((item) => {
        if (item.get('EPUID') === summaryLine.EPUID) {
          return item.set('Selected', true);
        }

        return item;
      }),
    });
  },
  [SUMMARY_DESELECT_LINE]: (state, action) => {
    const { summaryLine } = action;
    const list = state.get('list');
    const apiList = state.get('apiList');

    return state.merge({
      loading: false,
      errorMessage: null,
      list: list.filter((line) => line.get('EPUID') !== summaryLine.EPUID),
      apiList: apiList.map((item) => {
        if (item.get('EPUID') === summaryLine.EPUID) {
          return item.set('Selected', false);
        }

        return item;
      }),
    });
  },
};

export default function summary(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
