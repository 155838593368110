import gql from 'graphql-tag';

export const contestantFragments = {
  ContestantId: gql`
    fragment ContestantId on Contestant {
      id
      ERAUID
      userId
    }
  `,
  ContestantName: gql`
    fragment ContestantName on Contestant {
      FullName
      FName
      LName
      firstName
      lastName
      Nickname
      LegalFirstName
      LegalLastName
      Suffix
    }
  `,
  ContestantAddress: gql`
    fragment ContestantAddress on Contestant {
      street
      city
      state
      zip
      country
      street2
    }
  `,
  ContestantSocialAccounts: gql`
    fragment ContestantSocialAccounts on Contestant {
      Facebook
      Twitter
      Instagram
      YouTube
      Pinterest
      Snapchat
      LinkedIn
    }
  `,
};
