import { Record } from 'immutable';

export class AuthPayload extends Record(
  {
    token: null,
  },
  'AuthPayload',
) {
  constructor(values) {
    super({
      ...values,
    });
  }
}

const defaultValues = {
  authenticated: false,
  authPayload: new AuthPayload(),
  refreshTokenPromise: null,
};

export default class Auth extends Record(defaultValues, 'Auth') {
  constructor(values) {
    super({
      ...values,
      authPayload:
        !!values && !!values.authPayload
          ? new AuthPayload(values.authPayload)
          : defaultValues.authPayload,
    });
  }
}
