import { Record } from 'immutable';

const defaultValues = {
  CouponCode: null,
  NominationRevenue: null,
  TransFee: null,
  aggregatePoints: null,
  city: '',
  contestantId: null,
  OriginSource: null,
  createdAt: '',
  dateOfCompetitionSelected: '',
  disciplineNominated: '',
  email: '',
  eventDivision: null,
  eventDivisionDesc: '',
  eventLocation: '',
  eventName: '',
  firstName: '',
  fullName: '',
  lastName: '',
  nominationFee: null,
  nominationRevenue: null,
  phone: '',
  primaryDiscipline: '',
  socialTags: '',
  state: '',
  streetOne: '',
  streetTwo: null,
  stripeChargeId: '',
  userId: null,
  zip: '',
  totalFee: 0,
  NomUID: null,
  ClassName: null,
  SegmentAsString: null,
  ShortName: null,
  SegmentUID: null,
  EventEntryDisciplineFeeUID: null,
};

export default class Nominations extends Record(defaultValues, 'Nominations') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
