const ABILITY_TYPES = {
  ADMIN_SETTING: 'AdmingSetting',
  ATHLETE: 'Athlete',
  MEMBERSHIP_SEASON: 'MembershipSeason',
  CONTRACTORS: 'Contractors',
  COUPON: 'Coupon',
  GIFTCARD: 'GiftCard',
  DASHBOARD: 'Dashboard',
  EVENT: 'Event',
  LIVESTOCK: 'Livestock',
  NOMINATION: 'Nomination',
  VENUES: 'Venues',
  RESULTS_AND_POINTS: 'ResultsAndPoints',
  ASSOCIATION_SETTINGS: 'AssociationSettings',
  ASSOCIATIONS_PROMOTERS: 'AssociationsPromoters',
};

const ACTION_TYPES = {
  READ: 'read',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

export { ABILITY_TYPES, ACTION_TYPES };
