import gql from 'graphql-tag';

export const LIST_EVENT_LOGS = gql`
  query GET_LOGS($input: EventLogsInput!) {
    eventLogsPayload(input: $input) {
      logs {
        id
        description
        action
        userId
        email
        payloadJSON
        createdAt
        updatedAt
        giftCardPurchaseUID
        EventUID
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
