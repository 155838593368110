import moment from 'moment';

export const isYouthByDOB = (DOB) => {
  if (!DOB) {
    return false;
  }

  const maxYouthAge = moment()
    .subtract(19, 'years')
    .format('YYYY-MM-DD');

  return moment(DOB).isSameOrAfter(maxYouthAge);
};
