import { Record } from 'immutable';

const defaultValues = {
  NomUID: null,
  ERAUID: null,
  EventUID: null,
  CompType: null,
  NomDate: null,
  CouponUID: null,
  NomFee: null,
  TransFee: null,
  totalTransactionAmount: null,
  totalAmountWithDiscount: null,
  NomFeeActual: null,
  totalOnStripe: null,
  TransFeeActual: null,
  NominationRevenue: null,
  NumberOfNominations: null,
  NominationRefundedCount: null,
  stripeFee: null,
  totalTransactionFee: null,
  chargeHasMoreThanOneNomination: null,
  chargeId: null,
  createdAt: null,
  DisciplineAsString: null,
  OriginSource: null,
  // Event data
  EventName: null,
  EventLocation: null,
  EventType: null,
  SegmentAsString: null,
  EventDate: null,
  EventDateTo: null,
  // Athlete data
  firstName: null,
  lastName: null,
  FullName: null,
  email: null,
  userId: null,
  mainDiscipline: null,
  mainDisciplineAsString: null,
  CouponCode: null,
  Segments: [],
  rank: null,
  ShortName: null,
  DivisionName: null,
  Points: null,
  ClassName: null,
  SegmentUID: null,
  EventEntryDisciplineFeeUID: null,
  last4: null,
  DollarDisc: null,
  NomDisc: null,
  FeeDisc: null,
  ERAGiftCardAccountUID: null,
  hasGiftCard: null,
  IsRefunded: null,
  IsInactive: null,
  transactionFee: null,
  currentDivision: null,
  currentDivisionAsString: null,
  originalDivision: null,
  originalDivisionFee: null,
  originalDivisionAsString: null,
  lastRefundType: null,
  divisionChangeCount: null,
};

export default class Nomination extends Record(defaultValues, 'Nomination') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
