import api from 'api';
import client from 'api/apollo';
import {
  getFromState,
  getToken,
  filterToRequest,
  getFilters,
  logError,
} from 'helpers';
import {
  createAlertSuccess,
  clearAlertSuccessAsync,
  clearAlertSuccess,
} from 'actions/alert';
import { DIVISION_CHANGE, REFUND_WITH_VRQ_COUPON } from 'constants/nomination';

import { hasFeatureFlag } from '../utils/flagsmith';
import { LIST_NOMINATIONS } from 'graphql/queries/nominations/listNominations';
import { GET_NOMINATION } from 'graphql/queries/nominations/Nomination';
import { LIST_NOMINATIONS_PER_EVENT } from 'graphql/queries/nominations/listNominationsPerEvent';

export const NOMINATION_ASYNC_START = 'NOMINATION_ASYNC_START';
export const NOMINATION_ASYNC_STOP = 'NOMINATION_ASYNC_STOP';
export const NOMINATION_ACTION_CLEAR = 'NOMINATION_ACTION_CLEAR';
export const NOMINATION_ASYNC_FAIL = 'NOMINATION_ASYNC_FAIL';
export const NOMINATION_ASYNC_SUCCESS = 'NOMINATION_ASYNC_SUCCESS';
export const NOMINATION_LIST_ASYNC_SUCCESS = 'NOMINATION_LIST_ASYNC_SUCCESS';
export const NOMINATIONS_PER_EVENT_LIST_ASYNC_SUCCESS =
  'NOMINATIONS_PER_EVENT_LIST_ASYNC_SUCCESS';
export const NOMINATION_ASYNC_CLEAR_ERROR = 'NOMINATION_ASYNC_CLEAR_ERROR';
export const NOM_APPLY_LIST_OPTIONS = 'NOM_APPLY_LIST_OPTIONS';
export const NOMINATION_REMOVE = 'NOMINATION_REMOVE';
export const NOMINATION_REMOVE_SUCCESS = 'NOMINATION_REMOVE_SUCCESS';
export const NOMINATION_REFUND_SUCCESS = 'NOMINATION_REFUND_SUCCESS';
export const NOMINATION_UPDATE_OPTIONS = 'NOMINATION_UPDATE_OPTIONS';
export const NOMINATIONS_SELECTED = 'NOMINATIONS_SELECTED';

export function applyConfig(data) {
  return {
    type: NOM_APPLY_LIST_OPTIONS,
    data,
  };
}

export function nominationAsyncStart() {
  return {
    type: NOMINATION_ASYNC_START,
  };
}

export function nominationAsyncStop() {
  return {
    type: NOMINATION_ASYNC_STOP,
  };
}

export function nominationActionClear() {
  return {
    type: NOMINATION_ACTION_CLEAR,
  };
}

function nominationAsyncFail(errorMessage) {
  return {
    type: NOMINATION_ASYNC_FAIL,
    errorMessage,
  };
}

function nominationRemoveAsyncSuccess() {
  return {
    type: NOMINATION_REMOVE_SUCCESS,
  };
}

function nominationRefundAsyncSuccess() {
  return {
    type: NOMINATION_REFUND_SUCCESS,
  };
}

export function nominationAsyncSuccess(data) {
  return {
    type: NOMINATION_ASYNC_SUCCESS,
    data,
  };
}

export function nominationsListAsyncSuccess(data) {
  return {
    type: NOMINATION_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function nominationsListPerEventAsyncSuccess(data) {
  return {
    type: NOMINATIONS_PER_EVENT_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function nominationAsyncClearError() {
  return {
    type: NOMINATION_ASYNC_CLEAR_ERROR,
  };
}

export function removeNomination(nomination, reason) {
  return async (dispatch, getState) => {
    dispatch(nominationAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `nominations/${nomination.NomUID}`,
        method: 'DELETE',
        accessToken,
        body: { reason },
      });

      dispatch(nominationRemoveAsyncSuccess());

      await dispatch(
        createAlertSuccess({
          message: 'Nomination removed!',
          type: 'success',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      return response;
    } catch (error) {
      logError(error);
      dispatch(nominationAsyncFail(error.message));
      await dispatch(
        createAlertSuccess({
          message: 'Error on remove your nomination.',
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      return null;
    }
  };
}

export function refundNomination(nomination, values) {
  return async (dispatch, getState) => {
    dispatch(nominationAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `nominations/refund/${nomination.NomUID}`,
        method: 'POST',
        accessToken,
        body: values,
      });

      await dispatch(nominationRefundAsyncSuccess());

      await dispatch(
        createAlertSuccess({
          message:
            'The refund workflow is complete and the refund will be applied within 5-10 days.',
          type: 'success',
        }),
      );
      await dispatch(clearAlertSuccessAsync());

      return response;
    } catch (error) {
      logError(error);
      dispatch(nominationAsyncFail(error.message));

      await dispatch(
        createAlertSuccess({
          message: 'Error on refund your nomination.',
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());

      return null;
    }
  };
}

export function refund(NomUID, values, additionalNomUIDs) {
  return async (dispatch, getState) => {
    dispatch(nominationAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `nominations/${NomUID}/refund?additionalNomUIDs=${additionalNomUIDs}`,
        method: 'POST',
        accessToken,
        body: values,
      });

      await dispatch(nominationRefundAsyncSuccess());
      const { RefundType, RefundMethod } = values;
      const isDivisionChange = DIVISION_CHANGE === RefundType;

      let message =
        RefundMethod === REFUND_WITH_VRQ_COUPON
          ? 'Nomination Was Deleted From The System And A Coupon Was Created Instead Of Refunding'
          : 'Nomination Was Fully Refunded And Deleted From The System';

      if (isDivisionChange) {
        message =
          'Nomination Has Been Updated And The Athlete Has Been Refunded';
      }

      await dispatch(
        createAlertSuccess({
          message,
          type: 'success',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      dispatch(nominationAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(nominationAsyncFail(error.message));

      await dispatch(
        createAlertSuccess({
          message: 'Error processing your nomination.',
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());

      return null;
    }
  };
}

export function listNominations(values) {
  return async (dispatch, getState) => {
    try {
      dispatch(nominationAsyncStart());
      const state = getState();
      const { id: EventUID } = getFromState(getState, 'event');
      const filters = getFilters(values, state);
      const { pageNumber, sortDirection, orderBy, resultsPerPage } = values;
      const variables = {
        input: {
          EventUID,
          filters: filterToRequest(filters),
          sortDirection,
          orderBy,
          pageNumber,
          resultsPerPage,
        },
      };

      const nominationsQuery = {
        query: LIST_NOMINATIONS,
        variables,
        fetchPolicy: 'network-only',
      };

      const response = await client.query(nominationsQuery);
      dispatch(nominationsListAsyncSuccess(response.data));
    } catch (error) {
      logError(error);
      dispatch(nominationAsyncFail(error.message));
      return null;
    }
  };
}

export function getNomination(NomUID) {
  return async (dispatch) => {
    try {
      dispatch(nominationAsyncStart());
      const variables = {
        NomUID,
      };

      const nominationQuery = {
        query: GET_NOMINATION,
        variables,
        fetchPolicy: 'network-only',
      };

      const response = await client.query(nominationQuery);
      dispatch(
        nominationAsyncSuccess(response.data.nominationPayload.nomination),
      );
      return response.data.nominationPayload.nomination;
    } catch (error) {
      logError(error);
      dispatch(nominationAsyncFail(error.message));
      return null;
    }
  };
}

export function getNominationsByChargeId(chargeId, nomUID) {
  return async (dispatch, getState) => {
    dispatch(nominationAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path = `nominations/${chargeId}/list?excludeNomUID=${nomUID}`;
      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(nominationAsyncStop());

      return response;
    } catch (error) {
      logError(error);
      dispatch(nominationAsyncFail(error.message));
      return null;
    }
  };
}

export function listNominationsPerEvent(values) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { id: EventUID } = getFromState(getState, 'event');
      const filters = getFilters(values, state);
      const { pageNumber, sortDirection, orderBy, resultsPerPage } = values;
      const variables = {
        input: {
          EventUID,
          filters: filterToRequest(filters),
          sortDirection,
          orderBy,
          pageNumber,
          resultsPerPage,
        },
      };

      const nominationsQuery = {
        query: LIST_NOMINATIONS_PER_EVENT,
        variables,
        fetchPolicy: 'network-only',
      };

      const response = await client.query(nominationsQuery);

      dispatch(nominationsListPerEventAsyncSuccess(response.data));
    } catch (error) {
      logError(error);
      dispatch(nominationAsyncFail(error.message));
      return null;
    }
  };
}

export function saveNomination(values) {
  return async (dispatch, getState) => {
    dispatch(nominationAsyncStart());
    const allowNominationV2 = hasFeatureFlag('add_nomination_v2');
    if (allowNominationV2) dispatch(clearAlertSuccess());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      let response;
      if (values.NomUID) {
        response = await api({
          path: `nominations/${values.NomUID}`,
          method: 'PATCH',
          body: values,
          accessToken,
        });
      } else {
        response = await api({
          path: 'nominations',
          method: 'POST',
          body: values,
          accessToken,
        });
      }

      setTimeout(async () => {
        await dispatch(
          createAlertSuccess({
            message: 'Nomination saved!',
            type: 'success',
          }),
        );
        await dispatch(clearAlertSuccessAsync());
      });
      dispatch(nominationAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(nominationAsyncFail(error.message));
      if (allowNominationV2) {
        dispatch(
          createAlertSuccess({
            message: error.message,
            type: 'fail',
          }),
        );
      }

      return null;
    }
  };
}

export function nominationCalculateFee(values) {
  return async (dispatch, getState) => {
    dispatch(nominationAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'nominations/calculate-fees',
        method: 'POST',
        body: values,
        accessToken,
      });

      dispatch(nominationAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(nominationAsyncFail(error.message));
      return null;
    }
  };
}

export function selectNomination(nomination) {
  return (dispatch) => {
    dispatch(nominationAsyncSuccess(nomination));
  };
}

export function updateNominationAttributes(nomination) {
  return (dispatch) => {
    dispatch(nominationAsyncSuccess(nomination));
  };
}

export function clearSelectedNomination() {
  return (dispatch) => {
    dispatch(nominationActionClear());
  };
}

export function getNominationClassNames(values) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'nominations/get-class-names',
        method: 'POST',
        body: values,
        accessToken,
      });

      return response;
    } catch (error) {
      logError(error);
      return null;
    }
  };
}

export function updateNominationOptions(orderBy, order, page, rowsPerPage) {
  return {
    type: NOMINATION_UPDATE_OPTIONS,
    data: { orderBy, order, page, rowsPerPage },
  };
}

export function selectNominations(nominations) {
  return {
    type: NOMINATIONS_SELECTED,
    data: { nominations },
  };
}
