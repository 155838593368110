import { Record } from 'immutable';

const defaultValues = {
  EventDisciplineGoUID: null,
  GoNumber: null,
  Name: '',
  Total: null,
};

export default class GoRoundOverAllResult extends Record(
  defaultValues,
  'GoRoundOverAllResult',
) {
  constructor(values) {
    super({
      ...values,
    });
  }
}
