import Segment from 'models/Segment';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  SEGMENT_ASYNC_START,
  SEGMENT_ACTION_CLEAR,
  SEGMENT_ASYNC_FAIL,
  SEGMENT_ASYNC_SUCCESS,
  SEGMENT_LIST_ASYNC_SUCCESS,
} from 'actions/segment';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Segment(),
  list: toEntityList([], Segment),
});

const actionsMap = {
  [SEGMENT_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [SEGMENT_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [SEGMENT_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Segment(action.data),
    });
  },
  [SEGMENT_LIST_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(action.data, Segment),
    });
  },
  [SEGMENT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
};

export default function segment(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
