// @flow
import { Record } from 'immutable';

export type DisciplineType = {
  id: ?number,
  name: ?string,
  SortOrder: ?number,
  Short: ?string,
  NumberOfGos: ?number,
  GoNumber: ?number,
  NumberOfAthletes: ?number,
  NumberOfAnimals: ?number,
  NumberOfJudges: ?number,
  StockDrawIsSubmitted: ?number,
  ClassName: ?string,
};

const defaultValues: DisciplineType = {
  id: null,
  name: null,
  SortOrder: null,
  Short: null,
  NumberOfGos: null,
  GoNumber: null,
  NumberOfAthletes: null,
  NumberOfAnimals: null,
  NumberOfJudges: null,
  StockDrawIsSubmitted: 0,
  ClassName: null,
};

export default class Discipline extends Record(defaultValues, 'Discipline') {
  constructor(values: ?DisciplineType) {
    super({
      ...values,
    });
  }
}
