import client from 'api/apollo';
import { filterToRequest, getFilters } from 'helpers';

import { LIST_NOMINATIONS_PER_CONTESTANT } from '../graphql/queries/nominations/listNominationsPerContestant';

export const ATHLETE_NOMINATION_ASYNC_START = 'ATHLETE_NOMINATION_ASYNC_START';
export const ATHLETE_NOMINATION_ASYNC_STOP = 'ATHLETE_NOMINATION_ASYNC_STOP';
export const ATHLETE_NOMINATION_ACTION_CLEAR =
  'ATHLETE_NOMINATION_ACTION_CLEAR';
export const ATHLETE_NOMINATION_ASYNC_FAIL = 'ATHLETE_NOMINATION_ASYNC_FAIL';
export const ATHLETE_NOMINATION_ASYNC_SUCCESS =
  'ATHLETE_NOMINATION_ASYNC_SUCCESS';
export const ATHLETE_NOMINATION_LIST_ASYNC_SUCCESS =
  'ATHLETE_NOMINATION_LIST_ASYNC_SUCCESS';
export const ATHLETE_NOMINATION_UPDATE_OPTIONS =
  'ATHLETE_NOMINATION_UPDATE_OPTIONS';
export const ATHLETE_NOMINATION_WITHOUT_PROFILE_LIST_ASYNC_SUCCESS =
  'ATHLETE_NOMINATION_WITHOUT_PROFILE_LIST_ASYNC_SUCCESS';
export const ATHLETE_NOMINATION_ASYNC_CLEAR_ERROR =
  'ATHLETE_NOMINATION_ASYNC_CLEAR_ERROR';
export const ATHLETE_NOMINATION_APPLY_LIST_OPTIONS =
  'ATHLETE_NOMINATION_APPLY_LIST_OPTIONS';
export const UPDATE_ATHLETE_NOMINATIONS_OPTIONS =
  'UPDATE_ATHLETE_NOMINATIONS_OPTIONS';

export function athleteNominationAsyncStart() {
  return {
    type: ATHLETE_NOMINATION_ASYNC_START,
  };
}

export function athleteNominationAsyncStop() {
  return {
    type: ATHLETE_NOMINATION_ASYNC_STOP,
  };
}

function athleteNominationAsyncFail(errorMessage) {
  return {
    type: ATHLETE_NOMINATION_ASYNC_FAIL,
    errorMessage,
  };
}

export function athleteNominationAsyncSuccess(data) {
  return {
    type: ATHLETE_NOMINATION_ASYNC_SUCCESS,
    data,
  };
}

export function athleteNominationAsyncClearError() {
  return {
    type: ATHLETE_NOMINATION_ASYNC_CLEAR_ERROR,
  };
}

export function updateAthleteNominationOptions(
  orderBy,
  order,
  page,
  rowsPerPage,
) {
  return {
    type: UPDATE_ATHLETE_NOMINATIONS_OPTIONS,
    data: { orderBy, order, page, rowsPerPage },
  };
}

export function contestantListAsyncSuccess(data) {
  return {
    type: ATHLETE_NOMINATION_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function getNominationsList(values) {
  return async (dispatch, getState) => {
    try {
      dispatch(athleteNominationAsyncStart());
      const state = getState();
      const filters = getFilters(values, state);
      const {
        athleteId,
        pageNumber,
        sortDirection,
        orderBy,
        resultsPerPage,
      } = values;

      const variables = {
        input: {
          filters: filterToRequest(filters),
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
          ERAUID: parseInt(athleteId),
        },
      };

      const contestantNominationsQuery = {
        query: LIST_NOMINATIONS_PER_CONTESTANT,
        variables,
        fetchPolicy: 'network-only',
      };

      const response = await client.query(contestantNominationsQuery);
      dispatch(contestantListAsyncSuccess(response.data));
    } catch (e) {
      dispatch(athleteNominationAsyncFail('Error'));
    }
  };
}
