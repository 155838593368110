import ErrorBoundary from './containers/App/ErrorBoundary';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/react-hooks';
import client from './api/apollo';
import Root from './containers/Root';
import React from 'react';
import configureStore from './store/configureStore';
import { Auth0Provider } from '@auth0/auth0-react';

const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirectUri: `${window.location.origin}/auth0`,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Barlow', 'sans-serif'].join(','),
    useNextVariants: true,
  },

  palette: {
    type: 'light',
    background: {
      paper: '#ffffff',
      default: '#f4f5f8',
    },
    primary: {
      light: '#2f3331',
      main: '#000000',
      dark: '#000000',
      contrastText: '#ccc',
    },
    secondary: {
      light: '#b4f5e6',
      main: '#0fb58f',
      dark: '#0a7960',
      contrastText: '#FFF',
    },
  },
});

const stripePubKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const { store, persistor } = configureStore();

export const App = () => {
  return (
    <ErrorBoundary>
      <CssBaseline />
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        audience={auth0Config.audience}
        useRefreshTokens
        cacheLocation="localstorage"
        redirectUri={auth0Config.redirectUri}
      >
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <StripeProvider apiKey={stripePubKey}>
              <PersistGate loading={null} persistor={persistor}>
                <ApolloProvider client={client}>
                  <Root store={store} />
                </ApolloProvider>
              </PersistGate>
            </StripeProvider>
          </Provider>
        </MuiThemeProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
};

if (window.Cypress) {
  window.store = store;
}
