import Base from 'models/Base';
import Performance from 'models/Performance';
import Discipline from 'models/Discipline';
import radixParameter from 'constants/radix';
import { toJS } from 'helpers';
import { Iterable } from 'immutable';

import {
  CLEAR_PERFORMANCE_SUCCESS,
  LOAD_PERFORMANCE_SUCCESS,
  ADD_PERFORMANCE_DISCIPLINE_SUCCESS,
  REMOVE_PERFORMANCE_DISCIPLINE_SUCCESS,
  UPDATE_PERFORMANCE_DISCIPLINE_SUCCESS,
  UPDATE_PERFORMANCE_SELECT_FIELD_SUCCESS,
  UPDATE_PERFORMANCE_DATE_SUCCESS,
  UPDATE_PERFORMANCE_NAME_SUCCESS,
  SET_PERFORMANCE_DISCIPLINE_SUCCESS,
} from 'actions/performance';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Performance(),
  index: null,
});

const actionsMap = {
  [CLEAR_PERFORMANCE_SUCCESS]: (state, action) => {
    const { disciplines } = action;
    return state.merge({
      loading: false,
      errorMessage: '',
      data: new Performance({
        Disciplines: disciplines,
      }),
    });
  },
  [LOAD_PERFORMANCE_SUCCESS]: (state, action) => {
    const { data, index = null } = action;
    const performance = Iterable.isIterable(data)
      ? data
      : new Performance(data);

    return state.merge({
      loading: false,
      errorMessage: '',
      data: performance,
      index,
    });
  },
  [ADD_PERFORMANCE_DISCIPLINE_SUCCESS]: (state) => {
    const disciplines = state.get('data').get('Disciplines');

    const newDisciplines = disciplines.concat(
      new Discipline({
        id: null,
        name: null,
        SortOrder: null,
        Short: null,
        NumberOfGos: null,
        NumberOfAthletes: null,
        NumberOfAnimals: null,
        NumberOfJudges: null,
        ClassName: null,
        GoNumber: null,
      }),
    );

    const updatedPerformance = state
      .get('data')
      .set('Disciplines', newDisciplines);
    return state.merge({
      data: updatedPerformance,
    });
  },
  [REMOVE_PERFORMANCE_DISCIPLINE_SUCCESS]: (state, action) => {
    const disciplines = state.get('data').get('Disciplines');
    const newDisciplines = disciplines.filter((d, i) => i !== action.data);
    const updatedPerformance = state
      .get('data')
      .set('Disciplines', [].concat(newDisciplines));
    return state.merge({
      data: updatedPerformance,
    });
  },
  [SET_PERFORMANCE_DISCIPLINE_SUCCESS]: (state, action) => {
    const newDisciplines = action.disciplines;
    const updatedPerformance = state
      .get('data')
      .set('Disciplines', [].concat(newDisciplines));
    return state.merge({
      data: updatedPerformance,
    });
  },
  [UPDATE_PERFORMANCE_DISCIPLINE_SUCCESS]: (state, action) => {
    const disciplines = state.get('data').get('Disciplines');
    const updatedDisciplines = Object.values(disciplines).map(toJS);

    if (action.fieldName === 'name') {
      const disciplineId = parseInt(action.fieldValue, radixParameter.DEFAULT);
      updatedDisciplines[action.index].id = disciplineId;
      const discipline = action.disciplines.find((disciplineItem) => {
        return disciplineItem.id === disciplineId;
      });
      updatedDisciplines[action.index].name = discipline
        ? discipline.name
        : null;
    } else if (action.fieldName === 'PreferenceEligible') {
      updatedDisciplines[action.index][action.fieldName] = action.fieldValue;
    } else {
      const fieldValue = action.fieldValue;
      updatedDisciplines[action.index][action.fieldName] = fieldValue;
    }

    const updatedPerformance = state
      .get('data')
      .set('Disciplines', updatedDisciplines);
    return state.merge({
      data: updatedPerformance,
    });
  },
  [UPDATE_PERFORMANCE_SELECT_FIELD_SUCCESS]: (state, action) => {
    const updatedPerformance = state
      .get('data')
      .set('CompLevel', action.fieldValue);
    return state.merge({
      data: updatedPerformance,
    });
  },
  [UPDATE_PERFORMANCE_DATE_SUCCESS]: (state, action) => {
    const updatedPerformance = state
      .get('data')
      .set('PerformanceDate', action.fieldValue);
    return state.merge({
      data: updatedPerformance,
    });
  },
  [UPDATE_PERFORMANCE_NAME_SUCCESS]: (state, action) => {
    const updatedPerformance = state
      .get('data')
      .set('PerformanceName', action.fieldValue);
    return state.merge({
      data: updatedPerformance,
    });
  },
};

export default function performance(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
