/* global APP_CONFIG */
import { getFromState, getToken, logError } from 'helpers';

export const CSV_ASYNC_START = 'CSV_ASYNC_START';
export const CSV_ACTION_CLEAR = 'CSV_ACTION_CLEAR';
export const CSV_ASYNC_FAIL = 'CSV_ASYNC_FAIL';
export const CSV_ASYNC_SUCCESS = 'CSV_ASYNC_SUCCESS';
export const CSV_ASYNC_CLEAR_ERROR = 'CSV_ASYNC_CLEAR_ERROR';

export function csvAsyncStart() {
  return {
    type: CSV_ASYNC_START,
  };
}

export function csvActionClear() {
  return {
    type: CSV_ACTION_CLEAR,
  };
}

function csvAsyncFail(errorMessage) {
  return {
    type: CSV_ASYNC_FAIL,
    errorMessage,
  };
}

export function csvAsyncSuccess(data) {
  return {
    type: CSV_ASYNC_SUCCESS,
    data,
  };
}

export function csvAsyncClearError() {
  return {
    type: CSV_ASYNC_CLEAR_ERROR,
  };
}

export function selectCsv(csv) {
  return (dispatch) => {
    dispatch(csvAsyncSuccess(csv));
  };
}

export function clearSelectedCsv() {
  return (dispatch) => {
    dispatch(csvActionClear());
  };
}

export function generateAthleteCSV() {
  return async (dispatch, getState) => {
    dispatch(csvAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path = `contestants/athletes-export?accessToken=${accessToken}`;
      const base = APP_CONFIG.api.url;
      const url = `${base}/${path}`;

      window.open(url);
    } catch (error) {
      logError(error);
      const err = error.message;

      dispatch(csvAsyncFail(err));
      return null;
    }
  };
}

export function generateContestantCSV() {
  return async (dispatch, getState) => {
    dispatch(csvAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path = `contestants/contestants-export?accessToken=${accessToken}`;
      const base = APP_CONFIG.api.url;
      const url = `${base}/${path}`;

      window.open(url);
    } catch (error) {
      logError(error);
      const err = error.message;

      dispatch(csvAsyncFail(err));
      return null;
    }
  };
}

export function generateEntryWithPreferencesCSV({ EventUID }) {
  return async (dispatch, getState) => {
    dispatch(csvAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path = `report/buddies-export?accessToken=${accessToken}&EventUID=${EventUID}`;
      const base = APP_CONFIG.api.url;
      const url = `${base}/${path}`;

      window.open(url);
      dispatch(csvAsyncClearError());
    } catch (error) {
      logError(error);
      const err = error.message;

      dispatch(csvAsyncFail(err));
      return null;
    }
  };
}

export function generateEventPaidUnpaidTransactionsCSV({ EventUID }) {
  return async (dispatch, getState) => {
    dispatch(csvAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path = `report/paid-unpaid-export?accessToken=${accessToken}&EventUID=${EventUID}`;
      const base = APP_CONFIG.api.url;
      const url = `${base}/${path}`;

      window.open(url);
      dispatch(csvAsyncClearError());
    } catch (error) {
      logError(error);
      const err = error.message;

      dispatch(csvAsyncFail(err));
      return null;
    }
  };
}

export function generateEventEntriesCSV({ EventUID }) {
  return async (dispatch, getState) => {
    dispatch(csvAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path = `report/event-entries?accessToken=${accessToken}&EventUID=${EventUID}`;
      const base = APP_CONFIG.api.url;
      const url = `${base}/${path}`;

      window.open(url);
      dispatch(csvAsyncClearError());
    } catch (error) {
      logError(error);
      const err = error.message;

      dispatch(csvAsyncFail(err));
      return null;
    }
  };
}

export function generateEventNominationsCSV({ EventUID }) {
  return async (dispatch, getState) => {
    dispatch(csvAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path = `report/event-nominations?accessToken=${accessToken}&EventUID=${EventUID}`;
      const base = APP_CONFIG.api.url;
      const url = `${base}/${path}`;

      window.open(url);
      dispatch(csvAsyncClearError());
    } catch (error) {
      logError(error);
      const err = error.message;

      dispatch(csvAsyncFail(err));
      return null;
    }
  };
}

export function generateAthleteNominationsCSV(ERAUID) {
  return async (dispatch, getState) => {
    dispatch(csvAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path = `report/athlete-nominations?accessToken=${accessToken}&ERAUID=${ERAUID}`;
      const base = APP_CONFIG.api.url;
      const url = `${base}/${path}`;

      window.open(url);
      dispatch(csvAsyncClearError());
    } catch (error) {
      logError(error);
      const err = error.message;

      dispatch(csvAsyncFail(err));
      return null;
    }
  };
}
