import { Record } from 'immutable';

const defaultValues = {
  StockDrawUID: null,
  PerformanceUID: null,
  CompType: null,
  EventUID: null,
  NumberOfAnimals: null,
  NumberOfAthletes: null,
  ERAUID: null,
  AnimalUID: null,
  Position: null,
  createdAt: null,
  updatedAt: null,
  EPUID: null,
  PartnerEPUID: null,
  PartnerERAUID: null,
};

export default class StockDraw extends Record(defaultValues, 'StockDraw') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
