// @flow
import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';
import Discipline from 'models/Discipline';
import type { DisciplineType } from 'models/Discipline';

export const PERFORMANCE_TYPES_IDS = {
  CONSOLATION: 20,
  CONSOLATION_POINTS: 155,
  NOMINATION_POINTS: 176,
  GOROUND: 16,
  ADVANCING: 17,
  SEMIFINALS: 18,
  FINALS: 19,
  AVERAGE: 35,
};

export const PERFORMANCE_TYPES_LABELS = {
  GOROUND: 'Go Round',
  ADVANCING: 'Advancing',
  SEMIFINALS: 'Semifinal',
  FINALS: 'Finals',
  AVERAGE: 'Average',
};

export const performanceTypes = [
  { id: PERFORMANCE_TYPES_IDS.GOROUND, name: PERFORMANCE_TYPES_LABELS.GOROUND },
  {
    id: PERFORMANCE_TYPES_IDS.ADVANCING,
    name: PERFORMANCE_TYPES_LABELS.ADVANCING,
  },
  {
    id: PERFORMANCE_TYPES_IDS.SEMIFINALS,
    name: PERFORMANCE_TYPES_LABELS.SEMIFINALS,
  },
  { id: PERFORMANCE_TYPES_IDS.FINALS, name: PERFORMANCE_TYPES_LABELS.FINALS },
  { id: PERFORMANCE_TYPES_IDS.CONSOLATION, name: 'Consolation' },
  { id: PERFORMANCE_TYPES_IDS.AVERAGE, name: PERFORMANCE_TYPES_LABELS.AVERAGE },
  { id: 133, name: 'Slack' },
];

export const competitionLevels = [
  { id: PERFORMANCE_TYPES_IDS.GOROUND, name: PERFORMANCE_TYPES_LABELS.GOROUND },
  {
    id: PERFORMANCE_TYPES_IDS.ADVANCING,
    name: PERFORMANCE_TYPES_LABELS.ADVANCING,
  },
  {
    id: PERFORMANCE_TYPES_IDS.SEMIFINALS,
    name: PERFORMANCE_TYPES_LABELS.SEMIFINALS,
  },
  { id: PERFORMANCE_TYPES_IDS.FINALS, name: PERFORMANCE_TYPES_LABELS.FINALS },
  { id: PERFORMANCE_TYPES_IDS.AVERAGE, name: PERFORMANCE_TYPES_LABELS.AVERAGE },
  { id: 133, name: 'Slack' },
  { id: PERFORMANCE_TYPES_IDS.CONSOLATION_POINTS, name: 'Consolation Points' },
  { id: PERFORMANCE_TYPES_IDS.NOMINATION_POINTS, name: 'Nomination Points' },
];

export const competitionLevelsForAddingPoints = [
  { id: PERFORMANCE_TYPES_IDS.GOROUND, name: PERFORMANCE_TYPES_LABELS.GOROUND },
  { id: PERFORMANCE_TYPES_IDS.FINALS, name: PERFORMANCE_TYPES_LABELS.FINALS },
  { id: PERFORMANCE_TYPES_IDS.AVERAGE, name: PERFORMANCE_TYPES_LABELS.AVERAGE },
];

export const nonCompetitionPoints = [
  PERFORMANCE_TYPES_IDS.CONSOLATION_POINTS,
  PERFORMANCE_TYPES_IDS.NOMINATION_POINTS,
];

export type PerformanceType = {
  PerformanceUID: ?number,
  EventUID: ?number,
  PerformanceNum: ?number,
  PerformanceDate: ?number,
  PerformanceName: ?string,
  TimeZoneUID: ?number,
  TimeZoneName: ?string,
  TimeZoneOffsetInMinutes: ?number,
  time: ?number,
  CompLevel: ?number,
  CompLevelAsString: ?string,
  Rot: ?number,
  Rnd: ?number,
  IsSlack: ?number,
  IsNominatable: ?boolean,
  Disciplines: DisciplineType[],
};

const defaultValues: PerformanceType = {
  PerformanceUID: null,
  EventUID: null,
  PerformanceNum: null,
  PerformanceDate: null,
  PerformanceName: null,
  TimeZoneUID: null,
  TimeZoneName: null,
  TimeZoneOffsetInMinutes: null,
  time: null,
  IsNominatable: true,
  CompLevel: null,
  CompLevelAsString: null,
  Rot: null,
  Rnd: null,
  IsSlack: null,
  Disciplines: toEntityList([], Discipline),
};

export default class Performance extends Record(defaultValues, 'Performance') {
  constructor(values: ?PerformanceType) {
    super({
      ...values,
    });
  }
}
