export const formatCurrency = (value) => {
  const monetaryValue =
    typeof value === 'number' ? value : Number.parseFloat(value);
  return monetaryValue.toLocaleString('en-US', {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
  });
};
