import GiftCard from 'models/GiftCard';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  GIFTCARD_ASYNC_START,
  GIFTCARD_ACTION_CLEAR,
  GIFTCARD_ASYNC_FAIL,
  GIFTCARD_ASYNC_SUCCESS,
  GIFTCARD_REMOVE_SUCCESS,
  GIFTCARD_ADD_BALANCE_SUCCESS,
  GIFTCARD_LIST_ASYNC_SUCCESS,
  GIFTCARD_ASYNC_CLEAR_ERROR,
  GIFTCARD_ACCOUNT_ASYNC_SUCCESS,
  GIFTCARD_ACCOUNT_ASYNC_FAIL,
} from 'actions/giftcard';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new GiftCard(),
  list: toEntityList([], GiftCard),
});

const actionsMap = {
  [GIFTCARD_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      data: new GiftCard(action.data),
    });
  },
  [GIFTCARD_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [GIFTCARD_REMOVE_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [GIFTCARD_ADD_BALANCE_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [GIFTCARD_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [GIFTCARD_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new GiftCard(action.data),
    });
  },
  [GIFTCARD_ACCOUNT_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new GiftCard(action.data),
    });
  },
  [GIFTCARD_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data) return state;
    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(action.data, GiftCard),
    });
  },
  [GIFTCARD_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [GIFTCARD_ACCOUNT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [GIFTCARD_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
};

export default function giftcard(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
