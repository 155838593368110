import api from 'api';
import { getFromState, getToken, logError } from 'helpers';

export const DISCIPLINE_ASYNC_START = 'DISCIPLINE_ASYNC_START';
export const DISCIPLINE_ACTION_CLEAR = 'DISCIPLINE_ACTION_CLEAR';
export const DISCIPLINE_ASYNC_FAIL = 'DISCIPLINE_ASYNC_FAIL';
export const DISCIPLINE_ASYNC_SUCCESS = 'DISCIPLINE_ASYNC_SUCCESS';
export const DISCIPLINE_LIST_ASYNC_SUCCESS = 'DISCIPLINE_LIST_ASYNC_SUCCESS';
export const DISCIPLINE_ASYNC_CLEAR_ERROR = 'DISCIPLINE_ASYNC_CLEAR_ERROR';

export function disciplineAsyncStart() {
  return {
    type: DISCIPLINE_ASYNC_START,
  };
}

export function disciplineActionClear() {
  return {
    type: DISCIPLINE_ACTION_CLEAR,
  };
}

function disciplineAsyncFail(errorMessage) {
  return {
    type: DISCIPLINE_ASYNC_FAIL,
    errorMessage,
  };
}

export function disciplineAsyncSuccess(data) {
  return {
    type: DISCIPLINE_ASYNC_SUCCESS,
    data,
  };
}

export function disciplineListAsyncSuccess(data) {
  return {
    type: DISCIPLINE_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function disciplineAsyncClearError() {
  return {
    type: DISCIPLINE_ASYNC_CLEAR_ERROR,
  };
}

export function listDisciplines() {
  return async (dispatch, getState) => {
    dispatch(disciplineAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'disciplines',
        method: 'GET',
        accessToken,
      });

      dispatch(disciplineListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch disciplines';

      dispatch(disciplineAsyncFail(err));
      return null;
    }
  };
}

export function listEventDisciplines() {
  return async (dispatch, getState) => {
    dispatch(disciplineAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const { id: eventId } = getFromState(getState, 'event');

      const response = await api({
        path: `disciplines/event?eventId=${eventId}`,
        method: 'GET',
        accessToken,
      });

      dispatch(disciplineListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch event disciplines';
      dispatch(disciplineAsyncFail(err));
      return null;
    }
  };
}

export function selectDiscipline(discipline) {
  return (dispatch) => {
    dispatch(disciplineAsyncSuccess(discipline));
  };
}

export function clearSelectedDiscipline() {
  return (dispatch) => {
    dispatch(disciplineActionClear());
  };
}
