import api from 'api';
import { getFromState, getToken, logError } from 'helpers';
import { createAlertSuccess } from 'actions/alert';

export const OVERALL_RESULT_ASYNC_START = 'OVERALL_RESULT_ASYNC_START';
export const OVERALL_RESULT_ASYNC_FAIL = 'OVERALL_RESULT_ASYNC_FAIL';
export const OVERALL_RESULT_ASYNC_SUCCESS = 'OVERALL_RESULT_ASYNC_SUCCESS';
export const OVERALL_RESULT_SELECT_ALL = 'OVERALL_RESULT_SELECT_ALL';
export const OVERALL_RESULT_SELECT_LINE = 'OVERALL_RESULT_SELECT_LINE';
export const OVERALL_RESULT_DESELECT_ALL = 'OVERALL_RESULT_DESELECT_ALL';
export const OVERALL_RESULT_DESELECT_LINE = 'OVERALL_RESULT_DESELECT_LINE';
export const OVERALL_ADVANCE_ATHLETES = 'OVERALL_ADVANCE_ATHLETES';

function overAllAsyncStart() {
  return {
    type: OVERALL_RESULT_ASYNC_START,
  };
}

function overAllAsyncFail(errorMessage) {
  return {
    type: OVERALL_RESULT_ASYNC_FAIL,
    errorMessage,
  };
}

function overAllAsyncSuccess(data) {
  return {
    type: OVERALL_RESULT_ASYNC_SUCCESS,
    data,
  };
}

function overAllAdvanceAthletesAsyncSuccess() {
  return {
    type: OVERALL_ADVANCE_ATHLETES,
  };
}

export function overAllSelectAll() {
  return {
    type: OVERALL_RESULT_SELECT_ALL,
  };
}

export function overAllSelectLine(overAllLine) {
  return {
    type: OVERALL_RESULT_SELECT_LINE,
    overAllLine,
  };
}

export function overAllDeselectAll() {
  return {
    type: OVERALL_RESULT_DESELECT_ALL,
  };
}

export function overAllDeselectLine(overAllLine) {
  return {
    type: OVERALL_RESULT_DESELECT_LINE,
    overAllLine,
  };
}

export function overAllAdvanceAthletes(selectedAthletes, round, discipline) {
  return async (dispatch, getState) => {
    dispatch(overAllAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const { id: EventUID } = getFromState(getState, 'event');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: '/performance-results/advance-athlete',
        method: 'POST',
        accessToken,
        body: {
          EventUID: EventUID,
          Athletes: selectedAthletes,
          GoNumber: round,
          DisciplineUID: discipline,
        },
      });

      await dispatch(
        createAlertSuccess({
          message: 'Athletes successfully advanced to finals',
          type: 'success',
        }),
      );

      dispatch(overAllAdvanceAthletesAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(overAllAsyncFail(error.message));
      return null;
    }
  };
}

export function overAllGetList(selectedDiscipline) {
  return async (dispatch, getState) => {
    dispatch(overAllAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const { id: EventUID } = getFromState(getState, 'event');
      const accessToken = getToken(authPayload);
      const response = await api({
        path: `/performance-results/go-round/overall?CompType=${selectedDiscipline}&EventUID=${EventUID}`,
        method: 'GET',
        accessToken,
      });

      dispatch(overAllAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(overAllAsyncFail(error.message));
      return null;
    }
  };
}
