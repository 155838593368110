export const EVENT_DIVISION_ID = {
  DIVISION_YOUTH: 177,
  DIVISION_YOUTH_2: 208,
  DIVISION_YOUTH_3: 209,
  DIVISION_YOUTH_4: 210,
  DIVISION_YOUTH_5: 211,
  DIVISION_1: 129,
  DIVISION_2: 130,
  DIVISION_3: 131,
  DIVISION_4: 132,
  DIVISION_5: 202,
  MULTIPLE: 215,
};

export const eventDivisions = {
  [EVENT_DIVISION_ID.DIVISION_1]: 'Division 1',
  [EVENT_DIVISION_ID.DIVISION_2]: 'Division 2',
  [EVENT_DIVISION_ID.DIVISION_3]: 'Division 3',
  [EVENT_DIVISION_ID.DIVISION_4]: 'Division 4',
  [EVENT_DIVISION_ID.DIVISION_5]: 'Division 5',
  [EVENT_DIVISION_ID.DIVISION_YOUTH]: 'Division Y1',
  [EVENT_DIVISION_ID.DIVISION_YOUTH_2]: 'Division Y2',
  [EVENT_DIVISION_ID.DIVISION_YOUTH_3]: 'Division Y3',
  [EVENT_DIVISION_ID.DIVISION_YOUTH_4]: 'Division Y4',
  [EVENT_DIVISION_ID.DIVISION_YOUTH_5]: 'Division Y5',
  [EVENT_DIVISION_ID.MULTIPLE]: 'Multiple',
};

export const youthDivisionIds = [
  EVENT_DIVISION_ID.DIVISION_YOUTH,
  EVENT_DIVISION_ID.DIVISION_YOUTH_2,
  EVENT_DIVISION_ID.DIVISION_YOUTH_3,
  EVENT_DIVISION_ID.DIVISION_YOUTH_4,
  EVENT_DIVISION_ID.DIVISION_YOUTH_5,
];

export const sortedEventDivisions = [
  {
    id: EVENT_DIVISION_ID.DIVISION_1,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_1],
  },
  {
    id: EVENT_DIVISION_ID.DIVISION_2,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_2],
  },
  {
    id: EVENT_DIVISION_ID.DIVISION_3,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_3],
  },
  {
    id: EVENT_DIVISION_ID.DIVISION_4,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_4],
  },
  {
    id: EVENT_DIVISION_ID.DIVISION_5,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_5],
  },
  {
    id: EVENT_DIVISION_ID.DIVISION_YOUTH,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_YOUTH],
  },
  {
    id: EVENT_DIVISION_ID.DIVISION_YOUTH_2,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_YOUTH_2],
  },
  {
    id: EVENT_DIVISION_ID.DIVISION_YOUTH_3,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_YOUTH_3],
  },
  {
    id: EVENT_DIVISION_ID.DIVISION_YOUTH_4,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_YOUTH_4],
  },
  {
    id: EVENT_DIVISION_ID.DIVISION_YOUTH_5,
    name: eventDivisions[EVENT_DIVISION_ID.DIVISION_YOUTH_5],
  },
];

export const sortedEventDivisionsWithSelectAtClassLevel = [
  ...sortedEventDivisions,
  {
    id: EVENT_DIVISION_ID.MULTIPLE,
    name: 'Select Division At Class Level',
  },
];

export const eventTypeClassId = 1;

export const EVENT_SUBMISSION_STATUS = {
  RESULT_LOADED: 195,
  AUDITED: 153,
  RESULT_PUBLIC: 152,
};

export const submissionsAfterResultLoaded = [
  EVENT_SUBMISSION_STATUS.RESULT_LOADED,
  EVENT_SUBMISSION_STATUS.AUDITED,
  EVENT_SUBMISSION_STATUS.RESULT_PUBLIC,
];

const submissionStatus = {
  150: 'Pending Event',
  151: 'Public Event',
  [EVENT_SUBMISSION_STATUS.RESULT_PUBLIC]: 'Results Public',
  [EVENT_SUBMISSION_STATUS.AUDITED]: 'Audited',
  158: 'Deleted Event',
  [EVENT_SUBMISSION_STATUS.RESULT_LOADED]: 'Results Loaded',
};

export const eventManagementType = {
  WCRA: 1,
  NON_WCRA: 0,
};

export const MAX_BUDDY_MEMBERS = 5;

export const DISCIPLINE_REMOVAL_TOOLTIP =
  'You cannot remove a discipline that has active nominations, entries or performances';

export default submissionStatus;

export const EVENT_REPORT_TYPES = {
  WEEKLY_ALLIANCE_EVENTS: 'WEEKLY_ALLIANCE_EVENTS',
  EVENTS_MATCHING_FILTER_CRITERIA: 'EVENTS_MATCHING_FILTER_CRITERIA',
};
export const EVENTS_REPORTS = [
  {
    title: 'WEEKLY ALLIANCE EVENTS',
    type: EVENT_REPORT_TYPES.WEEKLY_ALLIANCE_EVENTS,
  },
  {
    title: 'EVENTS MATCHING FILTER CRITERIA',
    type: 'EVENTS_MATCHING_FILTER_CRITERIA',
  },
];
