import gql from 'graphql-tag';

export const LOGIN_USER = gql`
  mutation loginUser($input: LoginInputType!) {
    loginUser(input: $input) {
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
      user {
        id
        TaxInfoUpdateDate
        LegalFirstName
        LegalLastName
        USCitizen
        Gender
        SSNLastFour
        photoFileName
        firstName
        lastName
        email
        lastLogin
        status
        socialTags
        createdAt
        updatedAt
        DOB
        phone
        disciplineId
        street
        street2
        city
        state
        zip
        country
        ERAUID
        nickname
        hometown
        careerEarnings
        worldChampionshipEarned
        PayeeNumber
        height
        weight
        facebook
        twitter
        instagram
        role
        associationId
        isAthlete
      }
      error {
        code
        message
      }
    }
  }
`;
