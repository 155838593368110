// @flow
import React from 'react';
import AlertCircle from 'react-feather/dist/icons/alert-circle';

import { Paragraph } from 'components/Typography';

type NotificationPropsType = {|
  errorMessage: string,
  style: Object,
|};

const Notification = (props: NotificationPropsType) => {
  const { errorMessage, style } = props;
  return !!errorMessage && errorMessage !== '' ? (
    <div style={style} className="notification-container">
      <div className="notification-icon column">
        <AlertCircle />
      </div>
      <div className="notification-content notification-column">
        <div className="notification-errorMessage">
          <Paragraph style={{ color: 'white' }}>{errorMessage}</Paragraph>
        </div>
      </div>
    </div>
  ) : null;
};

Notification.defaultProps = {
  errorMessage: '',
  style: {},
};

export default Notification;
