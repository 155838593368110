import gql from 'graphql-tag';
import { contestantFragments } from 'graphql/fragments/Contestant';
const {
  ContestantId,
  ContestantName,
  ContestantAddress,
  ContestantSocialAccounts,
} = contestantFragments;

export const LIST_ATHLETES = gql`
  query LIST_ATHLETES($input: ContestantsInput!) {
    athletesPayload(input: $input) {
      athletes {
        ...ContestantId
        ...ContestantName
        ...ContestantAddress
        ...ContestantSocialAccounts
        CType
        StatusType
        Penalty
        BioUID
        AwardUID
        HeritageUID
        HistoryUID
        InjuryCurrentUID
        InjuryHistoryUID
        QuoteUID
        PhotoUID
        Identifier
        ContractorUID
        Brand
        Hometown
        Website
        RookieYear
        Height
        Weight
        createdAt
        LastUpdate
        MemType
        PurchaseDate
        Disc
        CareerEarnings
        WorldChampionshipEarned
        PayeeNumber
        PhotoFilename
        email
        socialTags
        role
        status
        DOB
        Phone
        SSN
        USCitizen
        isParent
        isAthlete
        isYouth
        type
        linkedAccounts {
          ERAUID
          parentId
          FullName
          DOB
          Discipline
          Disc
        }
        disciplineAsString
        isUnregisteredAthlete
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
  ${ContestantId}
  ${ContestantName}
  ${ContestantAddress}
  ${ContestantSocialAccounts}
`;
