import PerformanceResult from 'models/PerformanceResult';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  PERFORMANCE_RESULT_ASYNC_START,
  PERFORMANCE_RESULT_ASYNC_STOP,
  PERFORMANCE_RESULT_ASYNC_FAIL,
  PERFORMANCE_RESULT_LIST_ASYNC_SUCCESS,
  CHANGE_JUDGE_SUCCESS,
  CLEAR_PERFORMANCE_RESULT_SUCCESS,
} from 'actions/performanceResult';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new PerformanceResult(),
  apiList: toEntityList([], PerformanceResult),
  list: toEntityList([], PerformanceResult),
  options: null,
});

const actionsMap = {
  [PERFORMANCE_RESULT_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [PERFORMANCE_RESULT_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [PERFORMANCE_RESULT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [PERFORMANCE_RESULT_LIST_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      apiList: toEntityList(action.apiData, PerformanceResult),
      list: toEntityList(action.singleJudgeData, PerformanceResult),
    });
  },
  [CHANGE_JUDGE_SUCCESS]: (state, action) => {
    const options = {
      selectedJudge: action.JudgeNumber,
    };
    return state.merge({
      options,
    });
  },
  [CLEAR_PERFORMANCE_RESULT_SUCCESS]: () => {
    return initialState;
  },
};

export default function performanceResult(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
