import Base from 'models/Base';
import {
  MERGE_EVENT_ASYNC_START,
  MERGE_EVENT_ASYNC_FAIL,
  MERGE_EVENT_ASYNC_SUCCESS,
  MERGE_EVENT_CLEAR,
  MERGE_EVENT_SET_BAD_EVENT_NOMINATIONS,
  MERGE_EVENT_SET_EVENT_INFO,
  MERGE_EVENT_SET_NEXT_STEPS,
  MERGE_EVENTS_SET_FORM_INFO,
  MERGE_EVENTS_SET_GOOD_PERFORMANCES,
  MERGE_EVENTS_NEXT_STEP,
  MERGE_EVENTS_PREVIOUS_STEP,
  MERGE_EVENT_SET_BAD_EVENT_ENTRIES,
  MERGE_EVENTS_SET_NOMINATIONS_DATA_TO_SEND,
  MERGE_EVENTS_SET_ENTRIES_DATA_TO_SEND,
  MERGE_EVENTS_SET_MISSING_DISCIPLINES,
  MERGE_EVENTS_CLEAN_MISSING_DISCIPLINES,
} from 'actions/mergeEvent';

import MergeEvent from 'models/MergeEvent';
import {
  FORM_MODAL_MERGE_EVENTS_ENTRIES,
  FORM_MODAL_MERGE_EVENTS_NOMINATIONS,
} from 'constants/mergeEvent';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new MergeEvent(),
});

const actionsMap = {
  [MERGE_EVENT_SET_EVENT_INFO]: (state, action) => {
    const { data: mergeEvent } = state;
    return state.merge({
      data: mergeEvent.set('eventsInfo', action.data),
    });
  },

  [MERGE_EVENT_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [MERGE_EVENT_SET_BAD_EVENT_NOMINATIONS]: (state, action) => {
    const { data: mergeEvent } = state;
    return state.merge({
      data: mergeEvent.set('badEventNominations', action.data),
    });
  },
  [MERGE_EVENT_SET_BAD_EVENT_ENTRIES]: (state, action) => {
    const { data: mergeEvent } = state;
    return state.merge({
      data: mergeEvent.set('badEventEntries', action.data),
    });
  },
  [MERGE_EVENT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },

  [MERGE_EVENT_ASYNC_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [MERGE_EVENT_CLEAR]: (state) => {
    const { data: mergeEvent } = state;
    return state.merge({
      data: mergeEvent
        .set('nextSteps', [])
        .set('previousSteps', [])
        .set('badEventNominations', [])
        .set('form', {})
        .set('goodPerformancesByDiscipline', [])
        .set('dataToSend', {})
        .set('badEventEntries', [])
        .set('missingDisciplines', []),
    });
  },
  [MERGE_EVENT_SET_NEXT_STEPS]: (state, action) => {
    const { data: mergeEvent } = state;
    return state.merge({
      data: mergeEvent.set('nextSteps', action.data),
    });
  },
  [MERGE_EVENTS_SET_GOOD_PERFORMANCES]: (state, action) => {
    const { data: mergeEvent } = state;
    return state.merge({
      data: mergeEvent.set('goodPerformancesByDiscipline', action.data),
    });
  },
  [MERGE_EVENTS_SET_FORM_INFO]: (state, action) => {
    const { data: mergeEvent } = state;
    const { id, values } = action.data;
    const formInfo = mergeEvent.get('form');
    formInfo[id] = values;
    return state.merge({
      data: mergeEvent.set('form', formInfo),
    });
  },
  [MERGE_EVENTS_NEXT_STEP]: (state) => {
    const { data: mergeEvent } = state;
    const nextSteps = mergeEvent.get('nextSteps');
    const previousSteps = mergeEvent.get('previousSteps');
    const firstStep = nextSteps[0];
    previousSteps.push(firstStep);
    return state.merge({
      data: mergeEvent
        .set('nextSteps', nextSteps.slice(1))
        .set('previousSteps', previousSteps),
    });
  },
  [MERGE_EVENTS_PREVIOUS_STEP]: (state) => {
    const { data: mergeEvent } = state;
    const nextSteps = mergeEvent.get('nextSteps');
    const previousSteps = mergeEvent.get('previousSteps');
    const lastStep = previousSteps[previousSteps.length - 1];
    nextSteps.unshift(lastStep);
    return state.merge({
      data: mergeEvent
        .set('nextSteps', nextSteps)
        .set('previousSteps', previousSteps.slice(0, -1)),
    });
  },
  [MERGE_EVENTS_SET_NOMINATIONS_DATA_TO_SEND]: (state) => {
    const { data: mergeEvent } = state;
    const formInfo = mergeEvent.get('form');
    const nominations = formInfo[FORM_MODAL_MERGE_EVENTS_NOMINATIONS];
    const dataToSend = {
      ...mergeEvent.get('dataToSend'),
      ...nominations,
    };
    return state.merge({
      data: mergeEvent.set('dataToSend', dataToSend),
    });
  },
  [MERGE_EVENTS_SET_ENTRIES_DATA_TO_SEND]: (state, action) => {
    const { data: mergeEvent } = state;
    const { data } = action;
    const formInfo = mergeEvent.get('form');
    const entries = data || formInfo[FORM_MODAL_MERGE_EVENTS_ENTRIES];
    const dataToSend = {
      ...mergeEvent.get('dataToSend'),
      ...entries,
    };
    return state.merge({
      data: mergeEvent.set('dataToSend', dataToSend),
    });
  },
  [MERGE_EVENTS_SET_MISSING_DISCIPLINES]: (state, action) => {
    const { data: mergeEvent } = state;
    const { data } = action;
    return state.merge({
      data: mergeEvent.set('missingDisciplines', data),
    });
  },
  [MERGE_EVENTS_CLEAN_MISSING_DISCIPLINES]: (state) => {
    const { data: mergeEvent } = state;
    return state.merge({
      data: mergeEvent.set('missingDisciplines', []),
    });
  },
};

export default function mergeEvent(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
