import Base from 'models/Base';
import Alert from 'models/Alert';

import { CREATE_ALERT_SUCCESS, CLEAR_ALERT_SUCCESS } from 'actions/alert';

const initialState = new Base({
  loading: false,
  errorMessage: null,
  data: new Alert(),
});

const actionsMap = {
  [CREATE_ALERT_SUCCESS]: (state, action) => {
    return state.merge({
      data: new Alert({
        message: action.alert.message,
        type: action.alert.type,
      }),
    });
  },
  [CLEAR_ALERT_SUCCESS]: () => {
    return initialState;
  },
};

export default function alert(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
