import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';

export function postToastMessage(message, type = 'success') {
  return async (dispatch) => {
    await dispatch(
      createAlertSuccess({
        message,
        type,
      }),
    );
    await dispatch(clearAlertSuccessAsync());
  };
}
