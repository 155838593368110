import Log from 'models/Log';
import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';

import {
  EVENT_LOG_BY_PAGINATION_ASYNC_START,
  EVENT_LOG_BY_PAGINATION_ASYNC_SUCCESS,
  EVENT_LOG_BY_PAGINATION_ASYNC_FAIL,
  EVENT_LOG_UPDATE_OPTIONS,
} from 'actions/log';

export const initialLogOptions = {
  sortDirection: 'asc',
  orderBy: 'createdAt',
  pageNumber: 0,
  resultsPerPage: 25,
};

const initialState = new Record({
  loading: false,
  errorMessage: '',
  data: new Log(),
  list: toEntityList([], Log),
  options: initialLogOptions,
  totalCount: 0,
})();

const actionsMap = {
  [EVENT_LOG_BY_PAGINATION_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [EVENT_LOG_BY_PAGINATION_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [EVENT_LOG_BY_PAGINATION_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.eventLogsPayload) return state;

    const { logs, meta } = action.data.eventLogsPayload;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(logs, Log),
      totalCount: meta.totalCount || 0,
    });
  },
  [EVENT_LOG_UPDATE_OPTIONS]: (state, action) => {
    const { sortDirection, orderBy, pageNumber, resultsPerPage } = action.data;

    return state.merge({
      options: Object.assign({}, state.options, {
        sortDirection,
        orderBy,
        pageNumber,
        resultsPerPage,
      }),
    });
  },
};

export default function log(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
