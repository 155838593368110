import Association from 'models/Association';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  ASSOCIATION_ASYNC_START,
  ASSOCIATION_ASYNC_FAIL,
  ASSOCIATION_LIST_ASYNC_SUCCESS,
  ASSOCIATION_CREATE_ASYNC_SUCCESS,
  ASSOCIATION_GET_ASYNC_SUCCESS,
  ASSOCIATION_UPDATE_ASYNC_SUCCESS,
} from 'actions/association';
import { hasFeatureFlag } from '../utils/flagsmith';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Association(),
  totalCount: 0,
  list: toEntityList([], Association),
});

const actionsMap = {
  [ASSOCIATION_LIST_ASYNC_SUCCESS]: (state, action) => {
    const associationsV2 = hasFeatureFlag('associations-v2');
    return state.merge({
      loading: false,
      errorMessage: null,
      list: !associationsV2
        ? toEntityList(action.data, Association)
        : toEntityList(action.data.associations, Association),
      totalCount: !associationsV2 ? 0 : action.data.total,
    });
  },
  [ASSOCIATION_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [ASSOCIATION_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [ASSOCIATION_CREATE_ASYNC_SUCCESS]: (state, action) => {
    const createdAssociation = action.data;
    const updatedList = state.get('list').push({
      ...createdAssociation,
      Name: `${createdAssociation.Name}${
        createdAssociation.ShortName ? `(${createdAssociation.ShortName})` : ''
      }`,
    });
    return state.merge({
      data: new Association(createdAssociation),
      list: updatedList,
    });
  },
  [ASSOCIATION_GET_ASYNC_SUCCESS]: (state, action) => {
    const createdAssociation = action.data;
    return state.merge({
      loading: false,
      data: new Association(createdAssociation),
    });
  },
  [ASSOCIATION_UPDATE_ASYNC_SUCCESS]: (state, action) => {
    const updatedAssociation = action.data;
    return state.merge({
      loading: false,
      data: new Association(updatedAssociation),
    });
  },
};

export default function association(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
