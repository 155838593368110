import gql from 'graphql-tag';

export const GET_AVAILABLE_STOCKDRAW_TEAMS = gql`
  query GET_TEAMS($performanceUID: ID!, $disciplineId: ID!) {
    stockDrawAvailableTeamsGet(
      performanceUID: $performanceUID
      disciplineId: $disciplineId
    ) {
      HeaderID
      HeelerID
      HeaderEPUID
      HeelerEPUID
      primaryTeammateName
      secondaryTeammateName
      PerformanceUID
      Position
      HeaderPhone
      HeaderEmail
      HeelerPhone
      HeelerEmail
      entryCountAsLetter
      partnerEntryCountAsLetter
    }
  }
`;
