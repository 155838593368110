import api from 'api';
import { getFromState, getToken, logError } from 'helpers';
import { getFormattedDateUTC } from 'helpers/getFormattedDate';
import moment from 'moment-timezone';

export const SEGMENT_ASYNC_START = 'SEGMENT_ASYNC_START';
export const SEGMENT_ACTION_CLEAR = 'SEGMENT_ACTION_CLEAR';
export const SEGMENT_ASYNC_FAIL = 'SEGMENT_ASYNC_FAIL';
export const SEGMENT_ASYNC_SUCCESS = 'SEGMENT_ASYNC_SUCCESS';
export const SEGMENT_LIST_ASYNC_SUCCESS = 'SEGMENT_LIST_ASYNC_SUCCESS';

export function segmentAsyncStart() {
  return {
    type: SEGMENT_ASYNC_START,
  };
}

export function segmentActionClear() {
  return {
    type: SEGMENT_ACTION_CLEAR,
  };
}

function segmentAsyncFail(errorMessage) {
  return {
    type: SEGMENT_ASYNC_FAIL,
    errorMessage,
  };
}

export function segmentAsyncSuccess(data) {
  return {
    type: SEGMENT_ASYNC_SUCCESS,
    data,
  };
}

export function segmentListAsyncSuccess(data) {
  return {
    type: SEGMENT_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function getCurrentActiveSegments() {
  return async (dispatch, getState) => {
    dispatch(segmentAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'segments?showExpiredSegments=false&active=false',
        method: 'GET',
        accessToken,
      });

      dispatch(segmentListAsyncSuccess(response));

      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch segments';
      dispatch(segmentAsyncFail(err));
      return null;
    }
  };
}

export function getAllSegments() {
  return async (dispatch, getState) => {
    dispatch(segmentAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'segments?showExpiredSegments=true&active=false',
        method: 'GET',
        accessToken,
      });

      dispatch(segmentListAsyncSuccess(response));

      return response.map((segment) => {
        const currentDate = getFormattedDateUTC(moment(Date.now()));
        const segmentEndDate = segment.EndDate
          ? getFormattedDateUTC(segment.EndDate)
          : '';

        if (new Date(segmentEndDate) < new Date(currentDate)) {
          segment.Name = 'EXPIRED ' + segment.Name;
        }
        return segment;
      });
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch segments';
      dispatch(segmentAsyncFail(err));
      return null;
    }
  };
}

export function getSegmentsOnContestantDiscipline() {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    dispatch(segmentAsyncStart());
    try {
      const path = `segments/on-contestant-discipline`;

      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(segmentListAsyncSuccess(response));

      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch segments';
      dispatch(segmentAsyncFail(err));
      return null;
    }
  };
}
