// @flow
import React from 'react';
import Notification from 'components/Notification';

type ErrorMessagePropsType = {|
  errorMessage: ?string,
  scrollToTop: boolean,
|};

const ErrorMessage = (props: ErrorMessagePropsType) => {
  const { errorMessage, scrollToTop } = props;
  if (errorMessage) {
    if (scrollToTop) window.scrollTo(0, 0);
    return <Notification errorMessage={errorMessage} />;
  }

  return null;
};

export default ErrorMessage;
