import Contestant from 'models/Contestant';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { List } from 'immutable';
import { setLocalStorage, getLocalStorage } from 'helpers/localStorage';

import {
  CONTESTANT_ASYNC_START,
  CONTESTANT_ASYNC_STOP,
  CONTESTANT_ACTION_CLEAR,
  CONTESTANT_ASYNC_FAIL,
  CONTESTANT_ASYNC_SUCCESS,
  CONTESTANT_LIST_ASYNC_SUCCESS,
  CONTESTANT_WITHOUT_PROFILE_LIST_ASYNC_SUCCESS,
  CONTESTANT_ASYNC_CLEAR_ERROR,
  UPDATE_CONTESTANT_PROFILE_FAIL,
  UPDATE_CONTESTANT_PROFILE_SUCCESS,
  UPDATE_CONTESTANT_PROFILE_START,
  CONTESTANT_APPLY_LIST_OPTIONS,
  CONTESTANT_CREATE_ASYNC_SUCCESS,
  CONTESTANT_UPDATE_OPTIONS,
  UPDATE_ATHLETE_NOMINATIONS_OPTIONS,
} from 'actions/contestant';

const tableOptions = getLocalStorage('TableOptions', 'contestants') || {};

export const initialContestantOptions = {
  order: 'desc',
  orderBy: 'ERAUID',
  orderIsDate: false,
  selected: new List(),
  page: 0,
  rowsPerPage: tableOptions.rowsPerPage || 50,
};

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Contestant(),
  list: toEntityList([], Contestant),
  options: initialContestantOptions,
});

const actionsMap = {
  [CONTESTANT_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [CONTESTANT_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [CONTESTANT_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [CONTESTANT_ASYNC_SUCCESS]: (state, action) => {
    const contestant = action.data;

    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Contestant(contestant),
    });
  },
  [CONTESTANT_CREATE_ASYNC_SUCCESS]: (state, action) => {
    const { contestant } = action;
    const list = state.get('list');
    return state.merge({
      loading: false,
      errorMessage: '',
      list: list.push(new Contestant(contestant)),
    });
  },
  [CONTESTANT_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.athletesPayload) return state;

    const { athletes, meta } = action.data.athletesPayload;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(athletes, Contestant),
      totalCount: meta.totalCount || 0,
    });
  },
  [CONTESTANT_UPDATE_OPTIONS]: (state, action) => {
    const { order, orderBy, page, rowsPerPage } = action.data;

    setLocalStorage('TableOptions', 'contestants', { rowsPerPage });

    return state.merge({
      options: Object.assign({}, state.options, {
        order,
        orderBy,
        page,
        rowsPerPage,
      }),
    });
  },
  [UPDATE_ATHLETE_NOMINATIONS_OPTIONS]: (state, action) => {
    const { order, orderBy, page, rowsPerPage } = action.data;

    setLocalStorage('TableOptions', 'athleteNominations', { rowsPerPage });

    return state.merge({
      options: Object.assign({}, state.options, {
        order,
        orderBy,
        page,
        rowsPerPage,
      }),
    });
  },

  [CONTESTANT_WITHOUT_PROFILE_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.contestantsWithoutProfilePayload)
      return state;

    const { contestants, meta } = action.data.contestantsWithoutProfilePayload;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(contestants, Contestant),
      totalCount: meta.totalCount || 0,
    });
  },
  [CONTESTANT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [CONTESTANT_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [UPDATE_CONTESTANT_PROFILE_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [UPDATE_CONTESTANT_PROFILE_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [UPDATE_CONTESTANT_PROFILE_SUCCESS]: (state, action) => {
    let contestants = state.get('list');

    const position = contestants.findIndex((contestantItem) => {
      return contestantItem.get('ERAUID') === action.data.ERAUID;
    });

    if (position) {
      contestants = contestants.set(position, new Contestant(action.data));
    }

    return state.merge({
      loading: false,
      errorMessage: '',
      data: new Contestant(),
      list: contestants,
    });
  },
  [CONTESTANT_APPLY_LIST_OPTIONS]: (state, action) => {
    const newOptions = action.data;
    const baseOptions =
      state.get && state.get('options') ? state.get('options').toJS() : {};
    const options = Object.assign({}, baseOptions, newOptions);
    return state.merge({
      options,
    });
  },
};

export default function contestant(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
