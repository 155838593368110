import Purse from 'models/Purse';
import Base from 'models/Base';
import CustomFee from 'models/CustomFee';

import {
  PURSE_ASYNC_START,
  PURSE_ASYNC_FAIL,
  PURSE_ASYNC_SUCCESS,
  PURSE_ADD_CUSTOM_FEE,
  PURSE_CHANGE_CUSTOM_FEES,
  PURSE_REMOVE_CUSTOM_FEE,
} from 'actions/purse';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Purse(),
});

const actionsMap = {
  [PURSE_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [PURSE_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [PURSE_ASYNC_SUCCESS]: (state, action) => {
    const purse = action.data;
    return state.merge({
      loading: false,
      errorMessage: '',
      data: new Purse(purse),
    });
  },
  [PURSE_ADD_CUSTOM_FEE]: (state, action) => {
    const { feeName } = action;
    const customFees = state.data.get('customFees');

    return state.merge({
      loading: false,
      errorMessage: '',
      data: purse.set(
        'customFees',
        customFees.push(
          new CustomFee({
            id: `Custom_${customFees.size + 1}`,
            value: null,
            name: feeName,
          }),
        ),
      ),
    });
  },
  [PURSE_CHANGE_CUSTOM_FEES]: (state, action) => {
    const { customFees: formCustomFees } = action;
    const customFees = state.data.get('customFees');

    return state.merge({
      loading: false,
      errorMessage: '',
      data: purse.set(
        'customFees',
        customFees.map((customFee) => {
          return customFee.set('value', formCustomFees[customFee.get('id')]);
        }),
      ),
    });
  },
  [PURSE_REMOVE_CUSTOM_FEE]: (state, action) => {
    const { customFeeId } = action;
    const { data: purse } = state;
    const customFees = purse.get('customFees');

    return state.merge({
      loading: false,
      errorMessage: '',
      data: purse.set(
        'customFees',
        customFees.filter((customFee) => {
          return customFee.get('id') !== customFeeId;
        }),
      ),
    });
  },
};

export default function purse(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
