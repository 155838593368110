import React from 'react';
import { MALE, FEMALE, PREFER_NOT_TO_SAY } from '../../constants/gender';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@material-ui/core';

function Gender(props) {
  const marginButtons = {
    marginTop: '16px',
  };

  const styleError = {
    color: '#f44336',
    fontSize: '0.75rem',
  };

  const handleClick = (event) => {
    let _gender = event.target.value;

    setGender(_gender);
    props.changeGender(_gender);
  };

  const [gender, setGender] = React.useState(props.gender);

  const style = {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: `${gender ? 'rgba(0, 0, 0, 0.54)' : '#f44336'}`,
    padding: 0,
    fontSize: '1rem',
    fontFamily: 'Barlow,sans-serif',
    lineHeight: 1,
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    marginTop: '8px',
  };

  return (
    <FormControl component="fieldset" required>
      <FormLabel component="legend" style={style}>
        Gender
      </FormLabel>
      <RadioGroup
        aria-label="gender"
        name="gender"
        style={marginButtons}
        onClick={handleClick}
        value={gender}
      >
        <FormControlLabel
          value={MALE}
          control={<Radio />}
          label="Male"
          disabled={props.disabled}
        />
        <FormControlLabel
          value={FEMALE}
          control={<Radio />}
          label="Female"
          disabled={props.disabled}
        />
        <FormControlLabel
          value={PREFER_NOT_TO_SAY}
          control={<Radio />}
          label="Prefer Not To Say"
          disabled={props.disabled}
        />
      </RadioGroup>
      {!gender && (
        <FormHelperText style={styleError}>
          This field is required
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default Gender;
