import Base from 'models/Base';
import AdminSetting from 'models/AdminSetting';
import { toEntityList } from 'models/BaseList';

import {
  LIST_ADMIN_SETTINGS_SUCCESS,
  LIST_ADMIN_SETTINGS_FAIL,
  DISABLE_ENABLE_NOMINATIONS_FAIL,
  SELECT_ADMIN_SETTING_SUCCESS,
} from 'actions/adminSetting';

const initialState = new Base({
  loading: false,
  errorMessage: null,
  data: new AdminSetting(),
  list: toEntityList([], AdminSetting),
});

const actionsMap = {
  [LIST_ADMIN_SETTINGS_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(action.data, AdminSetting),
    });
  },
  [LIST_ADMIN_SETTINGS_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [DISABLE_ENABLE_NOMINATIONS_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [SELECT_ADMIN_SETTING_SUCCESS]: (state, action) => {
    const setting = state.get('list').find((settingItem) => {
      return settingItem.get('Property') === action.data;
    });
    return state.merge({
      loading: false,
      errorMessage: null,
      data: setting || new AdminSetting(),
    });
  },
};

export default function adminSetting(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
