import StockDraw from 'models/StockDraw';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  PERFORMANCE_ORDER_ASYNC_START,
  PERFORMANCE_ORDER_ASYNC_STOP,
  PERFORMANCE_ORDER_ASYNC_FAIL,
  PERFORMANCE_ORDER_LIST_ASYNC_SUCCESS,
  MOVE_PERFORMANCE_ORDER_SUCCESS,
  MOVE_PERFORMANCE_ORDER_FAIL,
  ADD_UPDATE_PERFORMANCE_ORDER_SUCCESS,
  ADD_UPDATE_PERFORMANCE_ORDER_FAIL,
  ADD_UPDATE_TEAM_PERFORMANCE_ORDER_SUCCESS,
} from 'actions/stockDraw';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new StockDraw(),
  list: toEntityList([], StockDraw),
});

const actionsMap = {
  [PERFORMANCE_ORDER_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [PERFORMANCE_ORDER_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [PERFORMANCE_ORDER_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [MOVE_PERFORMANCE_ORDER_SUCCESS]: (state, action) => {
    let adjustingPosition = null;

    if (action.direction === 'up') {
      adjustingPosition = action.position - 1 < 1 ? 1 : action.position - 1;
    } else {
      adjustingPosition =
        action.position + 1 > action.limit ? action.limit : action.position + 1;
    }

    const foundStockDrawIndex = state.get('list').findIndex((stockDrawItem) => {
      return stockDrawItem.get('Position') === action.position;
    });

    const stockDrawToAdjustIndex = state
      .get('list')
      .findIndex((stockDrawItem) => {
        return stockDrawItem.get('Position') === adjustingPosition;
      });

    let updatedStockDrawList = state.get('list');
    if (foundStockDrawIndex >= 0) {
      updatedStockDrawList = state
        .get('list')
        .setIn([foundStockDrawIndex, 'Position'], adjustingPosition);

      if (stockDrawToAdjustIndex >= 0) {
        updatedStockDrawList = updatedStockDrawList.setIn(
          [stockDrawToAdjustIndex, 'Position'],
          action.position,
        );
      }
    } else if (stockDrawToAdjustIndex >= 0) {
      updatedStockDrawList = state
        .get('list')
        .setIn([stockDrawToAdjustIndex, 'Position'], action.position);
    }

    return state.merge({
      loading: false,
      errorMessage: '',
      list: updatedStockDrawList,
    });
  },
  [MOVE_PERFORMANCE_ORDER_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [ADD_UPDATE_PERFORMANCE_ORDER_SUCCESS]: (state, action) => {
    let updatedStockDrawList = state.get('list');
    const foundStockDrawIndex = state.get('list').findIndex((stockDrawItem) => {
      return stockDrawItem.get('Position') === action.position;
    });

    if (foundStockDrawIndex >= 0) {
      updatedStockDrawList =
        action.fieldType === 'animal'
          ? state
              .get('list')
              .setIn([foundStockDrawIndex, 'AnimalUID'], action.value.animal)
          : state
              .get('list')
              .setIn([foundStockDrawIndex, 'EPUID'], action.value.EPUID);
    } else {
      const newStockDraw = new StockDraw({
        StockDrawUID: null,
        EPUID: action.fieldType === 'athlete' ? action.value.EPUID : null,
        ERAUID: action.fieldType === 'athlete' ? action.value.ERAUID : null,
        AnimalUID: action.fieldType === 'animal' ? action.value.animal : null,
        Position: action.position,
      });
      updatedStockDrawList = state.get('list').push(newStockDraw);
    }

    return state.merge({
      loading: false,
      errorMessage: '',
      list: updatedStockDrawList,
    });
  },
  [ADD_UPDATE_PERFORMANCE_ORDER_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [ADD_UPDATE_TEAM_PERFORMANCE_ORDER_SUCCESS]: (state, action) => {
    let updatedStockDrawList = state.get('list');
    const foundStockDrawIndex = state.get('list').findIndex((stockDrawItem) => {
      return stockDrawItem.get('Position') === action.position;
    });
    if (foundStockDrawIndex >= 0) {
      updatedStockDrawList =
        action.fieldType === 'animal'
          ? state
              .get('list')
              .setIn([foundStockDrawIndex, 'AnimalUID'], action.value)
          : state
              .get('list')
              .setIn([foundStockDrawIndex, 'EPUID'], action.value.headerEPUID)
              .setIn(
                [foundStockDrawIndex, 'PartnerEPUID'],
                action.value.heelerEPUID,
              )
              .setIn([foundStockDrawIndex, 'ERAUID'], action.value.headerERAUID)
              .setIn(
                [foundStockDrawIndex, 'PartnerERAUID'],
                action.value.heelerERAUID,
              );
    } else {
      const newStockDraw = new StockDraw({
        StockDrawUID: null,
        EPUID: action.fieldType === 'athlete' ? action.value.headerEPUID : null,
        PartnerEPUID:
          action.fieldType === 'athlete' ? action.value.heelerEPUID : null,
        ERAUID:
          action.fieldType === 'athlete' ? action.value.headerERAUID : null,
        PartnerERAUID:
          action.fieldType === 'athlete' ? action.value.heelerERAUID : null,
        AnimalUID: action.fieldType === 'animal' ? action.value : null,
        Position: action.position,
      });
      updatedStockDrawList = state.get('list').push(newStockDraw);
    }
    return state.merge({
      loading: false,
      errorMessage: '',
      list: updatedStockDrawList,
    });
  },
  [PERFORMANCE_ORDER_LIST_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: Array.isArray(action.data)
        ? toEntityList(action.data, StockDraw)
        : action.data,
    });
  },
};

export default function stockDraw(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
