import api from 'api';

import { getFromState, getToken, logError } from 'helpers';

export const REPORTS_ASYNC_START = 'REPORTS_ASYNC_START';
export const REPORTS_ASYNC_SUCCESS = 'REPORTS_ASYNC_SUCCESS';
export const REPORTS_ASYNC_FAIL = 'REPORTS_ASYNC_FAIL';

export function reportsAsyncStart() {
  return {
    type: REPORTS_ASYNC_START,
  };
}

export function reportsAsyncSuccess(data) {
  return {
    type: REPORTS_ASYNC_SUCCESS,
    data,
  };
}

function reportsAsyncFail(errorMessage) {
  return {
    type: REPORTS_ASYNC_FAIL,
    errorMessage,
  };
}

export function getReport(reportInfo, id) {
  return async (dispatch, getState) => {
    dispatch(reportsAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `${reportInfo.path}/${id}`,
        method: 'GET',
        accessToken,
      });
      dispatch(reportsAsyncSuccess());
      return response;
    } catch (error) {
      logError(error);
      dispatch(reportsAsyncFail(error.message));
      return null;
    }
  };
}
