import { Record } from 'immutable';

const defaultValues = {
  GoNumber: null,
  Points: null,
  Score: null,
};

export default class GoRoundOverall extends Record(
  defaultValues,
  'GoRoundOverall',
) {
  constructor(values) {
    super({
      ...values,
    });
  }
}
