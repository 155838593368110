// @flow

export const getEmptyRows = (
  contestants: Object,
  pageNumber: number,
  resultsPerPage: number,
) => {
  if (contestants.length - pageNumber * resultsPerPage > 0) {
    const emptyRows =
      resultsPerPage -
      Math.min(
        resultsPerPage,
        contestants.length - pageNumber * resultsPerPage,
      );

    return emptyRows;
  }

  const emptyRows = resultsPerPage - contestants.length;
  return emptyRows;
};
