// @flow
const HEIGHT_TEXT_CELL = 16;

type SplitAthletes = {|
  index: number,
  athletes: Array<*>,
  isTeamDiscipline: boolean,
|};

type SplitAthletesConfig = {|
  itemsFirstPage: number,
  addEmptyArray: boolean,
  teamsFirstPage: number,
|};

type MaxRecordsPerPageConfig = {|
  maxTeamsPerPage: number,
  maxAthletesPerPage: number,
  additionalFields: number,
  itemsFirstPage: number,
  teamsFirstPage: number,
|};

export const cellHeight = (items: Array<any>) => {
  const itemsPerCell = items.length === 1 ? 2 : items.length + 1;
  return `${itemsPerCell * HEIGHT_TEXT_CELL}px`;
};

export const splitText = (str: string, size: number) => {
  if (str) {
    const chuckText = str.match(new RegExp('.{1,' + size + '}', 'g'));
    return chuckText && chuckText.join('\n');
  }
  return str;
};

export const splitArrayChunks = (
  array: Array<any>,
  perChunk: number,
): Array<*> =>
  array.reduce((all, one, i) => {
    const ch = Math.floor(i / perChunk);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  }, []);

export const openPdf = (blob: Blob, name: string) => {
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = `${name}.pdf`;
  link.click();
};

export const getSplitAthletes = (
  info: SplitAthletes,
  maxRecords: number,
  config: SplitAthletesConfig,
): Array<*> => {
  const { athletes, isTeamDiscipline, index } = info;
  const { addEmptyArray, itemsFirstPage, teamsFirstPage } = config;
  if (index === 0) {
    const recordsFirstPage = isTeamDiscipline ? teamsFirstPage : itemsFirstPage;
    const firstPage = athletes.slice(0, recordsFirstPage);
    const restAthletes = athletes.slice(recordsFirstPage);
    const restPages = splitArrayChunks(restAthletes, maxRecords);
    if (restPages.length === 0 && addEmptyArray) {
      const pages = [];
      pages.push(firstPage);
      pages.push([]);
      return pages;
    }
    return [firstPage, ...restPages];
  }
  return splitArrayChunks(athletes, maxRecords);
};

export const getMaxRecordsPerPage = (
  info: SplitAthletes,
  config: MaxRecordsPerPageConfig,
) => {
  const { athletes, isTeamDiscipline, index } = info;
  const {
    additionalFields,
    maxAthletesPerPage,
    maxTeamsPerPage,
    itemsFirstPage,
    teamsFirstPage,
  } = config;
  const tableLength = athletes.length + additionalFields;
  const maxAthletes = isTeamDiscipline ? maxTeamsPerPage : maxAthletesPerPage;
  let hasMaxAthletesPerPage = tableLength >= maxAthletes;
  if (index === 0) {
    const recordsFirstPage = isTeamDiscipline ? teamsFirstPage : itemsFirstPage;
    hasMaxAthletesPerPage = tableLength >= recordsFirstPage;
  }
  const maxRecords = maxAthletes - additionalFields;
  return { hasMaxAthletesPerPage, maxRecords };
};
