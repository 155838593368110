import { timedDisciplines } from 'constants/disciplines';

export function isTimed(disciplineId) {
  return timedDisciplines.includes(disciplineId);
}

export function isValidGoNumber(discipline, goNumber) {
  const { goNumbers } = discipline;
  if (!goNumber) {
    return goNumbers.length === 0 ? false : goNumbers[0];
  }

  if (!isNaN(goNumber) && goNumbers.includes(parseInt(goNumber, 10))) {
    return parseInt(goNumber, 10);
  }

  return goNumber === 'overall' && goNumber;
}
