import gql from 'graphql-tag';

export const GET_TEAM_STOCK_DRAW_LIST = gql`
  query GET_TEAM_STOCK_DRAW($performanceUID: ID!, $compType: Int!) {
    stockDrawListGet(
      input: { PerformanceUID: $performanceUID, CompType: $compType }
    ) {
      StockDrawUID
      PerformanceUID
      CompType
      EventUID
      NumberOfAnimals
      NumberOfAthletes
      NumberOfJudges
      PartnerERAUID
      PartnerEPUID
      EPUID
      Position
      AnimalUID
      AnimalName
      AnimalBrand
      AnimalIdentifier
      ERAUID
      ContestantName
      TeammateName
      createdAt
      entryCountAsLetter
    }
  }
`;
