import gql from 'graphql-tag';

export const CREATE_UPDATE_TEAM_STOCK_DRAW = gql`
  mutation CREATE_UPDATE_TEAM_STOCK_DRAW(
    $input: CreateUpdateTeamStockDrawInput!
  ) {
    createUpdateTeamStockDraw(input: $input) {
      StockDrawUID
      PerformanceUID
      CompType
      EventUID
      NumberOfAnimals
      NumberOfAthletes
      NumberOfJudges
      PartnerERAUID
      PartnerEPUID
      EPUID
      Position
      AnimalUID
      AnimalName
      AnimalBrand
      AnimalIdentifier
      ERAUID
      ContestantName
      TeammateName
      createdAt
      updatedAt
    }
  }
`;
