export const STRIPE_SET_STATUS = 'STRIPE_SET_STATUS';
export const STRIPE_SET_TOKEN = 'STRIPE_SET_TOKEN';
export const STRIPE_RESET = 'STRIPE_RESET';

export function stripeSetStatus(creditCardStatus) {
  return {
    type: STRIPE_SET_STATUS,
    creditCardStatus,
  };
}

export function stripeSetToken(token) {
  return {
    type: STRIPE_SET_TOKEN,
    token,
  };
}

export function stripeReset() {
  return {
    type: STRIPE_RESET,
  };
}
