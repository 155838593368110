// @flow
import { Record } from 'immutable';

export type EntryTransactionEntryType = {
  EPUID: string,
  ERAUID: string,
  disciplineId: number,
  entryFee: number,
  status: string,
  teammateERAUID: string,
  isPaid: boolean,
};

const defaultValues = {
  EPUID: null,
  ERAUID: null,
  disciplineId: null,
  entryFee: null,
  status: null,
  teammateERAUID: null,
  isPaid: null,
};

export default class EntryTransactionEntry extends Record(
  defaultValues,
  'EntryTransaction',
) {
  constructor(values: EntryTransactionEntryType) {
    super({
      ...values,
    });
  }
}
