import EventAnimal from 'models/EventAnimal';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  EVENT_ANIMAL_ACTION_SUCCESS,
  EVENT_ANIMAL_ACTION_CLEAR,
  EVENT_ANIMAL_ASYNC_START,
  EVENT_ANIMAL_ASYNC_STOP,
  EVENT_ANIMAL_ASYNC_FAIL,
  EVENT_ANIMAL_ASYNC_SUCCESS,
  EVENT_ANIMAL_LIST_ASYNC_SUCCESS,
  ADD_PERFORMANCE_TO_ANIMAL_SUCCESS,
  ADD_PERFORMANCE_TO_ANIMAL_FAIL,
  REMOVE_PERFORMANCE_FROM_ANIMAL_SUCCESS,
} from 'actions/eventAnimal';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new EventAnimal(),
  list: toEntityList([], EventAnimal),
});

const actionsMap = {
  [EVENT_ANIMAL_ACTION_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      data: new EventAnimal(action.data),
    });
  },
  [EVENT_ANIMAL_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [EVENT_ANIMAL_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [EVENT_ANIMAL_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [EVENT_ANIMAL_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new EventAnimal(action.data),
    });
  },
  [EVENT_ANIMAL_LIST_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(action.data, EventAnimal),
    });
  },
  [EVENT_ANIMAL_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [ADD_PERFORMANCE_TO_ANIMAL_SUCCESS]: (state, action) => {
    const animal = new EventAnimal(action.animal);
    const performances = animal.get('performances');
    const updatedPerformances = [action.performance].concat(performances);

    const index = state.list.findIndex((eventAnimalItem) => {
      return eventAnimalItem.get('EAUID') === animal.get('EAUID');
    });

    const updatedAnimal = state.list
      .get(index)
      .set('performances', updatedPerformances);

    const updatedList = state.list.set(index, updatedAnimal);

    return state.merge({
      list: updatedList,
    });
  },
  [ADD_PERFORMANCE_TO_ANIMAL_FAIL]: (state, action) => {
    return state.merge({
      errorMessage: action.error,
    });
  },
  [REMOVE_PERFORMANCE_FROM_ANIMAL_SUCCESS]: (state, action) => {
    const animal = new EventAnimal(action.animal);
    const performances = animal.get('performances');
    const updatedPerformances = performances.filter(
      (performance) =>
        performance.PerformanceUID !== action.performance.PerformanceUID,
    );

    const index = state.list.findIndex((eventAnimalItem) => {
      return eventAnimalItem.get('EAUID') === animal.get('EAUID');
    });

    const updatedAnimal = state.list
      .get(index)
      .set('performances', updatedPerformances);

    const updatedList = state.list.set(index, updatedAnimal);

    return state.merge({
      list: updatedList,
    });
  },
};

export default function eventAnimal(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
