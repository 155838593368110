import Coupon from 'models/Coupon';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  COUPON_ASYNC_START,
  COUPON_ACTION_CLEAR,
  COUPON_ASYNC_FAIL,
  COUPON_ASYNC_SUCCESS,
  COUPON_LIST_ASYNC_SUCCESS,
  COUPON_ASYNC_CLEAR_ERROR,
} from 'actions/coupon';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Coupon(),
  list: toEntityList([], Coupon),
});

const actionsMap = {
  [COUPON_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      data: new Coupon(action.data),
    });
  },
  [COUPON_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [COUPON_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [COUPON_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Coupon(action.data),
    });
  },
  [COUPON_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.couponsPayload) return state;

    const { coupons, meta } = action.data.couponsPayload;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(coupons, Coupon),
      totalCount: meta.totalCount || 0,
    });
  },
  [COUPON_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [COUPON_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
};

export default function coupon(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
