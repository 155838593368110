import { Record } from 'immutable';

const defaultValues = {
  TimeZoneUID: null,
  Name: null,
  OffsetInMinutes: null,
};

export default class TimeZone extends Record(defaultValues, 'TimeZone') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
