/**
 * @callback identifier - The function that will help us determine our groupings
 * @returns {string}
 */

/**
 * @function groupTogether
 * @param {Object[]} collection - an array of objects that we want to group together
 * @param {identifier} identifier - our identifier function to group items together
 * @returns {Array.<Object[]>} - an array of arrays grouped together by the identifier
 * @example
 * const performanceResults = [
 *  { AnimalUID: 1, ERAUID: 'la', name: 'Dan' },
 *  { AnimalUID: 1, ERAUID: 'la', name: 'Sky' }
 * ]
 * const identifier = item => `${ item.AnimalUID }-${ item.ERAUID }`
 * groupTogether(performanceResults, identifier)
 */

export const groupTogether = (collection, identifier) => {
  const groups = collection.reduce((prev, curr) => {
    if (!prev[identifier(curr)]) {
      // eslint-disable-next-line
      prev[identifier(curr)] = [curr];
    } else {
      prev[identifier(curr)].push(curr);
    }
    return prev;
  }, {});
  return Object.keys(groups).map((group) => groups[group]);
};
