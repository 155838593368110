// @flow
import React, { Component } from 'react';

type AsyncComponentProps = {||};
type AsyncComponentState = {|
  component: ?Object,
|};

export default function AsyncComponentHOC(importComponent: Object) {
  class AsyncComponent extends Component<
    AsyncComponentProps,
    AsyncComponentState,
  > {
    state = {
      component: null,
    };
    // eslint-disable-next-line
    async componentWillMount() {
      const { default: component } = await importComponent();
      this.setState({
        component,
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
