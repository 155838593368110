import api from 'api';
import { getFromState, getToken, logError } from 'helpers';
import { hasFeatureFlag } from '../utils/flagsmith';
import { filterToRequest, getFilters } from '../helpers';
import filterNames from '../constants/filters';

export const ASSOCIATION_ASYNC_START = 'ASSOCIATION_ASYNC_START';
export const ASSOCIATION_ASYNC_FAIL = 'ASSOCIATION_ASYNC_FAIL';
export const ASSOCIATION_CREATE_ASYNC_SUCCESS =
  'ASSOCIATION_CREATE_ASYNC_SUCCESS';
export const ASSOCIATION_LIST_ASYNC_SUCCESS = 'ASSOCIATION_LIST_ASYNC_SUCCESS';

export const ASSOCIATION_GET_ASYNC_SUCCESS = 'ASSOCIATION_GET_ASYNC_SUCCESS';

export const ASSOCIATION_UPDATE_ASYNC_SUCCESS =
  'ASSOCIATION_UPDATE_ASYNC_SUCCESS';

function associationAsyncStart() {
  return {
    type: ASSOCIATION_ASYNC_START,
  };
}

function associationAsyncFail(errorMessage) {
  return {
    type: ASSOCIATION_ASYNC_FAIL,
    errorMessage,
  };
}

function associationListAsyncSuccess(data) {
  return {
    type: ASSOCIATION_LIST_ASYNC_SUCCESS,
    data,
  };
}

function associationCreateAsyncSuccess(data) {
  return {
    type: ASSOCIATION_CREATE_ASYNC_SUCCESS,
    data,
  };
}

export function associationGetAsyncSuccess(data) {
  return {
    type: ASSOCIATION_GET_ASYNC_SUCCESS,
    data,
  };
}

function associationUpdateAsyncSuccess(data) {
  return {
    type: ASSOCIATION_UPDATE_ASYNC_SUCCESS,
    data,
  };
}

export function listAssociations(query) {
  return async (dispatch, getState) => {
    dispatch(associationAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const associationsV2 = hasFeatureFlag('associations-v2');
      let path;
      if (associationsV2) {
        const state = getState();
        const filters = getFilters(
          {
            filterName: filterNames.ASSOCIATION_PROMOTERS_FILTER,
          },
          state,
        );
        const {
          resultsPerPage = 0,
          pageNumber = 0,
          orderBy = 'Name',
          sortDirection = 'asc',
        } = query;
        path = `association?resultsPerPage=${resultsPerPage}&pageNumber=${pageNumber}&filters=${encodeURIComponent(
          filterToRequest(filters),
        )}&orderBy=${orderBy}&sortDirection=${sortDirection}`;
      } else {
        path = `association?q=${query}`;
      }
      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(associationListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(associationAsyncFail(error.message));
      return null;
    }
  };
}

export function createAssociation(association) {
  return async (dispatch, getState) => {
    dispatch(associationAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const associationsV2 = hasFeatureFlag('associations-v2');

      const path = !associationsV2 ? 'association' : 'association/v2';
      const response = await api({
        path,
        method: 'POST',
        accessToken,
        body: association,
      });

      dispatch(associationCreateAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(associationAsyncFail(error.message));
      return null;
    }
  };
}

export function updateAssociation(association) {
  return async (dispatch, getState) => {
    dispatch(associationAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const path = `association/${association.AssociationUID}`;
      const response = await api({
        path,
        method: 'PATCH',
        accessToken,
        body: association,
      });
      dispatch(associationUpdateAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(associationAsyncFail(error.message));
      return null;
    }
  };
}

export function getAssociation(id) {
  return async (dispatch, getState) => {
    dispatch(associationAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path = `association/${id}`;
      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(associationGetAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(associationAsyncFail(error.message));
      return null;
    }
  };
}
