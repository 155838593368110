import React from 'react';
import { Button } from '@material-ui/core';
import * as Sentry from '@sentry/react';

export default class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the error content.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.goHome = this.goHome.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  goHome() {
    this.setState({ hasError: false });
    window.location = '/';
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-bundary">
          <h1>OOPS! SOMETHING WENT WRONG</h1>
          <p>We Suggest You Go To The Homepage While We Fix The Problem</p>
          <Button
            variant="contained"
            type="button"
            className="wcra-dark-blue-cta"
            onClick={this.goHome}
          >
            BACK TO HOME
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}
