import api from 'api';
import client from 'api/apollo';
import {
  filterToRequest,
  getFilters,
  getFromState,
  getNestedProperty,
  getToken,
  logError,
} from 'helpers';
import get from 'lodash.get';

import { LIST_ATHLETES } from 'graphql/queries/contestants/listAthletes';
import { GET_ATHLETES } from 'graphql/queries/contestants/getAthlete';
import { LIST_CONTESTANTS_WITHOUT_PROFILE } from 'graphql/queries/contestants/listContestantsWithoutProfile';
import { MergeAthletes } from 'graphql/mutations/mergeAthletes';

import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';

import { FORM_NAME as ATHLETE_FORM_NAME } from '../containers/Athletes/AthleteForm';

export const CONTESTANT_ASYNC_START = 'CONTESTANT_ASYNC_START';
export const CONTESTANT_ASYNC_STOP = 'CONTESTANT_ASYNC_STOP';
export const CONTESTANT_ACTION_CLEAR = 'CONTESTANT_ACTION_CLEAR';
export const CONTESTANT_ASYNC_FAIL = 'CONTESTANT_ASYNC_FAIL';
export const CONTESTANT_ASYNC_SUCCESS = 'CONTESTANT_ASYNC_SUCCESS';
export const CONTESTANT_LIST_ASYNC_SUCCESS = 'CONTESTANT_LIST_ASYNC_SUCCESS';
export const CONTESTANT_WITHOUT_PROFILE_LIST_ASYNC_SUCCESS =
  'CONTESTANT_WITHOUT_PROFILE_LIST_ASYNC_SUCCESS';
export const CONTESTANT_ASYNC_CLEAR_ERROR = 'CONTESTANT_ASYNC_CLEAR_ERROR';
export const UPDATE_CONTESTANT_PROFILE_FAIL = 'UPDATE_CONTESTANT_PROFILE_FAIL';
export const UPDATE_CONTESTANT_PROFILE_SUCCESS =
  'UPDATE_CONTESTANT_PROFILE_SUCCESS';
export const UPDATE_CONTESTANT_PROFILE_START =
  'UPDATE_CONTESTANT_PROFILE_START';
export const CONTESTANT_APPLY_LIST_OPTIONS = 'CONTESTANT_APPLY_LIST_OPTIONS';
export const CONTESTANT_CREATE_ASYNC_SUCCESS =
  'CONTESTANT_CREATE_ASYNC_SUCCESS';
export const CONTESTANT_UPDATE_OPTIONS = 'CONTESTANT_UPDATE_OPTIONS';
export const UPDATE_ATHLETE_NOMINATIONS_OPTIONS =
  'UPDATE_ATHLETE_NOMINATIONS_OPTIONS';

export function contestantAsyncStart() {
  return {
    type: CONTESTANT_ASYNC_START,
  };
}

export function contestantAsyncStop() {
  return {
    type: CONTESTANT_ASYNC_STOP,
  };
}

export function contestantActionClear() {
  return {
    type: CONTESTANT_ACTION_CLEAR,
  };
}

function contestantAsyncFail(errorMessage) {
  return {
    type: CONTESTANT_ASYNC_FAIL,
    errorMessage,
  };
}

function contestantCreateAsyncSuccess(contestant) {
  return {
    type: CONTESTANT_CREATE_ASYNC_SUCCESS,
    contestant,
  };
}

export function contestantAsyncSuccess(data) {
  return {
    type: CONTESTANT_ASYNC_SUCCESS,
    data,
  };
}

export function contestantListAsyncSuccess(data) {
  return {
    type: CONTESTANT_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function contestantWithoutProfileListAsyncSuccess(data) {
  return {
    type: CONTESTANT_WITHOUT_PROFILE_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function contestantAsyncClearError() {
  return {
    type: CONTESTANT_ASYNC_CLEAR_ERROR,
  };
}

export function updateContestantProfileStart() {
  return {
    type: UPDATE_CONTESTANT_PROFILE_START,
  };
}

export function updateContestantProfileSuccess(data) {
  return {
    type: UPDATE_CONTESTANT_PROFILE_SUCCESS,
    data,
  };
}

export function updateContestantProfileFail(error) {
  return {
    type: UPDATE_CONTESTANT_PROFILE_FAIL,
    error,
  };
}

export function applyConfig(data) {
  return {
    type: CONTESTANT_APPLY_LIST_OPTIONS,
    data,
  };
}

export function updateContestantOptions(orderBy, order, page, rowsPerPage) {
  return {
    type: CONTESTANT_UPDATE_OPTIONS,
    data: { orderBy, order, page, rowsPerPage },
  };
}

export function createContestantForPoints(contestant) {
  return async (dispatch, getState) => {
    dispatch(contestantAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: '/performance-results/go-round/summary/add-contestant',
        method: 'POST',
        accessToken,
        body: contestant,
      });

      dispatch(contestantCreateAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(contestantAsyncFail(error.message));
      return null;
    }
  };
}

export function listContestants(values) {
  return async (dispatch, getState) => {
    try {
      dispatch(contestantAsyncStart());
      const state = getState();
      const filters = getFilters(values, state);
      const { pageNumber, sortDirection, orderBy, resultsPerPage } = values;
      const variables = {
        input: {
          filters: filterToRequest(filters),
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
        },
      };

      const contestantQuery = {
        query: LIST_ATHLETES,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(contestantQuery);

      dispatch(contestantListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(contestantAsyncFail(error.message));
      return null;
    }
  };
}

export function listContestantsWithoutProfile(values) {
  return async (dispatch, getState) => {
    try {
      dispatch(contestantAsyncStart());
      const state = getState();
      const filters = getFilters(values, state);
      const { pageNumber, sortDirection, orderBy, resultsPerPage } = values;
      const variables = {
        input: {
          filters: filterToRequest(filters),
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
        },
      };

      const contestantQuery = {
        query: LIST_CONTESTANTS_WITHOUT_PROFILE,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(contestantQuery);

      dispatch(contestantWithoutProfileListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(contestantAsyncFail(error.message));
      return null;
    }
  };
}

export function listCards(values) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `users/${values.ERAUID}/cards`,
        method: 'GET',
        accessToken,
      });

      return response;
    } catch (error) {
      return null;
    }
  };
}

export function selectContestant(contestant) {
  return (dispatch) => {
    dispatch(contestantAsyncSuccess(contestant));
  };
}

export function clearSelectedContestant() {
  return (dispatch) => {
    dispatch(contestantActionClear());
  };
}

export function uploadAthletePhoto(image) {
  return async (dispatch, getState) => {
    dispatch(contestantAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const body = new FormData();
      body.append('file', image);
      const response = await api({
        path: 'contestants/upload-photo/admin',
        method: 'POST',
        body,
        accessToken,
        cType: 'multipart/form-data',
      });

      dispatch(contestantAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(
        contestantAsyncFail(
          'File must have jpg or png format and be under 2mb',
        ),
      );
      return null;
    }
  };
}

export function updateAthlete(isYouth) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);
    const formValues = getNestedProperty(
      `form.${ATHLETE_FORM_NAME}.values`,
      getState(),
    );
    formValues.updatedAt = new Date();

    dispatch(updateContestantProfileStart());

    // compensating for the difference in how role comes in for contestant and athlete models
    formValues.UserRoleUID = formValues.role;

    // Address info for youth athletes is stored under the parent's account
    // therefore we don't need to send the address info as part of the request payload.
    if (isYouth) {
      formValues.city = null;
      formValues.country = null;
      formValues.street = null;
      formValues.street2 = null;
      formValues.state = null;
      formValues.Phone = null;
      formValues.zip = null;
    }

    try {
      const response = await api({
        path: `contestants/${formValues.ERAUID}/update-athlete/admin`,
        method: 'PATCH',
        body: formValues,
        accessToken,
      });
      await dispatch(updateContestantProfileSuccess(response));

      await dispatch(
        createAlertSuccess({
          message: 'Athlete saved!',
          type: 'success',
        }),
      );
      dispatch(clearAlertSuccessAsync());

      return response;
    } catch (error) {
      logError(error);
      dispatch(updateContestantProfileFail(error.message));

      await dispatch(
        createAlertSuccess({
          message: 'Unable to save athlete.',
          type: 'error',
        }),
      );

      await dispatch(clearAlertSuccessAsync());

      return error.message;
    }
  };
}

export function updateContestant() {
  return async (dispatch, getState) => {
    const state = getState();
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);
    const formValues = state.form.editContestant.values;

    dispatch(updateContestantProfileStart());

    try {
      const response = await api({
        path: `contestants/${formValues.ERAUID}/update-contestant/admin`,
        method: 'PATCH',
        body: formValues,
        accessToken,
      });
      await dispatch(updateContestantProfileSuccess(response));

      await dispatch(
        createAlertSuccess({
          message: 'Contestant saved!',
          type: 'success',
        }),
      );
      dispatch(clearAlertSuccessAsync());

      return response;
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Unable to save contestant.',
          type: 'error',
        }),
      );
      await dispatch(clearAlertSuccessAsync());

      dispatch(updateContestantProfileFail(error.message));
      return error.message;
    }
  };
}

export function mergeAthlete(mergeAthletePayload) {
  return async (dispatch, getState) => {
    dispatch(contestantAsyncStart());
    try {
      const user = getFromState(getState, 'user');
      const input = {
        ...mergeAthletePayload,
        mergedByUserId: parseInt(user.id),
      };
      const mutationPayload = {
        mutation: MergeAthletes,
        variables: { input },
      };
      const response = await client
        .mutate(mutationPayload)
        .then(({ data }) => data);
      dispatch(contestantAsyncStop());
      dispatch(
        createAlertSuccess({
          message: '✓ Records Successfully Merged',
          type: 'success',
        }),
      );
      dispatch(clearAlertSuccessAsync());

      return response;
    } catch (error) {
      dispatch(contestantAsyncStop());
      logError(error);
      dispatch(
        createAlertSuccess({
          message: 'Unable to merge athletes.',
          type: 'error',
        }),
      );
      dispatch(clearAlertSuccessAsync());
      return error.message;
    }
  };
}

export function bulkMerge(csvData) {
  csvData = JSON.stringify(csvData);

  return fetch(`${process.env.REACT_APP_URL}/users/mass-merge`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: csvData,
  }).then((res) =>
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json(),
  );
}

export function fetchAthleteByContestant(contestantId) {
  return async (dispatch) => {
    dispatch(contestantAsyncStart());

    try {
      const contestantQuery = {
        query: GET_ATHLETES,
        variables: {
          input: {
            ERAUID: contestantId,
            isQueryTypeSearch: false,
          },
        },
        fetchPolicy: 'network-only',
      };
      const response = await client.query(contestantQuery);
      const contestant = get(response, `data.athletesPayload.athletes[0]`);

      await dispatch(contestantAsyncSuccess(contestant));

      return response;
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Unable to fetch contestant.',
          type: 'error',
        }),
      );

      return error.message;
    }
  };
}

export function getResults({
  segment,
  type,
  discipline,
  userId = null,
  ERAUID = null,
}) {
  return async (dispatch, getState) => {
    const user = getFromState(getState, 'user');
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    try {
      const id = userId ? userId : user.id;
      const response = await api({
        path: `users/results/${id}?segment=${segment}&type=${type}&discipline=${discipline}${
          ERAUID ? `&ERAUID=${ERAUID}` : ''
        }`,
        method: 'GET',
        accessToken,
      });

      return response;
    } catch (error) {
      return error.message;
    }
  };
}

export function getAthleteLeaderboard({ segment, ERAUID = null }) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    try {
      const response = await api({
        path: `contestants/${ERAUID}/leaderboard-standing/admin?segment=${segment}`,
        method: 'GET',
        accessToken,
      });

      return response;
    } catch (error) {
      return error.message;
    }
  };
}

export function getListSegment({ segment, ERAUID = null }) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    try {
      const response = await api({
        path: `contestant-discipline/filters?ERAUID=${ERAUID}&SegmentUID=${segment}`,
        method: 'GET',
        accessToken,
      });

      return response;
    } catch (error) {
      return error.message;
    }
  };
}
