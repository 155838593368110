import { ABILITY_TYPES } from './types';
import adminSetting from './adminSetting';
import athlete from './athlete';
import contractors from './contractors';
import coupon from './coupon';
import giftCard from './giftcard';
import dashboard from './dashboard';
import event from './event';
import livestock from './livestock';
import nomination from './nomination';
import venues from './venues';
import resultsAndPoints from './resultsAndPoints';
import associationSettings from './associationSettings';
import membershipSeason from './membershipSeason';
import associationsPromoters from './associationsPromoters';

const abilityDefinitions = {
  [ABILITY_TYPES.ADMIN_SETTING]: adminSetting,
  [ABILITY_TYPES.ATHLETE]: athlete,
  [ABILITY_TYPES.CONTRACTORS]: contractors,
  [ABILITY_TYPES.COUPON]: coupon,
  [ABILITY_TYPES.GIFTCARD]: giftCard,
  [ABILITY_TYPES.DASHBOARD]: dashboard,
  [ABILITY_TYPES.EVENT]: event,
  [ABILITY_TYPES.LIVESTOCK]: livestock,
  [ABILITY_TYPES.NOMINATION]: nomination,
  [ABILITY_TYPES.VENUES]: venues,
  [ABILITY_TYPES.RESULTS_AND_POINTS]: resultsAndPoints,
  [ABILITY_TYPES.ASSOCIATION_SETTINGS]: associationSettings,
  [ABILITY_TYPES.MEMBERSHIP_SEASON]: membershipSeason,
  [ABILITY_TYPES.ASSOCIATIONS_PROMOTERS]: associationsPromoters,
};

export default function abilities(role, type) {
  const fn = abilityDefinitions[type];
  return fn(role);
}
