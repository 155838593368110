import gql from 'graphql-tag';

export const GET_ENTRY_POOL_UNMATCHED_PARTNERS = gql`
  query GET_ENTRY_POOL_UNMATCHED_PARTNERS($input: EntryPoolPartnerInput!) {
    entryPoolGetUnmatchedPartners(input: $input) {
      EPUID
      EventUID
      ERAUID
      FullName
      firstName
      lastName
      Nickname
      createdAt
      fullNameWithNickname
    }
  }
`;
