import omit from 'lodash.omit';

const _write = (collection, data) => {
  localStorage.setItem(collection, JSON.stringify(data));
};

const _read = (collection) => {
  const data = localStorage.getItem(collection);

  return typeof data === 'undefined' ? undefined : JSON.parse(data);
};

/**
 * For a given collection in local storage, adds or updates a new key/value
 * pair.
 * @param {string} collection - Name of the localStorage collection
 * @param {string} key - Key to add or update in the localStorage JSON
 * @param {*} value - Value to set, remove if undefined
 */
export const setLocalStorage = (collection, key, value) => {
  const state = _read(collection) || {};

  if (typeof key === 'undefined') {
    localStorage.removeItem(collection);
    return;
  }

  if (typeof value === 'undefined') {
    _write(collection, omit(state, key));
  } else {
    _write(
      collection,
      Object.assign({}, state, {
        [key]: value,
      }),
    );
  }
};

/**
 * Extracts and returns a value from the JSON of a localStorage colection.
 * @param {string} collection
 * @param {string} key
 */
export const getLocalStorage = (collection, key) => {
  const state = _read(collection) || {};

  return state[key];
};
