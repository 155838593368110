import gql from 'graphql-tag';

export const LIST_GIFTCARDS = gql`
  query LIST_GIFTCARDS($input: GiftCardsInput!) {
    giftCardsPayload(input: $input) {
      giftCards {
        GiftCardUID
        Color
        Name
        Balance
        Cost
        Validity
        GiftCardStatus
        Visibility
        Subject
        CreatedAt
        UpdatedAt
        LastUpdatedByERAUID
        RemainingBalance
        CreatedForERAUID
        EmailBody
        athleteName
        athleteEmail
      }
    }
  }
`;
