import { Record } from 'immutable';

const defaultValues = {
  id: null,
  value: 0,
  name: '',
};

export default class CustomFee extends Record(defaultValues, 'CustomFee') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
