/* global APP_CONFIG */

const base = APP_CONFIG.api.url;
const defaultContentType = 'application/json';

export default async (payload) => {
  const { path, method, body, cType, header, accessToken = null } = payload;
  const url = `${base}/${path}`;
  const contentType = cType || defaultContentType;
  const bodyJson =
    contentType === 'application/json' ? JSON.stringify(body) : body;

  const defaultHeaders = {
    'Content-Type': `${contentType}`,
  };

  if (accessToken) defaultHeaders.Authorization = `Bearer ${accessToken}`;
  if (contentType === 'multipart/form-data')
    delete defaultHeaders['Content-Type'];

  const headers = { ...defaultHeaders, ...header };

  const response = await fetch(url, { method, body: bodyJson, headers });
  const responseBody = response.json();

  if (response.status >= 400) {
    const errorResponse = await responseBody;
    throw Error(errorResponse.message);
  }
  return responseBody;
};
