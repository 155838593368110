import gql from 'graphql-tag';

export const LIST_LIVESTOCK_OWNERS = gql`
  query LIST_LIVESTOCK_OWNERS($input: LivestockOwnersInput!) {
    livestockOwnersPayload(input: $input) {
      livestockOwners {
        id
        name
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
