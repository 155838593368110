import { Record } from 'immutable';

const defaultValues = {
  ERAUID: null,
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  disciplineId: null,
  photoFileName: null,
  street: null,
  street2: null,
  city: null,
  state: null,
  zip: null,
  country: null,
  socialTags: null,
  lastLogin: null,
  phone: null,
  status: null,
  updatedAt: null,
  DOB: null,
  nickname: null,
  hometown: null,
  careerEarnings: null,
  worldChampionshipEarned: null,
  PayeeNumber: null,
  height: null,
  weight: null,
  facebook: null,
  twitter: null,
  instagram: null,
  role: null,
  associationId: null,
};

export const ROLES = {
  USER: 'user',
  ADMIN: 'admin',
  SALES_REP: 'sales_rep',
  SECRETARY: 'secretary',
  ATHLETE_SUPPORT: 'athlete_support',
  ADMIN_VIEW_ONLY: 'admin_view_only',
  MEMBERSHIP_ADMIN: 'membership_admin',
};

export const ALLOWED_LOGIN_ROLES = [
  ROLES.ADMIN,
  ROLES.SECRETARY,
  ROLES.ATHLETE_SUPPORT,
  ROLES.MEMBERSHIP_ADMIN,
  ROLES.SALES_REP,
  ROLES.ADMIN_VIEW_ONLY,
];

export const DEFAULT_PAGES = {
  [ROLES.USER]: '/login',
  [ROLES.ADMIN]: '/dashboard',
  [ROLES.SECRETARY]: '/events',
  [ROLES.ATHLETE_SUPPORT]: '/athletes',
  [ROLES.MEMBERSHIP_ADMIN]: '/dashboard',
  [ROLES.ADMIN_VIEW_ONLY]: '/dashboard',
  [ROLES.SALES_REP]: '/dashboard',
};

export default class User extends Record(defaultValues, 'User') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
