import { Record } from 'immutable';

const defaultValues = {
  ContactUID: null,
  ContactName: null,
  FirstName: null,
  LastName: null,
  Email: null,
  Phone1: null,
  Phone2: null,
  FullName: null,
};

export default class Contact extends Record(defaultValues, 'Contact') {
  constructor(values) {
    super({
      ...values,
      FullName:
        values.FullName ||
        (values && values.FirstName !== null && values.LastName !== null)
          ? `${values.FirstName} ${values.LastName}`
          : null,
    });
  }
}
