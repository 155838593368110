export function getTotalFees(purse) {
  const { feesCollected, drawOuts, drawOutMoney, substitutions } = purse;
  return feesCollected + drawOuts + drawOutMoney + substitutions;
}

export function getTotalCustomFees(purse) {
  const { customFees } = purse;
  return customFees.reduce((total, customFee) => {
    return total + customFee.value;
  }, 0);
}

export function getTotalPurse(purse) {
  return getTotalCustomFees(purse) + getTotalFees(purse);
}
