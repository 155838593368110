import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { auth0ValidateToken, authAsyncFail } from 'actions/auth';

const returnTo = window.location.origin;

export const useAuth = ({ onError, callGetToken = true }) => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const getToken = async () => {
    const token = await getAccessTokenSilently({
      ignoreCache: true,
      scope: 'offline_access',
    });
    dispatch(auth0ValidateToken(token))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        onError && onError();
        logout({ returnTo });
      });
  };

  useEffect(
    () => {
      if (isAuthenticated && callGetToken) getToken();
    },
    [isAuthenticated],
  );

  const handleLogout = () => {
    dispatch(authAsyncFail(error));
    logout({ returnTo });
  };

  useEffect(
    () => {
      if (error) {
        handleLogout();
      }
    },
    [error],
  );

  return {
    isLoading: isLoading || loading,
    isAuthenticated,
    getToken,
    error,
    user,
    logout: handleLogout,
  };
};
