import gql from 'graphql-tag';

export const MergeAthletes = gql`
  mutation MergeAthletes($input: MergeAthletesInput!) {
    mergeAthletes(input: $input) {
      mergedERAUID
      deletedERAUID
      error
    }
  }
`;
