// @flow
import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';
import EntryTransactionEntry from 'models/EntryTransactionEntry';

export type EntryTransactionType = {
  EPUID: string,
  baseFee: string,
  ccBrand: string,
  ccLast4: string,
  date: string,
  entries: Array<EntryTransactionEntry>,
  entryChargeRequestUID: string,
  eventId: string,
  isPaying: boolean,
  numberOfEntries: number,
  payeeFullName: string,
  payingERAUID: string,
  stripeFee: string,
  stripeRawResponse: string,
  subtotal: string,
  totalFee: string,
  transactionFee: string,
  paymentType: string,
  lateFee: string,
};

const defaultValues = {
  EPUID: null,
  baseFee: null,
  ccBrand: null,
  ccLast4: null,
  date: null,
  entries: toEntityList([], EntryTransactionEntry),
  entryChargeRequestUID: null,
  eventId: null,
  isPaying: false,
  numberOfEntries: null,
  payeeFullName: null,
  payingERAUID: null,
  stripeFee: null,
  stripeRawResponse: null,
  subtotal: null,
  totalFee: null,
  transactionFee: null,
  paymentType: null,
};

export default class EntryTransaction extends Record(
  defaultValues,
  'EntryTransaction',
) {
  constructor(values: EntryTransactionType) {
    super({
      ...values,
    });
  }
}
