import gql from 'graphql-tag';

export const LIST_LIVESTOCK = gql`
  query LIST_LIVESTOCK($input: LivestockInput!) {
    livestockPayload(input: $input) {
      livestock {
        AnimalType
        AnimalTypeId
        AnimalUID
        Brand
        ContractorUID
        DisciplineNames
        ERAUID
        NickName
        ContractorName
        OwnerName
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
