import { Record } from 'immutable';

const defaultValues = {
  SegmentUID: null,
  Name: '',
  StartDate: null,
  EndDate: null,
  HasPro: null,
  HasChallenger: null,
  HasYouth: null,
  ShortName: '',
};

export default class Segment extends Record(defaultValues, 'Segment') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
