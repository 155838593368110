// @flow
import { AVAILABLE_COUNTRIES_AND_DETAILS } from 'constants/countryData';
import countries from 'node-countries';

export const validate = (values: Object) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Please enter an email address.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address.';
  }

  if (!values.password) {
    errors.password = 'Please enter a password.';
  } else if (!/.{6}/i.test(values.password)) {
    errors.password = 'Password must be at least six characters.';
  }

  if (!values.firstName) {
    errors.firstName = 'Please enter a first name.';
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter a last name.';
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Please enter a password confirmation.';
  }

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'Passwords do not match';
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
    errors.Email = 'This is not a Valid Email';
  }

  if (!values.RequestEmail) {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.RequestEmail)
    ) {
      errors.RequestEmail = 'This is not a Valid Email';
    }
  }

  if (!values.RequestPhone) {
    if (!/^\d{3}-\d{3}-\d{4}$/i.test(values.RequestPhone)) {
      errors.RequestPhone = 'This is not a Valid Phone Number';
    }
  }

  return errors;
};

export const required = (value: string | number) => {
  return value === undefined ||
    value === null ||
    (typeof value === 'string' && value.trim().length === 0)
    ? 'This field is required'
    : undefined;
};

export const name = (value: string) => {
  if (value && !/^[a-zA-Z\s]*$/.test(value)) {
    return 'Letters only';
  }
  return undefined;
};

export const requiredMultiSelect = (value: string | number) => {
  return value === undefined || value === null || value.length === 0
    ? 'This field is required'
    : undefined;
};

export const requiredShort = (value: string | number) => {
  return value ? undefined : 'required';
};

export const number = (value: number) => {
  return value && isNaN(Number(value)) ? 'Must be a number' : undefined;
};

export const maxRefund = (max: number) => (value: number) => {
  return value && value > max
    ? `Refund cannot be more than $${max}`
    : undefined;
};

export const maxLength = (max: number) => (value: Object) => {
  return value && value.length > max
    ? `Must be ${max} characters or less`
    : undefined;
};

export const minLength = (min: number) => (value: Object) => {
  return value && value.length < min
    ? `Must be at least ${min} characters`
    : undefined;
};

export const maxValue = (max: number) => (value: number) => {
  return value && value > max ? `Cannot be more than ${max}` : undefined;
};

export const minValue = (min: number) => (value: number) => {
  return value && value < min ? `Cannot be < ${min}` : undefined;
};

export const maxDiscountValue = maxValue(100);

export const minValue0 = minValue(0);

export const exactLength = (length: number) => (value: Object) => {
  return value && value.length !== length
    ? `Must be exactly ${length} characters`
    : undefined;
};

export const exactLength2 = exactLength(2);
export const exactLength7 = exactLength(7);

export const onlyNumber = (value: string) => {
  return /^[0-9]$/i.test(value);
};

export const onlyAlphaNumeric = (value: string) => {
  return value && !/^[A-Za-z0-9]*$/.test(value)
    ? 'Invalid special characters'
    : undefined;
};

export const email = (value: string) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
};

export const password = (value: string) => {
  return value && !/.{6}/i.test(value)
    ? 'Password must be at least six characters.'
    : undefined;
};

export const url = (value: string) => {
  return value &&
    !/^(http:\/\/)?(www+\.)?[a-zA-Z0-9_-]+\.([a-zA-Z‌]{2,})+$/g.test(value)
    ? 'Invalid url'
    : undefined;
};

export const currency = (value: string) => {
  return value && !/^-?[\d]*?(.\d{2})?$/.test(value)
    ? 'Invalid. Ex 10.99'
    : undefined;
};

export const match = (compareValue: string) => (value: string) => {
  return value && compareValue !== value ? 'Fields do not match' : undefined;
};

export const inlineCurrency = (value: string) => {
  return (value && /^-?[\d]*?(.\d{2})?$/.test(value)) || false;
};

export const inlineNumber = (value: string) => {
  return (value && /^[0-9]*$/.test(value)) || false;
};

export const ssn = (value: string) => {
  return value && value.length !== 9 ? 'Invalid SSN/TIN' : undefined;
};

export const postalCode = (postalCode: string, country: string) => {
  switch (country) {
    default:
    case 'US':
      return !/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(postalCode)
        ? 'Zip invalid'
        : undefined;
    case 'CA':
      return !/^([A-Za-z][0-9][A-Za-z])\s*([0-9][A-Za-z][0-9])$/.test(
        postalCode,
      )
        ? 'Postal Code invalid'
        : undefined;
  }
};

export const stateProvince = (state: string, country: string) => {
  const countryToFind = country !== null ? country : 'US';
  const { [countryToFind]: countryDetails } = AVAILABLE_COUNTRIES_AND_DETAILS;
  const foundCountry = countries.getCountryByNameOrShortName(
    countryToFind,
    true,
  );
  const statesProvinces = foundCountry ? foundCountry.provinces : [];
  const hasFoundStateProvince = statesProvinces.find(
    (stateProvince: Object) => {
      return stateProvince.short === state;
    },
  );

  const administrativeDivision = countryDetails
    ? countryDetails.administrativeDivision
    : 'State or Province';

  return !hasFoundStateProvince
    ? `${administrativeDivision} is not valid`
    : undefined;
};

export const countryZip = (zip: string, country: string) => {
  if (!zip) {
    return 'Required';
  } else if (zip && country) {
    const postalCodeErrors = postalCode(zip, country);
    if (postalCodeErrors) {
      return postalCodeErrors;
    }
  }
  return undefined;
};

export const countryState = (state: string, country: string) => {
  if (!state) {
    return 'Required';
  } else if (state && country) {
    const stateProvinceErrors = stateProvince(state, country);
    if (stateProvinceErrors) {
      return stateProvinceErrors;
    }
  }
  return undefined;
};
