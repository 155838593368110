import { Record } from 'immutable';

const defaultValues = {
  PerformanceResultsUID: null,
  StockDrawUID: null,
  EPUID: null,
  PartnerEPUID: null,
  TeammateERAUID: null,
  TeammateName: null,
  ERAUID: null,
  ContestantName: null,
  AnimalUID: null,
  AnimalName: null,
  AnimalBrand: null,
  AnimalIdentifier: null,
  Position: null,
  entryCountAsLetter: null,
  partnerEntryCountAsLetter: null,

  // Performance fields
  EventUID: null,
  PerformanceUID: null,
  CompType: null,
  JudgeNumber: null,
  JudgeName: null,
  JudgeSide: null,

  // Rough Stock
  Ride: null,
  Stock: null,

  // Roping
  Time: null,
  Barrier: null,
  Field: null,

  // Barrel Racing
  FirstPenalty: null,
  SecondPenalty: null,
  ThirdPenalty: null,

  PenaltyUID: null,
  Subtotal: null,
  Total: null,
  Rank: null,
  IsReride: null,
  IsRerideCancelled: 0,
  partner: null,
};

export default class PerformanceResult extends Record(
  defaultValues,
  'PerformanceResult',
) {
  constructor(values) {
    super({
      ...values,
    });
  }
}
