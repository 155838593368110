import gql from 'graphql-tag';

export const EDIT_ATHLETE_ENTRY = gql`
  mutation editAthleteEntry($input: EditUserInput!) {
    editUser(input: $input) {
      user {
        id
        email
        phone
      }
      error
      success
    }
  }
`;
