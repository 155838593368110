import gql from 'graphql-tag';

export const LIST_CONTESTANTS_WITHOUT_PROFILE = gql`
  query LIST_CONTESTANTS_WITHOUT_PROFILE($input: ContestantsInput!) {
    contestantsWithoutProfilePayload(input: $input) {
      contestants {
        ERAUID
        FName
        LName
        LastUpdate
        Disc
        numberOfResults
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
