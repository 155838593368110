import Contractor from 'models/Contractor';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { List } from 'immutable';
import { setLocalStorage, getLocalStorage } from 'helpers/localStorage';

import {
  CONTRACTOR_ASYNC_START,
  CONTRACTOR_ACTION_CLEAR,
  CONTRACTOR_ASYNC_FAIL,
  CONTRACTOR_ASYNC_SUCCESS,
  CONTRACTOR_LIST_ASYNC_START,
  CONTRACTOR_LIST_ASYNC_SUCCESS,
  CONTRACTOR_LIST_ASYNC_FAIL,
  CONTRACTOR_ASYNC_CLEAR_ERROR,
  CONTRACTOR_CREATE_SAVE_ASYNC_SUCCESS,
  CONTRACTOR_APPLY_LIST_OPTIONS,
  CONTRACTOR_UPDATE_OPTIONS,
} from 'actions/contractor';

const tableOptions = getLocalStorage('TableOptions', 'contractors') || {};

export const initialContractorOptions = {
  order: 'asc',
  orderBy: 'ContractorUID',
  orderIsDate: false,
  selected: new List(),
  page: 0,
  rowsPerPage: tableOptions.rowsPerPage || 50,
};

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: null,
  list: toEntityList([], Contractor),
  options: initialContractorOptions,
});

const actionsMap = {
  [CONTRACTOR_APPLY_LIST_OPTIONS]: (state, action) => {
    const newOptions = action.data;
    const baseOptions =
      state.get && state.get('options') ? state.get('options').toJS() : {};
    const options = Object.assign({}, baseOptions, newOptions);
    return state.merge({
      options,
    });
  },
  [CONTRACTOR_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: null,
    });
  },
  [CONTRACTOR_CREATE_SAVE_ASYNC_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [CONTRACTOR_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [CONTRACTOR_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: action.data,
    });
  },
  [CONTRACTOR_LIST_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [CONTRACTOR_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.contractorsPayload) return state;

    const { contractors, meta } = action.data.contractorsPayload;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(contractors, Contractor),
      totalCount: meta.totalCount || 0,
    });
  },
  [CONTRACTOR_LIST_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [CONTRACTOR_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [CONTRACTOR_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [CONTRACTOR_UPDATE_OPTIONS]: (state, action) => {
    const { order, orderBy, page, rowsPerPage } = action.data;

    setLocalStorage('TableOptions', 'contractors', { rowsPerPage });

    return state.merge({
      options: Object.assign({}, state.options, {
        order,
        orderBy,
        page,
        rowsPerPage,
      }),
    });
  },
};

export default function contractor(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
