import Event from 'models/Event';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { setLocalStorage, getLocalStorage } from 'helpers/localStorage';
import { flatten } from 'lodash';

import {
  EVENT_ACTION_SUCCESS,
  EVENT_ACTION_CLEAR,
  EVENT_ASYNC_START,
  EVENT_ASYNC_STOP,
  EVENT_ASYNC_FAIL,
  EVENT_ASYNC_SUCCESS,
  EVENT_LIST_ASYNC_SUCCESS,
  EVENT_SET_IS_WCRA_MANAGED,
  EVENT_UPDATE_OPTIONS,
} from 'actions/event';

const tableOptions = getLocalStorage('TableOptions', 'events') || {};

export const initialEventOptions = {
  order: 'desc',
  orderBy: 'id',
  orderIsDate: false,
  page: 0,
  childOptions: [],
  rowsPerPage: tableOptions.rowsPerPage || 50,
};

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Event(),
  childOptions: [],
  list: toEntityList([], Event),
  options: initialEventOptions,
  selectedEventDisciplines: [],
});

const actionsMap = {
  [EVENT_ACTION_SUCCESS]: (state, action) => {
    const selectedEventDisciplines = action.data.DisciplineFees.filter(
      (disc) => disc.IsDisciplineInEvent === 1,
    );
    const _data = new Event(action.data);
    const childOptions = buildClassOptions(_data.DisciplineFees);
    return state.merge({
      loading: false,
      childOptions: childOptions,
      data: _data,
      selectedEventDisciplines: selectedEventDisciplines.map((item) => ({
        id: item.DisciplineUID,
        name: item.Discipline,
      })),
    });
  },
  [EVENT_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: new Event(),
    });
  },
  [EVENT_SET_IS_WCRA_MANAGED]: (state, action) => {
    const event = state.get('data');
    return state.merge({
      data: event.set('IsWcraManaged', action.isWcraManaged),
    });
  },
  [EVENT_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [EVENT_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [EVENT_ASYNC_SUCCESS]: (state, action) => {
    const _data = new Event(action.data);
    const childOptions = buildClassOptions(_data.DisciplineFees);
    return state.merge({
      loading: false,
      errorMessage: null,
      childOptions,
      data: { ..._data },
    });
  },
  [EVENT_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.eventsPayload) return state;

    const { events, meta } = action.data.eventsPayload;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(events, Event),
      totalCount: meta.totalCount || 0,
    });
  },
  [EVENT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [EVENT_UPDATE_OPTIONS]: (state, action) => {
    const { order, orderBy, page, rowsPerPage } = action.data;
    const { data } = state;
    const childOptions = buildClassOptions(data.DisciplineFees);
    setLocalStorage('TableOptions', 'events', { rowsPerPage });

    return state.merge({
      childOptions,
      options: Object.assign({}, state.options, {
        order,
        orderBy,
        page,
        rowsPerPage,
      }),
    });
  },
};

function buildClassOptions(Disciplines) {
  const data = Disciplines instanceof Array ? Disciplines : [];
  return flatten(
    data.map((discipline) => {
      if (discipline.Classes instanceof Array) {
        return discipline.Classes.map((c) => {
          return {
            id: c.ClassName ? c.ClassName.trim() : '',
            _key: c.EventEntryDisciplineFeeUID,
            value: c.ClassName,
            parentId: c.Discipline,
            IsDisciplineInEvent: c.IsDisciplineInEvent,
          };
        });
      }
      return [];
    }),
  ).filter((c) => c.IsDisciplineInEvent === 1);
}

export default function event(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
