import api from 'api';

import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';
import { getNestedProperty, logError } from 'helpers';
import PerformanceResult from 'models/PerformanceResult';
import {
  getSingleJudgeResults,
  removeAndReplaceAthleteFromReride,
  removeAthleteFromReride,
  addAthleteToReride,
  replacePerformanceResultsInList,
  filterResultsOnRerideChange,
  calculateRanking,
  getKeyFromState,
  getFromState,
  getToken,
  toJS,
} from '../helpers';

export const PERFORMANCE_RESULT_ASYNC_START = 'PERFORMANCE_RESULT_ASYNC_START';
export const PERFORMANCE_RESULT_ASYNC_STOP = 'PERFORMANCE_RESULT_ASYNC_STOP';
export const PERFORMANCE_RESULT_ASYNC_FAIL = 'PERFORMANCE_RESULT_ASYNC_FAIL';
export const PERFORMANCE_RESULT_LIST_ASYNC_SUCCESS =
  'PERFORMANCE_RESULT_LIST_ASYNC_SUCCESS';
export const CHANGE_JUDGE_SUCCESS = 'CHANGE_JUDGE_SUCCESS';
export const CLEAR_PERFORMANCE_RESULT_SUCCESS =
  'CLEAR_PERFORMANCE_RESULT_SUCCESS';

function performanceResultAsyncStart() {
  return {
    type: PERFORMANCE_RESULT_ASYNC_START,
  };
}

function performanceResultAsyncFail(error) {
  return {
    type: PERFORMANCE_RESULT_ASYNC_FAIL,
    error,
  };
}

export function performanceResultAsyncStop() {
  return {
    type: PERFORMANCE_RESULT_ASYNC_STOP,
  };
}

export function clearPerformanceResultSuccess() {
  return {
    type: CLEAR_PERFORMANCE_RESULT_SUCCESS,
  };
}

export function performanceResultListAsyncSuccess(apiData, ...judgeData) {
  if (!judgeData) {
    return {
      type: PERFORMANCE_RESULT_LIST_ASYNC_SUCCESS,
      data: apiData,
    };
  }

  const rankedData = calculateRanking(apiData);
  const singleJudgeData = getSingleJudgeResults(rankedData, ...judgeData);

  return {
    type: PERFORMANCE_RESULT_LIST_ASYNC_SUCCESS,
    singleJudgeData,
    apiData,
  };
}

export function listPerformanceResults(performance, discipline) {
  return async (dispatch, getState) => {
    dispatch(performanceResultAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { options } = getKeyFromState(getState, 'performanceResult');
      const JudgeNumber = options.selectedJudge || 1;

      const response = await api({
        path: `performance-results?CompType=${discipline.id}&PerformanceUID=${
          performance.PerformanceUID
        }`,
        method: 'GET',
        accessToken,
      });

      dispatch(
        performanceResultListAsyncSuccess(
          response,
          JudgeNumber,
          discipline.id,
          performance.PerformanceUID,
        ),
      );

      return response;
    } catch (error) {
      logError(error);
      dispatch(performanceResultAsyncFail(error.message));
      return null;
    }
  };
}

export function changeJudge(JudgeNumber) {
  return {
    type: CHANGE_JUDGE_SUCCESS,
    JudgeNumber,
  };
}

export function sendRecalculatedValues(values, CompType, PerformanceUID) {
  return async (dispatch, getState) => {
    dispatch(performanceResultAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const { options } = getKeyFromState(getState, 'performanceResult');
      const JudgeNumber = options.selectedJudge || 1;

      const filteredPerformanceResults = values.filter((item) => {
        return item.EPUID !== null && item.IsRerideCancelled === 0;
      });

      const response = await api({
        path: `performance-results?CompType=${CompType}&PerformanceUID=${PerformanceUID}&JudgeNumber=${JudgeNumber}`,
        method: 'PATCH',
        body: filteredPerformanceResults,
        accessToken,
      });

      await dispatch(
        createAlertSuccess({
          message: 'Results saved',
          type: 'success',
        }),
      );

      await dispatch(
        performanceResultListAsyncSuccess(
          response,
          JudgeNumber,
          CompType,
          PerformanceUID,
        ),
      );
      return setTimeout(() => {
        dispatch({ type: 'CLEAR_ALERT_SUCCESS' });
      }, 5000);
    } catch (error) {
      logError(error);
      return dispatch(performanceResultAsyncFail(error.message));
    }
  };
}

export function recalculateValues(values) {
  return async (dispatch, getState) => {
    try {
      const valueKeys = Object.keys(values);
      const { disciplineId, performanceUID, penaltyName } = values[
        valueKeys[0]
      ];
      const state = getState();

      const { options, apiList: fullListPerformanceResults } = getKeyFromState(
        getState,
        'performanceResult',
      );
      const JudgeNumber = options.selectedJudge || 1;

      const formValues = state.form.editPerformanceResults.values;

      const updatedPerformanceResults = valueKeys.map((value) => {
        const result = formValues[`editPerformanceResult-${value}`];
        if (result) {
          if (getNestedProperty('Stock', result)) {
            result.Stock = parseFloat(result.Stock);
          }
          if (getNestedProperty('Ride', result)) {
            result.Ride = parseFloat(result.Ride);
          }
          if (getNestedProperty('Field', result)) {
            result.Field = parseFloat(result.Field);
          }
          if (getNestedProperty('Barrier', result)) {
            result.Barrier = parseFloat(result.Barrier);
          }
          if (getNestedProperty('Time', result)) {
            result.Time = parseFloat(result.Time);
          }
          if (penaltyName) {
            const penaltyValue = result[penaltyName] === 5 ? 0 : 5;
            result[penaltyName] = penaltyValue;
          }
        }
        return toJS(new PerformanceResult(result));
      });

      const performanceResultsMergedList = replacePerformanceResultsInList(
        fullListPerformanceResults,
        updatedPerformanceResults,
      );

      return dispatch(
        performanceResultListAsyncSuccess(
          toJS(performanceResultsMergedList),
          JudgeNumber,
          disciplineId,
          performanceUID,
        ),
      );
    } catch (error) {
      logError(error);
      return dispatch(performanceResultAsyncFail(error.message));
    }
  };
}

export function updateJudgeDetails(
  discipline,
  performance,
  JudgeName,
  JudgeSide,
) {
  return async (dispatch, getState) => {
    dispatch(performanceResultAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const { options } = getKeyFromState(getState, 'performanceResult');
      const JudgeNumber = options.selectedJudge || 1;

      const { list: performanceResults } = getKeyFromState(
        getState,
        'performanceResult',
      );

      const updatedPerformanceResults = performanceResults.map(
        (performanceItem) => {
          return {
            ...performanceItem,
            JudgeName,
            JudgeSide,
            JudgeNumber,
          };
        },
      );

      const response = await api({
        path: `performance-results?CompType=${discipline.id}&PerformanceUID=${
          performance.PerformanceUID
        }&JudgeNumber=${JudgeNumber}`,
        method: 'PATCH',
        body: updatedPerformanceResults,
        accessToken,
      });

      dispatch(
        performanceResultListAsyncSuccess(
          response,
          JudgeNumber,
          discipline.id,
          performance.PerformanceUID,
        ),
      );

      await dispatch(
        createAlertSuccess({
          message: 'Judge saved',
          type: 'success',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: error.message,
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      return dispatch(performanceResultAsyncFail(error.message));
    }
  };
}

export function sendRerideRow(
  query,
  accessToken,
  discipline,
  performance,
  JudgeNumber,
  results,
) {
  return async (dispatch, getState) => {
    const { apiList: fullListPerformanceResults } = getKeyFromState(
      getState,
      'performanceResult',
    );

    try {
      const response = await api({
        path: `performance-results/${query}?CompType=${
          discipline.id
        }&PerformanceUID=${
          performance.PerformanceUID
        }&JudgeNumber=${JudgeNumber}`,
        method: 'POST',
        body: results,
        accessToken,
      });

      const filteredResults = await filterResultsOnRerideChange(
        results,
        fullListPerformanceResults,
        response,
        JudgeNumber,
      );

      return dispatch(
        performanceResultListAsyncSuccess(
          filteredResults,
          JudgeNumber,
          discipline.id,
          performance.PerformanceUID,
        ),
      );
    } catch (error) {
      logError(error);
      return dispatch(performanceResultAsyncFail(error.message));
    }
  };
}

export function createRerideRow(
  discipline,
  performance,
  EPUID,
  Reride,
  ERAUID,
  partner,
) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { options, list: performanceResults } = getKeyFromState(
        getState,
        'performanceResult',
      );

      const JudgeNumber = options.selectedJudge || 1;

      const updatedPerformanceResults = toJS(
        addAthleteToReride(
          Reride,
          performanceResults,
          EPUID,
          JudgeNumber,
          ERAUID,
          partner,
        ),
      );
      return dispatch(
        sendRerideRow(
          'add-stockdraw',
          accessToken,
          discipline,
          performance,
          JudgeNumber,
          updatedPerformanceResults,
        ),
      );
    } catch (error) {
      logError(error);
      return dispatch(performanceResultAsyncFail(error.message));
    }
  };
}

export function selectRerideRow(
  discipline,
  performance,
  EPUID,
  rerideId,
  ERAUID,
  partner,
) {
  return async (dispatch, getState) => {
    dispatch(performanceResultAsyncStart());
    try {
      const state = getState();
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { list: performanceResults } = getKeyFromState(
        getState,
        'performanceResult',
      );

      const formValues = state.form.editPerformanceResults.values;

      const { options } = getKeyFromState(getState, 'performanceResult');
      const JudgeNumber = options.selectedJudge || 1;

      const reride = toJS(
        new PerformanceResult(formValues[`addReride-${rerideId}`]),
      );

      const updatedPerformanceResults = toJS(
        addAthleteToReride(
          reride,
          performanceResults,
          EPUID,
          JudgeNumber,
          ERAUID,
          partner,
        ),
      );

      return dispatch(
        sendRerideRow(
          'add-contestant',
          accessToken,
          discipline,
          performance,
          JudgeNumber,
          updatedPerformanceResults,
        ),
      );
    } catch (error) {
      logError(error);
      return dispatch(performanceResultAsyncFail(error.message));
    }
  };
}

export function updateRerideRow(
  discipline,
  performance,
  EPUID,
  reride,
  ERAUID,
  partner,
) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const {
        options,
        list: performanceResults,
        apiList: fullListPerformanceResults,
      } = getKeyFromState(getState, 'performanceResult');
      const JudgeNumber = options.selectedJudge || 1;

      const method = EPUID ? 'PUT' : 'POST';
      const values = {
        reride,
        performanceResults,
        EPUID,
        JudgeNumber,
        ERAUID,
        partner,
      };
      const updatedRows = EPUID
        ? toJS(removeAndReplaceAthleteFromReride(values))
        : toJS(removeAthleteFromReride(values));

      const response = await api({
        path: `performance-results/add-contestant?CompType=${
          discipline.id
        }&PerformanceUID=${
          performance.PerformanceUID
        }&JudgeNumber=${JudgeNumber}`,
        method,
        body: updatedRows,
        accessToken,
      });

      const filteredResults = await filterResultsOnRerideChange(
        updatedRows,
        fullListPerformanceResults,
        response,
        JudgeNumber,
      );

      return dispatch(
        performanceResultListAsyncSuccess(
          filteredResults,
          JudgeNumber,
          discipline.id,
          performance.PerformanceUID,
        ),
      );
    } catch (error) {
      logError(error);
      return dispatch(performanceResultAsyncFail(error.message));
    }
  };
}
