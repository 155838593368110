import Venue from 'models/Venue';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { setLocalStorage, getLocalStorage } from 'helpers/localStorage';

import {
  VENUE_ASYNC_START,
  VENUE_ACTION_CLEAR,
  VENUE_ASYNC_FAIL,
  VENUE_ASYNC_SUCCESS,
  VENUE_LIST_ASYNC_SUCCESS,
  VENUE_ASYNC_CLEAR_ERROR,
  VENUE_CREATE_SAVE_ASYNC_SUCCESS,
  VENUE_UPDATE_OPTIONS,
} from 'actions/venue';

const tableOptions = getLocalStorage('TableOptions', 'venues') || {};

export const initialVenueOptions = {
  order: 'desc',
  orderBy: 'VenueUID',
  orderIsDate: false,
  page: 0,
  rowsPerPage: tableOptions.rowsPerPage || 50,
};

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: null,
  list: toEntityList([], Venue),
  options: initialVenueOptions,
});

const actionsMap = {
  [VENUE_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: null,
    });
  },
  [VENUE_CREATE_SAVE_ASYNC_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [VENUE_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [VENUE_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: action.data,
    });
  },
  [VENUE_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.venuesPayload) return state;

    const { venues, meta } = action.data.venuesPayload;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(venues, Venue),
      totalCount: meta.totalCount,
    });
  },
  [VENUE_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [VENUE_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [VENUE_UPDATE_OPTIONS]: (state, action) => {
    const { order, orderBy, page, rowsPerPage } = action.data;

    setLocalStorage('TableOptions', 'venues', { rowsPerPage });

    return state.merge({
      options: Object.assign({}, state.options, {
        order,
        orderBy,
        page,
        rowsPerPage,
      }),
    });
  },
};

export default function venue(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
