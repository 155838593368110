import api from 'api';
import { getFromState, getToken, logError } from 'helpers';

export const TIME_ZONE_ASYNC_START = 'TIME_ZONE_ASYNC_START';
export const TIME_ZONE_ASYNC_FAIL = 'TIME_ZONE_ASYNC_FAIL';
export const TIME_ZONE_ASYNC_SUCCESS = 'TIME_ZONE_ASYNC_SUCCESS';
export const TIME_ZONE_LIST_ASYNC_SUCCESS = 'TIME_ZONE_LIST_ASYNC_SUCCESS';

export function timeZoneAsyncStart() {
  return {
    type: TIME_ZONE_ASYNC_START,
  };
}

function timeZoneAsyncFail(errorMessage) {
  return {
    type: TIME_ZONE_ASYNC_FAIL,
    errorMessage,
  };
}

export function timeZoneAsyncSuccess(data) {
  return {
    type: TIME_ZONE_ASYNC_SUCCESS,
    data,
  };
}

export function timeZoneListAsyncSuccess(data) {
  return {
    type: TIME_ZONE_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function listTimeZones() {
  return async (dispatch, getState) => {
    dispatch(timeZoneAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'time-zones',
        method: 'GET',
        accessToken,
      });

      dispatch(timeZoneListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch time zones';

      dispatch(timeZoneAsyncFail(err));
      return null;
    }
  };
}
