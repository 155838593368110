import api from 'api';
import {
  getFromState,
  getToken,
  toJS,
  getNestedProperty,
  logError,
} from 'helpers';

export const EVENT_ANIMAL_ACTION_SUCCESS = 'EVENT_ANIMAL_ACTION_SUCCESS';
export const EVENT_ANIMAL_ACTION_CLEAR = 'EVENT_ANIMAL_ACTION_CLEAR';
export const EVENT_ANIMAL_ASYNC_START = 'EVENT_ANIMAL_ASYNC_START';
export const EVENT_ANIMAL_ASYNC_STOP = 'EVENT_ANIMAL_ASYNC_STOP';
export const EVENT_ANIMAL_ASYNC_FAIL = 'EVENT_ANIMAL_ASYNC_FAIL';
export const EVENT_ANIMAL_ASYNC_SUCCESS = 'EVENT_ANIMAL_ASYNC_SUCCESS';
export const EVENT_ANIMAL_LIST_ASYNC_SUCCESS =
  'EVENT_ANIMAL_LIST_ASYNC_SUCCESS';
export const ADD_PERFORMANCE_TO_ANIMAL_SUCCESS =
  'ADD_PERFORMANCE_TO_ANIMAL_SUCCESS';
export const ADD_PERFORMANCE_TO_ANIMAL_FAIL = 'ADD_PERFORMANCE_TO_ANIMAL_FAIL';
export const REMOVE_PERFORMANCE_FROM_ANIMAL_SUCCESS =
  'REMOVE_PERFORMANCE_FROM_ANIMAL_SUCCESS';
export const REMOVE_PERFORMANCE_FROM_ANIMAL_FAIL =
  'REMOVE_PERFORMANCE_FROM_ANIMAL_FAIL';

function eventAnimalActionSuccess(data) {
  return {
    type: EVENT_ANIMAL_ACTION_SUCCESS,
    data,
  };
}

function eventAnimalActionClear() {
  return {
    type: EVENT_ANIMAL_ACTION_CLEAR,
  };
}

function eventAnimalAsyncStart() {
  return {
    type: EVENT_ANIMAL_ASYNC_START,
  };
}

export function eventAnimalAsyncStop() {
  return {
    type: EVENT_ANIMAL_ASYNC_STOP,
  };
}

export function eventAnimalListAsyncSuccess(data) {
  return {
    type: EVENT_ANIMAL_LIST_ASYNC_SUCCESS,
    data,
  };
}

function eventAnimalAsyncFail(error) {
  return {
    type: EVENT_ANIMAL_ASYNC_FAIL,
    error,
  };
}

// eslint-disable-next-line
function addPerformanceToAnimalSuccess(performance, animal) {
  return {
    type: ADD_PERFORMANCE_TO_ANIMAL_SUCCESS,
    performance,
    animal,
  };
}

// eslint-disable-next-line
function addPerformanceToAnimalFail(error) {
  return {
    type: ADD_PERFORMANCE_TO_ANIMAL_FAIL,
    error,
  };
}

// eslint-disable-next-line
function removePerformanceFromAnimalSuccess(performance, animal) {
  return {
    type: REMOVE_PERFORMANCE_FROM_ANIMAL_SUCCESS,
    performance,
    animal,
  };
}

// eslint-disable-next-line
function removePerformanceFromAnimalFail(error) {
  return {
    type: REMOVE_PERFORMANCE_FROM_ANIMAL_FAIL,
    error,
  };
}

export function listEventAnimals(values) {
  return async (dispatch, getState) => {
    dispatch(eventAnimalAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { EventUID } = values;
      let { CompTypes } = values;
      CompTypes = CompTypes ? JSON.stringify(CompTypes) : null;

      const path =
        values && values.query
          ? `event-animals?EventUID=${EventUID}&CompTypes=${CompTypes}&q=${
              values.query
            }`
          : `event-animals?EventUID=${EventUID}&CompTypes=${CompTypes}`;
      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(eventAnimalListAsyncSuccess(response.data));
      return response.data;
    } catch (error) {
      logError(error);
      dispatch(eventAnimalAsyncFail(error.message));
      return null;
    }
  };
}

export function addEventAnimal(animals) {
  return async (dispatch, getState) => {
    const state = getState();
    const event = getFromState(getState, 'event');
    const animalList = toJS(getNestedProperty('animal.list', state, []));
    const discipline = getFromState(getState, 'discipline');
    const animalsFound = animalList.filter((animal) => {
      return animals.includes(animal.AnimalUID);
    });

    try {
      dispatch(eventAnimalAsyncStart());

      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const response = await api({
        path: `event-animals?EventUID=${event.id}&CompTypes=[${discipline.id}]`,
        method: 'POST',
        body: animalsFound,
        accessToken,
      });

      dispatch(eventAnimalListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAnimalAsyncFail(error.message));
      return null;
    }
  };
}

export function addEventAnimalV2(animalsPerformances) {
  return async (dispatch, getState) => {
    const state = getState();
    const animals = Object.keys(animalsPerformances).flatMap((animal) => {
      if (animalsPerformances[animal].length > 0) {
        return [+animal];
      }
      return [];
    });
    const event = getFromState(getState, 'event');
    const animalList = toJS(getNestedProperty('animal.list', state, []));
    const discipline = getFromState(getState, 'discipline');
    const animalsFound = animalList.filter((animal) => {
      return animals.includes(animal.AnimalUID);
    });
    const animalFoundPerformances = animalsFound.map((animal) => {
      const performances = animalsPerformances[animal.AnimalUID];
      return {
        ...animal,
        performances: performances.map(({ value, additionalInfo }) => ({
          PerformanceUID: value,
          ExtraStock: additionalInfo.extraStock,
        })),
      };
    });

    try {
      dispatch(eventAnimalAsyncStart());
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const response = await api({
        path: `event-animals?EventUID=${event.id}&CompTypes=[${discipline.id}]`,
        method: 'POST',
        body: animalFoundPerformances,
        accessToken,
      });
      dispatch(eventAnimalListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAnimalAsyncFail(error.message));
      return null;
    }
  };
}

export function removeEventAnimal(animals) {
  return async (dispatch, getState) => {
    const state = getState();
    const event = getFromState(getState, 'event');
    const animalList = toJS(getNestedProperty('animal.list', state, []));

    const discipline = getFromState(getState, 'discipline');
    const animalsFound = animalList.filter((animal) => {
      return animals.includes(animal.AnimalUID);
    });

    try {
      dispatch(eventAnimalAsyncStart());

      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `event-animals?EventUID=${event.id}&CompTypes=[${discipline.id}]`,
        method: 'DELETE',
        body: animalsFound,
        accessToken,
      });

      dispatch(eventAnimalListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAnimalAsyncFail(error.message));
      return null;
    }
  };
}

export function selectEventAnimal(eventAnimal) {
  return (dispatch) => {
    dispatch(eventAnimalActionSuccess(eventAnimal));
  };
}

export function clearEventAnimal() {
  return (dispatch) => {
    dispatch(eventAnimalActionClear());
  };
}

export function addPerformanceToAnimal(performance, animal) {
  return async (dispatch, getState) => {
    try {
      dispatch(eventAnimalAsyncStart());
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const discipline = getFromState(getState, 'discipline');
      const event = getFromState(getState, 'event');

      const response = await api({
        path: `event-animals?EventUID=${event.id}&CompTypes=[${
          discipline.id
        }]&PerformanceUID=${performance.PerformanceUID}&EAUID=${animal.EAUID}`,
        method: 'PATCH',
        accessToken,
      });

      dispatch(eventAnimalListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAnimalAsyncFail(error.message));
      return null;
    }
  };
}

export function addPerformanceToAnimalV2(EAUID, performancesData) {
  return async (dispatch, getState) => {
    try {
      dispatch(eventAnimalAsyncStart());
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const performances = performancesData.map(
        ({ value, additionalInfo }) => ({
          PerformanceUID: value,
          ExtraStock: additionalInfo.extraStock,
        }),
      );
      const discipline = getFromState(getState, 'discipline');
      const event = getFromState(getState, 'event');

      const response = await api({
        path: `event-animals/update-bulk`,
        method: 'PATCH',
        body: {
          performances,
          EAUID,
          EventUID: event.id,
          CompTypes: [discipline.id],
        },
        accessToken,
      });

      dispatch(eventAnimalListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAnimalAsyncFail(error.message));
      return null;
    }
  };
}

export function removePerformanceFromAnimalV2(EAUID, performances) {
  return async (dispatch, getState) => {
    try {
      dispatch(eventAnimalAsyncStart());
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const discipline = getFromState(getState, 'discipline');
      const event = getFromState(getState, 'event');
      const response = await api({
        path: `event-animals/delete-bulk-performance`,
        method: 'DELETE',
        accessToken,
        body: {
          performances,
          EAUID,
          EventUID: event.id,
          CompTypes: [discipline.id],
        },
      });

      dispatch(eventAnimalListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAnimalAsyncFail(error.message));
      return null;
    }
  };
}

export function removePerformanceFromAnimal(performance, animal) {
  return async (dispatch, getState) => {
    try {
      dispatch(eventAnimalAsyncStart());
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const discipline = getFromState(getState, 'discipline');
      const event = getFromState(getState, 'event');

      const response = await api({
        path: `event-animals/delete-performance?EventUID=${
          event.id
        }&CompTypes=[${discipline.id}]&PerformanceUID=${
          performance.PerformanceUID
        }&EAUID=${animal.EAUID}`,
        method: 'DELETE',
        accessToken,
      });

      dispatch(eventAnimalListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAnimalAsyncFail(error.message));
      return null;
    }
  };
}
