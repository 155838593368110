import { Record } from 'immutable';

const defaultValues = {
  VenueUID: null,
  Indoor: null,
  ContactName: null,
  City: null,
  State: null,
  Zip: null,
  FName: null,
  LName: null,
  Email: null,
  Phone1: null,
  Phone2: null,
  Street: null,
  VenueManager: null,
};

export default class Venue extends Record(defaultValues, 'Venue') {
  constructor(values) {
    const { FName, LName } = values;
    super({
      ...values,
      VenueManager: `${FName} ${LName}`,
    });
  }
}
