import { Record, Map } from 'immutable';
import { toEntityList } from 'models/BaseList';

const defaultValues = {
  loading: false,
  errorMessage: null,
  data: Record(),
  apiList: toEntityList([], Record),
  list: toEntityList([], Record),
  options: new Map(),
  childOptions: new Map(),
  selectedEventDisciplines: toEntityList([], Record),
  totalCount: 0,
  index: null,
};

export default class Base extends Record(defaultValues, 'Base') {
  constructor(values) {
    super({
      ...values,
      options:
        values && values.options ? values.options : defaultValues.options,
      childOptions:
        values && values.childOptions
          ? values.childOptions
          : defaultValues.childOptions,
      selectedEventDisciplines:
        values && values.selectedEventDisciplines
          ? values.selectedEventDisciplines
          : defaultValues.selectedEventDisciplines,
    });
  }
}
