import { Record } from 'immutable';

const defaultValues = {
  id: null,
  name: null,
  SortOrder: null,
  Short: null,
};

export default class AnimalType extends Record(defaultValues, 'AnimalType') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
