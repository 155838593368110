export const UPDATE_FILTER = 'UPDATE_FILTER';
export const LOAD_FILTER = 'LOAD_FILTER';

export function updateFilter(data) {
  return {
    type: UPDATE_FILTER,
    data,
  };
}

export function loadFilters(data) {
  return {
    type: LOAD_FILTER,
    data,
  };
}
