import moment from 'moment';
import api from 'api';
import client from 'api/apollo';
import { postToastMessage } from 'helpers/postToastMessage';
import { EDIT_ENTRY_PARTNER } from 'graphql/mutations/entryPool/editEntryPartner';
import { EDIT_ATHLETE_ENTRY } from 'graphql/mutations/entryPool/editAthleteEntry';
import { GET_PERFORMANCE_DRAW } from 'graphql/queries/entryPool/getPerformanceDraw';
import { GET_ENTRY_POOL } from 'graphql/queries/entryPool/getEntryPool';
import { GET_ENTRY_POOL_UNMATCHED_PARTNERS } from 'graphql/queries/entryPool/getEntryPoolUnmatchedPartners';

import {
  filterToRequest,
  getFilters,
  getFromState,
  getKeyFromState,
  getNestedProperty,
  getToken,
  toJS,
  logError,
} from 'helpers';
import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';
import { MASKED_SNN } from 'constants/user';

export const ENTRYPOOL_ACTION_SUCCESS = 'ENTRYPOOL_ACTION_SUCCESS';
export const ENTRYPOOL_ASYNC_START = 'ENTRYPOOL_ASYNC_START';
export const ENTRYPOOL_ASYNC_STOP = 'ENTRYPOOL_ASYNC_STOP';
export const ENTRYPOOL_ASYNC_FAIL = 'ENTRYPOOL_ASYNC_FAIL';
export const ENTRYPOOL_ASYNC_SUCCESS = 'ENTRYPOOL_ASYNC_SUCCESS';
export const ENTRYPOOL_LIST_ASYNC_SUCCESS = 'ENTRYPOOL_LIST_ASYNC_SUCCESS';
export const ENTRYPOOL_PAGINATED_LIST_ASYNC_SUCCESS =
  'ENTRYPOOL_PAGINATED_LIST_ASYNC_SUCCESS';
export const ENTRYPOOL_APPLY_LIST_OPTIONS = 'ENTRYPOOL_APPLY_LIST_OPTIONS';
export const ENTRYPOOL_REMOVE_SUCCESS = 'ENTRYPOOL_REMOVE_SUCCESS';
export const ENTRYPOOL_REFUND_SUCCESS = 'ENTRYPOOL_REFUND_SUCCESS';
export const ENTRYPOOL_UPDATE = 'ENTRYPOOL_UPDATE';
export const ENTRYPOOL_SAVE_PARTNER = 'ENTRYPOOL_SAVE_PARTNER';

function entryPoolActionSuccess(data) {
  return {
    type: ENTRYPOOL_ACTION_SUCCESS,
    data,
  };
}

export function entryPoolAsyncStart() {
  return {
    type: ENTRYPOOL_ASYNC_START,
  };
}

export function entryPoolAsyncStop() {
  return {
    type: ENTRYPOOL_ASYNC_STOP,
  };
}

function entryPoolAsyncSuccess(data) {
  return {
    type: ENTRYPOOL_ASYNC_SUCCESS,
    data,
  };
}

function entryPoolRefundAsyncSuccess() {
  return {
    type: ENTRYPOOL_REFUND_SUCCESS,
  };
}

function entryPoolRemoveAsyncStop() {
  return {
    type: ENTRYPOOL_REMOVE_SUCCESS,
  };
}

export function entryPoolListAsyncSuccess(data) {
  return {
    type: ENTRYPOOL_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function entryPoolPaginatedListAsyncSuccess(data) {
  return {
    type: ENTRYPOOL_PAGINATED_LIST_ASYNC_SUCCESS,
    data,
  };
}

function entryPoolAsyncFail(error) {
  return {
    type: ENTRYPOOL_ASYNC_FAIL,
    error,
  };
}

export function applyConfig(data) {
  return {
    type: ENTRYPOOL_APPLY_LIST_OPTIONS,
    data,
  };
}

export function entryPoolUpdate(data) {
  return {
    type: ENTRYPOOL_UPDATE,
    data,
  };
}

export function listPerformanceDraw({ EventUID }) {
  return async (dispatch) => {
    dispatch(entryPoolAsyncStart());
    try {
      const variables = {
        input: { EventUID },
      };
      const entryPoolQuery = {
        query: GET_PERFORMANCE_DRAW,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(entryPoolQuery);
      const performanceDraw = response.data.performanceDrawGet;

      dispatch(entryPoolListAsyncSuccess(performanceDraw));
      return performanceDraw;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail(error.message));
      return null;
    }
  };
}

export function getEntryPoolItem(EPUID) {
  return async (dispatch) => {
    dispatch(entryPoolAsyncStart());
    try {
      const variables = {
        input: { EPUID },
      };
      const entryPoolQuery = {
        query: GET_ENTRY_POOL,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(entryPoolQuery);
      const entryPool = response.data.entryPoolGet;
      dispatch(entryPoolAsyncSuccess(entryPool));

      return entryPool;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail(error.message));
      return null;
    }
  };
}

export function getUnmatchedPartners({ EPUID, EventUID, disciplineId }) {
  return async (dispatch) => {
    dispatch(entryPoolAsyncStart());
    try {
      const variables = {
        input: { EPUID, EventUID, disciplineId },
      };
      const partnersQuery = {
        query: GET_ENTRY_POOL_UNMATCHED_PARTNERS,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(partnersQuery);
      const unmatchedPartners = response.data.entryPoolGetUnmatchedPartners;
      dispatch(entryPoolAsyncSuccess(unmatchedPartners));

      return unmatchedPartners;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail(error.message));
      return null;
    }
  };
}

export function listPaginatedEntryPool(values) {
  return async (dispatch, getState) => {
    dispatch(entryPoolAsyncStart());
    try {
      const state = getState();
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const {
        EventUID,
        pageNumber,
        sortDirection,
        orderBy,
        resultsPerPage,
      } = values;
      const filters = getFilters(values, state);
      const path = `entry-pool/paginated?filters=${encodeURIComponent(
        filterToRequest(filters),
      )}&EventUID=${EventUID}&pageNumber=${pageNumber}&sortDirection=${sortDirection}&orderBy=${orderBy}&resultsPerPage=${resultsPerPage}`;

      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });
      dispatch(entryPoolPaginatedListAsyncSuccess(response.data));
      return response.data;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail(error.message));
      return null;
    }
  };
}

export function saveEntryPoolPreference(entry, PerformanceUID, position) {
  return async (dispatch, getState) => {
    const state = getState();
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);
    const entriesPoolList = toJS(
      getNestedProperty('entryPool.list', state, []),
    );

    const preferenceIndex = entry.preferences.findIndex((preference) => {
      return preference.PerformanceUID === PerformanceUID;
    });

    if (preferenceIndex !== -1) {
      entry.preferences[preferenceIndex].Position = position;
    }

    const updatedEntryPoolList = entriesPoolList.map((ent) => {
      const shouldBeUpdated =
        ent.EPUID === entry.EPUID || ent.EPUID === entry.PartnerEPUID;
      return shouldBeUpdated
        ? {
            ...ent,
            preferences: entry.preferences,
            updatedAt: moment.utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          }
        : ent;
    });

    dispatch(entryPoolListAsyncSuccess(updatedEntryPoolList));

    try {
      const response = await api({
        path: `entry-pool/${entry.EPUID}`,
        method: 'PATCH',
        body: entry,
        accessToken,
      });

      return response;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail(error.message));
      return null;
    }
  };
}

export function resetSelected(listOfEPUID) {
  return async (dispatch, getState) => {
    dispatch(entryPoolAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const { id } = getFromState(getState, 'event');

      const response = await api({
        path: `entry-pool/${id}/reset-all`,
        method: 'DELETE',
        body: listOfEPUID,
        accessToken,
      });

      dispatch(listPerformanceDraw({ EventUID: id }));

      return response;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail(error.message));
      return null;
    }
  };
}

export function selectEntryPool(entryPool) {
  return (dispatch) => {
    dispatch(entryPoolActionSuccess(entryPool));
  };
}

export function saveEntry(values) {
  return async (dispatch, getState) => {
    const { errorMessage } = getKeyFromState(getState, 'entryPool');
    if (errorMessage) return null;
    dispatch(entryPoolAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      if (!values.SSN || values.SSN.startsWith(MASKED_SNN)) {
        delete values.SSN;
      }

      const response = await api({
        path: 'entry-pool/save-entry',
        method: 'POST',
        body: values,
        accessToken,
      });

      await dispatch(
        createAlertSuccess({
          message: '✓ Entry Successfully Saved',
          type: 'success',
        }),
      );

      dispatch(clearAlertSuccessAsync());

      dispatch(entryPoolAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail('Unable to Save Entry'));
      return null;
    }
  };
}

export function saveUnregisteredAthleteEntry(values) {
  return async (dispatch, getState) => {
    const { errorMessage } = getKeyFromState(getState, 'entryPool');
    if (errorMessage) return null;
    dispatch(entryPoolAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      values = Object.assign({}, values, {
        SSN: values && values.SSN === MASKED_SNN ? null : values.SSN,
      });

      const response = await api({
        path: 'entry-pool/save-unregistered-athlete-entry',
        method: 'POST',
        body: values,
        accessToken,
      });

      await dispatch(
        createAlertSuccess({
          message: '✓ Entry Successfully Saved',
          type: 'success',
        }),
      );

      dispatch(clearAlertSuccessAsync());

      dispatch(entryPoolAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail('Unable to Save Entry'));
      return null;
    }
  };
}

export function payForUnmatchedPartner(values) {
  return async (dispatch, getState) => {
    const { errorMessage } = getKeyFromState(getState, 'entryPool');
    if (errorMessage) return null;
    dispatch(entryPoolAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      if (values.SSN === MASKED_SNN) {
        delete values.SSN;
      }

      const response = await api({
        path: 'payment/pay-for-entry',
        method: 'POST',
        body: values,
        accessToken,
      });

      dispatch(entryPoolAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail('Unable to pay for entry'));
      return null;
    }
  };
}

export function saveEntryAthleteInfo(values) {
  return async (dispatch) => {
    dispatch(entryPoolAsyncStart());
    try {
      const variables = {
        input: values,
      };

      const editAthletePayload = {
        mutation: EDIT_ATHLETE_ENTRY,
        variables,
      };

      const { data, error } = await client.mutate(editAthletePayload);

      if (error) {
        throw new Error(error);
      }

      dispatch(entryPoolAsyncStop());

      return data;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail(error.message));
      return null;
    }
  };
}

export function editEntryPartner(values) {
  return async (dispatch) => {
    dispatch(entryPoolAsyncStart());
    try {
      const variables = {
        input: values,
      };
      const editPartnerEntryMutation = {
        mutation: EDIT_ENTRY_PARTNER,
        variables,
      };
      const { data, error } = await client.mutate(editPartnerEntryMutation);
      if (error) {
        throw new Error(error);
      }

      await dispatch(entryPoolAsyncStop());
      return data;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail('Unable to save new partner'));
      return null;
    }
  };
}

export function removeEntry(entry, values) {
  return async (dispatch, getState) => {
    dispatch(entryPoolAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `entry-pool/${entry.EPUID}`,
        method: 'DELETE',
        accessToken,
        body: values,
      });

      await dispatch(entryPoolRemoveAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      await dispatch(entryPoolAsyncFail(''));
      await dispatch(postToastMessage('Error deleting entry.', 'fail'));
      return null;
    }
  };
}

export function refundEntry(entry, values) {
  return async (dispatch, getState) => {
    dispatch(entryPoolAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `entry-pool/refund/${entry.EntryChargeRequestUID}`,
        method: 'POST',
        accessToken,
        body: values,
      });

      await dispatch(entryPoolRefundAsyncSuccess());

      await dispatch(
        createAlertSuccess({
          message:
            'The refund workflow is complete and the refund will be applied within 5-10 days.',
          type: 'success',
        }),
      );
      await dispatch(clearAlertSuccessAsync());

      return response;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail(error.message));

      await dispatch(
        createAlertSuccess({
          message: 'Error on refund your nomination.',
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());

      return null;
    }
  };
}

export function calculateEntryFee(initialValues) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const {
        FullName,
        EventUID,
        ERAUID,
        CompType,
        PaymentMethod,
      } = initialValues;

      const entryFeePayload = {
        athleteFullName: FullName,
        EventUID: EventUID,
        EntryChargeRequestUID: null,
        ERAUID: ERAUID,
        paymentType: PaymentMethod,
        Disciplines: [
          {
            Out1: null,
            Out2: null,
            OutIfPrefNotMet: false,
            Pref1: null,
            Pref2: null,
            Pref3: null,
            id: parseInt(CompType, 10),
            isPayingForPartner: false,
            partner: null,
          },
        ],
      };

      const response = await api({
        path: 'event/entries/calculate-charges',
        method: 'POST',
        body: entryFeePayload,
        accessToken,
      });

      return response;
    } catch (error) {
      logError(error);
      dispatch(entryPoolAsyncFail('Unable to calculate entry cost'));
      return null;
    }
  };
}
