import Auth from 'models/Auth';
import Base from 'models/Base';

import {
  AUTH_ASYNC_START,
  AUTH_ASYNC_FAIL,
  AUTH_ASYNC_SUCCESS,
  DE_AUTH,
  AUTH_ACTION_FINISH,
} from 'actions/auth';

import { REHYDRATE } from 'redux-persist/lib/constants';

const initialState = new Base({
  loading: false,
  errorMessage: null,
  data: new Auth(),
});

const actionsMap = {
  [AUTH_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: null,
    });
  },
  [AUTH_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Auth({
        authenticated: true,
        authPayload: action.data,
      }),
    });
  },
  [AUTH_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
      data: new Auth(),
    });
  },
  [DE_AUTH]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Auth(),
    });
  },
  [AUTH_ACTION_FINISH]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [REHYDRATE]: (state, action) => {
    const payload = action.payload;
    const authPayload = payload ? payload.auth : null;
    if (authPayload) {
      return new Base({
        loading: false,
        errorMessage: payload.auth.errorMessage || null,
        data: new Auth(authPayload.data),
      });
    }
    return new Base({
      loading: false,
      errorMessage: null,
      data: new Auth(),
    });
  },
};

export default function auth(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
