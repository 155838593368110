import { Record } from 'immutable';

const defaultValues = {
  AnimalUID: null,
  Brand: null,
  NickName: null,
  AnimalType: null,
  AnimalTypeId: null,
  ContractorUID: null,
  DisciplineNames: null,
  ERAUID: null,
  OwnerName: null,
  ContractorName: null,
};

export default class Livestock extends Record(defaultValues, 'Livestock') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
