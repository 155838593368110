import { Record } from 'immutable';

const defaultValues = {
  id: null,
  CouponUID: null,
  CouponCode: null,
  CouponType: null,
  Description: null,
  DollarDisc: null,
  NomDisc: null,
  FeeDisc: null,
  StartDate: null,
  ExpireDate: null,
  ERAUID: null,
  EventUID: null,
  createdAt: null,
  updatedAt: null,
  MaxUsageNumberPerContestant: null,
  name: '',
  useCount: null,
  spent: null,
  discounted: null,
  EventName: null,
  ContestantFullName: null,
  ContestantPhoto: null,
  SegmentUID: null,
  AssociationUID: null,
  SegmentShortName: null,
  selectedDisciplines: null,
};

export default class Coupon extends Record(defaultValues, 'Coupon') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
