// good event has class and bad event has nominations
export const KEY_CLASS_NOMINATIONS_FORM = 'classNominationForm';
// good event has class and bad event has nominations
export const KEY_CLASS_NOMINATION_REVIEW = 'classNominationReview';
// good event has class and bad event has entries
export const KEY_CLASS_ENTRIES_FORM = 'classEntriesForm';
// good event no has class and bad has class event has nominations
export const KEY_NO_CLASS_NOMINATIONS_FORM = 'noClassNominationForm';
// good event no has class and bad has class event has nominations
export const KEY_NO_CLASS_NOMINATION_REVIEW = 'noClassNominationReview';
// good event has class and bad event has entries
export const KEY_CLASS_ENTRIES_REVIEW = 'classEntriesReview';
// good event no has class and bad event has class and entries
export const KEY_NO_CLASS_ENTRIES_REVIEW = 'noClassEntriesReview';
// bad Event no has nominations and no has entries
export const KEY_NO_NOMINATIONS_NO_ENTRIES = 'noNominationsNoEntries';

export const FORM_MODAL_MERGE_EVENTS_NOMINATIONS =
  'FORM_MODAL_MERGE_EVENTS_NOMINATIONS';

export const FORM_MODAL_MERGE_EVENTS_ENTRIES =
  'FORM_MODAL_MERGE_EVENTS_ENTRIES';

export const mergeEventsSteps = [
  {
    key: KEY_CLASS_NOMINATIONS_FORM,
    condition: (eventInfo) =>
      eventInfo.goodEventAllowsClasses &&
      eventInfo.badEventHasNominations &&
      !eventInfo.badEventAllowsClasses,
  },
  {
    key: KEY_CLASS_NOMINATION_REVIEW,
    condition: (eventInfo) =>
      eventInfo.goodEventAllowsClasses &&
      eventInfo.badEventHasNominations &&
      !eventInfo.badEventAllowsClasses,
  },
  {
    key: KEY_CLASS_ENTRIES_FORM,
    condition: (eventInfo) =>
      eventInfo.goodEventAllowsClasses &&
      eventInfo.badEventHasEntries &&
      !eventInfo.badEventAllowsClasses,
  },
  {
    key: KEY_CLASS_ENTRIES_REVIEW,
    condition: (eventInfo) =>
      eventInfo.goodEventAllowsClasses &&
      eventInfo.badEventHasEntries &&
      !eventInfo.badEventAllowsClasses,
  },
  {
    key: KEY_NO_CLASS_NOMINATIONS_FORM,
    condition: (eventInfo) =>
      !eventInfo.goodEventAllowsClasses &&
      eventInfo.badEventHasNominations &&
      eventInfo.badEventAllowsClasses,
  },
  {
    key: KEY_NO_CLASS_NOMINATION_REVIEW,
    condition: (eventInfo) =>
      !eventInfo.goodEventAllowsClasses &&
      eventInfo.badEventHasNominations &&
      eventInfo.badEventAllowsClasses,
  },
  {
    key: KEY_NO_CLASS_ENTRIES_REVIEW,
    condition: (eventInfo) =>
      !eventInfo.goodEventAllowsClasses &&
      eventInfo.badEventHasEntries &&
      eventInfo.badEventAllowsClasses,
  },
  {
    key: KEY_NO_NOMINATIONS_NO_ENTRIES,
    condition: (eventInfo) =>
      !eventInfo.badEventHasNominations && !eventInfo.badEventHasEntries,
  },
];
