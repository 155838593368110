import { List } from 'immutable';
import Base from 'models/Base';

import {
  GIFTCARDACCOUNT_LIST_ASYNC_START,
  GIFTCARDACCOUNT_LIST_ASYNC_SUCCESS,
  GIFTCARDACCOUNT_LIST_ASYNC_FAIL,
} from 'actions/giftcardAccount';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  list: List(),
});

const actionsMap = {
  [GIFTCARDACCOUNT_LIST_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [GIFTCARDACCOUNT_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data) return state;
    return state.merge({
      loading: false,
      errorMessage: '',
      list: action.data,
    });
  },
  [GIFTCARDACCOUNT_LIST_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
};

export default function giftcardAccount(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
