import api from 'api';
import client from 'api/apollo';
import {
  getFromState,
  getToken,
  getFilters,
  filterToRequest,
  logError,
} from 'helpers';
import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';

import { LIST_VENUES } from 'graphql/queries/venues/listVenues';

export const VENUE_ASYNC_START = 'VENUE_ASYNC_START';
export const VENUE_ACTION_CLEAR = 'VENUE_ACTION_CLEAR';
export const VENUE_ASYNC_FAIL = 'VENUE_ASYNC_FAIL';
export const VENUE_ASYNC_SUCCESS = 'VENUE_ASYNC_SUCCESS';
export const VENUE_LIST_ASYNC_SUCCESS = 'VENUE_LIST_ASYNC_SUCCESS';
export const VENUE_ASYNC_CLEAR_ERROR = 'VENUE_ASYNC_CLEAR_ERROR';
export const VENUE_UPDATE_OPTIONS = 'VENUE_UPDATE_OPTIONS';
export const VENUE_CREATE_SAVE_ASYNC_SUCCESS =
  'VENUE_CREATE_SAVE_ASYNC_SUCCESS';

export function venueAsyncStart() {
  return {
    type: VENUE_ASYNC_START,
  };
}

export function venueActionClear() {
  return {
    type: VENUE_ACTION_CLEAR,
  };
}

function venueAsyncFail(errorMessage) {
  return {
    type: VENUE_ASYNC_FAIL,
    errorMessage,
  };
}

function venueCreateOrSaveAsyncSuccess() {
  return {
    type: VENUE_CREATE_SAVE_ASYNC_SUCCESS,
  };
}

export function venueAsyncSuccess(data) {
  return {
    type: VENUE_ASYNC_SUCCESS,
    data,
  };
}

export function venueListAsyncSuccess(data) {
  return {
    type: VENUE_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function venueAsyncClearError() {
  return {
    type: VENUE_ASYNC_CLEAR_ERROR,
  };
}

export function listVenues(values = {}) {
  return async (dispatch, getState) => {
    dispatch(venueAsyncStart());
    try {
      const state = getState();
      const filters = getFilters(values, state);
      const { pageNumber, sortDirection, orderBy, resultsPerPage } = values;

      const variables = {
        input: {
          filters: filterToRequest(filters),
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
        },
      };

      const venueQuery = {
        query: LIST_VENUES,
        variables,
        fetchPolicy: 'network-only',
      };

      const response = await client.query(venueQuery);

      dispatch(venueListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(venueAsyncFail(error.message));
      return null;
    }
  };
}

export function createVenue(venue) {
  return async (dispatch, getState) => {
    dispatch(venueAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'venues',
        method: 'POST',
        accessToken,
        body: venue,
      });

      await dispatch(venueCreateOrSaveAsyncSuccess());
      await dispatch(
        createAlertSuccess({
          message: 'Venue successfully added',
          type: 'success',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      setTimeout(() => dispatch(listVenues()));
      return response;
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Error on add your venue.',
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      dispatch(venueAsyncFail(error.message));
      return null;
    }
  };
}

export function saveVenue(venue) {
  return async (dispatch, getState) => {
    dispatch(venueAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `venues/${venue.VenueUID}`,
        method: 'PATCH',
        accessToken,
        body: venue,
      });

      await dispatch(venueCreateOrSaveAsyncSuccess());
      await dispatch(
        createAlertSuccess({
          message: 'Venue successfully updated',
          type: 'success',
        }),
      );
      setTimeout(() => dispatch(listVenues()));
      await dispatch(clearAlertSuccessAsync());
      return response;
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Error on save your venue.',
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      dispatch(venueAsyncFail(error.message));
      return null;
    }
  };
}

export function selectVenue(venue) {
  return (dispatch) => {
    dispatch(venueAsyncSuccess(venue));
  };
}

export function clearVenue() {
  return (dispatch) => {
    dispatch(venueActionClear());
  };
}

export function updateVenueOptions(orderBy, order, page, rowsPerPage) {
  return {
    type: VENUE_UPDATE_OPTIONS,
    data: { orderBy, order, page, rowsPerPage },
  };
}
