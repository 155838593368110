import gql from 'graphql-tag';

export const LIST_CONTRACTORS = gql`
  query LIST_CONTRACTORS($input: ContractorsInput!) {
    contractorsPayload(input: $input) {
      contractors {
        id
        ContractorUID
        ContractorType
        DBA
        DBA_SHORT
        ShortName
        Rank
        Contact {
          ContactName
          FirstName
          LastName
          Email
          Phone1
          Phone2
        }
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
