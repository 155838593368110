import Discipline from 'models/Discipline';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { getDefaultDiscipline } from 'helpers';

import {
  DISCIPLINE_ASYNC_START,
  DISCIPLINE_ACTION_CLEAR,
  DISCIPLINE_ASYNC_FAIL,
  DISCIPLINE_ASYNC_SUCCESS,
  DISCIPLINE_LIST_ASYNC_SUCCESS,
  DISCIPLINE_ASYNC_CLEAR_ERROR,
} from 'actions/discipline';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Discipline(),
  list: toEntityList([], Discipline),
});

const actionsMap = {
  [DISCIPLINE_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [DISCIPLINE_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [DISCIPLINE_ASYNC_SUCCESS]: (state, action) => {
    const discipline = action.data;
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Discipline(discipline),
    });
  },
  [DISCIPLINE_LIST_ASYNC_SUCCESS]: (state, action) => {
    const { data: disciplines } = action;
    const targetDiscipline = getDefaultDiscipline(disciplines);
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(disciplines, Discipline),
      data: new Discipline(targetDiscipline),
    });
  },
  [DISCIPLINE_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [DISCIPLINE_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
};

export default function discipline(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
