// @flow
import { Record } from 'immutable';

export type LogType = {
  id: number,
  action: string,
  description: string,
  email: String,
  EventUID: number,
  userId: string,
  payloadJSON: any,
  createdAt: string,
  updatedAt: string,
  giftCardPurchaseUID: string,
};

const defaultValues = {
  id: 0,
  action: '',
  description: '',
  email: '',
  EventUID: 0,
  userId: '',
  payloadJSON: null,
  createdAt: '',
  updatedAt: '',
  giftCardPurchaseUID: '',
};

export default class Log extends Record(defaultValues, 'log') {
  constructor(values: ?LogType) {
    super({
      ...values,
    });
  }
}
