export const ATHLETE = 'athlete';
export const YOUTH = 'youth';

export const leaderboardTypes = [
  {
    id: ATHLETE,
    name: 'Open',
  },
  {
    id: YOUTH,
    name: 'Youth',
  },
];
