import Nomination from 'models/Nomination';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { setLocalStorage, getLocalStorage } from 'helpers/localStorage';

import {
  NOMINATION_ASYNC_START,
  NOMINATION_ASYNC_STOP,
  NOMINATION_ACTION_CLEAR,
  NOMINATION_ASYNC_FAIL,
  NOMINATION_ASYNC_SUCCESS,
  NOMINATION_LIST_ASYNC_SUCCESS,
  NOMINATIONS_PER_EVENT_LIST_ASYNC_SUCCESS,
  NOMINATION_ASYNC_CLEAR_ERROR,
  NOM_APPLY_LIST_OPTIONS,
  NOMINATION_REMOVE_SUCCESS,
  NOMINATION_UPDATE_OPTIONS,
  NOMINATIONS_SELECTED,
} from 'actions/nomination';

const tableOptions = getLocalStorage('TableOptions', 'nominations') || {};

export const initialNominationOptions = {
  order: 'desc',
  orderBy: 'NomUID',
  orderIsDate: false,
  page: 0,
  rowsPerPage: tableOptions.rowsPerPage || 50,
};

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Nomination(),
  list: toEntityList([], Nomination),
  options: initialNominationOptions,
  selectedNominationIds: [],
});

const actionsMap = {
  // Datatable options
  [NOM_APPLY_LIST_OPTIONS]: (state, action) => {
    const newOptions = action.data;
    const baseOptions =
      state.get && state.get('options') ? state.get('options').toJS() : {};
    const options = Object.assign({}, baseOptions, newOptions);
    return state.merge({
      options,
    });
  },
  [NOMINATION_REMOVE_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
    });
  },
  [NOMINATION_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [NOMINATION_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [NOMINATION_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
    });
  },
  [NOMINATION_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Nomination(action.data),
    });
  },
  [NOMINATION_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.nominationsPayload) return state;

    const { nominations, meta } = action.data.nominationsPayload;

    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(nominations, Nomination),
      totalCount: meta.totalCount,
    });
  },
  [NOMINATIONS_PER_EVENT_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.nominationsPerEventPayload) return state;

    const { nominations, meta } = action.data.nominationsPerEventPayload;

    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(nominations, Nomination),
      totalCount: meta.totalCount,
    });
  },
  [NOMINATION_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [NOMINATION_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [NOMINATION_UPDATE_OPTIONS]: (state, action) => {
    const { order, orderBy, page, rowsPerPage } = action.data;

    setLocalStorage('TableOptions', 'nominations', { rowsPerPage });

    return state.merge({
      options: Object.assign({}, state.options, {
        order,
        orderBy,
        page,
        rowsPerPage,
      }),
    });
  },
  [NOMINATIONS_SELECTED]: (state, action) => {
    return state.merge({
      list: action.data.nominations,
    });
  },
};

export default function nomination(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
