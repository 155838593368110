import { merge } from 'lodash';
import { getNestedProperty } from 'helpers';
import { ENTRYPOOL_OPEN_PARTNER_MODAL } from 'actions/modals';

const initialState = {
  isAddPartnerModalOpen: false,
};

const actionsMap = {
  [ENTRYPOOL_OPEN_PARTNER_MODAL]: (state, action) => {
    const isOpen = getNestedProperty('open', action, false);

    return merge({}, state, { isAddPartnerModalOpen: isOpen });
  },
};

export default function modals(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
