/**
 * Will receive filters and return only the data needed by backend
 *
 * @param {*} filters
 * Example return:
 * [
 *   {
 *     "columnName": "firstName",
 *     "value": "marty",
 *     "typeSearch": "like",
 *     "columnsToSearch": [
 *       "firstName"
 *     ]
 *   }
 * ]
 */
export const formatFilters = (filters) => {
  const notEmptyFilters = Object.values(filters).filter((filter) => {
    if (filter && filter.value && typeof filter.value === 'string') {
      return filter.value.trim() !== '';
    } else if (filter && filter.value && typeof filter.value === 'number') {
      return filter.value !== '';
    }

    return false;
  });

  return notEmptyFilters.map(
    ({ columnName, value, typeSearch, columnsToSearch }) => {
      return {
        columnName,
        value,
        typeSearch,
        columnsToSearch,
      };
    },
  );
};
