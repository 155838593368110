function Parser(fileString) {
  /**  @type {*|string[]} @private */
  let errors_ = [];

  /** @type {*|string[]} @private */
  let fileLines_ = [];

  /**
   * @description strips out lines that do not contain scores
   * @return {string}
   */
  this.start = () => {
    const lines = fileString.split('\n');
    lines.forEach((line) => {
      // Omit lines that are just page info
      const pageRegex = /\s*Page \d+/g;

      // Omit lines that repeat header info
      const headerRegex = /\s*International Professional Rodeo Association\s*/g;

      const rodeoResultsLineRegex = /\s*Rodeo Results for\s*/g;

      if (
        line.search(pageRegex) === -1 &&
        line.search(headerRegex) === -1 &&
        line.search(rodeoResultsLineRegex) === -1
      ) {
        if (line.trim().length > 0) {
          fileLines_.push(line.trim()); // initial split the file into its individual lines and trim whitespace
        }
      }
    });

    let parsedResults;

    if (invalidFile()) {
      errors_.push('Invalid File - unexpected content or blank content');
    } else {
      parsedResults = parse_();
    }

    if (errors_.length > 0) {
      // return JSON.stringify({ errors: errors_ });
      return { errors: errors_ };
    } else {
      // return JSON.stringify(parsedResults);
      return parsedResults;
    }
  };

  /**
   *
   * @return {boolean}
   */
  const invalidFile = () => {
    // the first line should never contain content based on the expected format
    const line = fileLines_[0];
    if (line.length === 0) {
      return true;
    }

    // some lines should be longer than 0 at a bare minimum, if not it is obviously invalid
    return !fileLines_.some((line) => line.length > 0);
  };

  /**
   * @typedef eventDate
   * @param {string} month
   * @param {number} start
   * @param {number} end
   */

  /**
   * @typedef {Object} eventDetails
   * @param {string} name
   * @param {eventDate}
   */

  /**
   * @typedef {Object} eventScores
   * @param {eventDetails}
   * @param {Array | Object}
   */

  /**
   * @description parses lines stores results
   * @return {eventScores|null}
   * @private
   */
  const parse_ = () => {
    /**
     *
     * @type {{name: *, dates: {month: string, start: number, end: number}}}
     */
    const eventStartLine = fileLines_.findIndex((line) =>
      line.includes('IPRA'),
    );
    if (eventStartLine === -1) {
      errors_.push('Unable to parse - event information not detectable');
      return;
    }

    const eventDataStartLine = eventStartLine + 1;
    const eventDataEndLine = eventDataStartLine + 2;

    const eventDetails = getEventDetails_(
      fileLines_.slice(eventDataStartLine, eventDataEndLine),
    );
    if (!eventDetails) {
      return null;
    }

    // check to see if the file contains go-rounds
    const scoresStart = fileLines_.slice(eventDataEndLine);

    /**
     *
     * @type {*[]}
     */
    const goRounds = getGoRoundPositions_(scoresStart);

    let roundScores = [];
    if (goRounds) {
      for (let round of goRounds) {
        let roundLines = scoresStart.slice(round['start'], round['end']);
        let discLines = parseDisciplines_(roundLines);
        let lineScores = parseAthleteScores_(discLines);

        roundScores.push(lineScores);
      }

      return {
        event: eventDetails,
        scores: roundScores,
      };
    }

    /**
     * we will start parsing at line 12 because that's where the stock contractor
     * info begins. We'll treat the stock contractor like a discipline and just
     * ignore it
     * @type {Array}
     */
    const disciplineResults = parseDisciplines_(scoresStart);
    if (!disciplineResults) {
      return null;
    }

    /**
     *
     * @type {[]}
     */
    // If discipline parsing succeeded, parse the results of each discipline
    const athleteScores = parseAthleteScores_(disciplineResults);
    if (!athleteScores) {
      return null;
    }

    // Will only make it this far if nothing else has prematurely returned with errors
    return {
      event: eventDetails,
      scores: athleteScores,
    };
  };

  /**
   *
   * @param {Array} eventLines
   * @private
   * @return {Object}
   */
  const getEventDetails_ = (eventLines) => {
    const eventNameLine = 0;
    const eventDatesLine = 1;

    const event = {
      name: getEventName_(eventLines[eventNameLine]),
      dates: getEventDates_(eventLines[eventDatesLine]),
    };

    if (!(event.name && event.dates)) {
      errors_.push('Unable to parse event details');
      return;
    }

    return event;
  };

  const getEventName_ = (fileLine) => {
    return fileLine.trim();
  };

  /**
   *
   * @param fileLine
   * @return {EventDate} | null
   * @private
   */
  const getEventDates_ = (fileLine) => {
    const eventDates = {
      month: '', // month will always be in the 3 letter form (e.g. AUG)
      start: 0,
      end: 0,
    };

    // find the position of the dollar sign & remove string from $ to EOL
    const purseStart = fileLine.indexOf('$');
    const dates = fileLine.substr(0, purseStart);

    const months = dates.split(',');
    const isMultiMonth = months.length > 1;

    // we need to use the second event
    const me = isMultiMonth ? months[1].trim() : months[0].trim();

    eventDates['month'] = me.substr(0, 3);

    const datesStartPos = me.indexOf(' ');
    const days = me.substr(datesStartPos);

    const eventDays = days.split('-');
    eventDates['start'] = eventDays[0];
    eventDates['end'] =
      eventDays.length > 1 ? eventDays[eventDays.length - 1] : eventDays[0];

    if (!(eventDates.start && eventDates.end)) {
      errors_.push('Unable to parse event dates');
    }

    return eventDates;
  };

  /**
   *
   * @param {[]} lines
   * @private
   * @return [start: {number}, end: {number}]
   */
  const getGoRoundPositions_ = (lines) => {
    const goRounds = [];
    let start = -1;

    lines.forEach((line, index) => {
      // test if the line contains the word go, if it doesn't, skip to next line
      if (line.indexOf('GO') === -1) {
        // are we at the last line of the line?
        if (index === lines.length - 1) {
          goRounds.push({ start, end: index });
        }
        return;
      }

      // the line contains go, is the the first time we found a go?
      if (start === -1) {
        start = index;
        return;
      }

      // we just encountered NOT the first go
      goRounds.push({ start, end: index });
      start = index;
    });

    if (goRounds.length === 1) {
      if (goRounds[0]['start'] === -1) {
        return null;
      }
    }

    return goRounds;
  };

  /**
   *
   * @param {Array} lines
   * @private
   * @return {Array}
   */
  const parseDisciplines_ = (lines) => {
    const disciplineResults = [];

    // In order to provide the most feedback, an attempt to parse all disciplines will occur.
    // Once all disciplines have been parsed, each with its own (potential) set of errors, they will be combined and returned.
    let allDisciplineErrors_ = [];

    lines.forEach((line, index) => {
      const disciplineErrors_ = [];

      // if the line does not contain ':' then it's not a line that contains a discipline name
      if (line.indexOf(':') === -1) {
        return;
      }
      // else, the line contains a ':', it's the start of a new discipline, meaning it contains
      // a discipline name and the beginning of the event results

      // split the string on the ":" and save the 2 indexes to values
      let value = line.split(':');
      const discipline = value[0].trim();

      // skip these disciplines
      switch (discipline) {
        case 'Judges':
        case 'Arena Secy':
        case 'Timers':
        case 'Pickupmen':
        case 'Arena Ancr':
        case 'Bull Fighters':
        case 'Barrelman':
        case 'Specialty Acts':
        case 'Prod':
        case 'Stk Cont':
        case 'Photographer':
        case 'All Around Cowboy':
          return;
      }

      // Before iterating through each line of results for the discipline, collect the result data found on the same line as the discipline
      const disciplineLineResults = value[1].trim();
      let contestantData = disciplineLineResults + ' ';

      // go till we find an empty line, that's the end of the discipline data
      // or if the next line contains a ":", then the current row contains
      // all contestants
      for (let nextIndex = index + 1; nextIndex < lines.length; nextIndex++) {
        let nextLine = lines[nextIndex].trim();

        // At the end of each displines results, the next line could either be a blank line or the start of a new discipline which would have a :
        if (nextLine.length === 0 || nextLine.indexOf(':') !== -1) {
          // If the next line is empty or contains :, that is the end of this discipline
          // Break down the results found into individual entry results then let the forEach catch up to the next line with :
          // No action is taken on lines without : by the forEach
          break;
        }

        contestantData += `${nextLine} `;
      }

      // When appending a line to contestantData, an extra space is added at the end assuming more.
      // After the last line is added, there will be an extraneous space at the end so trim()
      contestantData = contestantData.trim();

      // return forEach iteration if contestantData.length === 0
      if (contestantData.length === 0) {
        disciplineErrors_.push(
          `No contestant data found for discipline: ${discipline}`,
        );
        return;
      }

      // Individual results will be split by ;, filter out empty result strings
      const results = contestantData.split(';').filter((r) => r);

      const validResults = [];
      results.forEach((result) => {
        let res = result.trim();

        if (res.length > 1) {
          validResults.push(res);
        } else {
          disciplineErrors_.push(
            `Invalid result found in discipline: ${discipline}`,
          );
        }
      });

      // if there are parse errors for this discipline, add to the all disciplines error array, and try to parse the next discipline
      // Instead of breaking, return is used here so all parse errors are sent to the user instead of the first encountered error
      if (disciplineErrors_.length > 0) {
        allDisciplineErrors_ = allDisciplineErrors_.concat(disciplineErrors_);
        return;
      }

      disciplineResults.push({ discipline, results: validResults });
    });

    // if there are errors in parsing any of the disciplines, add to the error array and return without result
    if (allDisciplineErrors_.length > 0) {
      errors_ = errors_.concat(allDisciplineErrors_);
      return;
    }

    return disciplineResults;
  };

  /**
   *
   * @param {[]} disciplineList
   * @private
   * @return {Object}
   */
  const parseAthleteScores_ = (disciplineList) => {
    const disciplineScores = {};
    let allScoreErrors_ = [];

    disciplineList.forEach(({ discipline, results }) => {
      let disciplineScoreErrors_ = [];
      disciplineScores[discipline] = [];

      // Results are in order of place. The place index reflects array position (0, 1, 2...) not actual place (1, 1, 2, 3).
      // Actual place will be determined later
      results.forEach((result, placeIndex) => {
        /**
         * contains results from parsing the line
         * @type {{winners: Array, score: string, money: string}}
         */
        const placeRecord = parseRecord_(result);
        if (!placeRecord) {
          disciplineScoreErrors_.push(
            `Error parsing score record in discipline: ${discipline}`,
          );
          return;
        }

        /**
         * @type {[]}
         */
        // once the record has been parsed (has contestants, money, and score) create the actual object
        const records = createRecord_(discipline, placeRecord, placeIndex);

        // unable to create records so return
        if (!records) {
          disciplineScoreErrors_.push(
            `Error creating score record in discipline: ${discipline}`,
          );
          return;
        }

        // at this point, records should be able to be added to the discipline without issue or error
        records.forEach((record) => {
          disciplineScores[discipline].push(record);
        });
      });

      if (disciplineScoreErrors_.length > 0) {
        allScoreErrors_.concat(disciplineScoreErrors_);
      }
    });

    if (allScoreErrors_.length > 0) {
      errors_.concat(allScoreErrors_);
      return;
    }

    return disciplineScores;
  };

  /**
   *
   * @param result
   * @return {{winners: string[], score: string, money: string}}
   * @private
   */
  const parseRecord_ = (result) => {
    const regexScore = /[0-9]+(\.[0-9]{1,3})?/g; // Scores can be any amount of numbers followed optionally by a . and up to 3 digits
    const regexN = /\[N]/g; // [N] is an irrelevant attribute, can be ignored when parsing a result
    const regexSplit = /split/g; // split is used to denote tie, $ amount will be written as "$00.00 ea"

    const moneyStart = result.indexOf('$');
    // no need to keep "ea" for each as it is assumed if its a header/heeler group or two people for the result that it is each
    const money = result
      .slice(moneyStart)
      .replace(/ea/g, '')
      .replace(/[$,]/g, '')
      .trim();

    const nameAndScore = result.slice(0, moneyStart);

    const scoreStarts = nameAndScore.search(regexScore);
    const names = nameAndScore.slice(0, scoreStarts); // this could be 1 name, multiple names if tie, or hyphenated if team
    const score = nameAndScore.slice(scoreStarts).trim();

    const winnerNames = names.split(','); // in the case of a tie separate unrelated winners will be separated by a comma

    const winners = [];
    let indexToSkip = -1;
    winnerNames.forEach((fullName, index) => {
      if (index === indexToSkip) {
        return;
      }

      let nameParts = fullName.split(' ');

      if (nameParts.length === 1) {
        indexToSkip = index + 1;
        fullName = fullName.trim() + ' ' + winnerNames[indexToSkip].trim();
      }

      winners.push(fullName);
    });

    // for each winner in the result (typically 1 but more if a tie), extraneous text [N] and "split" can be removed
    winners.forEach((winner, index) => {
      winners[index] = winner
        .replace(regexN, '')
        .replace(regexSplit, '')
        .trim();
    });

    // if the individual result sent in doesn't have a $ value, a score, and at least 1 winner, the result is not
    // parsable so return
    if (!(money && score && winners)) {
      return;
    }

    return {
      money,
      score,
      winners,
    };
  };

  /**
   *
   * @param {string} discipline
   * @param {Object} placeRecord
   * @param {number} placeIndex
   * @private
   */
  const createRecord_ = (discipline, placeRecord, placeIndex) => {
    const { money, score, winners } = placeRecord;
    const records = [];
    let createRecordErrors_ = [];

    winners.forEach((winner) => {
      const record = {
        place: placeIndex + 1, // change place from array position to actual place (1, 2, 3, etc.)
        name: winner, // may or may not be the final name of the winner
        money: money, // $ amount of parsed record as is
      };

      // for the sake of clarity, do not do this if this is a Team Roping result as team roping names are handled in the switch below
      if (discipline !== 'Team Roping') {
        const { firstName, lastName, suffix } = getName_(winner);

        if (!(firstName && lastName)) {
          createRecordErrors_.push(
            `Unable to determine first name/last name for: ${winner} in ${discipline}`,
          );
          return;
        }

        record['firstName'] = firstName;
        record['lastName'] = lastName;
        record['suffix'] = suffix;
      }

      // name the score appropriately (either time or score) based on the
      // discipline, if team roping, handle team creation
      switch (discipline) {
        case 'Cowgirl Barrel Racing':
        case 'Cowgirl Breakaway':
        case 'Steer Wrestling':
        case 'Tie-Down Roping':
          record['time'] = score;
          break;
        case 'Team Roping': {
          record['time'] = score;

          const { header, heeler } = createHeaderHeeler_(winner);
          if (!(header && heeler)) {
            createRecordErrors_.push(`Unable to create a header/heeler record`);
            return;
          }
          record['header'] = header;
          record['heeler'] = heeler;
          break;
        }
        default:
          record['score'] = score;
      }

      records.push(record);
    });

    if (createRecordErrors_.length > 0) {
      errors_ = errors_.concat(createRecordErrors_);
      return;
    }

    return records;
  };

  /**
   *
   * @param {string} names
   * @private
   * @return {Object}
   */
  const createHeaderHeeler_ = (names) => {
    const ropers = names.split(' - ');

    // There must be a header/heeler. No more, no less.
    if (ropers.length !== 2) {
      errors_.push(`Not enough or too many team winner names found: ${names}.`);
      return;
    }

    // const team = {header: null, heeler: null};
    const team = {};

    ropers.forEach((name, position) => {
      const { firstName, lastName, suffix } = getName_(name);

      if (!(firstName && lastName)) {
        errors_.push(
          `Unable to determine first name/last name for a team entry: ${name}.`,
        );
        return;
      }

      // header is always first
      if (position === 0) {
        team['header'] = {
          name: name.trim(),
          firstName,
          lastName,
          suffix,
        };

        return;
      }

      team['heeler'] = {
        name: name.trim(),
        firstName,
        lastName,
        suffix,
      };
    });

    return team;
  };

  const getName_ = (name) => {
    const nicknameRegex = /\s" \w+ "/g;
    let fixedName = name.replace(nicknameRegex, '');

    // TODO: handle separatedWinnerName.length > 2 better
    let separatedName = fixedName.split(' ');

    if (separatedName.length < 2) {
      errors_.push(`Unable to derive a first and last name for: ${name}`);
      return;
    }

    let firstName = separatedName[0];

    let suffix = ''; // does the contestant have a suffix?
    if (
      separatedName[1].toLowerCase() === 'junior' ||
      separatedName[1].toLowerCase() === 'jr'
    ) {
      suffix = 'Jr';
    }

    let i = 1; // where is last name in the array?
    if (suffix.length !== 0) {
      // user is a jr, so start at 3rd element
      i = 2;
    }

    let lastName = '';
    for (; i < separatedName.length; i++) {
      lastName += `${separatedName[i]}`;
    }

    return { firstName, lastName, suffix };
  };
} // end Parser

export { Parser };
