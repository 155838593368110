import Base from 'models/Base';
import { Map } from 'immutable';
import filterNames from 'constants/filters';
import { UPDATE_FILTER, LOAD_FILTER } from 'actions/filter';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Map(),
});

const actionsMap = {
  [UPDATE_FILTER]: (state, action) => {
    const isMultiFilters = Array.isArray(action.data);
    const filterKeys = isMultiFilters
      ? Object.keys(action.data[0])
      : Object.keys(action.data);
    const filterKeyToModify =
      filterKeys && filterKeys.length > 0 ? filterKeys[0] : null;

    const allFilters = state.get('data');
    const filterToModify = state.get('data').get(filterKeyToModify);
    const newFilter = isMultiFilters
      ? action.data[0][filterKeyToModify]
      : action.data[filterKeyToModify];

    let parentId = null;

    const filters = filterToModify.map((filt, i) => {
      if (filt) {
        if (newFilter && filt.columnName === newFilter.columnName) {
          parentId = i;
          return newFilter;
        }
      }
      return filt;
    });

    // todo: this is our first related filter update. We'll need to improve the 'releated' filters logic to handle future filter relationships
    if (isMultiFilters && newFilter.columnName === 'Discipline') {
      filters[parentId].childOptions = filterToModify[parentId].childOptions;
      let childIdx = null;
      const classFilters = filters.find((v, i) => {
        if (v.columnName === 'ClassName') {
          childIdx = i;
          return true;
        }
        return false;
      });

      if (newFilter.value.length === 0) {
        classFilters.options = filters[parentId].childOptions;
      } else {
        classFilters.options = filterToModify[parentId].childOptions.filter(
          (c) => c.parentId === newFilter.value,
        );
      }

      filters[childIdx] = classFilters;
    }

    const filterToSave = state.get('data').set(filterKeyToModify, filters);

    return state.merge({
      loading: false,
      errorMessage: '',
      data: new Map({ ...allFilters.toJS(), ...filterToSave.toJS() }),
    });
  },
  [LOAD_FILTER]: (state, { data }) => {
    const allFilters = state.get('data').toJS();
    const { name } = data;

    if (
      name === allFilters.name &&
      allFilters.name !== filterNames.NOMINATION_FILTER &&
      allFilters.name !== 'EventFilter'
    ) {
      return state;
    }
    if (name === 'EventFilter') {
      let dateFilter = data.EventFilter.find(
        (item) => item.columnName === 'dateStart',
      );
      dateFilter.value = '';
      data[name][3] = dateFilter;
    }
    return state.merge({
      loading: false,
      errorMessage: '',
      data: new Map({
        name,
        [name]: data[name],
      }),
    });
  },
};

export default function filter(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
