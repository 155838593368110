import gql from 'graphql-tag';

export const LIST_NOMINATIONS_PER_EVENT = gql`
  query LIST_NOMINATIONS_PER_EVENT($input: NominationsInput!) {
    nominationsPerEventPayload(input: $input) {
      nominations {
        NomUID
        ERAUID
        EventUID
        CompType
        OriginSource
        SegmentUID
        SegmentAsString
        ShortName
        NomFee
        TransFee
        TTLFees
        NomDate
        chargeId
        CouponUID
        createdAt
        DisciplineAsString
        NumberOfNominations
        totalOnStripe
        NominationRevenue
        totalTransactionFee
        chargeHasMoreThanOneNomination
        EventName
        EventLocation
        EventType
        firstName
        lastName
        FullName
        email
        userId
        mainDisciplineAsString
        mainDiscipline
        CouponCode
        isFree
        Segments
        ClassName
        EventEntryDisciplineFeeUID
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
