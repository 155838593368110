import Nomination from 'models/Nomination';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { List } from 'immutable';
import { setLocalStorage, getLocalStorage } from 'helpers/localStorage';

import {
  ATHLETE_NOMINATION_ASYNC_START,
  ATHLETE_NOMINATION_ASYNC_STOP,
  ATHLETE_NOMINATION_UPDATE_OPTIONS,
  ATHLETE_NOMINATION_LIST_ASYNC_SUCCESS,
  ATHLETE_NOMINATION_APPLY_LIST_OPTIONS,
  ATHLETE_NOMINATION_ASYNC_FAIL,
} from 'actions/athleteNomination';

const tableOptions =
  getLocalStorage('TableOptions', 'athleteNominations') || {};

export const initialAthleteNominationOptions = {
  order: 'desc',
  orderBy: 'ERAUID',
  orderIsDate: false,
  selected: new List(),
  page: 0,
  rowsPerPage: tableOptions.rowsPerPage || 50,
};

const initialState = new Base({
  loading: false,
  errorMessage: '',
  list: toEntityList([], Nomination),
  options: initialAthleteNominationOptions,
  totalCount: 0,
});

const actionsMap = {
  [ATHLETE_NOMINATION_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [ATHLETE_NOMINATION_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [ATHLETE_NOMINATION_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.nominationsPerContestantPayload)
      return state;

    const { meta } = action.data.nominationsPerContestantPayload;
    const nominations = action.data.nominationsPerContestantPayload
      ? action.data.nominationsPerContestantPayload.nominations
      : [];

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(nominations, Nomination),
      totalCount: meta.totalCount || 0,
    });
  },

  [ATHLETE_NOMINATION_UPDATE_OPTIONS]: (state, action) => {
    const { order, orderBy, page, rowsPerPage } = action.data;

    setLocalStorage('TableOptions', 'athleteNominations', { rowsPerPage });

    return state.merge({
      options: Object.assign({}, state.options, {
        order,
        orderBy,
        page,
        rowsPerPage,
      }),
    });
  },

  [ATHLETE_NOMINATION_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },

  [ATHLETE_NOMINATION_APPLY_LIST_OPTIONS]: (state, action) => {
    const newOptions = action.data;
    const baseOptions =
      state.get && state.get('options') ? state.get('options').toJS() : {};
    const options = Object.assign({}, baseOptions, newOptions);
    return state.merge({
      options,
    });
  },
};

export default function athleteNomination(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
