import api from 'api';
import { getFromState, getToken, logError } from 'helpers';
import { applyConfig as applyEntryPoolConfig } from 'actions/entryPool';
import { applyConfig as applyContestantConfig } from 'actions/contestant';
import { createAlertSuccess } from 'actions/alert';

export const USER_ASYNC_START = 'USER_ASYNC_START';
export const USER_ASYNC_FAIL = 'USER_ASYNC_FAIL';
export const USER_ASYNC_SUCCESS = 'USER_ASYNC_SUCCESS';
export const USER_ASYNC_CLEAR_ERROR = 'USER_ASYNC_CLEAR_ERROR';
export const USER_TEXT_MESSAGE_ASYNC_START = 'USER_TEXT_MESSAGE_ASYNC_START';
export const USER_TEXT_MESSAGE_ASYNC_FAIL = 'USER_TEXT_MESSAGE_ASYNC_FAIL';
export const USER_TEXT_MESSAGE_ASYNC_SUCCESS =
  'USER_TEXT_MESSAGE_ASYNC_SUCCESS';
export const USER_OPEN_TEXT_MESSAGE_DIALOG = 'USER_OPEN_TEXT_MESSAGE_DIALOG';
export const USER_CLOSE_TEXT_MESSAGE_DIALOG = 'USER_CLOSE_TEXT_MESSAGE_DIALOG';
export const USER_OPEN_EXPORT_ENTRIES_DIALOG =
  'USER_OPEN_EXPORT_ENTRIES_DIALOG';
export const USER_CLOSE_EXPORT_ENTRIES_DIALOG =
  'USER_CLOSE_EXPORT_ENTRIES_DIALOG';

export function userAsyncStart() {
  return {
    type: USER_ASYNC_START,
  };
}

function userAsyncFail(errorMessage) {
  return {
    type: USER_ASYNC_FAIL,
    errorMessage,
  };
}

export function userAsyncSuccess(data) {
  return {
    type: USER_ASYNC_SUCCESS,
    data,
  };
}

export function userTextMessageAsyncStart() {
  return {
    type: USER_TEXT_MESSAGE_ASYNC_START,
  };
}

function userTextMessageAsyncFail(errorMessage) {
  return {
    type: USER_TEXT_MESSAGE_ASYNC_FAIL,
    errorMessage,
  };
}

export function userTextMessageAsyncSuccess(data) {
  return {
    type: USER_TEXT_MESSAGE_ASYNC_SUCCESS,
    data,
  };
}

export function userAsyncClearError() {
  return {
    type: USER_ASYNC_CLEAR_ERROR,
  };
}

export function updateUser(values) {
  return async (dispatch, getState) => {
    dispatch(userAsyncStart());
    try {
      const user = getFromState(getState, 'user');
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `users/${user.id}`,
        method: 'PATCH',
        body: values,
        accessToken,
      });

      dispatch(userAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      let err = 'An error occurred while trying to update your account';

      if (error.message.indexOf('Validation Error') >= 0) {
        err += ' Try using a different email';
      }

      dispatch(userAsyncFail(err));
      return null;
    }
  };
}

export function updateUserPassword(values) {
  return async (dispatch, getState) => {
    dispatch(userAsyncStart());
    try {
      const user = getFromState(getState, 'user');
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `users/${user.id}/password`,
        method: 'PUT',
        body: values,
        accessToken,
      });

      dispatch(userAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      let err = 'An error occurred while trying to update your password';

      if (error.message.indexOf('Validation Error') >= 0) {
        err = 'Incorrect current password';
      }

      dispatch(userAsyncFail(err));
      return null;
    }
  };
}

export function updateUserPhoto(image) {
  return async (dispatch, getState) => {
    const user = getFromState(getState, 'user');
    dispatch(userAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const body = new FormData();
      body.append('file', image);
      const response = await api({
        path: `users/${user.id}/photo`,
        method: 'POST',
        body,
        accessToken,
        cType: 'multipart/form-data',
      });

      dispatch(userAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(
        userAsyncFail('File must have jpg or png format and be under 2mb'),
      );
      return null;
    }
  };
}

export function openTextMessageDialog() {
  return {
    type: USER_OPEN_TEXT_MESSAGE_DIALOG,
  };
}

export function closeTextMessageDialog() {
  return {
    type: USER_CLOSE_TEXT_MESSAGE_DIALOG,
  };
}

export function openExportEntriesDialog() {
  return {
    type: USER_OPEN_EXPORT_ENTRIES_DIALOG,
  };
}

export function closeExportEntriesDialog() {
  return {
    type: USER_CLOSE_EXPORT_ENTRIES_DIALOG,
  };
}

export function sendTextMessageToUsers(
  phoneNumbers,
  textMessage,
  messageType = 'live',
) {
  return async (dispatch, getState) => {
    dispatch(userTextMessageAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'texting/send-text-to-user-list',
        method: 'POST',
        body: { phoneNumbers, textMessage },
        accessToken,
      });

      dispatch(userTextMessageAsyncSuccess(response));

      if (messageType === 'live') {
        await dispatch(
          createAlertSuccess({
            message:
              'Text message sent. All recipients should recieve the text within the hour.',
            type: 'success',
          }),
        );
        dispatch(applyEntryPoolConfig({ selected: [] }));
        dispatch(applyContestantConfig({ selected: [] }));
      }

      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to send the text message';

      dispatch(userTextMessageAsyncFail(err));
      return null;
    }
  };
}
