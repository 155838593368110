import gql from 'graphql-tag';

export const LIST_EVENTS = gql`
  query LIST_EVENTS($input: EventsInput!) {
    eventsPayload(input: $input) {
      events {
        id
        EventUID
        totalNominations
        dateStart
        name
        location
        dateEnd
        EventName
        EventDate
        rank
        rankLabel
        rankShort
        SubmissionStatus
        isAlliancePartner
        ShouldCollectTaxInfo
        ShouldCollectSNNAndCitizenshipInfo
        AllowsPayLater
        AllowsBuddyGroup
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
