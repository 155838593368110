import Base from 'models/Base';

import { REPORTS_ASYNC_FAIL, REPORTS_ASYNC_SUCCESS } from 'actions/reports';

const initialState = new Base({
  loading: false,
  errorMessage: '',
});

const actionsMap = {
  [REPORTS_ASYNC_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },

  [REPORTS_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
};

export default function reports(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
