import api from 'api';
import { getFromState, getToken, logError } from 'helpers';

export const PERSONNEL_ROLE_ASYNC_START = 'PERSONNEL_ROLE_ASYNC_START';
export const PERSONNEL_ROLE_ASYNC_FAIL = 'PERSONNEL_ROLE_ASYNC_FAIL';
export const PERSONNEL_ROLE_ASYNC_SUCCESS = 'PERSONNEL_ROLE_ASYNC_SUCCESS';

export function personnelRoleAsyncStart() {
  return {
    type: PERSONNEL_ROLE_ASYNC_START,
  };
}

export function personnelRoleAsyncFail(errorMessage) {
  return {
    type: PERSONNEL_ROLE_ASYNC_FAIL,
    errorMessage,
  };
}

export function personnelRoleAsyncSuccess(data) {
  return {
    type: PERSONNEL_ROLE_ASYNC_SUCCESS,
    data,
  };
}

export function listPersonnelRoles() {
  return async (dispatch, getState) => {
    dispatch(personnelRoleAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const response = await api({
        // TODO change it on integration task
        path: 'mock/personnelRoles',
        method: 'GET',
        accessToken,
      });
      dispatch(personnelRoleAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch Personnel Roles';
      dispatch(personnelRoleAsyncFail(err));
      return null;
    }
  };
}
