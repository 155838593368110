import gql from 'graphql-tag';

export const LIST_VENUES = gql`
  query LIST_VENUES($input: VenuesInput!) {
    venuesPayload(input: $input) {
      venues {
        VenueUID
        Indoor
        ContactName
        City
        State
        Zip
        FName
        LName
        Email
        Phone1
        Phone2
        Street
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
