// @flow
import { createNumberMask } from 'redux-form-input-masks';
import radixParameter from 'constants/radix';

const currencyOptions = {
  prefix: '$ ',
  suffix: '',
  decimalPlaces: 2,
  locale: 'en-US',
};

export const emptyCurrency = createNumberMask({
  ...currencyOptions,
  allowEmpty: true,
});

export const currency = createNumberMask(currencyOptions);

const percentageOptions = {
  prefix: '',
  suffix: '%',
  decimalPlaces: 2,
  locale: 'en-US',
};

export const emptyPercent = createNumberMask({
  ...percentageOptions,
  allowEmpty: true,
});

export const percentage = createNumberMask(percentageOptions);

export const phone = (value: string) => {
  if (value) {
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 3) {
      return onlyNums;
    }
    if (onlyNums.length <= 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      10,
    )}`;
  }
  return value;
};

export const onlyNumbersParsingToNumber = (value: string) => {
  if (value) {
    const numberValue = parseInt(
      value.replace(/[^\d]/g, ''),
      radixParameter.DEFAULT,
    );
    return !isNaN(numberValue) ? numberValue : null;
  }
  return null;
};

export const onlyNumbers = (value: string) => {
  return value ? value.replace(/[^\d]/g, '') : null;
};

export const onlyNumbersAndFloats = (value: string) => {
  return value ? value.replace(/[^0-9.]/g, '') : null;
};

const getDecimalCount = (floatValue: number) => {
  if (Math.floor(floatValue) === floatValue) return 0;
  const splitDecimalValue = floatValue.toString().split('.')[1];

  return splitDecimalValue ? splitDecimalValue.length : 0;
};

function hasValidFloatEntry(filteredValue: string, decimalPlaceCount: number) {
  const decimalPointsEntered = filteredValue.match(/\./g) || [];
  if (decimalPointsEntered.length !== 1) return false;

  const lastChar = filteredValue.substring(filteredValue.length - 1);

  const postDecimalCharacters = filteredValue.split('.').pop();
  if (postDecimalCharacters.length > decimalPlaceCount) return false;

  return lastChar === '.' || lastChar === '0';
}

function getRoundedFloatValue(value: string, decimalPlaceCount: number = 2) {
  if (!value) return null;

  const filteredValue = value.replace(/[^\d.-]/g, '');
  if (hasValidFloatEntry(filteredValue, decimalPlaceCount)) {
    return filteredValue;
  }

  const floatValue = parseFloat(filteredValue);
  const roundedFloatValue =
    getDecimalCount(floatValue) > decimalPlaceCount
      ? floatValue.toFixed(decimalPlaceCount)
      : floatValue;

  return roundedFloatValue && parseFloat(roundedFloatValue) >= 1
    ? roundedFloatValue.toString().replace(/^0+/, '0.')
    : roundedFloatValue.toString().replace(/^00+/, '0');
}

export const onlyTwoDigitDecimalFloats = (value: string) => {
  return getRoundedFloatValue(value);
};

export const onlyThreeDigitDecimalFloats = (value: string) => {
  return getRoundedFloatValue(value, 3);
};

export const nullifyEmptyDateEntry = (value: string) =>
  value === '' ? null : value;
