// @flow
import React, { PureComponent } from 'react';
import { ExternalLink } from 'react-feather';
import { Field as NameField } from 'redux-form-normalize-on-blur';
import {
  Field,
  reduxForm,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';
import { connect } from 'react-redux';
import flagsmith from 'flagsmith';
import 'react-select/dist/react-select.css';
import { compose } from 'recompose';
import withToJS from 'enhancers/withToJS';
import { delay, isYouthByDOB } from 'helpers';
import moment from 'moment-timezone';
import { getAllSegments } from 'actions/segment';
import { leaderboardTypes } from 'constants/leaderboardType';
import { phone } from 'components/Forms/Normalizer';

import {
  InputAdornment,
  Avatar,
  Grid,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import PublishIcon from '@material-ui/icons/Publish';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DollarIcon from '@material-ui/icons/AttachMoney';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { getInitialContestantValues } from 'selectors/contestant';
import {
  uploadAthletePhoto,
  updateAthlete,
  getAthleteLeaderboard,
  getListSegment,
} from 'actions/contestant';
import { withRouter } from 'react-router';

// $FlowIgnore
import AthletesIcon from 'assets/img/icons/athletes-icon.svg';
import { renderSelectBoxField } from 'components/FormField/SelectBox';
import FileUploadButton from 'components/FileUploadButton';
import FormField from './../../components/FormField';
import {
  required,
  name,
  email,
  onlyAlphaNumeric,
  postalCode as postalCodeValidator,
  stateProvince as stateProvinceValidator,
} from './../../components/Forms/Validation';
import ErrorMessage from 'components/ErrorMessage';
import { mapDispatchToProps } from 'helpers';
import routeCodes from '../../constants/routes';
import { LeaderboardStandingsTable } from './LeaderboardStandingsTable';
import { ClassificationsTable } from './ClassificationsTable';
import Gender from './Gender';
import countries from 'node-countries';
import {
  AVAILABLE_COUNTRIES_AND_DETAILS,
  AVAILABLE_COUNTRY_CODES,
} from 'constants/countryData';
import { ROLES } from '../../models/User';
import Checkbox from 'components/FormField/Checkbox';

export const FORM_NAME = 'editAthlete';

const formSelector = formValueSelector(FORM_NAME);
const cantEdit = [ROLES.ADMIN_VIEW_ONLY];

/**
 * @desc state comes from reducer/contestant file
 * @param state
 */
const mapStateToProps = (state) => ({
  segments: state.segment.get('list'),
  loadingAuth: state.auth.get('loading'),
  loadingContestant: state.contestant.get('loading'),
  contestant: state.contestant.get('data'),
  linkedAccounts: state.contestant.get('data').get('linkedAccounts'),
  disciplines: state.discipline.get('list'),
  contestantErrorMessage: state.contestant.errorMessage,
  initialValues: getInitialContestantValues(state),
  formValueSelector: (fieldName) => formSelector(state, fieldName),
  errors: getFormSyncErrors(FORM_NAME)(state),
  user: state.user.get('data'),
});

type FormProps = {|
  handleSubmit: (o: Object) => void,
  dispatch: (o: Object) => any,
  change: (name: string, o: Object) => void,
  segments: Object,
  loadingAuth: boolean,
  loadingContestant: boolean,
  contestantErrorMessage: string,
  disciplines: Object,
  contestant: Object,
  // youthList: Object,
  history: Object,
  formValueSelector: (string) => any,
  errors: Object,
  user: Object,
|};

type FormState = {|
  error: string,
  uploading: boolean,
  showPassword: boolean,
  leaderboard: ?Object,
  country: ?string,
  state: ?string,
  classifications: ?Object,
|};

export class AthleteForm extends PureComponent<FormProps, FormState> {
  static defaultProps = {
    contestantErrorMessage: null,
  };

  state = {
    error: '',
    uploading: false,
    showPassword: false,
    leaderboard: [],
    country: null,
    state: null,
    classifications: [],
  };

  componentDidMount() {
    const { dispatch } = this.props;
    this.setCountryAndState();
    dispatch(getAllSegments());
    this.loadLeaderboardCard();
    this.loadClassificationsCard();
  }

  // This was on state but the linter kept bug'n out that it was unused.
  // Used to temporarily hold image data a user submits to the form before
  // the form is submitted.
  imageProfileBlob = undefined;

  onSubmit = async () => {
    const { dispatch, history, contestant } = this.props;
    const { isYouth } = contestant;
    await dispatch(updateAthlete(isYouth));
    if (!this.getErrorMessage()) {
      history.push(routeCodes.ATHLETES);
    }
  };

  getImage = () => {
    const formImage = this.props.formValueSelector('imageProfileBlob');
    return formImage
      ? formImage
      : this.props.formValueSelector('PhotoFilename');
  };

  getLoadingState = () => {
    const { loadingContestant, loadingAuth } = this.props;
    const { uploading } = this.state;
    return loadingAuth || loadingContestant || uploading;
  };

  getRoles = () => {
    const { contestant } = this.props;
    const viewType = contestant.userId ? 'athlete' : 'youth';
    return viewType === 'athlete'
      ? [
          { id: 1, name: 'Athlete' },
          { id: 2, name: 'Admin' },
          { id: 3, name: 'Secretary' },
          { id: 4, name: 'Admin - Sanctioning Body' },
        ]
      : [{ id: '', name: 'Athlete' }];
  };

  getLeaderboardTypes = () => {
    return leaderboardTypes;
  };

  loadLeaderboardCard = async () => {
    const { dispatch } = this.props;
    const segments = await dispatch(getAllSegments());

    const lastSegment =
      segments && segments[0] ? segments[segments.length - 1] : null;
    const lastSegmentUID = lastSegment ? lastSegment.SegmentUID : '';
    const selectedSegment = this.props.formValueSelector('SegmentUID')
      ? this.props.formValueSelector('SegmentUID')
      : lastSegmentUID;

    if (!this.props.formValueSelector('SegmentUID'))
      this.setSegment(lastSegmentUID);

    const ERAUID = this.props.formValueSelector('ERAUID');
    const leaderboardData = await dispatch(
      getAthleteLeaderboard({ segment: selectedSegment, ERAUID }),
    );

    const leaderboard = leaderboardData ? leaderboardData : [];
    this.setState({ leaderboard });
  };

  setSegment = async (value: string) => {
    this.changeField('SegmentUID', value);
    this.loadLeaderboardCard();
  };

  loadClassificationsCard = async () => {
    const { dispatch } = this.props;
    const { isYouth } = this.props.contestant;

    const segments = await dispatch(getAllSegments());

    const filteredAndSorteredSegments = segments
      .filter(
        (segment) =>
          ((!isYouth && !segment.HasYouth) || isYouth) &&
          (segment.HasYouth || segment.HasPro || segment.HasChallenger),
      )
      .sort((a, b) => new Date(b.EndDate) - new Date(a.EndDate));

    const lastSegment =
      filteredAndSorteredSegments && filteredAndSorteredSegments[0]
        ? filteredAndSorteredSegments[filteredAndSorteredSegments.length - 1]
        : null;
    const lastSegmentUID = lastSegment ? lastSegment.SegmentUID : '';
    const selectedSegment = this.props.formValueSelector(
      'ClassficationsSegmentUID',
    )
      ? this.props.formValueSelector('ClassficationsSegmentUID')
      : lastSegmentUID;

    if (!this.props.formValueSelector('ClassficationsSegmentUID'))
      this.setClassificationsSegment(lastSegmentUID);

    const ERAUID = this.props.formValueSelector('ERAUID');

    const classificationsData = await dispatch(
      getListSegment({
        segment: selectedSegment,
        ERAUID,
      }),
    );

    const classifications = classificationsData ? classificationsData : [];
    this.setState({ classifications });
  };

  setClassificationsSegment = async (value: string) => {
    this.changeField('ClassficationsSegmentUID', value);
    this.loadClassificationsCard();
  };

  changeField = async (fieldName: string, value: Object) => {
    const { change } = this.props;
    change(fieldName, value);
    await delay(200);
    return this.setState((prevState) => prevState);
  };

  togglePasswordVisiblity = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleImageSelected = (images: Object[]) => {
    const { dispatch } = this.props;
    const image = images[0];
    this.imageProfileBlob = image.preview;
    try {
      dispatch(uploadAthletePhoto(image)).then(async (resultUpload) => {
        // Since AWS S3 won't answer the valid image immediately
        await delay(1000);
        this.changeField('PhotoFilename', resultUpload.imageUrl);
      });
    } catch (error) {
      // eslint-disable-line react/no-unused-state, linter bug
      this.imageProfileBlob = '';
    }
  };

  isValidToSave = () => {
    const fname = this.props.formValueSelector('firstName');
    const lname = this.props.formValueSelector('lastName');
    const discipline = this.props.formValueSelector('Disc');
    const gender = this.props.formValueSelector('Gender');
    const { isParent, isYouth, isAthlete } = this.props.contestant;

    if (!fname) {
      return false;
    } else if (lname === undefined || lname === '') {
      return false;
    } else if (gender === null || gender === '') {
      return false;
    } else if (!discipline) {
      if (isParent && !isYouth && !isAthlete) return true;
      return false;
    }
    return true;
  };

  getErrorMessage = () => {
    const { contestantErrorMessage } = this.props;
    const { error } = this.state;
    if (!!contestantErrorMessage || error) {
      return contestantErrorMessage || error;
    }
    return null;
  };

  changeGender = (newValue: string) => {
    this.changeField('Gender', newValue);
  };

  getCountryDetails = () => {
    const { country } = this.state;
    const countryToFind = country ? country : 'US';
    const { [countryToFind]: countryDetails } = AVAILABLE_COUNTRIES_AND_DETAILS;
    return countryDetails
      ? countryDetails
      : AVAILABLE_COUNTRIES_AND_DETAILS['US'];
  };

  getCountryOptions = () => {
    const countryOptions = countries.JSON.filter((country) =>
      AVAILABLE_COUNTRY_CODES.includes(country.alpha2),
    );
    return countryOptions;
  };

  getStateOptions = () => {
    const { country } = this.state;
    if (!AVAILABLE_COUNTRY_CODES.includes(country)) {
      return [];
    }
    const foundCountry = countries.getCountryByNameOrShortName(country, true);
    return foundCountry ? foundCountry.provinces : [];
  };
  changeCountry = (newValue: string) => {
    this.setState({ country: newValue });
  };

  setCountryAndState = () => {
    const { country, state } = this.props.contestant;
    this.setState({ country, state });
  };

  renderStatesProvinces = (disabled: boolean) => {
    const { errors } = this.props;
    const { state } = this.state;
    const statesProvinces = this.getStateOptions();
    const countryDetails = this.getCountryDetails();
    const label = countryDetails
      ? countryDetails.administrativeDivision
      : 'State';

    const component = (
      <Field
        margin="none"
        name="state"
        component={renderSelectBoxField}
        label={label}
        placeholder={label}
        type="text"
        options={statesProvinces}
        value={state}
        multi={false}
        valueKey="short"
        labelKey="name"
        disabled={disabled}
        searchable={false}
        invalid={errors.state}
        error={errors.state}
        touched={true}
        variant="filled"
        className="address-field"
        parentClass="no-padding"
      />
    );
    return component;
  };

  onBlurNormalize = (value: string) => {
    return value && value.trim();
  };

  render() {
    const {
      contestant,
      disciplines,
      handleSubmit,
      history,
      segments,
      errors,
      user,
    } = this.props;

    const { isYouth, isAthlete, country } = this.props.contestant;
    const { leaderboard, classifications } = this.state;
    const standings =
      leaderboard && typeof leaderboard === 'object' ? leaderboard : [];
    const roleOptions = this.getRoles();
    const viewType = contestant.userId ? 'athlete' : 'youth';
    const linkedAccounts = contestant.linkedAccounts || [];
    const postalLabel = this.getCountryDetails().postalTerm;
    const valueDOBVerified = this.props.formValueSelector('DOBVerified');
    const disabled = cantEdit.includes(user.role);
    const isYouthAthlete = isYouthByDOB(contestant.DOB);
    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit)} role="presentation">
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} className="grid-athlete-edit">
              <Paper className="paper-content">
                <h2 className="athlete-form-section title">Athlete Details</h2>
                <h3 className="athlete-form-section subtitle">Account Info</h3>
                <Grid container spacing={24}>
                  <Grid item xs={12} sm={4}>
                    <NameField
                      margin="none"
                      name="firstName"
                      className="form-row-field"
                      component={FormField}
                      label="First Name"
                      parentClass="no-padding"
                      placeholder="First Name"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                      invalid={errors.firstName}
                      error={errors.firstName}
                      normalizeOnBlur={this.onBlurNormalize}
                      validate={[required, name]}
                      required
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <NameField
                      margin="none"
                      name="lastName"
                      className="form-row-field"
                      component={FormField}
                      label="Last Name"
                      parentClass="no-padding"
                      placeholder="Last Name"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                      invalid={errors.lastName}
                      error={errors.lastName}
                      normalizeOnBlur={this.onBlurNormalize}
                      validate={[required, name]}
                      required
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      margin="none"
                      name="Nickname"
                      className="form-row-field"
                      component={FormField}
                      label="Nickname"
                      parentClass="no-padding"
                      placeholder=""
                      type="select"
                      helpertext=""
                      normalizeOnBlur={this.onBlurNormalize}
                      variant="filled"
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={24}>
                  <Grid item xs={12} sm={4}>
                    <NameField
                      margin="none"
                      name="ERAUID"
                      className="form-row-field"
                      component={FormField}
                      parentClass="no-padding"
                      label="WCRA Number"
                      placeholder="WCRA Number"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                      startAdornment={
                        <InputAdornment position="start">
                          <LockOutlinedIcon className="color-black field-icon" />
                        </InputAdornment>
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      margin="none"
                      name="userId"
                      className="form-row-field"
                      component={FormField}
                      parentClass="no-padding"
                      label="User Number"
                      placeholder="User Number"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                      startAdornment={
                        <InputAdornment position="start">
                          <LockOutlinedIcon className="color-black field-icon" />
                        </InputAdornment>
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      margin="none"
                      name="PayeeNumber"
                      className="form-row-field"
                      disabled={disabled || contestant.PayeeNumber}
                      component={FormField}
                      startAdornment={
                        contestant.PayeeNumber ? (
                          <InputAdornment position="start">
                            <LockOutlinedIcon className="color-black field-icon" />
                          </InputAdornment>
                        ) : null
                      }
                      label="Payee Number"
                      parentClass="no-padding"
                      placeholder="Payee Number"
                      type="text"
                      validate={[onlyAlphaNumeric]}
                      helpertext=""
                      variant="filled"
                      invalid={errors.PayeeNumber}
                      error={errors.PayeeNumber}
                    />
                  </Grid>
                </Grid>

                <h3 className="athlete-form-section subtitle with-margin">
                  Profile Info
                </h3>
                <Grid container spacing={24}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="role"
                      className="athlete-select"
                      component={renderSelectBoxField}
                      label="User Type"
                      parentClass="no-padding"
                      placeholder=""
                      helpertext=""
                      searchable={false}
                      closeOnSelect
                      multi={false}
                      valueKey="id"
                      labelKey="name"
                      options={roleOptions}
                      variant="filled"
                      validate={viewType === 'athlete' ? [required] : []}
                      required={viewType === 'athlete'}
                      disabled={viewType === 'youth' || disabled}
                      invalid={errors.role}
                      error={errors.role}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="type"
                      className="form-row-field"
                      component={FormField}
                      parentClass="no-padding"
                      label="Account Type"
                      placeholder="Account Type"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                      startAdornment={
                        <InputAdornment position="start">
                          <LockOutlinedIcon className="color-black field-icon" />
                        </InputAdornment>
                      }
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={16}>
                      <Grid item>
                        <Field
                          name="DOBVerified"
                          label="Verified"
                          component={Checkbox}
                          isInteger
                          margin="none"
                          className="green"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          margin="none"
                          name="DOB"
                          component={FormField}
                          label="Date of Birth"
                          type="date"
                          parentClass="no-padding"
                          placeholder="Date of Birth"
                          disabled={disabled || valueDOBVerified}
                          startAdornment={
                            valueDOBVerified ? (
                              <InputAdornment position="start">
                                <LockOutlinedIcon className="color-black field-icon" />
                              </InputAdornment>
                            ) : null
                          }
                          helpertext=""
                          variant="filled"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="Disc"
                      className="athlete-select"
                      component={renderSelectBoxField}
                      label="Discipline"
                      parentClass="no-padding"
                      placeholder=""
                      helpertext=""
                      disabled={disabled}
                      searchable={false}
                      closeOnSelect
                      multi={false}
                      valueKey="id"
                      labelKey="name"
                      options={disciplines}
                      variant="filled"
                      validate={[required]}
                      invalid={errors.Disc}
                      error={errors.Disc}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="Hometown"
                      disabled={disabled}
                      className="form-row-field"
                      component={FormField}
                      label="Home Town"
                      parentClass="no-padding"
                      placeholder="Home Town"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                      startAdornment={
                        <InputAdornment position="start">
                          <LocationOnIcon className="color-green field-icon" />
                        </InputAdornment>
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      component={Gender}
                      name="gender"
                      type="radio"
                      disabled={disabled}
                      changeGender={this.changeGender}
                      gender={this.props.contestant.Gender}
                    />
                  </Grid>
                </Grid>

                {viewType === 'athlete' && (
                  <>
                    <h3 className="athlete-form-section subtitle with-margin">
                      Profile Picture
                    </h3>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={1}>
                        <div className="athlete-photo">
                          {(this.getImage() && (
                            <Avatar
                              className="athlete-photo-circle"
                              src={this.getImage()}
                            />
                          )) || (
                            <Avatar className="athlete-photo-circle">
                              <img
                                alt=""
                                className="menu-icon-svg"
                                src={AthletesIcon}
                              />
                            </Avatar>
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FileUploadButton
                          handleSelected={this.handleImageSelected}
                          types="image/jpeg, image/png"
                          disabled={this.getLoadingState() || disabled}
                          className="btn-upload-event-image"
                          color="primary"
                        >
                          Upload image&nbsp;
                          <PublishIcon className="btn-upload-photo-icon" />
                        </FileUploadButton>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Paper>

              <Paper className="paper-content margin-top-20">
                <h3 className="athlete-form-section title">
                  {isYouth ? 'Parent ' : ''}Contact Info
                </h3>
                <Grid container spacing={24}>
                  {viewType === 'athlete' && (
                    <Grid item xs={12} sm={6}>
                      <Field
                        margin="none"
                        name="email"
                        className="form-row-field"
                        component={FormField}
                        label="Email"
                        parentClass="no-padding"
                        placeholder="Email"
                        type="textarea"
                        helpertext=""
                        variant="filled"
                        validate={[required, email]}
                        invalid={errors.email}
                        error={errors.email}
                        required
                        disabled={disabled}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="Phone"
                      className="form-row-field"
                      component={FormField}
                      label="Phone Number"
                      parentClass="no-padding"
                      placeholder="Phone Number"
                      type="textarea"
                      helpertext=""
                      disabled={disabled || isYouth}
                      variant="filled"
                      normalize={phone}
                      maxLength={25}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={24}>
                  <Grid item xs={6} sm={6}>
                    <Field
                      margin="none"
                      name="country"
                      component={renderSelectBoxField}
                      parentClass="no-padding"
                      label="Country"
                      disabled={disabled || isYouth}
                      placeholder="Country"
                      required={true}
                      type="text"
                      helpertext=""
                      variant="filled"
                      options={this.getCountryOptions()}
                      touched={true}
                      validate={[required]}
                      invalid={errors.country}
                      error={errors.country}
                      value={country}
                      multi={false}
                      valueKey="alpha2"
                      labelKey="name"
                      onChange={this.changeCountry}
                      className="form-row-field"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} />
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="street"
                      className="form-row-field"
                      component={FormField}
                      label="Street Address"
                      parentClass="no-padding"
                      placeholder="Street Address"
                      type="textarea"
                      helpertext=""
                      invalid={errors.street}
                      error={errors.street}
                      disabled={disabled || isYouth}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="street2"
                      className="form-row-field"
                      component={FormField}
                      label="Address Line 2"
                      parentClass="no-padding"
                      placeholder="Address Line 2"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                      disabled={disabled || isYouth}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={24}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      margin="none"
                      name="city"
                      className="form-row-field"
                      component={FormField}
                      label="City"
                      parentClass="no-padding"
                      placeholder="City"
                      type="textarea"
                      helpertext=""
                      error={errors.city}
                      invalid={errors.city}
                      disabled={disabled || isYouth}
                      variant="filled"
                      startAdornment={
                        <InputAdornment position="start">
                          <LocationOnIcon className="color-green field-icon" />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {this.renderStatesProvinces(disabled || isYouth)}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      margin="none"
                      name="zip"
                      className="form-row-field"
                      component={FormField}
                      label={postalLabel}
                      parentClass="no-padding"
                      placeholder={postalLabel}
                      type="textarea"
                      helpertext=""
                      variant="filled"
                      invalid={errors.zip}
                      error={errors.zip}
                      disabled={disabled || isYouth}
                      startAdornment={
                        <InputAdornment position="start">
                          <LocationOnIcon className="color-green field-icon" />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Paper className="paper-content margin-top-20">
                <h3 className="athlete-form-section title">Career Info</h3>
                <Grid container spacing={24}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="CareerEarnings"
                      className="form-row-field"
                      component={FormField}
                      label="Career Earnings"
                      parentClass="no-padding"
                      disabled={disabled}
                      placeholder="Career Earnings"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                      startAdornment={
                        <InputAdornment position="start">
                          <DollarIcon className="color-green field-icon" />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="WorldChampionshipEarned"
                      className="form-row-field"
                      component={FormField}
                      label="World Championship Earned"
                      disabled={disabled}
                      parentClass="no-padding"
                      placeholder="World Championship Earned"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={24}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="Height"
                      className="form-row-field"
                      disabled
                      component={FormField}
                      label="Height"
                      parentClass="no-padding"
                      placeholder="Height"
                      type="textarea"
                      helpertext=""
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="Weight"
                      className="form-row-field"
                      component={FormField}
                      label="Weight"
                      parentClass="no-padding"
                      placeholder="Weight"
                      disabled={disabled}
                      type="textarea"
                      helpertext=""
                      variant="filled"
                    />
                  </Grid>
                </Grid>
              </Paper>

              {viewType === 'athlete' && (
                <Paper className="paper-content margin-top-20">
                  <h3 className="athlete-form-section title">Social Media</h3>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        margin="none"
                        name="socialTags"
                        className="form-row-field"
                        component={FormField}
                        label="Social Media Hashtags"
                        parentClass="no-padding"
                        placeholder='Social Media Hashtags ("Example", "Example")'
                        type="textarea"
                        helpertext=""
                        disabled={disabled}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        margin="none"
                        name="Facebook"
                        className="form-row-field"
                        component={FormField}
                        label="Facebook URL"
                        parentClass="no-padding"
                        placeholder="Facebook URL"
                        type="textarea"
                        helpertext=""
                        disabled={disabled}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        margin="none"
                        name="Twitter"
                        className="form-row-field"
                        component={FormField}
                        label="Twitter"
                        parentClass="no-padding"
                        placeholder="Twitter"
                        type="textarea"
                        helpertext=""
                        disabled={disabled}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        margin="none"
                        name="Instagram"
                        className="form-row-field"
                        component={FormField}
                        label="Instagram"
                        parentClass="no-padding"
                        placeholder="Instagram"
                        type="textarea"
                        helpertext=""
                        variant="filled"
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              )}

              {viewType === 'athlete' && (
                <Paper className="paper-content margin-top-20">
                  <h3 className="athlete-form-section title">Password Reset</h3>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        margin="none"
                        name="Password"
                        className="form-row-field"
                        component={FormField}
                        label="Password"
                        parentClass="no-padding"
                        placeholder="Reset Password"
                        type={this.state.showPassword ? 'textarea' : 'password'}
                        helpertext=""
                        variant="filled"
                        autoComplete="new-password"
                        disabled={disabled}
                        invalid={errors.Password}
                        error={errors.Password}
                        startAdornment={
                          <InputAdornment
                            className="password-icon edit-athlete"
                            position="end"
                            onClick={() => this.togglePasswordVisiblity()}
                          >
                            <VisibilityIcon className="color-green" />
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </Grid>

            <Grid item xs={12} sm={6} className="grid-athlete-edit">
              {(isYouth || isAthlete) &&
              flagsmith.hasFeature('athlete_classifications_card') ? (
                <Paper className="paper-content margin-bottom-20">
                  <h3 className="athlete-form-section title">
                    Classifications
                  </h3>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        margin="none"
                        name="ClassficationsSegmentUID"
                        className="athlete-select"
                        component={renderSelectBoxField}
                        label="Segment"
                        parentClass="no-padding"
                        placeholder=""
                        helpertext=""
                        searchable={false}
                        closeOnSelect
                        multi={false}
                        valueKey="id"
                        labelKey="name"
                        options={segments
                          .filter(
                            (segment) =>
                              ((!isYouthAthlete && !segment.HasYouth) ||
                                isYouthAthlete) &&
                              (segment.HasYouth ||
                                segment.HasPro ||
                                segment.HasChallenger),
                          )
                          .sort(
                            (a, b) => new Date(b.EndDate) - new Date(a.EndDate),
                          )
                          .reverse()
                          .map((segment) => ({
                            id: segment.SegmentUID,
                            name: segment.Name,
                          }))}
                        onChange={this.setClassificationsSegment}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>

                  <ClassificationsTable
                    classifications={classifications}
                    loadClassificationsCard={this.loadClassificationsCard}
                    disabled={disabled}
                    athleteObj={() => {
                      if (
                        Array.isArray(segments) &&
                        segments.length &&
                        !!this.props.formValueSelector(
                          'ClassficationsSegmentUID',
                        )
                      ) {
                        const parent = isYouth ? linkedAccounts[0] : null;
                        const currentSegment = segments.find(
                          ({ SegmentUID }) =>
                            parseInt(SegmentUID) ===
                            parseInt(
                              this.props.formValueSelector(
                                'ClassficationsSegmentUID',
                              ),
                            ),
                        );
                        const {
                          HasPro,
                          HasChallenger,
                          HasYouth,
                          HasOpen,
                          Name: SegmentName,
                          ShortName: SegmentShortName,
                          SegmentUID,
                        } = currentSegment;

                        return {
                          FullName: `${this.props.formValueSelector(
                            'firstName',
                          )} ${this.props.formValueSelector('lastName')}`,
                          Email: parent
                            ? parent.Email
                            : this.props.formValueSelector('email'),
                          PhoneNumber: this.props.formValueSelector('Phone'),
                          HasPro,
                          HasChallenger,
                          HasYouth,
                          HasOpen,
                          SegmentName,
                          SegmentShortName,
                          SegmentUID,
                          ERAUID: parseInt(
                            this.props.formValueSelector('ERAUID'),
                          ),
                        };
                      } else {
                        return {};
                      }
                    }}
                  />
                </Paper>
              ) : (
                ''
              )}
              {isYouth || isAthlete ? (
                <Paper className="paper-content margin-bottom-20">
                  <h3 className="athlete-form-section title">
                    Leaderboard standing
                  </h3>

                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        margin="none"
                        name="SegmentUID"
                        className="athlete-select"
                        component={renderSelectBoxField}
                        label="Segment"
                        parentClass="no-padding"
                        placeholder=""
                        helpertext=""
                        searchable={false}
                        closeOnSelect
                        disabled={disabled}
                        multi={false}
                        valueKey="id"
                        labelKey="name"
                        options={segments.map((segment) => ({
                          id: segment.SegmentUID,
                          name: segment.Name,
                        }))}
                        onChange={this.setSegment}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>

                  <LeaderboardStandingsTable standings={standings} />
                </Paper>
              ) : (
                ''
              )}

              <Paper className="paper-content margin-bottom-20 admin-notes">
                <h3 className="athlete-form-section title">Admin notes</h3>

                <Grid container spacing={24}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      margin="none"
                      name="AdminNotes"
                      className="form-row-field"
                      component={FormField}
                      parentClass="no-padding"
                      multiline
                      maxLength={2000}
                      rows={10}
                      placeholder="Admin Notes"
                      type="textarea"
                      helpertext=""
                      disabled={disabled}
                      variant="filled"
                    />
                  </Grid>
                </Grid>
              </Paper>

              {linkedAccounts && linkedAccounts.length > 0 && (
                <Paper className="paper-content margin-bottom-20">
                  <h3 className="athlete-form-section title">
                    Linked Accounts
                  </h3>

                  <Table aria-label="simple table" padding="none">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          padding="none"
                          className="linked-accounts-cell"
                        >
                          WCRA #
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="left"
                          className="linked-accounts-cell"
                        >
                          NAME
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="left"
                          className="linked-accounts-cell"
                        >
                          DISCIPLINE
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="left"
                          className="linked-accounts-cell"
                        >
                          DATE OF BIRTH
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="left"
                          className="linked-accounts-cell"
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {linkedAccounts.map((account) => (
                        <TableRow key={account.ERAUID}>
                          <TableCell padding="none" component="th" scope="row">
                            {account.ERAUID}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="left"
                            style={{ textTransform: 'uppercase' }}
                          >
                            {account.FullName}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="left"
                            style={{ textTransform: 'uppercase' }}
                          >
                            {account.Discipline}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="left"
                            style={{ textTransform: 'uppercase' }}
                          >
                            {moment(account.DOB)
                              .utc()
                              .format('MM/DD/YY')}
                          </TableCell>
                          <TableCell padding="none" align="left">
                            <a
                              className="nav-to-youth"
                              href={`${routeCodes.ATHLETES_EDIT.replace(
                                ':id',
                                account.ERAUID,
                              )}`}
                            >
                              <ExternalLink />
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              )}
              <div className="marTop-20">
                <ErrorMessage
                  errorMessage={this.getErrorMessage()}
                  scrollToTop={true}
                />
              </div>
              <div className="buttons-control">
                <Button
                  onClick={() => history.push(routeCodes.ATHLETES)}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={
                    this.getLoadingState() || !this.isValidToSave() || disabled
                  }
                  color="secondary"
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.zip) {
    delete errors.zip;
  } else if (postalCodeValidator(values.zip, values.country)) {
    errors.zip = postalCodeValidator(values.zip, values.country);
  }

  if (!values.state) {
    delete errors.state;
  } else if (values.state && values.country) {
    errors.state = stateProvinceValidator(values.state, values.country);
  }

  if (!/^\S*$/.test(values.Password)) {
    errors.Password = 'Spaces are not allowed.';
  } else if (!/.{6}/i.test(values.Password)) {
    errors.Password = 'Password must be at least six characters.';
  }

  return errors;
};

export default compose(
  // $FlowIgnore
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: FORM_NAME,
    validate,
    enableReinitialize: true,
  }),
  withToJS,
)(AthleteForm);
