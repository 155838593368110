import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import adminSetting from './adminSetting';
import alert from './alert';
import animal from './animal';
import animalType from './animalType';
import association from './association';
import athleteNomination from './athleteNomination';
import auth from './auth';
import contestant from './contestant';
import contractor from './contractor';
import coupon from './coupon';
import csv from './csv';
import dashboard from './dashboard';
import discipline from './discipline';
import entryPool from './entryPool';
import event from './event';
import eventAnimal from './eventAnimal';
import eventType from './eventType';
import filter from './filter';
import layout from './layout';
import livestock from './livestock';
import modals from './modals';
import nomination from './nomination';
import overAllResult from './overAllResult';
import performance from './performance';
import performanceAnimal from './performanceAnimal';
import performanceResult from './performanceResult';
import purse from './purse';
import segment from './segment';
import stockDraw from './stockDraw';
import stripe from './stripe';
import summary from './summary';
import summaryOverall from './summaryOverall';
import timeZone from './timeZone';
import user from './user';
import venue from './venue';
import giftcard from './giftcard';
import reports from './reports';
import contestantDiscipline from './contestantDiscipline';
import personnelRole from './personnelRole';
import giftcardAccount from './giftcardAccount';
import mergeEvent from './mergeEvent';
import log from './log';

export default combineReducers({
  adminSetting,
  alert,
  animal,
  animalType,
  association,
  athleteNomination,
  auth,
  contestant,
  contractor,
  coupon,
  csv,
  dashboard,
  discipline,
  entryPool,
  event,
  eventAnimal,
  eventType,
  filter,
  form: FormReducer,
  layout,
  livestock,
  modals,
  nomination,
  overAllResult,
  performance,
  performanceAnimal,
  performanceResult,
  purse,
  segment,
  stockDraw,
  stripe,
  summary,
  summaryOverall,
  timeZone,
  user,
  venue,
  mergeEvent,
  giftcard,
  reports,
  contestantDiscipline,
  personnelRole,
  giftcardAccount,
  log,
});
