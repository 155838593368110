import Livestock from 'models/Livestock';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';
import { setLocalStorage, getLocalStorage } from 'helpers/localStorage';

import {
  LIVESTOCK_ASYNC_START,
  LIVESTOCK_ACTION_CLEAR,
  LIVESTOCK_ASYNC_STOP,
  LIVESTOCK_ASYNC_FAIL,
  LIVESTOCK_ASYNC_SUCCESS,
  LIVESTOCK_LIST_ASYNC_SUCCESS,
  LIVESTOCK_REMOVE_SUCCESS,
  LIVESTOCK_ASYNC_CLEAR_ERROR,
  LIVESTOCK_CREATE_SAVE_ASYNC_SUCCESS,
  LIVESTOCK_UPDATE_OPTIONS,
} from 'actions/livestock';

const tableOptions = getLocalStorage('TableOptions', 'livestock') || {};

export const initialLivestockOptions = {
  order: 'desc',
  orderBy: 'AnimalUID',
  orderIsDate: false,
  page: 0,
  rowsPerPage: tableOptions.rowsPerPage || 50,
};

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: null,
  list: toEntityList([], Livestock),
  options: initialLivestockOptions,
});

const actionsMap = {
  [LIVESTOCK_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: null,
    });
  },
  [LIVESTOCK_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
    });
  },
  [LIVESTOCK_CREATE_SAVE_ASYNC_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [LIVESTOCK_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [LIVESTOCK_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: action.data,
    });
  },
  [LIVESTOCK_LIST_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.livestockPayload) return state;

    const { livestock, meta } = action.data.livestockPayload;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(livestock, Livestock),
      totalCount: meta.totalCount,
    });
  },
  [LIVESTOCK_REMOVE_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
    });
  },
  [LIVESTOCK_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [LIVESTOCK_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [LIVESTOCK_UPDATE_OPTIONS]: (state, action) => {
    const { order, orderBy, page, rowsPerPage } = action.data;

    setLocalStorage('TableOptions', 'livestock', { rowsPerPage });

    return state.merge({
      options: Object.assign({}, state.options, {
        order,
        orderBy,
        page,
        rowsPerPage,
      }),
    });
  },
};

export default function livestock(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
