import AnimalType from 'models/AnimalType';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  ANIMAL_TYPE_ASYNC_START,
  ANIMAL_TYPE_ACTION_CLEAR,
  ANIMAL_TYPE_ASYNC_FAIL,
  ANIMAL_TYPE_ASYNC_SUCCESS,
  ANIMAL_TYPE_LIST_ASYNC_SUCCESS,
  ANIMAL_TYPE_ASYNC_CLEAR_ERROR,
} from 'actions/animalType';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new AnimalType(),
  list: toEntityList([], AnimalType),
});

const actionsMap = {
  [ANIMAL_TYPE_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [ANIMAL_TYPE_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [ANIMAL_TYPE_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new AnimalType(action.data),
    });
  },
  [ANIMAL_TYPE_LIST_ASYNC_SUCCESS]: (state, action) => {
    const animalTypes = action.data;
    const selectedAnimalType =
      animalTypes && animalTypes.length > 0
        ? new AnimalType(animalTypes[0])
        : null;
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(animalTypes, AnimalType),
      data: selectedAnimalType,
    });
  },
  [ANIMAL_TYPE_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [ANIMAL_TYPE_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
};

export default function animalType(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
