import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';
import GoRoundOverAllResult from './GoRoundOverAllResult';

const defaultValues = {
  ERAUID: null,
  Discipline: '',
  ContestantName: '',
  TeammateName: '',
  GoRounds: toEntityList([], GoRoundOverAllResult),
  Total: 0,
  Rank: 0,
  AverageMoney: 0,
  Selected: false,
};

export default class OverAllResult extends Record(
  defaultValues,
  'OverAllResult',
) {
  constructor(values) {
    super({
      ...values,
      GoRounds: values.GoRounds
        ? toEntityList(values.GoRounds, GoRoundOverAllResult)
        : defaultValues.GoRounds,
    });
  }
}
