import client from 'api/apollo';
import { logError } from 'helpers';
import { LIST_EVENT_LOGS } from 'graphql/queries/log/getLogs';

export const EVENT_LOG_BY_PAGINATION_ASYNC_START =
  'EVENT_LOG_BY_PAGINATION_ASYNC_START';
export const EVENT_LOG_BY_PAGINATION_ASYNC_SUCCESS =
  'EVENT_LOG_BY_PAGINATION_ASYNC_SUCCESS';
export const EVENT_LOG_BY_PAGINATION_ASYNC_FAIL =
  'EVENT_LOG_BY_PAGINATION_ASYNC_FAIL';

export const EVENT_LOG_UPDATE_OPTIONS = 'EVENT_LOG_UPDATE_OPTIONS';

export const EVENT_LOG_UPDATE = 'EVENT_LOG_UPDATE';

export const EVENT_LOG_CARD_UPDATE = 'EVENT_LOG_CARD_UPDATE';

function eventLogByPaginationStart() {
  return {
    type: EVENT_LOG_BY_PAGINATION_ASYNC_START,
  };
}

function eventLogByPaginationSuccess(data) {
  return {
    type: EVENT_LOG_BY_PAGINATION_ASYNC_SUCCESS,
    data,
  };
}

function eventLogByPaginationFail(error) {
  return {
    type: EVENT_LOG_BY_PAGINATION_ASYNC_FAIL,
    error,
  };
}

export function updateLogOptions(
  sortDirection,
  orderBy,
  pageNumber,
  resultsPerPage,
) {
  return {
    type: EVENT_LOG_UPDATE_OPTIONS,
    data: { sortDirection, orderBy, pageNumber, resultsPerPage },
  };
}

export function eventLogByPagination(values) {
  return async (dispatch) => {
    try {
      const {
        EventUID,
        pageNumber,
        sortDirection,
        orderBy,
        resultsPerPage,
      } = values;
      const variables = {
        input: {
          EventUID,
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
        },
      };

      dispatch(eventLogByPaginationStart());
      const eventLogQuery = {
        query: LIST_EVENT_LOGS,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(eventLogQuery);

      dispatch(eventLogByPaginationSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      const err =
        'There was a problem getting the event logs, please try again.';
      dispatch(eventLogByPaginationFail(err));
      return null;
    }
  };
}

export function logOptions(sortDirection, orderBy, pageNumber, resultsPerPage) {
  return {
    type: EVENT_LOG_UPDATE_OPTIONS,
    data: { sortDirection, orderBy, pageNumber, resultsPerPage },
  };
}
