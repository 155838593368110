import { Record } from 'immutable';

const defaultValues = {
  message: null,
  type: null,
  showClose: null,
};

export default class Alert extends Record(defaultValues, 'Alert') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
