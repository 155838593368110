export const STATUS = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  REFUND: 'REFUND',
  CREATED: 'CREATED',
};

export const NOMINATION = {
  options_status: [
    { id: 1, value: STATUS.ACTIVE },
    //{ id: 2, value: 'PARTIALLY REFUNDED' },
    { id: 3, value: STATUS.DELETED },
  ],
};

export const DIVISION_CHANGE = 1;
export const FULL_REFUND = 2;

export const REFUND_TO_CREDIT_CARD = 1;
export const REFUND_WITH_VRQ_COUPON = 2;

export const refundTypesOptions = [
  { value: FULL_REFUND, label: 'Full Refund' },
  { value: DIVISION_CHANGE, label: 'Division Change' },
];

export const refundMethods = [
  { value: REFUND_TO_CREDIT_CARD, label: 'REFUND TO CREDIT CARD' },
  { value: REFUND_WITH_VRQ_COUPON, label: 'REFUND WITH VRQ COUPON' },
];
