// @flow
import React, { type Node } from 'react';
import { Typography } from '@material-ui/core';

type ParagraphPropsType = {|
  +className?: string,
  +children: Node,
  +color?: string,
  +fontSize?: number,
  +style?: Object,
|};

export const Paragraph = (props: ParagraphPropsType) => {
  const style = props.style || {};
  return (
    <Typography
      className={props.className || ''}
      style={{ fontSize: props.fontSize || 14, ...style }}
      color={props.color || 'textSecondary'}
    >
      {props.children}
    </Typography>
  );
};

export const ParagraphUpper = (props: { children: Node }) => (
  <Paragraph fontSize={16} style={{ textTransform: 'uppercase' }}>
    {props.children}
  </Paragraph>
);
