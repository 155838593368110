import api from 'api';
import client from 'api/apollo';
import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';
import { filterToRequest } from 'helpers/filterToRequest';
import { getFromState, getToken, getFilters, logError } from 'helpers';
import { LIST_LIVESTOCK } from 'graphql/queries/livestock/listLivestock';
import { LIST_LIVESTOCK_OWNERS } from 'graphql/queries/livestock/listLivestockOwners';

export const LIVESTOCK_ASYNC_START = 'LIVESTOCK_ASYNC_START';
export const LIVESTOCK_ACTION_CLEAR = 'LIVESTOCK_ACTION_CLEAR';
export const LIVESTOCK_ASYNC_STOP = 'LIVESTOCK_ASYNC_STOP';
export const LIVESTOCK_ASYNC_FAIL = 'LIVESTOCK_ASYNC_FAIL';
export const LIVESTOCK_REMOVE_SUCCESS = 'LIVESTOCK_REMOVE_SUCCESS';
export const LIVESTOCK_ASYNC_SUCCESS = 'LIVESTOCK_ASYNC_SUCCESS';
export const LIVESTOCK_LIST_ASYNC_SUCCESS = 'LIVESTOCK_LIST_ASYNC_SUCCESS';
export const LIVESTOCK_ASYNC_CLEAR_ERROR = 'LIVESTOCK_ASYNC_CLEAR_ERROR';
export const LIVESTOCK_UPDATE_OPTIONS = 'LIVESTOCK_UPDATE_OPTIONS';
export const LIVESTOCK_CREATE_SAVE_ASYNC_SUCCESS =
  'LIVESTOCK_CREATE_SAVE_ASYNC_SUCCESS';

export function livestockAsyncStart() {
  return {
    type: LIVESTOCK_ASYNC_START,
  };
}

export function livestockActionClear() {
  return {
    type: LIVESTOCK_ACTION_CLEAR,
  };
}

export function livestockAsyncStop() {
  return {
    type: LIVESTOCK_ASYNC_STOP,
  };
}

function livestockAsyncFail(errorMessage) {
  return {
    type: LIVESTOCK_ASYNC_FAIL,
    errorMessage,
  };
}

function livestockRemoveAsyncSuccess() {
  return {
    type: LIVESTOCK_REMOVE_SUCCESS,
  };
}

function livestockCreateOrSaveAsyncSuccess() {
  return {
    type: LIVESTOCK_CREATE_SAVE_ASYNC_SUCCESS,
  };
}

export function livestockAsyncSuccess(data) {
  return {
    type: LIVESTOCK_ASYNC_SUCCESS,
    data,
  };
}

export function livestockListAsyncSuccess({ data }) {
  return {
    type: LIVESTOCK_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function livestockAsyncClearError() {
  return {
    type: LIVESTOCK_ASYNC_CLEAR_ERROR,
  };
}

export function listLivestock(values = {}) {
  return async (dispatch, getState) => {
    dispatch(livestockAsyncStart());
    try {
      const state = getState();
      const filters = getFilters(values, state);
      const { pageNumber, sortDirection, orderBy, resultsPerPage } = values;
      const variables = {
        input: {
          filters: filterToRequest(filters),
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
        },
      };

      const livestockQuery = {
        query: LIST_LIVESTOCK,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(livestockQuery);

      dispatch(livestockListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(livestockAsyncFail(error.message));
      return null;
    }
  };
}

export function listLivestockOwners(values) {
  return async (dispatch) => {
    dispatch(livestockAsyncStart());
    try {
      const { type, query, selectedOwnerId } = values;

      const variables = {
        input: {
          type,
          inputQuery: query,
          selectedOwnerId: parseInt(selectedOwnerId, 10),
        },
      };

      const livestockOwnerQuery = {
        query: LIST_LIVESTOCK_OWNERS,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(livestockOwnerQuery);

      return response.data.livestockOwnersPayload
        ? response.data.livestockOwnersPayload.livestockOwners
        : [];
    } catch (error) {
      logError(error);
      dispatch(livestockAsyncFail(error.message));
      return null;
    }
  };
}

export function saveLivestock(livestockAnimal) {
  return async (dispatch, getState) => {
    dispatch(livestockAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `livestock/${livestockAnimal.AnimalUID}`,
        method: 'POST',
        accessToken,
        body: livestockAnimal,
      });

      await dispatch(livestockCreateOrSaveAsyncSuccess());
      await dispatch(
        createAlertSuccess({
          message: 'Livestock successfully updated.',
          type: 'success',
        }),
      );
      setTimeout(() => dispatch(listLivestock()));
      await dispatch(clearAlertSuccessAsync());
      return response;
    } catch (error) {
      logError(error);
      await dispatch(
        createAlertSuccess({
          message: 'Error on save your livestock.',
          type: 'fail',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      dispatch(livestockAsyncFail(error.message));
      return null;
    }
  };
}

export function removeLivestockAnimal(livestockAnimal) {
  return async (dispatch, getState) => {
    dispatch(livestockAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);
      const { AnimalUID } = livestockAnimal;

      const response = await api({
        path: `livestock/${AnimalUID}`,
        method: 'DELETE',
        accessToken,
      });

      await dispatch(
        createAlertSuccess({
          message: 'Livestock successfully removed.',
          type: 'success',
        }),
      );
      await dispatch(clearAlertSuccessAsync());
      dispatch(livestockRemoveAsyncSuccess());
      return response;
    } catch (error) {
      logError(error);
      dispatch(livestockAsyncFail(error.message));
      return null;
    }
  };
}

export function selectLivestock(livestock) {
  return (dispatch) => {
    dispatch(livestockAsyncSuccess(livestock));
  };
}

export function clearLivestock() {
  return (dispatch) => {
    dispatch(livestockActionClear());
  };
}

export function updateLivestockOptions(orderBy, order, page, rowsPerPage) {
  return {
    type: LIVESTOCK_UPDATE_OPTIONS,
    data: { orderBy, order, page, rowsPerPage },
  };
}
