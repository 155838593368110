import { Record, List, Map } from 'immutable';

const defaultValues = {
  loading: false,
  errorMessage: '',
  filters: new Map(),
  options: new Map(),
  chlidOptions: new Map(),
  data: new List(),
};

export default class BaseList extends Record(defaultValues, 'BaseList') {
  constructor(values) {
    super({
      ...values,
      filters:
        values && values.filters ? values.filters : defaultValues.filters,
      options:
        values && values.options ? values.options : defaultValues.options,
      childOptions:
        values && values.childOptions
          ? values.childOptions
          : defaultValues.childOptions,
    });
  }
}

export const toEntityList = (data, Entity) => {
  if (!data) return new List();

  return new List(data.map((item) => new Entity(item)));
};

export const shuffleList = (entityList) => {
  const array = entityList;
  let i = 0;
  let j = 0;
  let temp = null;

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};
