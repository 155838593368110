import api from 'api';
import client from 'api/apollo';
import {
  getFilters,
  getFromState,
  getToken,
  logError,
  filterToRequest,
} from 'helpers';
import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';
import { LIST_GIFTCARDS } from 'graphql/queries/giftCards/listGiftCards';
export const GIFTCARD_ASYNC_START = 'GIFTCARD_ASYNC_START';
export const GIFTCARD_REMOVE_SUCCESS = 'GIFTCARD_REMOVE_SUCCESS';
export const GIFTCARD_ACTION_CLEAR = 'GIFTCARD_ACTION_CLEAR';
export const GIFTCARD_ASYNC_FAIL = 'GIFTCARD_ASYNC_FAIL';
export const GIFTCARD_ASYNC_SUCCESS = 'GIFTCARD_ASYNC_SUCCESS';
export const GIFTCARD_LIST_ASYNC_SUCCESS = 'GIFTCARD_LIST_ASYNC_SUCCESS';
export const GIFTCARD_ASYNC_CLEAR_ERROR = 'GIFTCARD_ASYNC_CLEAR_ERROR';
export const GIFTCARD_ACCOUNT_ASYNC_SUCCESS = 'GIFTCARD_ACCOUNT_ASYNC_SUCCESS';
export const GIFTCARD_ACCOUNT_ASYNC_FAIL = ' GIFTCARD_ACCOUNT_ASYNC_FAIL';
export const GIFTCARD_ADD_BALANCE_SUCCESS = 'GIFTCARD_ADD_BALANCE_SUCCESS';

export function giftCardAsyncStart() {
  return {
    type: GIFTCARD_ASYNC_START,
  };
}

export function giftCardRemoveAsyncStop() {
  return {
    type: GIFTCARD_REMOVE_SUCCESS,
  };
}

export function giftCardAddBalanceAsyncSuccess() {
  return {
    type: GIFTCARD_ADD_BALANCE_SUCCESS,
  };
}

export function giftCardActionClear() {
  return {
    type: GIFTCARD_ACTION_CLEAR,
  };
}

function giftCardAsyncFail(errorMessage) {
  return {
    type: GIFTCARD_ASYNC_FAIL,
    errorMessage,
  };
}

function giftCardAccountAsyncFail(errorMessage) {
  return {
    type: GIFTCARD_ACCOUNT_ASYNC_FAIL,
    errorMessage,
  };
}

export function giftCardAsyncSuccess(data) {
  return {
    type: GIFTCARD_ASYNC_SUCCESS,
    data,
  };
}
export function giftCardAccountAsyncSuccess(data) {
  return {
    type: GIFTCARD_ACCOUNT_ASYNC_SUCCESS,
    data,
  };
}

export function giftCardListAsyncSuccess(data) {
  return {
    type: GIFTCARD_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function giftCardAsyncClearError() {
  return {
    type: GIFTCARD_ASYNC_CLEAR_ERROR,
  };
}

export function listGiftCards(values) {
  return async (dispatch, getState) => {
    dispatch(giftCardAsyncStart());
    try {
      const filters = getFilters(values, getState());
      const variables = {
        input: {
          filters: filterToRequest(filters),
        },
      };

      const giftCardsQuery = {
        query: LIST_GIFTCARDS,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(giftCardsQuery);
      const { data } = response;
      const { giftCardsPayload } = data;
      const { giftCards } = giftCardsPayload;

      dispatch(giftCardListAsyncSuccess(giftCards));
      return response;
    } catch (error) {
      logError(error);
      dispatch(giftCardAsyncFail(error.message));
      return null;
    }
  };
}

export function saveGiftCard(values) {
  return async (dispatch, getState) => {
    dispatch(giftCardAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      let response;
      if (values.GiftCardUID) {
        response = await api({
          path: `gift-card/${values.GiftCardUID}`,
          method: 'PATCH',
          body: values,
          accessToken,
        });

        await dispatch(
          createAlertSuccess({
            message: 'Gift Card updated successfully',
            type: 'success',
          }),
        );
        dispatch(clearAlertSuccessAsync());
      } else {
        response = await api({
          path: 'gift-card',
          method: 'POST',
          body: values,
          accessToken,
        });

        await dispatch(
          createAlertSuccess({
            message: 'Gift Card saved successfully',
            type: 'success',
          }),
        );
        dispatch(clearAlertSuccessAsync());
      }
      return response;
    } catch (error) {
      logError(error);
      dispatch(giftCardAsyncFail(error.message));
      return null;
    }
  };
}

export function addBalance(GiftCardUID, values) {
  return async (dispatch, getState) => {
    dispatch(giftCardAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      let response;
      response = await api({
        path: `gift-card/${GiftCardUID}/add-balance`,
        method: 'PATCH',
        body: values,
        accessToken,
      });

      await dispatch(
        createAlertSuccess({
          message: 'Balance was successfully added to the gift card.',
          type: 'success',
        }),
      );
      dispatch(giftCardAddBalanceAsyncSuccess());
      return response;
    } catch (error) {
      logError(error);
      dispatch(giftCardAsyncFail(error.message));
      return null;
    }
  };
}

export function removeGiftCard(GiftCardID) {
  return async (dispatch, getState) => {
    dispatch(giftCardAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `gift-card/${GiftCardID}`,
        method: 'DELETE',
        accessToken,
      });

      await dispatch(
        createAlertSuccess({
          message: 'Gift Card removed successfully',
          type: 'success',
        }),
      );
      dispatch(clearAlertSuccessAsync());
      dispatch(giftCardRemoveAsyncStop());

      return response;
    } catch (error) {
      logError(error);
      dispatch(giftCardAsyncFail(error.message));
      return null;
    }
  };
}

export function getGiftCardAccount(GiftCardID) {
  return async (dispatch, getState) => {
    dispatch(giftCardAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `gift-card-accountNumber/${GiftCardID}`,
        method: 'GET',
        accessToken,
      });

      dispatch(giftCardAccountAsyncSuccess(response));

      return response;
    } catch (error) {
      logError(error);
      dispatch(giftCardAccountAsyncFail(error.message));
      return null;
    }
  };
}

export function selectGiftCard(giftCard) {
  return (dispatch) => {
    dispatch(giftCardAsyncSuccess(giftCard));
  };
}

export function clearGiftCard() {
  return (dispatch) => {
    dispatch(giftCardActionClear());
  };
}
