import api from 'api';
import { getFromState, getToken, logError } from 'helpers';

export const ANIMAL_TYPE_ASYNC_START = 'ANIMAL_TYPE_ASYNC_START';
export const ANIMAL_TYPE_ACTION_CLEAR = 'ANIMAL_TYPE_ACTION_CLEAR';
export const ANIMAL_TYPE_ASYNC_FAIL = 'ANIMAL_TYPE_ASYNC_FAIL';
export const ANIMAL_TYPE_ASYNC_SUCCESS = 'ANIMAL_TYPE_ASYNC_SUCCESS';
export const ANIMAL_TYPE_LIST_ASYNC_SUCCESS = 'ANIMAL_TYPE_LIST_ASYNC_SUCCESS';
export const ANIMAL_TYPE_ASYNC_CLEAR_ERROR = 'ANIMAL_TYPE_ASYNC_CLEAR_ERROR';

export function animalTypeAsyncStart() {
  return {
    type: ANIMAL_TYPE_ASYNC_START,
  };
}

export function animalTypeActionClear() {
  return {
    type: ANIMAL_TYPE_ACTION_CLEAR,
  };
}

function animalTypeAsyncFail(errorMessage) {
  return {
    type: ANIMAL_TYPE_ASYNC_FAIL,
    errorMessage,
  };
}

export function animalTypeAsyncSuccess(data) {
  return {
    type: ANIMAL_TYPE_ASYNC_SUCCESS,
    data,
  };
}

export function animalTypeListAsyncSuccess(data) {
  return {
    type: ANIMAL_TYPE_LIST_ASYNC_SUCCESS,
    data,
  };
}

export function animalTypeAsyncClearError() {
  return {
    type: ANIMAL_TYPE_ASYNC_CLEAR_ERROR,
  };
}

export function listAnimalTypes() {
  return async (dispatch, getState) => {
    dispatch(animalTypeAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'animal-types',
        method: 'GET',
        accessToken,
      });

      dispatch(animalTypeListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to fetch animalTypes';
      dispatch(animalTypeAsyncFail(err));
      return null;
    }
  };
}

export function selectAnimalType(animalType) {
  return (dispatch) => {
    dispatch(animalTypeAsyncSuccess(animalType));
  };
}

export function clearSelectedAnimalType() {
  return (dispatch) => {
    dispatch(animalTypeActionClear());
  };
}
