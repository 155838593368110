const filterNames = {
  ANIMAL_FILTER: 'AnimalFilter',
  ATHLETE_NOMINATION_FILTER: 'AthleteNominationFilter',
  ATHLETE_FILTER: 'AthleteFilter',
  CONTESTANT_FILTER: 'ContestantFilter',
  CONTRACTOR_FILTER: 'ContractorsFilter',
  ENTRYPOOL_FILTER: 'EntryPoolFilter',
  EVENT_ANIMAL_FILTER: 'EventAnimalFilter',
  EVENT_FILTER: 'EventFilter',
  ASSOCIATION_PROMOTERS_FILTER: 'associationPromotersFilter',
  ASSOCIATION_PROMOTERS_EVENTS_FILTER: 'associationPromotersEventsFilter',
  LIVESTOCK_FILTER: 'LivestockFilter',
  NOMINATION_FILTER: 'NominationsFilter',
  VENUES_FILTER: 'VenuesFilter',
  CLASSIFICATION_FILTER: 'ClassificationFilter',
  RESULTS_EVENT_FILTER: 'ResultsEventFilter',
  COUPONS_FILTER: 'CouponsFilter',
  GIFTCARD_FILTER: 'GiftCardFilter',
};

export const SORT_ORDER = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const ROWS_PER_PAGE_OPTIONS = [50, 100, 250, 500];

export default filterNames;
