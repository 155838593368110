import { Record } from 'immutable';

const defaultValues = {
  ERAUID: null,
  FullName: null,
  FName: null,
  firstName: null,
  LName: null,
  lastName: null,
  Nickname: null,
  DOB: null,
  Suffix: null,
  CType: null,
  StatusType: null,
  Penalty: null,
  BioUID: null,
  AwardUID: null,
  HeritageUID: null,
  HistoryUID: null,
  InjuryCurrentUID: null,
  InjuryHistoryUID: null,
  QuoteUID: null,
  PhotoUID: null,
  Identifier: null,
  ContractorUID: null,
  Brand: null,
  DOBVerified: null,
  Hometown: null,
  AdminNotes: null,
  Website: null,
  Facebook: null,
  Twitter: null,
  Instagram: null,
  YouTube: null,
  Pinterest: null,
  RookieYear: null,
  Height: null,
  Weight: null,
  createdAt: null,
  LastUpdate: null,
  Snapchat: null,
  LinkedIn: null,
  MemType: null,
  PurchaseDate: null,
  userId: null,
  Disc: null,
  CareerEarnings: null,
  WorldChampionshipEarned: null,
  PayeeNumber: null,
  socialTags: null,
  PhotoFilename: null,
  email: null,
  LegalFirstName: null,
  LegalLastName: null,
  SSN: null,
  USCitizen: null,
  street: null,
  city: null,
  state: null,
  zip: null,
  country: null,
  street2: null,
  Phone: null,
  role: null,
  status: null,
  numberOfResults: null,
  linkedAccounts: [],
  isParent: null,
  isAthlete: null,
  isYouth: null,
  type: null,
  disciplineAsString: null,
  entryCountAsLetter: null,
  isUnregisteredAthlete: null,
  SegmentUID: null,
  leaderboardType: null,
  Gender: null,
};
const getUpper = (value) => {
  const result =
    value && typeof value === 'string' ? value.toUpperCase() : value;
  return result;
};
export default class Contestant extends Record(defaultValues, 'Contestant') {
  constructor(values) {
    super({
      ...values,
      country: values ? getUpper(values.country) : null,
      state: values ? getUpper(values.state) : null,
    });
  }
}
