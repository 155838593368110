// @flow
import React from 'react';
import Dropzone from 'react-dropzone';
import { Button } from '@material-ui/core';

type FileUploadButtonProps = {
  children: any,
  handleSelected: (f: Function) => void,
  types: string,
  disabled: boolean,
  variant: ?string,
  className: ?string,
  color: ?string,
};
const FileUploadButton = (props: FileUploadButtonProps) => {
  const {
    children,
    handleSelected,
    types,
    disabled,
    className,
    variant,
    color,
  } = props;
  const onDrop = (acceptedFiles: ?(Object[])) => {
    handleSelected(acceptedFiles);
  };

  return (
    <Button
      disabled={disabled}
      variant={variant}
      className={className}
      color={color}
    >
      <Dropzone
        className="ignore upload-drop-zone"
        multiple={false}
        accept={types}
        onDrop={onDrop}
      >
        {children}
      </Dropzone>
    </Button>
  );
};
FileUploadButton.defaultProps = {
  variant: 'contained',
  className: 'wcra-dark-blue-cta',
  disabled: false,
  color: 'inherit',
};

export default FileUploadButton;
