/**
 * Will receive filters and return only the data needed by backend
 *
 * @param {*} filters
 * Example return:
 * [
 *   {
 *     "columnName": "firstName",
 *     "value": "marty",
 *     "typeSearch": "like",
 *     "columnsToSearch": [
 *       "firstName"
 *     ]
 *   }
 * ]
 */
import { formatFilters } from './formatFilters';
export const filterToRequest = (filters) => {
  const formattedFilters = formatFilters(filters);

  const parsedFilters =
    formattedFilters && formattedFilters.length > 0
      ? JSON.stringify(formattedFilters)
      : '';

  return parsedFilters;
};
