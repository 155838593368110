import { change } from 'redux-form';
import api from 'api';
import client from 'api/apollo';
import {
  formatFilters,
  filterToRequest,
  getFilters,
  getFromState,
  getToken,
  getUser,
  toJS,
  logError,
} from 'helpers';
import Performance from 'models/Performance';
import editPerformanceFormTypes from 'constants/formTypes';
import {
  createAlertSuccess,
  clearAlertSuccess,
  clearAlertSuccessAsync,
} from 'actions/alert';
import { LIST_EVENTS } from 'graphql/queries/events/listEvents';
import { postToastMessage } from 'helpers/postToastMessage';
import { disciplinesById } from 'constants/disciplines';
import {
  getPartnerDisciplineId,
  isPrimaryTeamDiscipline,
} from '../constants/disciplines';
import { DISCIPLINE_REMOVAL_TOOLTIP } from 'constants/event';
const { BAREBACK } = disciplinesById;

export const EVENT_ACTION_SUCCESS = 'EVENT_ACTION_SUCCESS';
export const EVENT_ACTION_CLEAR = 'EVENT_ACTION_CLEAR';
export const EVENT_ASYNC_START = 'EVENT_ASYNC_START';
export const EVENT_ASYNC_STOP = 'EVENT_ASYNC_STOP';
export const EVENT_ASYNC_FAIL = 'EVENT_ASYNC_FAIL';
export const EVENT_ASYNC_SUCCESS = 'EVENT_ASYNC_SUCCESS';
export const EVENT_LIST_ASYNC_SUCCESS = 'EVENT_LIST_ASYNC_SUCCESS';
export const EVENT_SET_IS_WCRA_MANAGED = 'EVENT_SET_IS_WCRA_MANAGED';
export const EVENT_UPDATE_OPTIONS = 'EVENT_UPDATE_OPTIONS';

function eventActionSuccess(data) {
  return {
    type: EVENT_ACTION_SUCCESS,
    data,
  };
}

function eventActionClear() {
  return {
    type: EVENT_ACTION_CLEAR,
  };
}

function eventAsyncStart() {
  return {
    type: EVENT_ASYNC_START,
  };
}

export function eventAsyncStop() {
  return {
    type: EVENT_ASYNC_STOP,
  };
}

export function setIsWcraManagedNewEvent(isWcraManaged) {
  return {
    type: EVENT_SET_IS_WCRA_MANAGED,
    isWcraManaged,
  };
}

// eslint-disable-next-line
function eventAsyncSuccess(data) {
  return {
    type: EVENT_ASYNC_SUCCESS,
    data,
  };
}

function eventListAsyncSuccess(data) {
  return {
    type: EVENT_LIST_ASYNC_SUCCESS,
    data,
  };
}

function eventAsyncFail(error) {
  return {
    type: EVENT_ASYNC_FAIL,
    error,
  };
}

export function getRawEventFilterValues(values) {
  return async (dispatch, getState) => {
    const state = getState();
    const filters = getFilters(values, state);
    return formatFilters(filters);
  };
}

export function getEventFilterValues(values) {
  return async (dispatch, getState) => {
    const state = getState();
    const filters = getFilters(values, state);
    return filterToRequest(filters);
  };
}

export function listEvents(values) {
  return async (dispatch, getState) => {
    dispatch(eventAsyncStart());
    try {
      const state = getState();
      const filters = getFilters(values, state);
      const {
        pageNumber,
        sortDirection,
        orderByType: orderBy,
        resultsPerPage,
        isUpcoming,
      } = values;

      const variables = {
        input: {
          filters: filterToRequest(filters),
          pageNumber,
          sortDirection,
          orderBy,
          resultsPerPage,
          isUpcoming,
        },
      };

      const eventQuery = {
        query: LIST_EVENTS,
        variables,
        fetchPolicy: 'network-only',
      };
      const response = await client.query(eventQuery);

      dispatch(eventListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAsyncFail(error.message));
      return null;
    }
  };
}

export function listEventsOpenForNomination(values) {
  return async (dispatch, getState) => {
    dispatch(eventAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const path =
        values && values.query
          ? `event/open-for-nomination?q=${values.query}`
          : 'event/open-for-nomination';

      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(eventListAsyncSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAsyncFail(error.message));
      return null;
    }
  };
}

export function addPerformance(values, formType) {
  return async (dispatch, getState) => {
    const state = getState();
    const eventForm = state.form.editEvent.values;
    const { PerformanceUID } = getFromState(getState, 'performance');

    eventForm.performances = eventForm.performances || [];

    const updatedDisciplines = [];
    if (values.editPerformance.Disciplines) {
      values.editPerformance.Disciplines.forEach((discipline) => {
        if (!discipline.id) {
          discipline.id = BAREBACK;
        }
        updatedDisciplines.push(discipline);
      });
    }

    values.editPerformance.Disciplines = updatedDisciplines;

    const indexOfPerformance =
      eventForm.performances.findIndex((performance) => {
        return performance.PerformanceUID === PerformanceUID;
      }) || getState().performance.index;

    if (indexOfPerformance >= 0 && formType === editPerformanceFormTypes.EDIT) {
      eventForm.performances[indexOfPerformance] = toJS(
        new Performance(values.editPerformance),
      );
    } else {
      eventForm.performances = [].concat(
        eventForm.performances,
        new Performance(values.editPerformance),
      );
    }

    dispatch(eventAsyncStart());
    try {
      dispatch(eventActionSuccess(eventForm));
      return eventForm;
    } catch (error) {
      logError(error);
      dispatch(eventAsyncFail('Unable to add performance'));
      return null;
    }
  };
}

export function saveExistingPerformance(values, formType) {
  return async (dispatch, getState) => {
    const state = getState();
    const eventForm = state.form.editEvent.values;
    const { PerformanceUID } = getFromState(getState, 'performance');

    if (!eventForm.id) return null;

    eventForm.performances = toJS(eventForm.performances) || [];

    const updatedDisciplines = [];
    if (values.editPerformance.Disciplines) {
      values.editPerformance.Disciplines.forEach((discipline) => {
        if (!discipline.id) {
          discipline.id = BAREBACK;
        }
        const disciplineFee = eventForm.DisciplineFees.find(
          ({ DisciplineUID }) => DisciplineUID === discipline.id,
        );

        if (disciplineFee.Classes) {
          const disciplineFeeClass = disciplineFee.Classes.find(
            ({ ClassName }) =>
              ClassName &&
              ClassName.toString() === discipline.ClassName.toString(),
          );

          if (disciplineFeeClass && !discipline.EventEntryDisciplineFeeUID) {
            discipline.EventEntryDisciplineFeeUID =
              disciplineFeeClass.EventEntryDisciplineFeeUID;
          }

          if (disciplineFeeClass && !disciplineFeeClass.NumberOfGos) {
            discipline.GoNumber = null;
          }
        }

        updatedDisciplines.push(discipline);
      });
    }

    values.editPerformance.Disciplines = updatedDisciplines;

    const indexOfPerformance = eventForm.performances.findIndex(
      (performance) => {
        return performance.PerformanceUID === PerformanceUID;
      },
    );

    if (indexOfPerformance >= 0 && formType === editPerformanceFormTypes.EDIT) {
      eventForm.performances[indexOfPerformance] = toJS(
        new Performance(values.editPerformance),
      );
    } else {
      eventForm.performances.push(
        toJS(new Performance(values.editPerformance)),
      );
    }

    // Consolidate team disciplines fees before submitting so that the API
    // cant match disciplineFees and Performances, Only ClassNames is needed
    const discFees = JSON.parse(JSON.stringify(eventForm.DisciplineFees));
    discFees.forEach((fee) => {
      if (!isPrimaryTeamDiscipline(fee.DisciplineUID)) return;

      const partnerDisciplineUID = getPartnerDisciplineId(fee.DisciplineUID);
      const partnerDiscipline = discFees.find(
        (f) => f.DisciplineUID === partnerDisciplineUID,
      );
      if (partnerDiscipline && partnerDiscipline.Classes) {
        partnerDiscipline.Classes = partnerDiscipline.Classes.map((c, i) => {
          const correspondingMainDisciplineClasses = fee.Classes[i];
          return {
            ...c,
            ClassName: c.ClassName
              ? c.ClassName
              : correspondingMainDisciplineClasses.ClassName,
          };
        });
      }
    });

    dispatch(eventAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: `event/${eventForm.id}`,
        method: 'PATCH',
        body: {
          ...eventForm,
          DisciplineFees: discFees,
        },
        accessToken,
      });
      dispatch(eventActionSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAsyncFail(error.message));
      return null;
    }
  };
}

export function saveEvent(values) {
  return async (dispatch, getState) => {
    dispatch(eventAsyncStart());
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);
    const user = getUser();
    values.LastUpdatedByERAUID = user.data.ERAUID;

    try {
      let response;
      if (values.id) {
        response = await api({
          path: `event/${values.id}`,
          method: 'PATCH',
          body: values,
          accessToken,
        });
      } else {
        response = await api({
          path: 'event/admin',
          method: 'POST',
          body: values,
          accessToken,
        });
      }

      dispatch(eventActionSuccess(response));
      return response;
    } catch (error) {
      dispatch(postToastMessage(DISCIPLINE_REMOVAL_TOOLTIP, 'fail'));
      if (values.id) {
        const response = await api({
          path: `event/${values.id}`,
          method: 'GET',
          accessToken,
        });
        if (response.DisciplineFees) {
          dispatch(
            change('editEvent', 'DisciplineFees', response.DisciplineFees),
          );
        }
      }
      dispatch(eventAsyncFail(error.message));
      return null;
    }
  };
}

export function getEvent(id) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    dispatch(eventAsyncStart());
    try {
      const response = await api({
        path: `event/${id}`,
        method: 'GET',
        accessToken,
      });

      dispatch(eventActionSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'There was a problem getting your event, please try again.';
      dispatch(eventAsyncFail(err));
      return null;
    }
  };
}

export function getEventDuplicatedData(id) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);
    const user = getUser();

    dispatch(eventAsyncStart());
    try {
      const response = await api({
        path: `event/${id}`,
        method: 'GET',
        accessToken,
      });

      // Remove id, event dates and Performances from the duplicated event data
      response.id = null;
      response.dateStart = null;
      response.dateEnd = null;
      response.dateRegistrationStart = null;
      response.dateRegistrationEnd = null;
      response.EventDate = null;
      response.SubmissionStatus = null;
      response.EventImage = null;
      response.LastUpdatedByERAUID = user.data.ERAUID;
      response.BlockWomenSegment = false;
      response.BlockYouthSegment = false;

      // Remove EventUID and EventEntryDisciplineFeeUID from response
      if (response.DisciplineFees.length > 0) {
        // non-class disciplines
        response.DisciplineFees.forEach((discipline) => {
          discipline.EventUID = null;
          discipline.EventEntryDisciplineFeeUID = null;

          if (discipline.Classes?.length > 0) {
            // class disciplines
            discipline.Classes.forEach((classEntry) => {
              classEntry.EventUID = null;
              classEntry.EventEntryDisciplineFeeUID = null;
              classEntry.BlockWomenSegment = false;
              classEntry.BlockYouthSegment = false;
            });
          }
        });
      }

      dispatch(eventActionSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'There was a problem getting your event, please try again.';
      dispatch(eventAsyncFail(err));
      return null;
    }
  };
}

export function getEventSearchResults(searchTerm) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    dispatch(eventAsyncStart());
    try {
      const response = await api({
        path: `event/search/${searchTerm}`,
        method: 'GET',
        accessToken,
      });
      dispatch(eventListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'Problem fetching event search events.';
      dispatch(eventAsyncFail(err));
      return null;
    }
  };
}

export function selectEvent(event) {
  return (dispatch) => {
    dispatch(eventActionSuccess(event));
  };
}

export function clearEvent() {
  return (dispatch) => {
    dispatch(eventActionClear());
  };
}

export function getEventPerformances(eventVal) {
  return async (dispatch, getState) => {
    const eventData = getFromState(getState, 'event');
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    dispatch(eventAsyncStart());
    try {
      const response = await api({
        path: `event/${eventVal.id}/performances/admin`,
        method: 'GET',
        accessToken,
      });

      const event = { ...eventData, performances: response };

      dispatch(eventActionSuccess(event));
      return response;
    } catch (error) {
      logError(error);
      const err = 'Problem fetching event performances.';
      dispatch(eventAsyncFail(err));
      return null;
    }
  };
}

export function getTradePerformancesStatus() {
  return async (dispatch, getState) => {
    const event = getFromState(getState, 'event');
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    try {
      if (event && event.id) {
        dispatch(eventAsyncStart());

        const response = await api({
          path: `entry-pool/status?EventUID=${event.id}`,
          method: 'GET',
          accessToken,
        });

        const eventNewData = {
          ...event,
          TradePerformancesStatus: response,
        };

        dispatch(eventActionSuccess(eventNewData));
        return response;
      }
    } catch (error) {
      logError(error);
      dispatch(eventAsyncFail(error.message));
      return null;
    }
  };
}

const submitOrResubmitTradePerformances = (httpVerb) => (values) => {
  return async (dispatch, getState) => {
    const event = getFromState(getState, 'event');
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getToken(authPayload);

    try {
      if (event && event.id) {
        dispatch(eventAsyncStart());

        const response = await api({
          path: `entry-pool/status?EventUID=${values.EventUID}`,
          method: httpVerb,
          body: values,
          accessToken,
        });

        const eventNewData = {
          ...event,
          TradePerformancesStatus: response.status,
        };

        dispatch(eventActionSuccess(eventNewData));
        dispatch(
          createAlertSuccess({
            message: 'Positions Successfully Posted',
            type: 'success',
          }),
        );

        setTimeout(async () => {
          dispatch(clearAlertSuccess());
          if (response.errorMessages.length > 0) {
            const messagesFailed = response.errorMessages.reduce(
              (str, { obj }, i) => {
                const name = obj.fullName || obj.firstName || obj.email;
                if (i === 0) {
                  return name;
                }
                return `${str}, ${name}`;
              },
              '',
            );
            dispatch(
              createAlertSuccess({
                message: `Text message was not sent out to ${messagesFailed}. Please review phone number`,
                type: 'fail',
              }),
            );
            dispatch(clearAlertSuccessAsync());
          }
        }, 5000);

        return response;
      }
    } catch (error) {
      logError(error);
      dispatch(eventAsyncFail(error.message));
      dispatch(
        createAlertSuccess({
          message: error.message,
          type: 'fail',
        }),
      );
      dispatch(clearAlertSuccessAsync());
      return error.message;
    }
  };
};

export const submitTradePerformances = submitOrResubmitTradePerformances(
  'POST',
);

export const resubmitTradePerformances = submitOrResubmitTradePerformances(
  'PUT',
);

export function uploadEventPhoto(image) {
  return async (dispatch, getState) => {
    dispatch(eventAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const body = new FormData();
      body.append('file', image);
      const response = await api({
        path: 'event/upload-photo/admin',
        method: 'POST',
        body,
        accessToken,
        cType: 'multipart/form-data',
      });

      dispatch(eventAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(
        eventAsyncFail('File must have jpg or png format and be under 2mb'),
      );
      return null;
    }
  };
}

export function uploadAthleteAgreement(file) {
  return async (dispatch, getState) => {
    dispatch(eventAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const body = new FormData();
      body.append('file', file);
      const response = await api({
        path: 'event/upload-agreement/admin',
        method: 'POST',
        body,
        accessToken,
        cType: 'multipart/form-data',
      });

      dispatch(eventAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventAsyncFail('File must have PDF format and be under 2mb'));
      return null;
    }
  };
}

export function updateEventOptions(orderBy, order, page, rowsPerPage) {
  return {
    type: EVENT_UPDATE_OPTIONS,
    data: { orderBy, order, page, rowsPerPage },
  };
}
