// @flow
import React from 'react';
import { RotateCw } from 'react-feather';
import classNames from 'classnames';

type LayoutDialogProps = {|
  className: string,
  loading: boolean,
  children: any,
|};

const LayoutDialog = (props: LayoutDialogProps) => {
  const { className, children, loading } = props;
  const visibilityClass = loading ? 'show' : '';
  return (
    <div className={classNames('pos-relative', className)}>
      <div className={`spinner-container modal ${visibilityClass}`}>
        <RotateCw className="spinner" />
      </div>
      {children}
    </div>
  );
};

LayoutDialog.defaultProps = {
  className: '',
  loading: false,
  children: null,
};

export default LayoutDialog;
