import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import routeCodes from 'constants/routes';
import withToJS from 'enhancers/withToJS';
import { DEFAULT_PAGES } from '../../models/User';
import abilities from 'abilities';
import { useAuth } from '../../hooks/useAuth';
import { hasFeatureFlag } from '../../utils/flagsmith';

class AuthenticatedRoute extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    props: PropTypes.object.isRequired,
    exact: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    abilityType: PropTypes.string.isRequired,
  };

  renderComponent = (props) => {
    const { component: C, props: cProps } = this.props;
    const { auth, user } = cProps;
    const { authenticated } = auth;

    if (authenticated && user.role) {
      const defaultPage = DEFAULT_PAGES[user.role];
      const ability = abilities(user.role, this.props.abilityType);
      const allowed = ability.rules[0].actions.length > 0;

      const auth0 = hasFeatureFlag('auth0');

      if (auth0 && !this.props.isAuthenticated) {
        this.props.getToken();
      }
      return (
        <>
          {allowed ? (
            <C {...props} {...cProps} />
          ) : (
            <Redirect
              to={`${routeCodes.LOGIN}?redirect=${defaultPage}${
                props.location.search
              }`}
            />
          )}
        </>
      );
    }

    return (
      <Redirect
        to={`${routeCodes.LOGIN}?redirect=${props.location.pathname}${
          props.location.search
        }`}
      />
    );
  };

  render() {
    const { exact, path } = this.props;

    return (
      <Route
        exact={exact}
        path={path}
        render={(props) => this.renderComponent(props)}
      />
    );
  }
}
const AuthenticatedRouteWithAuth0 = (props) => {
  const { getToken, isAuthenticated } = useAuth({ callGetToken: false });
  return (
    <AuthenticatedRoute
      {...props}
      getToken={getToken}
      isAuthenticated={isAuthenticated}
    />
  );
};

export default withToJS(AuthenticatedRouteWithAuth0);
