import gql from 'graphql-tag';

export const EDIT_ENTRY_PARTNER = gql`
  mutation editEntryPartner($input: editPartnerInputType!) {
    editEntryPartner(input: $input) {
      EntryChargeRequestUID
      EventEntryDisciplineFeeUID
      EPUID
      EventUID
      ERAUID
      PayingERAUID
      userId
      CompType
      EventName
      FullName
      Discipline
      email
      firstName
      lastName
      TeammateERAUID
      PaymentStatus
      PartnerPaymentStatus
      TeammateName
      PaymentType
      PartnerEPUID
    }
  }
`;
