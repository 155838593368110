import gql from 'graphql-tag';

export const LIST_NOMINATIONS_PER_CONTESTANT_MERGE = gql`
  query LIST_NOMINATIONS_PER_CONTESTANT_MERGE(
    $input: NominationsPerContestantInput!
  ) {
    nominationsPerContestantPayload(input: $input) {
      nominations {
        NomUID
      }
    }
  }
`;

export const LIST_NOMINATIONS_PER_CONTESTANT = gql`
  query LIST_NOMINATIONS_PER_CONTESTANT(
    $input: NominationsPerContestantInput!
  ) {
    nominationsPerContestantPayload(input: $input) {
      nominations {
        NomUID
        ERAUID
        EventName
        EventDate
        EventDateTo
        EventUID
        DivisionNumber
        DivisionName
        createdAt
        NominationRevenue
        totalOnStripe
        totalAmountWithDiscount
        NumberOfNominations
        mainDiscipline
        NomDate
        chargeId
        DisciplineAsString
        Points
        SegmentUID
        SegmentAsString
        ShortName
        userId
        ClassName
        EventEntryDisciplineFeeUID
      }
      meta(input: $input) {
        totalCount
      }
    }
  }
`;
