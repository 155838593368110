import { delay } from 'helpers';
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';
export const CLEAR_ALERT_SUCCESS = 'CLEAR_ALERT_SUCCESS';

export function createAlertSuccess(alert) {
  return {
    type: CREATE_ALERT_SUCCESS,
    alert,
  };
}

export function clearAlertSuccess() {
  return {
    type: CLEAR_ALERT_SUCCESS,
  };
}

export function clearAlertSuccessAsync() {
  return async (dispatch) => {
    await delay(5000);
    return dispatch(clearAlertSuccess());
  };
}
