import api from 'api';
import { getFromState, getToken, logError } from 'helpers';

export const SUMMARY_ASYNC_START = 'SUMMARY_ASYNC_START';
export const SUMMARY_ASYNC_FAIL = 'SUMMARY_ASYNC_FAIL';
export const SUMMARY_ASYNC_SUCCESS = 'SUMMARY_ASYNC_SUCCESS';
export const SUMMARY_SELECT_ALL = 'SUMMARY_SELECT_ALL';
export const SUMMARY_DESELECT_ALL = 'SUMMARY_DESELECT_ALL';
export const SUMMARY_SELECT_LINE = 'SUMMARY_SELECT_LINE';
export const SUMMARY_DESELECT_LINE = 'SUMMARY_DESELECT_LINE';
export const SUMMARY_ADD_NEW_LINE = 'SUMMARY_ADD_NEW_LINE';
export const SUMMARY_EDIT_LINE = 'SUMMARY_EDIT_LINE';
export const SUMMARY_EDIT_LINE_SAVE = 'SUMMARY_EDIT_LINE_SAVE';
export const SUMMARY_EDIT_LINE_CANCEL = 'SUMMARY_EDIT_LINE_CANCEL';
export const SUMMARY_SYNC_WITH_FORM = 'SUMMARY_SYNC_WITH_FORM';
export const SUMMARY_REMOVE_LINE_SUCCESS = 'SUMMARY_REMOVE_LINE_SUCCESS';
export const SUMMARY_RESET = 'SUMMARY_RESET';
export const SUMMARY_UPDATE_ORDERS = 'SUMMARY_UPDATE_ORDERS';

import { createAlertSuccess, clearAlertSuccessAsync } from 'actions/alert';
import { postToastMessage } from 'helpers/postToastMessage';

function summaryAsyncStart() {
  return {
    type: SUMMARY_ASYNC_START,
  };
}

function summaryAsyncFail(errorMessage) {
  return {
    type: SUMMARY_ASYNC_FAIL,
    errorMessage,
  };
}

function summaryAsyncSuccess(data) {
  return {
    type: SUMMARY_ASYNC_SUCCESS,
    data,
  };
}

function summaryLineRemoveAsyncSuccess(data) {
  return {
    type: SUMMARY_REMOVE_LINE_SUCCESS,
    data,
  };
}

export function summaryUpdateOrders(sortDirection, orderBy) {
  return {
    type: SUMMARY_UPDATE_ORDERS,
    payload: {
      sortDirection,
      orderBy,
    },
  };
}

export function summaryReset() {
  return {
    type: SUMMARY_RESET,
  };
}

export function summarySyncWithForm(summaryLines) {
  return {
    type: SUMMARY_SYNC_WITH_FORM,
    summaryLines,
  };
}

export function summaryAddNewLine(rows) {
  return {
    type: SUMMARY_ADD_NEW_LINE,
    rows,
  };
}

export function summaryEditLine(summaryLine) {
  return {
    type: SUMMARY_EDIT_LINE,
    summaryLine,
  };
}

export function summaryEditLineSave(summaryValues) {
  return {
    type: SUMMARY_EDIT_LINE_SAVE,
    summaryValues,
  };
}

export function summaryLineRemoveAsync(summaryLine, summaryLines) {
  return async (dispatch, getState) => {
    if (summaryLine.ERAUID && !summaryLine.IsNew) {
      try {
        const {
          GoRoundResultUID,
          CompType: selectedDiscipline,
          GoNumber,
          ERAUID,
          PartnerERAUID,
          PartnerEPUID,
        } = summaryLine;

        const { authPayload } = getFromState(getState, 'auth');
        const accessToken = getToken(authPayload);

        const { id: EventUID } = getFromState(getState, 'event');

        await api({
          path: `/performance-results/go-round/summary?CompType=${selectedDiscipline}&EventUID=${EventUID}&GoNumber=${GoNumber}&ERAUID=${ERAUID}&GoRoundResultUID=${GoRoundResultUID}`,
          body: {
            PartnerERAUID,
            PartnerEPUID,
          },
          method: 'DELETE',
          accessToken,
        });

        await dispatch(summaryGetGoRoundList(selectedDiscipline, GoNumber));
      } catch (error) {
        logError(error);
        await dispatch(
          createAlertSuccess({
            message: error.message,
            type: 'fail',
          }),
        );
        await dispatch(clearAlertSuccessAsync());
      }
    }

    await dispatch(summaryLineRemoveAsyncSuccess(summaryLine));
    await dispatch(
      createAlertSuccess({
        message: 'Results Successfully removed.',
        type: 'success',
      }),
    );
    await dispatch(clearAlertSuccessAsync());

    if (summaryLine.GoRoundResultUID) {
      // Trigger save to recalculate
      const newSummaryLines = [...summaryLines].filter((summary) => {
        return summary.EPUID != summaryLine.EPUID;
      });
      await dispatch(summarySave(newSummaryLines));
    }
  };
}

export function summaryEditLineCancel(summaryLine) {
  return {
    type: SUMMARY_EDIT_LINE_CANCEL,
    summaryLine,
  };
}

export function summarySelectAll() {
  return {
    type: SUMMARY_SELECT_ALL,
  };
}

export function summarySelectLine(summaryLine) {
  return {
    type: SUMMARY_SELECT_LINE,
    summaryLine,
  };
}

export function summaryDeselectAll() {
  return {
    type: SUMMARY_DESELECT_ALL,
  };
}

export function summaryDeselectLine(summaryLine) {
  return {
    type: SUMMARY_DESELECT_LINE,
    summaryLine,
  };
}

export function summaryGetGoRoundList(selectedDiscipline, GoNumber) {
  return async (dispatch, getState) => {
    dispatch(summaryAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { id: EventUID } = getFromState(getState, 'event');

      const response = await api({
        path: `/performance-results/go-round/summary?CompType=${selectedDiscipline}&EventUID=${EventUID}&GoNumber=${GoNumber}`,
        method: 'GET',
        accessToken,
      });

      dispatch(summaryAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(summaryAsyncFail(error.message));
      return null;
    }
  };
}

export function copySummary(summaryLines, goNumbers) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { id: EventUID } = getFromState(getState, 'event');

      const response = await api({
        path: `/performance-results/go-round/summary/copy?EventUID=${EventUID}`,

        method: 'POST',
        body: { summaryLines, goNumbers },
        accessToken,
      });

      dispatch(postToastMessage('Contestants were copied successfully!'));

      return response;
    } catch (error) {
      logError(error);
      dispatch(summaryAsyncFail(error.message));
      return null;
    }
  };
}

export function summarySave(summaryLines, goNumber, discipline) {
  return async (dispatch, getState) => {
    dispatch(summaryAsyncStart());
    try {
      const getSummaryParams = (summaryLines) => {
        return summaryLines && summaryLines.length > 0
          ? {
              GoNumber: summaryLines[0].GoNumber,
              CompType: summaryLines[0].CompType,
            }
          : null;
      };

      const selectedDiscipline =
        getSummaryParams(summaryLines).CompType || discipline;
      const GoNumber = getSummaryParams(summaryLines).GoNumber || goNumber;

      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { id: EventUID } = getFromState(getState, 'event');

      const response = await api({
        path: `/performance-results/go-round/summary?CompType=${selectedDiscipline}&EventUID=${EventUID}&GoNumber=${GoNumber}`,
        method: 'PATCH',
        body: summaryLines,
        accessToken,
      });

      dispatch(summaryAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(summaryAsyncFail(error.message));
      return null;
    }
  };
}
