import EntryPool from 'models/EntryPool';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  ENTRYPOOL_ACTION_SUCCESS,
  ENTRYPOOL_APPLY_LIST_OPTIONS,
  ENTRYPOOL_ASYNC_FAIL,
  ENTRYPOOL_ASYNC_START,
  ENTRYPOOL_ASYNC_STOP,
  ENTRYPOOL_ASYNC_SUCCESS,
  ENTRYPOOL_LIST_ASYNC_SUCCESS,
  ENTRYPOOL_PAGINATED_LIST_ASYNC_SUCCESS,
  ENTRYPOOL_REFUND_SUCCESS,
  ENTRYPOOL_REMOVE_SUCCESS,
  ENTRYPOOL_UPDATE,
} from 'actions/entryPool';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new EntryPool(),
  list: toEntityList([], EntryPool),
});

const actionsMap = {
  [ENTRYPOOL_ACTION_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      data: new EntryPool(action.data),
    });
  },
  [ENTRYPOOL_REFUND_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [ENTRYPOOL_REMOVE_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [ENTRYPOOL_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [ENTRYPOOL_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [ENTRYPOOL_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: new EntryPool(action.data),
    });
  },
  [ENTRYPOOL_LIST_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(action.data, EntryPool),
    });
  },
  [ENTRYPOOL_PAGINATED_LIST_ASYNC_SUCCESS]: (state, action) => {
    const { entries, totalCount } = action.data;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(entries, EntryPool),
      totalCount,
    });
  },
  [ENTRYPOOL_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [ENTRYPOOL_APPLY_LIST_OPTIONS]: (state, action) => {
    const newOptions = action.data;
    const baseOptions =
      state.get && state.get('options') ? state.get('options').toJS() : {};
    const options = Object.assign({}, baseOptions, newOptions);
    return state.merge({
      options,
    });
  },
  [ENTRYPOOL_UPDATE]: (state, action) => {
    const {
      TeammateName,
      PartnerFirstName,
      PartnerLastName,
      ...args
    } = action.data;
    const entryData = {
      ...args,
    };

    if (PartnerFirstName) {
      entryData.PartnerFirstName = PartnerFirstName;
    }

    if (PartnerLastName) {
      entryData.PartnerLastName = PartnerLastName;
    }

    if (TeammateName) {
      entryData.TeammateName = TeammateName;
    }
    return state.merge({
      data: state.data.merge(entryData),
      loading: false,
    });
  },
};

export default function entryPool(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
