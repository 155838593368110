// @flow
import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';
import Performance from 'models/Performance';
import Association from 'models/Association';
import { eventManagementType as EVENT_MANAGEMENT_TYPE } from 'constants/event';
import type { PerformanceType } from 'models/Performance';

export type EventType = {
  AllowClasses: ?boolean,
  AllowEntry: ?number,
  AllowsNomination: ?number,
  AllowsNominationOnEntry: ?number,
  NominationOnEntryDiscountPercentage: ?number,
  AllowOutIfPrefNotMet: ?number,
  Associations: number[],
  BaseFee: ?number,
  BlockWomenSegment: ?boolean,
  BlockYouthSegment: ?boolean,
  LateFee: ?number,
  CommunicationTextPostedResults: ?number,
  CommunicationTextStockDraw: ?number,
  CommunicationTextTradeDate: ?number,
  ContractorUID: ?number,
  DisciplineFees: Object[],
  EventAthleteAgreementURL: ?string,
  EventFormat: ?number,
  EventImage: ?string,
  EventName: ?string,
  EventRank: ?string,
  EventText: ?string,
  Facebook: ?string,
  Instagram: ?string,
  IsNomLastPerformance: ?number,
  NominationDuringEntryOption: ?number,
  IsWcraManaged: ?number,
  NumberOfNots: ?number,
  NumberOfPrefs: ?number,
  RequirePreferenceSelection: ?number,
  ResultsURL: ?string,
  SanctionBodyUID: ?number,
  Season: ?number,
  SecondaryContractors: number[],
  SecretaryPhone: ?string,
  SecretaryUID: ?number,
  ShowStallsLink: ?number,
  SubmissionStatus: ?number,
  TimeZoneName: ?string,
  TimeZoneOffsetInMinutes: ?number,
  TimeZoneUID: ?number,
  TradeDate: ?string,
  TradePerformancesStatus: Object[],
  TransFee: ?number,
  VenueUID: ?number,
  Website: ?string,
  dateEnd: ?string,
  dateRegistrationEnd: ?string,
  dateRegistrationStart: ?string,
  dateStart: ?string,
  entryFee: ?number,
  eventDrawDate: ?string,
  eventType: ?number,
  eventTypeName: ?string,
  firstPerformance: ?string,
  firstPerformanceRiding: ?string,
  hasChanged: ?boolean,
  id: ?number,
  isAlliancePartner: ?boolean,
  isSlack: ?number,
  location: ?string,
  name: ?string,
  performances: PerformanceType[],
  purse: ?number,
  rank: ?number,
  rankLabel: ?string,
  rankShort: ?string,
  totalEntries: ?number,
  totalNominations: ?number,
  ShouldCollectTaxInfo: ?boolean,
  AllowsPayLater: ?boolean,
  AllowsBuddyGroup: ?boolean,
  MaxNumberOfBuddies: ?number,
  EventPayout: ?number,
  DisablePartnerSelection: ?boolean,
  PrimaryAssociationUID: ?number,
  EventMessaging: ?string,
  ShowEntryCountOnPerformances: boolean,
  ShouldCollectSNNAndCitizenshipInfo: boolean,
  CreatedAt: ?string,
  LastUpdatedDate: ?string,
  LastUpdatedByERAUID: ?number,
};

export const defaultValues: EventType = {
  AllowClasses: false,
  AllowEntry: 0,
  AllowsNomination: 1,
  AllowsNominationOnEntry: 1,
  NominationOnEntryDiscountPercentage: 0,
  AllowOutIfPrefNotMet: 0,
  AllowsPayLater: false,
  AllowsBuddyGroup: false,
  Associations: [],
  BaseFee: 0,
  BlockWomenSegment: false,
  BlockYouthSegment: false,
  LateFee: 0,
  CommunicationTextPostedResults: null,
  CommunicationTextStockDraw: null,
  CommunicationTextTradeDate: null,
  ContractorUID: null,
  DisciplineFees: [],
  EventAthleteAgreementURL: null,
  EventFormat: null,
  EventImage: null,
  EventName: null, // Only event name
  EventRank: null,
  EventText: null,
  Facebook: null,
  Instagram: null,
  IsNomLastPerformance: 1,
  NominationDuringEntryOption: 0,
  IsWcraManaged: EVENT_MANAGEMENT_TYPE.WCRA,
  NumberOfNots: null,
  NumberOfPrefs: null,
  RequirePreferenceSelection: 0,
  ResultsURL: null,
  SanctionBodyUID: null,
  Season: null,
  SecondaryContractors: [],
  SecretaryPhone: null,
  SecretaryUID: null,
  ShowStallsLink: 0,
  SubmissionStatus: null,
  TimeZoneName: null,
  TimeZoneOffsetInMinutes: null,
  TimeZoneUID: 1,
  TradeDate: null,
  TradePerformancesStatus: [],
  TransFee: 0,
  VenueUID: null,
  Website: null,
  dateEnd: null,
  dateRegistrationEnd: null,
  dateRegistrationStart: null,
  dateStart: null,
  entryFee: null,
  eventDrawDate: null,
  eventType: null,
  eventTypeName: null,
  firstPerformance: null,
  firstPerformanceRiding: null,
  hasChanged: false,
  id: null,
  isAlliancePartner: null,
  isSlack: null,
  location: null,
  name: null,
  performances: toEntityList([], Performance),
  purse: null,
  rank: null,
  rankLabel: null,
  rankShort: null,
  totalEntries: 0,
  totalNominations: 0,
  ShouldCollectTaxInfo: true,
  ShouldCollectSNNAndCitizenshipInfo: false,
  EventPayout: 0,
  MaxNumberOfBuddies: 2,
  DisablePartnerSelection: false,
  PrimaryAssociationUID: null,
  EventMessaging: null,
  ShowEntryCountOnPerformances: true,
  CreatedAt: null,
  LastUpdatedDate: null,
  LastUpdatedByERAUID: null,
};

export default class Event extends Record(defaultValues, 'Event') {
  constructor(values: EventType) {
    super({
      ...values,
      Associations:
        values && values.Associations
          ? toEntityList(values.Associations, Association)
          : defaultValues.Associations,
    });
  }
}
