import api from 'api';
import { getFromState, getToken, logError } from 'helpers';

export const SUMMARY_OVERALL_ASYNC_START = 'SUMMARY_OVERALL_ASYNC_START';
export const SUMMARY_OVERALL_ASYNC_FAIL = 'SUMMARY_OVERALL_ASYNC_FAIL';
export const SUMMARY_OVERALL_ASYNC_SUCCESS = 'SUMMARY_OVERALL_ASYNC_SUCCESS';
export const SUMMARY_OVERALL_SELECT_ALL = 'SUMMARY_OVERALL_SELECT_ALL';
export const SUMMARY_OVERALL_DESELECT_ALL = 'SUMMARY_OVERALL_DESELECT_ALL';
export const SUMMARY_OVERALL_SELECT_LINE = 'SUMMARY_OVERALL_SELECT_LINE';
export const SUMMARY_OVERALL_DESELECT_LINE = 'SUMMARY_OVERALL_DESELECT_LINE';

function summaryOverallAsyncStart() {
  return {
    type: SUMMARY_OVERALL_ASYNC_START,
  };
}

function summaryOverallAsyncFail(errorMessage) {
  return {
    type: SUMMARY_OVERALL_ASYNC_FAIL,
    errorMessage,
  };
}

function summaryOverallAsyncSuccess(data) {
  return {
    type: SUMMARY_OVERALL_ASYNC_SUCCESS,
    data,
  };
}

export function summaryOverallSelectAll() {
  return {
    type: SUMMARY_OVERALL_SELECT_ALL,
  };
}

export function summaryOverallSelectLine(overallLine) {
  return {
    type: SUMMARY_OVERALL_SELECT_LINE,
    overallLine,
  };
}

export function summaryOverallDeselectAll() {
  return {
    type: SUMMARY_OVERALL_DESELECT_ALL,
  };
}

export function summaryOverallDeselectLine(overallLine) {
  return {
    type: SUMMARY_OVERALL_DESELECT_LINE,
    overallLine,
  };
}

export function summaryOverallGetList(selectedDiscipline) {
  return async (dispatch, getState) => {
    dispatch(summaryOverallAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const { id: EventUID } = getFromState(getState, 'event');

      const response = await api({
        path: `/performance-results/go-round/summary-overall?CompType=${selectedDiscipline}&EventUID=${EventUID}`,
        method: 'GET',
        accessToken,
      });

      dispatch(summaryOverallAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(summaryOverallAsyncFail(error.message));
      return null;
    }
  };
}
