import { getNestedProperty } from 'helpers';

export const getSingleJudgeResults = (
  collection,
  JudgeNumber,
  CompType,
  PerformanceUID,
) => {
  const flags = {};
  return collection.reduce((prev, curr) => {
    const samePerformanceAndDiscicipline =
      curr.CompType === CompType && curr.PerformanceUID === PerformanceUID;
    const judgeMatches = !curr.JudgeNumber || curr.JudgeNumber === JudgeNumber;
    const hasStockDraw = !!curr.StockDrawUID;
    if (
      samePerformanceAndDiscicipline ||
      (judgeMatches && !flags[curr.StockDrawUID])
    ) {
      prev.push(curr);
      if (hasStockDraw) flags[curr.StockDrawUID] = true;
    }
    return prev;
  }, []);
};

export const getPerformanceResultRowClass = (performanceResult) => {
  let rowClass = '';
  if (performanceResult.IsRerideCancelled) {
    rowClass = `${rowClass} cancelled-reride`;
  }
  if (performanceResult.IsReride) {
    rowClass = `${rowClass} reride-row`;
  }
  return rowClass;
};

export function findLastMatchingCancelledReride(reride, performanceResults) {
  const cancelledPerformancResults = performanceResults.filter((item) => {
    return item.EPUID === reride.EPUID && item.IsRerideCancelled;
  });
  return cancelledPerformancResults.slice(-1)[0];
}

export function findMatchingActivePerformanceResult(EPUID, performanceResults) {
  return performanceResults.find((item) => {
    return item.EPUID === EPUID && !item.IsReride && !item.IsRerideCancelled;
  });
}

export function findMatchingActiveReride(EPUID, performanceResults) {
  return performanceResults.find((item) => {
    return item.EPUID === EPUID && !item.IsRerideCancelled;
  });
}

export function resetSelectedReride(selectedReride) {
  return Object.assign({}, selectedReride, {
    EPUID: null,
    ERAUID: null,
    IsRerideCancelled: 0,
    IsReride: 0,
    JudgeNumber: null,
    JudgeName: null,
    JudgeSide: null,
    ContestantName: null,
    TeammateName: null,
    Ride: null,
    Stock: null,
    Time: null,
    Barrier: null,
    Field: null,
    partner: {
      EPUID: null,
      ERAUID: null,
    },
  });
}

export function updateSelectedReride(
  selectedReride,
  EPUID,
  JudgeNumber,
  updateValues,
  ERAUID,
) {
  const JudgeName = getNestedProperty('JudgeName', updateValues);
  const JudgeSide = getNestedProperty('JudgeSide', updateValues);
  return Object.assign({}, selectedReride, {
    EPUID,
    ERAUID,
    JudgeNumber,
    JudgeName,
    JudgeSide,
    ContestantName: null,
    TeammateName: null,
    Ride: null,
    Stock: null,
    Time: null,
    Barrier: null,
    Field: null,
  });
}

export function enableCancelledPerformanceResult(reride, performanceResults) {
  const oldCancelledPerformanceResult = findLastMatchingCancelledReride(
    reride,
    performanceResults,
  );
  return { ...oldCancelledPerformanceResult, IsRerideCancelled: 0 };
}

export function removeAndReplaceAthleteFromReride({
  reride,
  performanceResults,
  EPUID,
  JudgeNumber,
  ERAUID,
  partner,
}) {
  const collectionToUpdate = [];

  const enabledPerformanceResult = enableCancelledPerformanceResult(
    reride,
    performanceResults,
  );
  collectionToUpdate.push({
    ...enabledPerformanceResult,
  });

  const newCancelledPerformanceResult = findMatchingActivePerformanceResult(
    EPUID,
    performanceResults,
  );

  collectionToUpdate.push({
    ...newCancelledPerformanceResult,
    IsRerideCancelled: 1,
  });

  const updatedReride = updateSelectedReride(
    reride,
    EPUID,
    JudgeNumber,
    newCancelledPerformanceResult,
    ERAUID,
    partner,
  );
  collectionToUpdate.push(updatedReride);

  return collectionToUpdate;
}

export function removeAthleteFromReride({ reride, performanceResults }) {
  const collectionToUpdate = [];

  const enabledPerformanceResult = enableCancelledPerformanceResult(
    reride,
    performanceResults,
  );

  collectionToUpdate.push({
    ...enabledPerformanceResult,
  });

  const updatedReride = resetSelectedReride(reride);
  collectionToUpdate.push(updatedReride);

  return collectionToUpdate;
}

export function addAthleteToReride(
  reride,
  performanceResults,
  EPUID,
  JudgeNumber,
  ERAUID,
  partner,
) {
  const collectionToUpdate = [];

  const rerideToCancel = findMatchingActiveReride(EPUID, performanceResults);
  collectionToUpdate.push({
    ...rerideToCancel,
    IsRerideCancelled: 1,
    partner,
  });
  const updatedReride = updateSelectedReride(
    reride,
    EPUID,
    JudgeNumber,
    rerideToCancel,
    ERAUID,
  );
  collectionToUpdate.push({
    ...updatedReride,
    IsReride: 1,
    partner,
  });

  return collectionToUpdate;
}

export function findPerformanceResultIndexByPerformanceResultUID(
  performanceResultsList,
  PerformanceResultsUID,
  StockDrawUID,
  EPUID,
  JudgeNumber,
) {
  return performanceResultsList.findIndex((performanceResult) => {
    if (StockDrawUID) {
      return (
        performanceResult.StockDrawUID === StockDrawUID &&
        performanceResult.JudgeNumber === JudgeNumber
      );
    }
    if (EPUID) {
      return (
        performanceResult.EPUID === EPUID &&
        performanceResult.JudgeNumber === JudgeNumber
      );
    }
    return performanceResult.PerformanceResultsUID === PerformanceResultsUID;
  });
}

export function replacePerformanceResultsInList(
  performanceResultsList = [],
  updatedPerformanceResults = [],
) {
  let performanceResults = performanceResultsList;
  updatedPerformanceResults.forEach((performanceResultItem) => {
    const index = findPerformanceResultIndexByPerformanceResultUID(
      performanceResultsList,
      performanceResultItem.PerformanceResultsUID,
      performanceResultItem.StockDrawUID,
      performanceResultItem.EPUID,
      performanceResultItem.JudgeNumber,
    );
    if (index !== null) {
      performanceResults[index] = performanceResultItem;
    }
  });

  return performanceResults;
}

export const filterResultsOnRerideChange = (
  resultsToUpdate,
  resultsInState,
  resultsResponse,
  JudgeNumber,
) => {
  const updatedStockDrawUIDs = resultsToUpdate.map((result) => {
    return result.StockDrawUID;
  });

  const updatedResults = updatedStockDrawUIDs.map((StockDrawUID) => {
    return resultsResponse.find((result) => {
      return (
        StockDrawUID === result.StockDrawUID &&
        result.JudgeNumber === JudgeNumber
      );
    });
  });

  const filteredResponse = replacePerformanceResultsInList(
    resultsInState,
    updatedResults,
  );

  return filteredResponse;
};
