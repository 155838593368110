import api from 'api';
import adminSettingProperty from 'constants/adminSettings';
import { getFromState, getToken, logError } from 'helpers';

export const LIST_ADMIN_SETTINGS_FAIL = 'LIST_ADMIN_SETTINGS_FAIL';
export const LIST_ADMIN_SETTINGS_SUCCESS = 'LIST_ADMIN_SETTINGS_SUCCESS';
export const DISABLE_ENABLE_NOMINATIONS_FAIL =
  'DISABLE_ENABLE_NOMINATIONS_FAIL';
export const SELECT_ADMIN_SETTING_SUCCESS = 'SELECT_ADMIN_SETTING_SUCCESS';

export function listAdminSettingsFail(errorMessage) {
  return {
    type: LIST_ADMIN_SETTINGS_FAIL,
    errorMessage,
  };
}

export function listAdminSettingsSuccess(data) {
  return {
    type: LIST_ADMIN_SETTINGS_SUCCESS,
    data,
  };
}

export function disableEnableNominationsFail(errorMessage) {
  return {
    type: DISABLE_ENABLE_NOMINATIONS_FAIL,
    errorMessage,
  };
}

export function selectAdminSettingSuccess(data) {
  return {
    type: SELECT_ADMIN_SETTING_SUCCESS,
    data,
  };
}

export function listAdminSettings() {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const response = await api({
        path: 'adminSetting',
        method: 'GET',
        accessToken,
      });
      dispatch(listAdminSettingsSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(listAdminSettingsFail(error.message));
      return null;
    }
  };
}

export function selectAdminSetting(name) {
  return (dispatch) => {
    dispatch(selectAdminSettingSuccess(name));
  };
}

export function disableEnableNominations(value) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getToken(authPayload);

      const adminSetting = getFromState(getState, 'adminSetting');
      const updatedAdminSetting = { ...adminSetting, Value: value };

      const response = await api({
        path: `adminSetting/${adminSetting.AdminSettingUID}`,
        method: 'PATCH',
        body: updatedAdminSetting,
        accessToken,
      });
      await dispatch(listAdminSettingsSuccess(response.data));
      await dispatch(
        selectAdminSettingSuccess(adminSettingProperty.NOMINATIONS_ENABLED),
      );
      return response;
    } catch (error) {
      logError(error);
      dispatch(disableEnableNominationsFail(error.message));
      return null;
    }
  };
}
