import './utils/sentryInit';
import 'react-dates/initialize';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'assets/styles/app.scss';
import { App } from './App';
import FeatureFlagConfig from 'components/FeatureFlagConfig';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <FeatureFlagConfig>
    <App />
  </FeatureFlagConfig>,
);
