import { Record } from 'immutable';

const defaultValues = {
  splashScreenOpen: null,
};

export default class Layout extends Record(defaultValues, 'Layout') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
