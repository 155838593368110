import {
  barrelRacingDisciplines,
  timedDisciplines,
  ladiesPoleBendingDiscipline,
  roughStockDisciplines,
} from 'constants/disciplines';

export function getDisciplineType(discipline) {
  const { id } = discipline;
  if (barrelRacingDisciplines.includes(id)) {
    return 'barrel racing';
  } else if (
    timedDisciplines.includes(id) ||
    ladiesPoleBendingDiscipline === id
  ) {
    return 'roping';
  } else if (
    roughStockDisciplines.includes(id) &&
    ladiesPoleBendingDiscipline !== id
  ) {
    return 'stock';
  }
  return null;
}
