import ContestantDiscipline from 'models/ContestantDiscipline';
import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';

import { competitionLevels } from 'constants/competitionLevel';

import {
  CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_START,
  CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_SUCCESS,
  CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_FAIL,
  CONTESTANT_DISCIPLINE_UPDATE_OPTIONS,
  CONTESTANT_DISCIPLINE_UPDATE,
} from 'actions/contestantDiscipline';

export const initialContestantDisciplineOptions = {
  sortDirection: 'asc',
  orderBy: 'Verified',
  pageNumber: 0,
  resultsPerPage: 25,
};

const initialState = new Record({
  loading: false,
  errorMessage: '',
  data: new ContestantDiscipline(),
  list: toEntityList([], ContestantDiscipline),
  options: initialContestantDisciplineOptions,
  totalCount: 0,
})();

const actionsMap = {
  [CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [CONTESTANT_DISCIPLINE_BY_PAGINATION_ASYNC_SUCCESS]: (state, action) => {
    if (!action.data || !action.data.contestantDisciplinesPayload) return state;

    const {
      contestantDisciplines,
      meta,
    } = action.data.contestantDisciplinesPayload;

    return state.merge({
      loading: false,
      errorMessage: '',
      list: toEntityList(contestantDisciplines, ContestantDiscipline),
      totalCount: meta.totalCount || 0,
    });
  },
  [CONTESTANT_DISCIPLINE_UPDATE_OPTIONS]: (state, action) => {
    const { sortDirection, orderBy, pageNumber, resultsPerPage } = action.data;

    return state.merge({
      options: Object.assign({}, state.options, {
        sortDirection,
        orderBy,
        pageNumber,
        resultsPerPage,
      }),
    });
  },
  [CONTESTANT_DISCIPLINE_UPDATE]: (state, action) => {
    const {
      ERAContestantDisciplineUID,
      CompetitionLevel,
      LocalVerified,
      ClassificationDate,
      Notes,
    } = action.data;

    const index = state.list.findIndex((data) => {
      return (
        data.get('ERAContestantDisciplineUID') === ERAContestantDisciplineUID
      );
    });

    const competitionLevelData = competitionLevels.find(
      ({ id }) => id === CompetitionLevel,
    );

    const updated = state.list
      .get(index)
      .set('LocalVerified', LocalVerified)
      .set('Classification', competitionLevelData.name)
      .set('CompetitionLevel', CompetitionLevel)
      .set('ClassificationDate', ClassificationDate)
      .set('Notes', Notes);

    const updatedList = state.list.set(index, updated);

    return state.merge({
      loading: false,
      list: updatedList,
    });
  },
};

export default function contestantDiscipline(
  state = initialState,
  action = {},
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
