import { AbilityBuilder } from '@casl/ability';
import { ROLES } from 'models/User';
import { ACTION_TYPES, ABILITY_TYPES } from './types';

const abilitiesMap = {
  [ROLES.ADMIN]: () => {
    const ability = AbilityBuilder.define((can) => {
      can(
        [ACTION_TYPES.READ, ACTION_TYPES.CREATE, ACTION_TYPES.UPDATE],
        ABILITY_TYPES.EVENT,
      );
    });
    return ability;
  },
  [ROLES.SECRETARY]: () => {
    const ability = AbilityBuilder.define((can) => {
      can(
        [ACTION_TYPES.READ, ACTION_TYPES.CREATE, ACTION_TYPES.UPDATE],
        ABILITY_TYPES.EVENT,
      );
    });
    return ability;
  },
  [ROLES.ATHLETE_SUPPORT]: () => {
    const ability = AbilityBuilder.define((can) => {
      can([], ABILITY_TYPES.EVENT);
    });
    return ability;
  },
  [ROLES.ADMIN_VIEW_ONLY]: () => {
    const ability = AbilityBuilder.define((can) => {
      can([ACTION_TYPES.READ], ABILITY_TYPES.EVENT);
    });
    return ability;
  },
  [ROLES.MEMBERSHIP_ADMIN]: () => {
    const ability = AbilityBuilder.define((can) => {
      can(
        [ACTION_TYPES.READ, ACTION_TYPES.CREATE, ACTION_TYPES.UPDATE],
        ABILITY_TYPES.EVENT,
      );
    });
    return ability;
  },
  [ROLES.SALES_REP]: () => {
    const ability = AbilityBuilder.define((can) => {
      can([ACTION_TYPES.READ], ABILITY_TYPES.EVENT);
    });
    return ability;
  },
};

export default function event(role = ROLES.USER) {
  const fn = abilitiesMap[role];
  return fn();
}
